import { useRef, useState } from "react";
import eFormsApi from "api/eFormsApi";
import { Button, Dialog, SuccessModal } from "packages/ui";

type Props = {
    formId: number;
    show: boolean;
    onDuplicating?: () => void;
    onDuplicated: () => void;
    onError?: (error: string) => void;
    onCancel: () => void;
    onDuplicateSuccessModalClose: (formId: number) => void;
}

const DuplicateEFormDialogs = ({ formId, show, onDuplicating, onDuplicated, onError, onCancel, onDuplicateSuccessModalClose }: Props) => {
    const [duplicating, setDuplicating] = useState(false);
    const [showDuplicateSuccessModal, setShowDuplicateSuccessModal] = useState(false);
    const duplicatedFormId = useRef<number>(0);

    const handleDuplicate = async () => {
        onDuplicating && onDuplicating();

        try {
            setDuplicating(true);
            const response = await eFormsApi.duplicateEForm(Number(formId));
            if (response.data.data) {
                duplicatedFormId.current = response.data.data;
            }
            setDuplicating(false);

            if (response.status === 200) {
                setShowDuplicateSuccessModal(true);
            } else {
                onError && onError("An error occurred while duplicating the eForm.");
            }
        } catch {
            onError && onError("An error occurred while duplicating the eForm.");
        }

        onDuplicated();
    };

    const handleDeleteSuccessModalClose = () => {
        setShowDuplicateSuccessModal(false);
        onDuplicateSuccessModalClose(duplicatedFormId.current);
    };

    return <>
        <Dialog
            show={show}
            title="Duplicate eForm"
            footerButtons={<>
                <Button onClick={handleDuplicate} disabled={duplicating} primary>Duplicate form</Button>
                <Button onClick={onCancel} disabled={duplicating}>Cancel</Button>
            </>}
        >
            Are you sure you want to duplicate the current form?
            <ul>
                <li>Please click on the Duplicate form button to duplicate the form.</li>
                <li>Click on the Cancel if you do not wish to proceed.</li>
            </ul>
        </Dialog>
        <SuccessModal show={showDuplicateSuccessModal} onClose={handleDeleteSuccessModalClose} title="eForm duplicated successfully!">
            You will now be redirected to the duplicated eForm. Please wait...
        </SuccessModal>
    </>
};

export default DuplicateEFormDialogs;
