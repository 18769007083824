import React from "react";
import { PaymentPlanDetailsModel, PaymentPlanOptionModelIntervalEnum } from "@premier/webapi-client";
import { PaddedContainer, DescriptionList } from "@premier/ui";
// @ts-ignore
import labels from "constants/labels";
import currencyUtil from "@premier/utils/currency";

interface PaymentPlanSummaryTableProps {
    paymentPlanDetails: PaymentPlanDetailsModel | null | undefined;
    crn1: string | undefined;
    crn2: string | undefined;
    crn3: string | undefined;
    crn1Name: string | undefined;
    crn2Name: string | undefined;
    crn3Name: string | undefined;
}

type CrnDetails = {
    name: string;
    value: string | undefined;
}

const PaymentPlanSummaryTable: React.FC<PaymentPlanSummaryTableProps> = ({ paymentPlanDetails, crn1, crn2, crn3, crn1Name, crn2Name, crn3Name }) => {
    const frequencyGetter = (frequency?: number, interval?: PaymentPlanOptionModelIntervalEnum) => {
        if (!frequency) {
            return "Once";
        } else {
            return `Recur every ${frequency} ${interval?.toLowerCase() ?? "period"}${frequency > 1 ? "s" : ""}`;
        }
    };

    const getCrnDetails = () => {
        const details: CrnDetails[] = [];

        if (crn1Name || crn1) {
            details.push({ name: crn1Name ?? labels.reference1, value: crn1 });
        }

        if (crn2Name || crn2) {
            details.push({ name: crn2Name ?? labels.reference2, value: crn2 });
        }

        if (crn3Name || crn3) {
            details.push({ name: crn3Name ?? labels.reference3, value: crn3 });
        }

        return details;
    };

    return (
        <PaddedContainer
            className="payment-plan-summary"
            title="Payment plan summary"
        >
            {paymentPlanDetails && <DescriptionList items={[
                { name: labels.paymentPlanOption, value: paymentPlanDetails.paymentPlanCommitment?.paymentPlanOptionName },
                { name: labels.frequency, value: frequencyGetter(paymentPlanDetails.paymentPlanCommitment?.frequency, paymentPlanDetails.paymentPlanCommitment?.interval) },
                { name: labels.totalAmountToPay, value: paymentPlanDetails.totalAmountToPay ? currencyUtil.convertToDisplayString(paymentPlanDetails.totalAmountToPay) : null },
                ...getCrnDetails()
            ]} />}
        </PaddedContainer>
    );
};

export default PaymentPlanSummaryTable;