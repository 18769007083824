export enum ControlCategory {
    Basic = "basic",
    Customer = "customer",
    Payment = "payment"
}

export enum ControlType {
    Address = "control_address",
    Amount = "control_amount",
    Checkbox = "control_checkbox",
    Crn1 = "control_crn1",
    Crn2 = "control_crn2",
    Crn3 = "control_crn3",
    DateTime = "control_datetime",
    DropDown = "control_dropdown",
    Email = "control_email",
    Heading = "control_heading",
    LongAnswer = "control_longanswer",
    Name = "control_name",
    Number = "control_number",
    PhoneNumber = "control_phonenumber",
    Radio = "control_radio",
    ShortAnswer = "control_shortanswer",
    Submit = "control_submit",
    Text = "control_text"
}

export enum ControlProperty {
    AllowOtherOption = "allowOtherOption",
    AllowTime = "allowTime",
    AreaPrefilledText = "areaPrefilledText",
    ButtonAlign = "buttonAlign",
    ButtonColor = "buttonColor",
    ButtonText = "buttonText",
    ClearForm = "clearForm",
    Country = "country",
    DateFormat = "dateFormat",
    Description = "description",
    Display = "display",
    Encrypt = "encrypt",
    Encrypted = "encrypted",
    FirstPrefilledText = "firstPrefilledText",
    FontColor = "fontColor",
    FontColour = "fontColour",
    FontFamily = "fontFamily",
    FontSize = "fontSize",
    FullName = "fullName",
    Heading = "heading",
    HoverText = "hoverText",
    LabelAlign = "labelAlign",
    LastPrefilledText = "lastPrefilledText",
    Line1PrefilledText = "line1PrefilledText",
    Line2PrefilledText = "line2PrefilledText",
    Maximum = "maximum",
    MaximumCharacters = "maximumCharacters",
    MaximumType = "maximumType",
    MiddleName = "middleName",
    MiddlePrefilledText = "middlePrefilledText",
    Minimum = "minimum",
    OnSubmitGoTo = "onSubmitGoTo",
    Options = "options",
    PhonePrefilledText = "phonePrefilledText",
    PlaceholderText = "placeholderText",
    PostcodePrefilledText = "postcodePrefilledText",
    PrefilledText = "prefilledText",
    PrintForm = "printForm",
    Required = "required",
    Rows = "rows",
    Selected = "selected",
    ShowBillerCode = "showBillerCode",
    ShowCRN1 = "showCRN1",
    ShowCRN2 = "showCRN2",
    ShowCRN3 = "showCRN3",
    Special = "special",
    SpreadColumns = "spreadColumns",
    StatePrefilledText = "statePrefilledText",
    SubHeading = "subHeading",
    SubLabel = "subLabel",
    SuburbPrefilledText = "suburbPrefilledText",
    Text = "text",
    TimeFormat = "timeFormat",
    Title = "title",
    TitlePrefilledText = "titlePrefilledText",
    Type = "type",
    ValidationFormat = "validationFormat"
}

export enum FontFamily {
    Arial = "Arial",
    ArialBlack = "Arial Black",
    ComicSansMS = "Comic Sans MS",
    CourierNew = "Courier New",
    Narrow = "Narrow",
    Garamond = "Garamond",
    Georgia = "Georgia",
    Helvetica = "Helvetica",
    Impact = "Impact",
    SansSerif = "Sans Serif",
    Serif = "Serif",
    Tahoma = "Tahoma",
    TrebuchetMS = "Trebuchet MS",
    Verdana = "Verdana"
}

export enum ValidationFormat {
    None = "none",
    Alphabetic = "alphabetic",
    AlphaNumeric = "alphanumeric",
    Numeric = "numeric",
    Email = "email"
}

export enum Align {
    Left = "left",
    Top = "top",
    Right = "right"
}

export enum HeaderAlign {
    Left = "left",
    Center = "center",
    Right = "right"
}

export enum Special {
    None = "none",
    Countries = "countries",
    Last100Years = "last100years",
    Gender = "gender",
    Days = "days",
    Months = "months",
    TimeZones = "timezones"
}

export enum DateFormat {
    DayMonthYear = "ddmmyyyy",
    MonthDayYear = "mmddyyyy"
}

export enum TimeFormat {
    Hour24 = "24hour",
    AmPm = "ampm"
}

export enum MaximumType {
    NoLimit = "nolimit",
    Words = "words",
    Letters = "letters"
}

export enum HppPreviewMode {
    Content,
    Style
}

export enum SubmitType {
    CreditCard = "creditcard",
    RegistrationPage = "dataVault",
    SuccessScreen = "successScreen",
    CustomUrl = "customUrl"
}
