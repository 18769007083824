import { useState, ReactNode } from "react";
import _ from "lodash";

import { DatePicker } from "@premier/ui";
import FormGroup from "./FormGroup";

import { DateLimit, DateOption } from "flatpickr/dist/types/options";
import withField from "./withField";
import { FormProps } from "./FormProps";
import { GroupProps } from "./GroupProps";

import "./DatePickerField.scss";

type Props = {
    name: string;
    label: string | ReactNode;
    help: string | ReactNode;
    onChange: (value: string) => void;
    onBlur: () => void;

    // Flatpickr
    mode: "single" | "range";
    enableTime?: boolean;
    formProps: FormProps;
    groupProps: GroupProps;
    /** Date format from the server (in .NET). Defaults to merchant's date format. */
    dotnetDateFormat?: string;
    /** Time format from the server (in .NET). Defaults to merchant's time format. */
    dotnetTimeFormat?: string;
    /** Disable some dates. See the docs (https://flatpickr.js.org/examples/#disabling-specific-dates) */
    disable?: DateLimit<DateOption>[];
    /** The earliest selectable date. Note that dynamic time might cause infinite refreshes */
    minDate?: DateOption;
    /** The latest selectable date. Note that dynamic time might cause infinite refreshes */
    maxDate?: DateOption;
    /** Set to true for automatic placeholder (from merchant settings), otherwise specify the placeholder manually as a string */
    placeholder?: string;

    // Deprecated
    /** The TZ timezone to show date/time in. By default, it shows them in local time. */
    /**
     * @deprecated Deprecated because it created more confusions
     */
    timeZone: string;
    /**
     * @deprecated Please use validate().required() in the Form initialValidation
     */
    mandatory: string;
};

const DatePickerField = ({
    formProps,
    groupProps,
    onBlur,
    ...otherProps
}: Props) => {

    const [id] = useState(_.uniqueId(`${formProps.name}_`));

    function handleChange(dates: Date[]) {
        formProps.setValue(dates);
    }

    return (
        <FormGroup fieldId={id} {...groupProps}>
            <div className="date-picker-wrapper">
                <DatePicker
                    value={formProps.value}
                    error={formProps.error}
                    aria-labelledby={groupProps.label && `${id}_label`}
                    aria-required={groupProps.mandatory}
                    aria-invalid={!!formProps.error}
                    aria-describedby={formProps.error && `${id}_error`}
                    {...otherProps}
                    onChange={handleChange}
                    onClose={onBlur}
                />
            </div>
        </FormGroup>
    );
};

export default withField(DatePickerField);