import { Fragment } from "react/jsx-runtime";
import { ControlType, HppPreviewMode } from "../enums";
import { Address, Amount, Checkboxes, Email, ShortAnswer, LongAnswer, Number, Text, PhoneNumber, DropDown, DateTime, Heading, Crn, Submit } from "components/EForms";
import { EditFormStyleFormValues } from "./EditFormStyle";
import Name from "../templates/Name";
import RadioButtons from "../templates/RadioButtons";
import { generateNewControlId } from "../helpers";
import { Control } from "../types";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import "./EFormsDesigner.scss";
import "./EFormsView.scss";

type Props = {
    controls: Control[] | undefined;
    style: EditFormStyleFormValues;
    mode: HppPreviewMode;
    focusedId?: string;
    onControlEditClicked?: (id: string) => void;
    onControlDuplicateClicked?: (id: string) => void;
    onControlRemoveClicked?: (id: string) => void;
    onControlSelectionChanged?: (id: string, selected: string | string[]) => void;
};

const EFormsHppPreview = ({ controls, style, mode, focusedId, onControlEditClicked, onControlDuplicateClicked, onControlRemoveClicked, onControlSelectionChanged }: Props) => {
    const getElements = () => {
        return controls?.map((control, index) => (
            <Fragment key={`control_fragment_${index}`}>
                {renderComponentByControlType(control)}
            </Fragment>)
        );
    };

    const renderComponentByControlType = (control: Control) => {
        const name = generateNewControlId();
        let { id, controlType, attributes } = control;

        if (!attributes) {
            attributes = {};
        }

        switch (controlType) {
            case ControlType.Address:
                return <Address id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Amount:
                return <Amount id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onRemoveClicked={onControlRemoveClicked} hideDuplicate />;
            case ControlType.Checkbox:
                return <Checkboxes id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} onControlSelectionChanged={onControlSelectionChanged} />;
            case ControlType.Crn1:
            case ControlType.Crn2:
            case ControlType.Crn3:
                return <Crn id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onRemoveClicked={onControlRemoveClicked} hideDuplicate />;
            case ControlType.DateTime:
                return <DateTime id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.DropDown:
                return <DropDown id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} onControlSelectionChanged={onControlSelectionChanged} />;
            case ControlType.Email:
                return <Email id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Heading:
                return <Heading id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.LongAnswer:
                return <LongAnswer id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Name:
                return <Name id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Number:
                return <Number id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.PhoneNumber:
                return <PhoneNumber id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Radio:
                return <RadioButtons id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} onControlSelectionChanged={onControlSelectionChanged} />;
            case ControlType.ShortAnswer:
                return <ShortAnswer id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            case ControlType.Submit:
                return <Submit id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} dragDropDisable hideDuplicate hideRemove />;
            case ControlType.Text:
                return <Text id={id} name={name} attributes={attributes} order={control.order} mode={mode} focusedId={focusedId} onEditClicked={onControlEditClicked} onDuplicateClicked={onControlDuplicateClicked} onRemoveClicked={onControlRemoveClicked} />;
            default:
                return null;
        }
    };

    // Drop event handler
    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (destination && controls) {
            // Grab the item that has been drag and dropped
            const itemToMove = controls[source.index];

            // Can't find it? Do nothing.
            if (!itemToMove) {
                return;
            }

            // Remove the item that was drag and dropped.
            controls.splice(source.index, 1);

            // Put the item that was drag and dropped into it's new position.
            controls.splice(destination.index, 0, itemToMove);

            // Assign new order to item.
            controls.forEach((item, index) => {
                item.order = index;
            });
        }
    };

    const backgroundColor = style?.backgroundColor ?? "#FFFFFF";

    return (
        <div className="eforms-content-container">
            {mode === HppPreviewMode.Style && <>
                <h3>Preview for mobile</h3>
                To preview the desktop version, please save and then use the preview button below.
            </>}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="eforms-droppable-container">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className="eforms-content"
                            style={{ backgroundColor }}
                            {...provided.droppableProps}
                        >
                            {getElements()}
                        </div>)}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default EFormsHppPreview;
