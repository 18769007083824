import { Form, validate } from "@premier/form";
// @ts-ignore
import labels from "constants/labels";

import "./NewPasswordFields.scss";
import PasswordFieldShowHide from "packages/form/fields/PasswordFieldShowHide";

type Props = {
    changePasswordRequired?: boolean;
}

// Password rules are in: NewPasswordFields.js, ChangePasswordPage.js, ProfilePage.js

/** Sub-form used for inputting a new password. Has password validation within this sub-form */
const NewPasswordFields = ({
    changePasswordRequired, //validation logic
    ...otherProps //data
}: Props) => {

    return (
        <>
            <div className="password-rules">
                <p>
                    <b>Your password must:</b>
                </p>
                <ul>
                    <li>be at least 10 characters long</li>
                    <li>contain both letters and numbers</li>
                    <li>contain at least one uppercase and one lowercase letter</li>
                    <li>
                        contain at least one special character such as ! &ldquo; # $ % &amp; &lsquo; ( ) * + , - . / : ;
                        = ? @ [ \ ] ^ &#x60; _ {"{ | }"} ~
                    </li>
                </ul>
                <p>
                    <b>Your password must not:</b>
                </p>
                <ul>
                    <li>be the same as one used previously</li>
                    <li>contain the symbols &lt; &gt;</li>
                </ul>
            </div>

            <Form
                name="newPasswordForm"
                initialValues={{
                    newPassword: "",
                    confirmPassword: "",
                }}
                initialValidation={{
                    newPassword: validate().if(
                        (value, values) => value || changePasswordRequired,
                        validate()
                            .required()
                            .minLength(10, "Must be at least 10 characters")
                            .matches(/^[^<>]*$/, "Must not contain the symbols < >")
                            .matches(/\d/, "Must contain a number")
                            .matches(/[A-Z]/, "Must contain an uppercase")
                            .matches(/[a-z]/, "Must contain a lowercase")
                            .matches(/[^a-zA-Z0-9]/, "Must contain a special character")
                            
                    ),
                    confirmPassword: validate()
                        .required()
                        .when((value, values) => {
                            if (values.newPassword || value) {
                                return values.newPassword === value;
                            }
                            return true;
                        }, "Passwords don't match")
                }}
            >
                <PasswordFieldShowHide
                    name="newPassword"
                    label={labels.newPassword}
                    {...otherProps} 
                />
                
                <PasswordFieldShowHide
                    name="confirmPassword"
                    label={labels.confirmPassword}
                    {...otherProps} 
                />
            </Form>
        </>
    );
};

export default NewPasswordFields;
