import { useMemo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import countryUtil from "@premier/utils/country";
import { Divider, Link, ButtonContainer, Button } from "@premier/ui";
import { Form, FormErrorList, SubmitButton, CheckboxField } from "@premier/form";
import { MerchantBillerDetailsForm, BillerCrnForm } from "components/Transactions";
import { PaymentMethodDetailsForms } from "components/DataVault";
import { PlatformRoutesConfiguration } from "components/Routing";
// @ts-ignore
import { PaymentMethodDetailsTabKeys } from "constants/billpay";
// @ts-ignore
import errorMaps from "constants/errorMaps";
import { Merchant, Phone } from "models";
import { FieldError } from "api/mapErrors";
import { RootState } from "store/store";
import { Token } from "models/Request";
import { Biller } from "packages/webapi-client";
import { useApiCall } from "components/Common";
import { customerApi } from "api";
import { Customer } from "packages/utils/models";

type Props = {
    /** Name of the form */
    name?: string;
    token: Token;
    parentBiller?: Biller;
    merchant: Merchant;
    hideBillerSelector?: boolean;
    /** Text on the cancel button, defaults to Cancel */
    cancelButtonText?: string;
    /** Text on the submit button, defaults to Save */
    submitButtonText?: string;
    errors: FieldError[];
    onCancel?: () => void;
    onSubmit: (values: FormValues) => void;
}

type FormValues = {
    paymentMethodDetailsTab: string;
    tokenDetailsBillerCodeForm: BillerCodeForm;
    customerIdentified: boolean;
    paymentRequest: PaymentRequest;
}

type BillerCodeForm = {
    merchantNumber: string | undefined;
    billerCode: string;
    billerCrnList: CrnList;
}

type PaymentRequest = {
    email?: string;
    mobile: Phone;
}

type CrnList = {
    crn1: string;
    crn2: string;
    crn3: string;
}

/** A Form for creating/editing a Payment Method */
const TokenDetailsForm = ({
    name, token, parentBiller, submitButtonText = "Save", cancelButtonText = "Cancel", errors, //data
    merchant, //state values
    hideBillerSelector, //logic render
    onSubmit, onCancel, //functions
}: Props) => {
    const [formContext, setFormContext] = useState<any>();

    // Get the customer on the token (if any)
    const [customer] = useApiCall<Customer>(async () => {
        if (token?.customerV2Id) {
            const dto = await customerApi.getCustomer(token.customerV2Id);
            return { data: { data: dto }, status: 200 };
        }
    }, [token]);

    // Handle setting the email and mobile for the customer fetched above
    useEffect(() => {
        // Grab whichever phone number is first
        const customerFirstPhoneNumber = customer?.phoneNumbers && customer?.phoneNumbers.length >= 1 ? customer?.phoneNumbers[0] : null;

        if (customer?.emailAddress) {
            formContext?.setValue("paymentRequest.email", customer.emailAddress);
        }

        if (customerFirstPhoneNumber) {
            formContext?.setValue("paymentRequest.mobile", customerFirstPhoneNumber.phoneNumber);
        }
    }, [customer]);

    const contextRef = useRef<any>();

    useEffect(() => {
        if (!formContext || !_.isEqual(contextRef.current?.values, formContext.values)) {
            setFormContext(contextRef.current);
        }
    }, [formContext, contextRef.current?.values]);

    function getInitialValues(): FormValues {
        return {
            paymentMethodDetailsTab: PaymentMethodDetailsTabKeys.CREATE,
            tokenDetailsBillerCodeForm: {
                merchantNumber: _.get(token, "childMerchantNumber", _.get(parentBiller, "merchantNumber", merchant.merchantNumber)),
                billerCode: _.get(parentBiller, "billerCode", ""),
                billerCrnList: {
                    crn1: _.get(token, "crn1", ""),
                    crn2: _.get(token, "crn2", ""),
                    crn3: _.get(token, "crn3", "")
                },
            },
            customerIdentified: false,
            paymentRequest: {
                mobile: { phoneNumber: "", iddCode: countryUtil.getIddCode(merchant.countryCode) }
            }
        };
    }

    function handleSubmit(values: FormValues, context: any) {
        if (!values.customerIdentified) {
            context.setError("customerIdentified", "Please check the box if you want to proceed");
            return;
        }

        onSubmit(values);
    }

    function renderForm(context: any) {
        // We need to do this so that this component always has the most up to date formContext
        if (!formContext || !_.isEqual(context.values, formContext.values)) {
            contextRef.current = context;
        }

        return <>
            <h2>Billing details</h2>

            {(hideBillerSelector && parentBiller &&
                <Form name="tokenDetailsBillerCodeForm">
                    <BillerCrnForm biller={parentBiller} />
                </Form>
            )}

            {(!hideBillerSelector && !parentBiller &&
                <MerchantBillerDetailsForm
                    hideMerchantField={!!token}
                    name="tokenDetailsBillerCodeForm"
                    tokenPage
                />
            )}

            <h2>Payment method details</h2>

            <PaymentMethodDetailsForms
                token={token}
                merchantNumber={context.getValue("tokenDetailsBillerCodeForm.merchantNumber") || merchant.merchantNumber}
                billerCode={context.getValue("tokenDetailsBillerCodeForm.billerCode") || _.get(parentBiller, "billerCode")}
                merchant={merchant}
            />

            <Divider />

            <CheckboxField mandatory name="customerIdentified" labelText="Customer confirmation">
                I have identified the customer and advised that the conditions of the Direct Debit will be made available within 7 days
            </CheckboxField>
            <Divider />

            <FormErrorList/>

            <ButtonContainer>
                <SubmitButton>{context.getValue("paymentMethodDetailsTab") === PaymentMethodDetailsTabKeys.CREATE ? submitButtonText : "Send"}</SubmitButton>
                {(onCancel &&
                    <Button
                        default
                        onClick={() => {onCancel();}}
                    >
                        {cancelButtonText}
                    </Button>
                )}
                {(!onCancel &&
                    <Link button to={_.get(token, "dataVaultId") ? PlatformRoutesConfiguration.tokenRoute?.viewToken.generatePath(token.dataVaultId) : PlatformRoutesConfiguration.tokenRoute?.manageTokens.generatePath()} >
                        {cancelButtonText}
                    </Link>
                )}
            </ButtonContainer>
        </>;
    }

    function mapErrors(errors: FieldError[]) {
        return errors?.map(e => ({
            ...e,
            field: e.field ? mapErrorsFromDto(e.field) : ""
        }));
    }

    function mapErrorsFromDto(parameter: string) {
        switch (parameter) {
            case "billerCodeForm.billerCrnList.crn1":
                return "tokenDetailsBillerCodeForm.billerCrnList.crn1";
            case "billerCodeForm.billerCrnList.crn2":
                return "tokenDetailsBillerCodeForm.billerCrnList.crn2";
            case "billerCodeForm.billerCrnList.crn3":
                return "tokenDetailsBillerCodeForm.billerCrnList.crn3";
            default:
                return parameter;
        }
    }

    return (
        <Form
            errors={mapErrors(errors)}
            errorMaps={errorMaps}
            name={name || "TokenDetailsForm"}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            forceParent
            render={renderForm}
        />
    );
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.activeBillers
    };
}


export default connect(mapStateToProps)(TokenDetailsForm);
