import { PageHeader } from "components/Common";
import { PageSection, Link, LoadingIndicator, SuccessModal } from "@premier/ui";
import { Field, Form, Formik } from "formik";
import React, { useState, useRef } from "react";
import { PlatformRoutesConfiguration } from "components/Routing";
import apiIntegrationApi from 'api/apiIntegrationApi';
import dateUtil from '@premier/utils/date'
import { useApiCall, APICallRequestState } from "components/Common";
import * as Yup from 'yup';
import { Input, FormGroup, FormikDatePicker, FormikFileSelector } from "@linkly/formik-ui";
import { Button, ErrorText, Row, SingleColumnFormContainer } from "@premier/ui";
import labels from "platforms/current/constants/labels";
import moment from "moment";

type Props = {
};

const PrivateKeySettingsPage: React.FC<Props> = () => {
    const formRef = useRef<any>();
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);
    const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false);
    const [PrivateKeySetting, PrivateKeySettingStatus] = useApiCall(() => {
        return apiIntegrationApi.getPrivateKeySetting();
    }, []);
    const currentDate = moment().startOf('day').toDate();

    const handleSubmit = async (formValues: any) => {
        try {
            await apiIntegrationApi.UpdateApplePayPrivateKeyWithFile({
                merchantId: formValues.merchantId,
                expiryDate: formValues.expiryDate ? dateUtil.convertDateRangeToApiDateTimeRange([formValues.expiryDate])?.to : undefined,
            }, formValues.file?.newFiles);
            setSubmitErrors([]);
            setShowUpdateSuccessModal(true);
        }
        catch (err: any) {
            if (err?.length) {
                setSubmitErrors(err.map((r: any) => r.message));
            } else {
                setSubmitErrors([labels.unknownErrorMessage]);
            }
        }
    };

    const PrivateKeySettingsValidationSchema = Yup.object().shape({
        merchantId: Yup.string()
            .required('Apple Pay merchant ID is required'),
        file: Yup.mixed()
            .test(
                'fileExists',
                'Private key file is required',
                value => value !== undefined && value !== null && Object.keys(value).length > 0
            )
            .test(
                'fileFormat',
                'Unsupported file format. Please upload a .pk8 file.',
                value => {
                    if (typeof value === 'string' && value.endsWith('.pk8')) {
                        return true;
                    }
                    else if (value && value.newFiles && value.newFiles[0].name?.endsWith('.pk8')) {
                        return true;
                    }
                    return false;
                }
            ),
        expiryDate: Yup.mixed()
            .required('Expiry date is required'),
    });

    return (
        <PageSection>
            <PageHeader backButton title='Edit private key' />
            {PrivateKeySettingStatus === APICallRequestState.SUCCESSFUL ?
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        merchantId: PrivateKeySetting?.merchantId,
                        file: null,
                        expiryDate: PrivateKeySetting?.expiryDate && new Date(PrivateKeySetting.expiryDate) >= currentDate
                            ? new Date(PrivateKeySetting.expiryDate)
                            : null
                    }}
                    validationSchema={PrivateKeySettingsValidationSchema}
                    onSubmit={async (formValues, { setSubmitting }) => {
                        await handleSubmit(formValues);
                        setSubmitting(false);
                    }}
                >
                    {() => (
                        <Form>
                            <SingleColumnFormContainer>
                                <Row>
                                    <FormGroup label="Apple Pay merchant ID" className="col-sm-12" name="merchantId">
                                        <Field as={Input} name="merchantId" />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup label="Private key file (pk8 file only)" className="col-sm-12" name="file">
                                        <Field as={FormikFileSelector} name="file" deleteButtonText={'delete file'} />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup label="Expiry date" className="col-sm-12" name="expiryDate">
                                        <Field as={FormikDatePicker} name='expiryDate' minDate={currentDate} />
                                    </FormGroup>
                                </Row>
                                <div className="dialog-actions">
                                    <Button type="submit" primary>
                                        Save
                                    </Button>
                                    <Link button to={PlatformRoutesConfiguration.apiIntegrationRoute?.root.generatePath()}>Cancel</Link>
                                </div>
                                {submitErrors.length
                                    ? submitErrors.map((errorMessage, i) => <ErrorText key={i}>{errorMessage}</ErrorText>)
                                    : null}
                            </SingleColumnFormContainer>
                        </Form>
                    )}
                </Formik>
                : <LoadingIndicator />}
            <SuccessModal
                show={showUpdateSuccessModal}
                onClose={() => setShowUpdateSuccessModal(false)}
            >
                Private key saved successfully
            </SuccessModal>
        </PageSection>
    );
};

export default PrivateKeySettingsPage;
