import { useState } from "react";
import eFormsApi from "api/eFormsApi";
import { Button, Dialog, Icon, SuccessModal } from "packages/ui";

type Props = {
    formId: number;
    show: boolean;
    onDeleting?: () => void;
    onDeleted: () => void;
    onError?: (error: string) => void;
    onCancel: () => void;
    onDeleteSuccessModalClose: () => void;
}

const DeleteEFormDialogs = ({ formId, show, onDeleting, onDeleted, onError, onCancel, onDeleteSuccessModalClose }: Props) => {
    const [deleting, setDeleting] = useState(false);
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

    const handleDelete = async () => {
        onDeleting && onDeleting();

        try {
            setDeleting(true);
            const response = await eFormsApi.deleteEForm(Number(formId));
            setDeleting(false);

            if (response.status === 200) {
                setShowDeleteSuccessModal(true);
            } else {
                onError && onError("An error occurred while deleting the eForm.");
            }
        } catch {
            onError && onError("An error occurred while deleting the eForm.");
        }

        onDeleted();
    };

    const handleDeleteSuccessModalClose = () => {
        setShowDeleteSuccessModal(false);
        onDeleteSuccessModalClose();
    };

    return <>
        <Dialog
            show={show}
            icon={<Icon alert />}
            title="Delete eForm?"
            footerButtons={<>
                <Button onClick={handleDelete} disabled={deleting}>Delete</Button>
                <Button onClick={onCancel} disabled={deleting}>Cancel</Button>
            </>}
            onClose={onCancel}
            closeButton
        >
            Submissions for this eForm are still accessible from the reports after this eForm is deleted.
        </Dialog>
        <SuccessModal
            show={showDeleteSuccessModal}
            title="eForm deleted successfully!"
            onClose={handleDeleteSuccessModalClose}
        />
    </>
};

export default DeleteEFormDialogs;
