import { useMemo, useRef } from "react";
import { Editor as TinyMceEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";

import "./RichTextEditor.scss";

type Props = {
    disabled?: boolean;
    hasError?: boolean;
    initialValue?: string;
    value?: string;
    onChange?: (value: string) => void;
    onDirty?: () => void;
    onSelectionChange?: (selection: RichTextEditorSelection) => void;
    toolbar?: string;
    plugins?: string;
};

export type RichTextEditorSelection = {
    setContent: (content: string) => void;
    editor: RichTextEditorInternal;
}

export type RichTextEditorInternal = {
    focus(skipFocus?: boolean): void;
    setDirty: (state: boolean) => void;
};

const RichTextEditor = ({ disabled, hasError, initialValue, value, onChange, onDirty, onSelectionChange, toolbar, plugins }: Props) => {
    const editorRef = useRef<TinyMceEditor>();

    useMemo(() => {
        if (hasError) {
            editorRef.current?.container?.classList.add("has-error");
        } else {
            editorRef.current?.container?.classList.remove("has-error");
        }
    }, [hasError]);

    const handleSetup = (editor: TinyMceEditor) => {
        // Focusin and focusout events are used to add a class to the tinymce editor when it is focused
        // to give it a blue border
        editor.on("focusin", () => {
            editorRef.current?.container?.classList.add("tinymce-editor-focused");
        });
        editor.on("focusout", () => {
            editorRef.current?.container?.classList.remove("tinymce-editor-focused");
        });

        // This is used to remove the "mail merge" elements when they are clicked
        editor.on("mousedown", (e) => {
            if (e.target.classList.contains("emailph")) {
                editor.selection.select(e.target);
                editor.selection.setContent("")
                editor.setDirty(true);
            }
        });

        // This is used to show an error message when editing an existing link with an invalid href
        editor.on("SetAttrib", (e) => {
            if (e.attrName === 'href') {
                if (!e?.attrValue) {
                    showErrorMessage(editor, e);
                }
            }
        });

        // This is used to show an error message when creating a new link with an invalid href through the insert link command
        editor.on("BeforeExecCommand", (e) => {
            if (e.command === 'mceInsertLink') {
                if (!e.value?.href) {
                    showErrorMessage(editor, e);
                }
            }

            if (e.command === 'mceInsertContent' && e.value) {
                const scriptUrlPattern = /<a\s+href=["']\s*["'][^>]*>.*<\/a>/i;
                if (scriptUrlPattern.test(e.value)) {
                    showErrorMessage(editor, e,);
                }
            }
        });
    };

    const showErrorMessage = (editor: TinyMceEditor, e: any) => {
        editor.notificationManager.open({
            text: "Invalid contents.",
            type: 'error',
            timeout: 3000
        });
        e.preventDefault();
    };

    return <Editor
        disabled={disabled}
        initialValue={initialValue}
        value={value}
        init={{
            menubar: false,
            statusbar: false,
            content_css: `${process.env.PUBLIC_URL}/css/tinymce.css`,
            setup: handleSetup,
            toolbar: toolbar ?? false,
            plugins: plugins,
            toolbar_mode: 'wrap',  // Ensures the toolbar wraps instead of collapsing
            relative_urls: false,
            remove_script_host: false,
            entity_encoding: 'raw', // Prevent tinymce from encoding entities
        }}
        onDirty={() => onDirty && onDirty()}
        onEditorChange={(value) => onChange && onChange(value)}
        onInit={(e, editor) => editorRef.current = editor}
        onSelectionChange={(e, editor) => onSelectionChange && onSelectionChange(editor.selection)}
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
    />
};

export default RichTextEditor;
