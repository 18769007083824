import { Biller } from "models/Biller";
import { PaymentReceipt } from "models/PaymentReceipt";
import { Modal } from "@premier/ui";
import { SendReceiptForm } from "components/Transactions";

import "./SendReceiptModal.scss";

type Props = {
    showModal: boolean;
    closeModal: () => void;

    transaction?: PaymentReceipt;
    biller?: Biller | {billerCode: string | undefined} | null
};

const SendReceiptModal = ({
    showModal,
    transaction, biller, //data
    closeModal //function
}: Props) => {

    return (<>
        <Modal show={showModal} onHide={closeModal} className="transaction-refund-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Send receipt
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SendReceiptForm receipt={transaction} biller={biller}/>
            </Modal.Body>
        </Modal>
    </>);

};

export default SendReceiptModal;