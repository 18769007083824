import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

// @ts-ignore
import labels from "constants/labels";
import currencyUtil from "@premier/utils/currency";
import errorUtil from "@premier/utils/error";

import { validate, Form, CurrencyField, TextareaField, SubmitButton } from "@premier/form";
import { Modal, Icon, LoadingIndicator, DescriptionList, Button } from "@premier/ui";
import { withError, FormError } from "components/Common";
import { RefundResultModal } from "components/Transactions";

import * as paymentActions from "components/Transactions/_actions/paymentActions";
import { Transaction } from "models/Transaction";
import { Biller } from "models/Biller";
import { PaymentReceipt } from "models/PaymentReceipt";
import { RootState } from "store/store";
import { LabelledApiError } from "models";
import { getCrnDetails } from "packages/utils/crnHelpers";

import "./RefundModal.scss";

type Props = {
    showModal: boolean;
    closeModal: (refresh: boolean) => void;
    transaction: Transaction;
    biller: Biller;
    refundReceipt: PaymentReceipt;
    isLoading: boolean;
    actions: any;
    errors: string[];
}

const RefundModal = ({
    showModal, closeModal, //logic render
    transaction, biller, //data
    refundReceipt, isLoading,  //state
    actions, errors
}: Props) => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const maxRefundAmountCents = (transaction.paymentProcessedTxnModel.totalAmount ?? 0) - (transaction.paymentSource?.refundedAmount ?? 0);
    const maxRefundAmountFormatted = currencyUtil.formatApiValue(maxRefundAmountCents, transaction.paymentProcessedTxnModel.currencyCode);
    const txnCurrency = currencyUtil.getCurrency(transaction.paymentProcessedTxnModel.currencyCode);
    const currencyAlphaCode = txnCurrency.alphaCode;
    const currencySymbol = currencyUtil.getSymbol(txnCurrency.code);

    function getAmount(amount: number | undefined) {
        return currencyUtil.formatApiValue(
            amount ?? 0,
            transaction.paymentProcessedTxnModel.currencyCode
        );
    }

    function handleModalClose(formContext?: any) {
        if (formContext) {
            formContext.resetForm();
        }

        closeModal(true);
    }

    function closeSuccessModal() {
        closeModal(true);
        setShowSuccessModal(false);
    }

    function handleSubmit(values: any, formContext: any) {
        const submitObject = {
            txnHistoryId: transaction.paymentProcessedTxnModel.txnHistoryId,
            childMerchantNumber: transaction.paymentSource?.merchantNumber,
            amount: currencyUtil.convertToApiValue(values.amount),
            refundReason: values.reason
        };

        actions.refundPayment(submitObject).then(() => {
            handleModalClose(formContext);
            setShowSuccessModal(true);
        });
    }

    // ---------- Render ----------

    const modalContent = !transaction || !transaction.paymentProcessedTxnModel.originalAmount
        ? <LoadingIndicator />
        : <Form
            initialValues={{
                amount: maxRefundAmountFormatted,
                reason: ""
            }}
            validationSchema={{
                amount: validate()
                    .required()
                    .when(x => currencyUtil.convertToApiValue(x)! > 0,
                        "Must be positive"
                    )
                    .when(x => currencyUtil.convertToApiValue(x)! <= maxRefundAmountCents,
                        `Must not exceed ${maxRefundAmountFormatted}`
                    )
            }}
            onSubmit={handleSubmit}
        >
            <Modal.Body>
                <DescriptionList
                    spaceBetween
                    hideBlankValue
                    divider
                    items={[
                        {
                            name: "Merchant number",
                            value: transaction.paymentSource?.merchantNumber
                        },
                        {
                            name: labels.billerName,
                            value: biller.billName
                        },
                        {
                            name: labels.billerCode,
                            value: biller.billerCode
                        },
                        ...getCrnDetails(biller, transaction.paymentProcessedTxnModel.crn1, transaction.paymentProcessedTxnModel.crn2, transaction.paymentProcessedTxnModel.crn3)
                    ]}
                />

                {transaction.paymentProcessedTxnModel.cardDetails && (
                    <DescriptionList
                        spaceBetween
                        hideBlankValue
                        divider
                        items={[
                            {
                                name: "Card number",
                                value: transaction.paymentProcessedTxnModel.cardDetails.cardNumber
                            },
                            {
                                name: `Amount (${currencyAlphaCode})`,
                                value: getAmount(transaction.paymentProcessedTxnModel.originalAmount)
                            },
                            {
                                name: `Surcharge amount (${currencyAlphaCode})`,
                                value: getAmount(transaction.paymentProcessedTxnModel.surchargeAmount)
                            },
                            {
                                name: `Total amount (${currencyAlphaCode})`,
                                value: getAmount(transaction.paymentProcessedTxnModel.totalAmount)
                            }
                        ]}
                    />
                )}

                <CurrencyField
                    name="amount"
                    label={`Refund amount (${currencyAlphaCode})`}
                    currencySymbol={currencySymbol}
                />

                <TextareaField name="reason" label="Refund reason" />

                <p style={{ margin: "2.5rem 0 0" }}>
                    <Icon alert inline /> Once a refund has been processed, it cannot be reversed
                </p>

                <FormError errors={errors} />
            </Modal.Body>

            <Modal.Footer buttons divider>
                <SubmitButton disabled={isLoading}>Refund payment</SubmitButton>
                <Button onClick={() => { handleModalClose(); }} >
                    Cancel
                </Button>
            </Modal.Footer>
        </Form>;

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} className="transaction-refund-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Process refund</Modal.Title>
                </Modal.Header>

                {modalContent}
            </Modal>

            <RefundResultModal
                showModal={showSuccessModal}
                closeModal={closeSuccessModal}
                receipt={refundReceipt}
                biller={biller}
            />
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        refundReceipt: state.transactions.payments.refundReceipt,
        isLoading: state.transactions.payments.isLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error: LabelledApiError) {
    const paramLabels = {
        Amount: "Amount"
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(RefundModal),
    mapStoreToErrors,
    mapErrorToString
);
