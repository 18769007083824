import { useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getMessagingSettings, updateMessagingSettings, clearUpdateMessagingSettings } from "components/Settings/_actions/messagingActions";
import { MessagingSettingsModel } from "@premier/webapi-client";
import { RootState } from "store/store";

const settingsSelector = (state : RootState) => state.settings.messagingSettings;
const updateSettingsSelector = (state : RootState) => state.settings.updateMessagingSettings;

export type MessageSettings = {
    isInitial: boolean;
    hasLoaded: boolean;
    hasFailed: boolean;
    hasUpdated: boolean;
    data: MessagingSettingsModel;
};

export const useMessagingSettings = () => {
    const { isInitial, hasLoaded, hasFailed, data: settings }: MessageSettings = useSelector(settingsSelector);
    const { isLoading: isUpdating, hasSucceeded: hasUpdated, errors } = useSelector(updateSettingsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isInitial) {
            dispatch(getMessagingSettings());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isInitial]);

    const updateSettings = async (values : MessagingSettingsModel) => {
        await dispatch(updateMessagingSettings(_.cloneDeep(values)));
        dispatch(getMessagingSettings());
    };

    const resetUpdateSettings = () => {
        dispatch(clearUpdateMessagingSettings());
    };

    return {
        hasLoaded,
        hasFailed,
        isUpdating,
        hasUpdated,
        errors,
        settings,
        updateSettings,
        resetUpdateSettings
    };
};