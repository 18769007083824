import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import dateUtil from '@premier/utils/date';
import fileUtil from '@premier/utils/file';
import * as billpayUtil from '@premier/utils/billpay';

import { PageSection, ResponsiveTable, DatePicker, DateTime, FormGroup, PaginationControl, Dropdown } from '@premier/ui';
import { PageHeader, FormError, withError, ProductTooltip } from 'components/Common';

import * as reportActions from 'components/Reports/_actions/reportsActions';
import labels from 'constants/labels';

import './CustomReports.scss'

const CustomReports = ({
    actions, //API actions
    reportsList, merchant, childMerchants, //state values
    isLoading, //logic render
    errors //form
}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage] = useState(10)
    const [reload, setReload] = useState(true);
    const [transactionDates, setTransactionDates] = useState([
        dateUtil.getMerchantNowAsLocal().add(-1,'month').add(1,'day').startOf('day').toDate(),
        dateUtil.getMerchantNowAsLocal().endOf('day').toDate()
    ])
    const [childMerchant, setChildMerchant] = useState(billpayUtil.getMerchantOptions(merchant)[1]);

    useEffect(() => {
        if(reload) {
            actions.getDailyReports(transactionDates, currentPage - 1, resultsPerPage, childMerchant.value);

            setReload(false);
        }
    }, [reload]);

    function exportItems(items) {
        var itemsToExport = items;

        itemsToExport.forEach(report => {
            actions.getDailyReport(report.fileName, report.childMerchantNumber).then(data => {
                fileUtil.download(fileUtil.decodeBase64Binary(data.fileData), data.fileName);
            })
        });
    }

    function pageChanged(newPage) {
        setCurrentPage(newPage);
        setReload(true);
    }

    function dateChanged(data) {
        if (data.length === 2) {
            setTransactionDates(data);
            setReload(true);
        }
    }

    function handleChildMerchantChanged(val) {
        setChildMerchant(val);
        setReload(true);
    }

    return (
        <PageSection className='custom-reports-wrapper'>
            <PageHeader title={<>{'Custom reports'}<ProductTooltip productTooltipModule={"CUSTOM_REPORTS"} /></>} />

            <div className='row'>
                <div className='col-lg-5'>
                    <FormGroup fieldId='dateRange' label='Date range'>
                        <DatePicker id='dateRange' mode='range' value={transactionDates} onChange={dateChanged} isStatic={false} />
                    </FormGroup>
                </div>
                <div className='col-lg-5'>
                    {childMerchants && childMerchants.length > 0 && (
                        <FormGroup fieldId='childMerchantNumber' label={labels.merchant}>
                            <Dropdown id='childMerchantNumber'
                                options={billpayUtil.getMerchantOptions(merchant)}
                                value={childMerchant}
                                onChange={handleChildMerchantChanged}
                            />
                        </FormGroup>
                    )}
                </div>
            </div>

            <FormError errors={errors} />

            <ResponsiveTable
                data={ _.get(reportsList, 'list', [])}
                selectable
                isLoading={isLoading}
                columns={[
                    {
                        label: 'Filename',
                        getter: r => r.fileName,
                    },
                    {
                        label: 'Created date',
                        getter: r => <DateTime utcValue={r.timeCreated}/>,
                    },
                ]}
                batchActions={[{
                    label: 'Export',
                    batchLabel: 'Export Selected',
                    handleClick: exportItems,
                    tableButtonProps: {
                        primary: true
                    },
                }]}
            />

            <PaginationControl
                currentPage={currentPage}
                onPageChanged={pageChanged}
                itemCount={_.get(reportsList, 'totalCount', 0)}
                itemsPerPage={resultsPerPage}
            />
        </PageSection>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        reportsList: state.reports.customReports.reportsList,
        isLoading: state.reports.customReports.isLoading,
        merchant: state.accounts.users.merchant,
        childMerchants: state.accounts.users.merchant.childMerchants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(reportActions, dispatch),
    };
}

function mapStoreToErrors(state) {
    return state.reports.customReports.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(CustomReports),
    mapStoreToErrors
);
