import React, { useState, useEffect } from 'react';
import { Icon, PaddedContainer, MultiDropdown, LoadingIndicator, Divider } from '@premier/ui';
import { useApiCall, APICallRequestState } from 'components/Common';
import { FraudRuleToggle } from 'components/Settings/components/FraudSettings/FraudRuleToggle';
import { FraudBasicSettingsModel, BillerFraudBasicSettingsModel, FraudBasicExclusionsModel } from 'packages/webapi-client';
import { fraudSettingsPageCopy } from 'components/Settings/pages/FraudSettingsPage';
import { useFraudSettings } from 'components/Settings/_hooks/useFraudSettings';
import { fraudApi } from 'api';
import countryUtil from '@premier/utils/country';
import './FraudBasicForm.scss';
interface Props {
    settings: FraudBasicSettingsModel;
    handleToggleSettings: (key: keyof FraudBasicSettingsModel) => void;
    hasLoaded?: boolean;
    isUpdating?: boolean;
    pageInfoMessage?: string;
    newBillerListSettings?:{};
}

const FraudBasicForm: React.FC<Props> = ({
    settings,
    handleToggleSettings,
    hasLoaded,
    isUpdating,
    pageInfoMessage,
}) => {
   // @ts-ignore
   const [ countries ] = useState<any>(countryUtil.getCountryOptions().filter(o => o.value !== countryUtil?.country?.countryCode));
   // Represents the countries already excluded by the user on page load (does not represent current selection)
   const [ currentExclusionSettings, setCurrentExclusionSettings ] = useState<any>(null);
   const { updateBasicExclusions, updateBillerBasicSettings } = useFraudSettings();
   const [ currentBillerAllowSettings, setCurrentBillerAllowSettings ] = useState<any>(() => {
        let storedBilleredAllowSettings = localStorage.getItem('AllowedBillerSettingsData');
        return storedBilleredAllowSettings ? JSON.parse(storedBilleredAllowSettings) : null;
   });
   const [ billerExcludedCaptchaList, setBillerExcludedCaptchaList ] = useState<any>(null);
   const [ billers, setBillers ] = useState<any>(null);
   const [ captchaBillers, setCaptchaBillers ] = useState<any>(null);
   const [ validCaptchaBillers, setValidCaptchaBillers ] = useState<any>(null);
   const [ allowCaptchaToggleOn, setAllowCaptchaToggleOn ] = useState<any>(null);

   const [ loadedExclusionSettings, exclusionSettingsStatus ] = useApiCall<FraudBasicExclusionsModel>(() => {
        return fraudApi.fraudGetFraudBasicExclusions();
    }, [settings])

    const [ loadedBillersSettings, loadedBillersSettingsStatus ] = useApiCall<BillerFraudBasicSettingsModel[]>(() => {
        return fraudApi.getBillersFraudBasicSettings();
    }, [settings]);

// load billers for captcha settings
const [ loadedCaptchaBillersSettings, loadedCaptchaBillersSettingsStatus ] = useApiCall<BillerFraudBasicSettingsModel[]>(() => {
    return fraudApi.getBillersFraudBasicCaptchaSettings();
}, [settings]);

   // Responsible for processing the merchant's existing exclusion rules and loading the selections
   // into the multiselect component.
   useEffect(() => {
       if (exclusionSettingsStatus === APICallRequestState.SUCCESSFUL) {
           if(loadedExclusionSettings?.fraudConfigurationCountryExclusions instanceof Array) {
               let alreadyExcludedCountryCodeSet = new Set();
               for(let rule of loadedExclusionSettings.fraudConfigurationCountryExclusions) {
                   if(rule?.excludedFromBlockIntlCards && rule?.excludedFromBlockIntlIps)
                       alreadyExcludedCountryCodeSet.add(rule?.countryCode ?? 0);
               }

               const currentExclusionsAsDropdownSelections = countries.filter((o : any) => alreadyExcludedCountryCodeSet.has(o.value));
               setCurrentExclusionSettings(currentExclusionsAsDropdownSelections);
           } else {
               setCurrentExclusionSettings([])
           }
       }
   }, [exclusionSettingsStatus, loadedExclusionSettings, countries]);

   const onMultiDropDownChange = (selectedCountries : any) => {
       let dtos = selectedCountries?.map((o : any) => {
           return { countryCode: o.value, excludedFromBlockIntlCards: true, excludedFromBlockIntlIps: true };
       }) ?? [];
       updateBasicExclusions({fraudConfigurationCountryExclusions : dtos});
    }

   useEffect(() => {
        if (loadedBillersSettingsStatus === APICallRequestState.SUCCESSFUL) {
			if (!billers && Array.isArray(loadedBillersSettings)) {
                setBillers(loadedBillersSettings.map((biller: BillerFraudBasicSettingsModel) => ({
                    label: `[${biller.billerCode}] ${biller.billerDescription}`,
                    value: biller.billerCode
                })));
            }

            if(loadedBillersSettings != null && loadedBillersSettings.length > 0) {
                let alreadyBillerAllowedSet = new Set();
                for(let settings of loadedBillersSettings) {
                    if(settings?.allowHppAnonymousAccess)
                        alreadyBillerAllowedSet.add(settings?.billerCode ?? 0);
                }

                const currentAllowedBillersAsDropdownSelections = loadedBillersSettings.map((biller: BillerFraudBasicSettingsModel) => ({
                    label: `[${biller.billerCode}] ${biller.billerDescription}`,
                    value: biller.billerCode
                })).filter((o : any) => alreadyBillerAllowedSet.has(o.value));

                // Avoid currentBillerAllowSettings set all billers anonymous access to enabled when block anonymous access toggle is off
                const isOn = settings?.blockAnonymousPaymentFromFeatures ? true : settings?.blockHppAnonymousAccess;
                if (isOn) {
                    setCurrentBillerAllowSettings(currentAllowedBillersAsDropdownSelections);
                    localStorage.setItem('AllowedBillerSettingsData', JSON.stringify(currentAllowedBillersAsDropdownSelections));
                }
                else {
                    let storedBilleredAllowSettings = localStorage.getItem('AllowedBillerSettingsData');
                    setCurrentBillerAllowSettings(storedBilleredAllowSettings ? JSON.parse(storedBilleredAllowSettings) : null);
                }

                const billerExcludedCaptchaSettings = loadedBillersSettings.filter((biller : any) => !biller.allowCaptcha)
                    .map((biller: BillerFraudBasicSettingsModel) => ({
                        label: `[${biller.billerCode}] ${biller.billerDescription}`,
                        value: biller.billerCode
                    }));

                setBillerExcludedCaptchaList(billerExcludedCaptchaSettings);
            } else {
                setCurrentBillerAllowSettings([]);
                setBillerExcludedCaptchaList([]);
                localStorage.setItem('AllowedBillerSettingsData', JSON.stringify([]));
            }
        }

        if (loadedCaptchaBillersSettingsStatus === APICallRequestState.SUCCESSFUL) {
            if (!captchaBillers && loadedCaptchaBillersSettings != null) {
                var captchaBillersList = loadedCaptchaBillersSettings.map((biller: BillerFraudBasicSettingsModel) => ({
                    label: `[${biller.billerCode}] ${biller.billerDescription}`,
                    value: biller.billerCode,
                }));
                setValidCaptchaBillers(captchaBillersList);
                // set billCode which intCaptcha=2 as disabled
                if (loadedBillersSettingsStatus === APICallRequestState.SUCCESSFUL) {
                    const disabledBillerCaptcha = loadedBillersSettings != null ? loadedBillersSettings.filter((id1:any ) => !loadedCaptchaBillersSettings.some((id2:any) => id2.billerCode === id1.billerCode)) : [];

                    if(disabledBillerCaptcha){
                        let newCaptchaBillers=disabledBillerCaptcha.map((biller: BillerFraudBasicSettingsModel) => ({
                            label: `[${biller.billerCode}] ${biller.billerDescription}`,
                            value: biller.billerCode,
                            disabled: true
                        }));
                        captchaBillersList=captchaBillersList.concat(newCaptchaBillers);
                    }
                        setCaptchaBillers(captchaBillersList);
                }
            }
        }
    }, [loadedBillersSettingsStatus, loadedBillersSettings, billers, captchaBillers, loadedCaptchaBillersSettings, loadedCaptchaBillersSettingsStatus, settings]);

    useEffect(() => {
        if (settings?.enableCaptchaBank) {
            setAllowCaptchaToggleOn(true);
        } else {
            if (billerExcludedCaptchaList && validCaptchaBillers) {
                if (validCaptchaBillers?.length !== 0) {
                    var isToggleOn = !(!settings?.enableCaptchaBank && billerExcludedCaptchaList?.length === validCaptchaBillers?.length);
                    setAllowCaptchaToggleOn(isToggleOn);
                }
            }
        }

    }, [billerExcludedCaptchaList, validCaptchaBillers, settings?.enableCaptchaBank]);

   const onBillersMultiDropDownChange = (selectedBillers : any) => {

        let newSettings = selectedBillers?.map((o:any) => {
            var isAllowCaptcha = billerExcludedCaptchaList.filter((biller: any) => biller.value === o.value).length === 0;

            var settings : BillerFraudBasicSettingsModel = {
                billerCode: o.value,
                allowHppAnonymousAccess: true,
                allowCaptcha: isAllowCaptcha
            }
            updateBillerBasicSettings(settings);

            return {billerCode: o.value}
        }) ?? [];

        let updatedCurrentBillerAllowSettings = [...currentBillerAllowSettings];
        if (newSettings.length > updatedCurrentBillerAllowSettings.length){
            let newBillerSettings = newSettings.map((biller: BillerFraudBasicSettingsModel) => ({
                label: `[${biller.billerCode}] ${biller.billerDescription}`,
                value: biller.billerCode
            }));

            setCurrentBillerAllowSettings(newBillerSettings);
            localStorage.setItem('AllowedBillerSettingsData', JSON.stringify(newBillerSettings));
        }
        else{
            // find the dropped biller and update the database to remove the record
            currentBillerAllowSettings.forEach((item: any) => {
                var isDeletedSettings = !!!newSettings.some((element:any) => element.billerCode === item.value);

                var isAllowCaptcha = billerExcludedCaptchaList.filter((biller: any) => biller.value === item.value).length === 0;

                if (isDeletedSettings) {
                    var deletedSettings: BillerFraudBasicSettingsModel ={
                        billerCode: item.value,
                        allowHppAnonymousAccess: false,
                        allowCaptcha: isAllowCaptcha
                    }

                    updateBillerBasicSettings(deletedSettings);
                    updatedCurrentBillerAllowSettings = updatedCurrentBillerAllowSettings.filter((o: any) => o.value !== item.value);

                    setCurrentBillerAllowSettings(updatedCurrentBillerAllowSettings);
                    localStorage.setItem('AllowedBillerSettingsData', JSON.stringify(updatedCurrentBillerAllowSettings));
                }
            });
        }
   }

   const onCaptchaMultiDropDownChange = (selectedBillers : any) => {
    let newSettings = selectedBillers?.map((o:any) => {
        var isAllowHppAnonymousAccess = currentBillerAllowSettings.filter((biller: any) => biller.value === o.value).length > 0;
        var settings : BillerFraudBasicSettingsModel = {
            billerCode: o.value,
            allowCaptcha: false,
            allowHppAnonymousAccess: isAllowHppAnonymousAccess,
        }
        updateBillerBasicSettings(settings);

        return {billerCode: o.value}
    }) ?? [];

    let updatedCurrentBillerExcludedCaptcha = [...billerExcludedCaptchaList];

    if (newSettings.length > updatedCurrentBillerExcludedCaptcha.length){
        setBillerExcludedCaptchaList(newSettings.map((biller: BillerFraudBasicSettingsModel) => ({
            label: `[${biller.billerCode}]`,
            value: biller.billerCode
        })));
    }
    else{
        // find the dropped biller and update the database to remove the record
        billerExcludedCaptchaList.forEach((item: any) => {
            let isDeletedSettings = !!!newSettings.some((element:any) => element.billerCode === item.value);

            if (isDeletedSettings) {
                    var isAllowHppAnonymousAccess = currentBillerAllowSettings.filter((biller: any) => biller.value === item.value).length > 0;

                    let deletedSettings: BillerFraudBasicSettingsModel = {
                        billerCode: item.value,
                        allowCaptcha: true,
                        allowHppAnonymousAccess: isAllowHppAnonymousAccess,
                    }

                    updateBillerBasicSettings(deletedSettings);
                    updatedCurrentBillerExcludedCaptcha = updatedCurrentBillerExcludedCaptcha.filter((o: any) => o.value !== item.value);
                    setBillerExcludedCaptchaList(updatedCurrentBillerExcludedCaptcha);
                }
            });
        }
    }

    const onEnableCaptchaChange = () => {
        if (allowCaptchaToggleOn && validCaptchaBillers != null){
                validCaptchaBillers.forEach((item: any) => {
                var isAllowHppAnonymousAccess = currentBillerAllowSettings.filter((biller: any) => biller.value === item.value).length > 0;

                let deletedSettings: BillerFraudBasicSettingsModel ={
                    billerCode: item.value,
                    allowCaptcha: false,
                    allowHppAnonymousAccess: isAllowHppAnonymousAccess,
                }
                updateBillerBasicSettings(deletedSettings);
            });
            setBillerExcludedCaptchaList(validCaptchaBillers);
            setAllowCaptchaToggleOn(false);
        } else if (validCaptchaBillers != null){
            validCaptchaBillers.forEach((item: any) => {
                var isAllowHppAnonymousAccess = currentBillerAllowSettings.filter((biller: any) => biller.value === item.value).length > 0;

                let deletedSettings: BillerFraudBasicSettingsModel ={
                    billerCode: item.value,
                    allowCaptcha: true,
                    allowHppAnonymousAccess: isAllowHppAnonymousAccess,
                }
                updateBillerBasicSettings(deletedSettings);
            });
            setBillerExcludedCaptchaList([]);

            setAllowCaptchaToggleOn(true);
        }
    }

    return (
        <>
            {pageInfoMessage && (
                <p>
                    <Icon info inline />
                    {pageInfoMessage}
                </p>
            )}
            <PaddedContainer>
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockIntlCards.title}
                    subtitle={fraudSettingsPageCopy.blockIntlCards.subtitle}
                    on={settings?.blockIntlCards}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockIntlCards'
                />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockIntlIps.title}
                    subtitle={fraudSettingsPageCopy.blockIntlIps.subtitle}
                    on={settings?.blockIntlIps}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockIntlIps'
                />
                {currentExclusionSettings?.length >= 0 ? (settings?.blockIntlCards || settings?.blockIntlIps) && <MultiDropdown
                options={countries}
                defaultValue={currentExclusionSettings}
                placeholder={fraudSettingsPageCopy.multiDropDownMessage}
                disabled={!hasLoaded || isUpdating}
                onChange={onMultiDropDownChange}
                useValueAsLabel = {false}
                /> : <LoadingIndicator />}
                <Divider />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockPaymentsFromProxy.title}
                    on={settings?.blockPaymentsFromProxy}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockPaymentsFromProxy'
                />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockPaymentsFromVpn.title}
                    on={settings?.blockPaymentsFromVpn}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockPaymentsFromVpn'
                />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockPaymentsWithIntlCardAndIntlIp.title}
                    subtitle={fraudSettingsPageCopy.blockPaymentsWithIntlCardAndIntlIp.subtitle}
                    on={settings?.blockPaymentsWithIntlCardAndIntlIp}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockPaymentsWithIntlCardAndIntlIp'
                />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockPaymentsWhereCardAndIpCountryDiffer.title}
                    subtitle={fraudSettingsPageCopy.blockPaymentsWhereCardAndIpCountryDiffer.subtitle}
                    on={settings?.blockPaymentsWhereCardAndIpCountryDiffer}
                    onChange={handleToggleSettings}
                    disabled={!hasLoaded || isUpdating}
                    value='blockPaymentsWhereCardAndIpCountryDiffer'
                />
                <FraudRuleToggle
                    title={fraudSettingsPageCopy.blockHppAnonymousAccess.title}
                    subtitle={fraudSettingsPageCopy.blockHppAnonymousAccess.subtitle}
                    on={settings?.blockAnonymousPaymentFromFeatures?true:settings?.blockHppAnonymousAccess}
                    onChange={handleToggleSettings}
                    disabled={settings?.blockAnonymousPaymentFromFeatures?true:(!hasLoaded || isUpdating)}
                    value='blockHppAnonymousAccess'
                    additionalText={settings?.blockAnonymousPaymentFromFeatures}
                    additionalTextContent={fraudSettingsPageCopy.HppAnonymousAccessAdditionalText}
                />
                {currentBillerAllowSettings?.length >= 0 ? settings?.blockHppAnonymousAccess && !settings?.blockAnonymousPaymentFromFeatures && <MultiDropdown
                options={billers}
                defaultValue={currentBillerAllowSettings}
                placeholder={fraudSettingsPageCopy.multiBillerDropDownMessage}
                disabled={!hasLoaded || isUpdating}
                onChange={onBillersMultiDropDownChange}
                useValueAsLabel = {true}
                /> : <LoadingIndicator />}

                <FraudRuleToggle
                    title={fraudSettingsPageCopy.enableCaptcha.title}
                    subtitle={fraudSettingsPageCopy.enableCaptcha.subtitle}
                    on={allowCaptchaToggleOn}
                    onChange={onEnableCaptchaChange}
                    disabled={!hasLoaded || isUpdating || settings?.enableCaptchaBank}
                    value='enableCaptchaBank'
                    additionalText={!hasLoaded || isUpdating || settings?.enableCaptchaBank}
                    additionalTextContent={fraudSettingsPageCopy.CaptchaAdditionalText}
                />
                {billerExcludedCaptchaList?.length >= 0 ? (allowCaptchaToggleOn &&!settings?.enableCaptchaBank) && <MultiDropdown
                    options={captchaBillers}
                    defaultValue={billerExcludedCaptchaList}
                    placeholder={fraudSettingsPageCopy.multiBillerDropDownMessageCaptcha}
                    disabled={!hasLoaded || isUpdating}
                    onChange={onCaptchaMultiDropDownChange}
                    useValueAsLabel={true}

                /> : <LoadingIndicator />}
            </PaddedContainer>
        </>
    );

};

export default FraudBasicForm;
