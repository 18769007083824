import { Biller } from "models/Biller";

// @ts-ignore
import labels from "constants/labels";

export type CrnDetails = {
    name: string;
    value: string | undefined;
}

export const getCrnDetails = (biller: Biller, crn1: string | undefined, crn2: string | undefined, crn3: string | undefined) => {
    const details: CrnDetails[] = [];

    if (biller?.acceptedCrn1?.crnName || crn1) {
        details.push({ name: biller?.acceptedCrn1?.crnName || labels.crn1, value: crn1 });
    }

    if (biller?.acceptedCrn2?.crnName || crn2) {
        details.push({ name: biller?.acceptedCrn2?.crnName || labels.crn2, value: crn2 });
    }

    if (biller?.acceptedCrn3?.crnName || crn3) {
        details.push({ name: biller?.acceptedCrn3?.crnName || labels.crn3, value: crn3 });
    }

    return details;
};
