import { useState } from "react";
import { Button, Dialog } from "packages/ui";
import { Field, FormikProvider, useFormik } from "formik";
import { FormGroup, Input } from "packages/formik-ui";
import * as Yup from "yup";
import { FormError } from "components/Common";
import eFormsApi from "api/eFormsApi";

type Props = {
    show: boolean;
    onClose: (showSuccess: boolean, reload: boolean, newFormId?: number) => void;
};

type FormValues = {
    title: string;
};

const NewEFormDialog = ({ show, onClose }: Props) => {
    const [errors, setErrors] = useState<string[]>([]);

    const formik = useFormik<FormValues>({
        initialValues: {
            title: "",
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().label("Form name").trim().required(),
        }),
        onSubmit: async (formValues: FormValues) => {
            try {
                const response = await eFormsApi.createEForm(formValues.title);

                if (response.status === 200) {
                    handleClose(true, true, response.data.data);
                } else {
                    setErrors(["An error occurred while creating the eForm."]);
                }
            } catch {
                setErrors(["An error occurred while creating the eForm."]);
            }
        },
    });

    const handleClose = (showSuccess: boolean, reload: boolean, formId?: number) => {
        formik.resetForm();
        setErrors([]);
        onClose(showSuccess, reload, formId);
    }

    return <Dialog
        title="Create new eForm"
        show={show}
        onClose={() => handleClose(false, false)}
        footerButtons={<>
            <Button onClick={formik.submitForm} disabled={formik.isSubmitting} primary>Create eForm</Button>
            <Button onClick={() => handleClose(false, false)}>Cancel</Button>
        </>}
        closeButton
    >
        <p>Enter the following information to create a new eForm.</p>
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <FormGroup name="title" label="Form name" mandatory>
                    <Field name="title" as={Input} />
                </FormGroup>
            </form>
        </FormikProvider>
        <FormError errors={errors} />
    </Dialog>
};

export default NewEFormDialog;
