import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
//import _ from "lodash";
// @ts-ignore
import labels from "constants/labels";
import currencyUtil from "@premier/utils/currency";
import errorUtil from "@premier/utils/error";
import { Modal, Icon, LoadingIndicator, DescriptionList, Button } from "@premier/ui";
import { Form, CurrencyField, validate, SubmitButton } from "@premier/form";
import { withError, FormError } from "components/Common";
import { CaptureResultModal } from "components/Transactions";
import * as paymentActions from "../_actions/paymentActions";
import { RootState } from "store/store";
import { LabelledApiError } from "models";
import { Biller } from "models/Biller";
import { Transaction } from "models/Transaction";
import { PaymentReceipt } from "models/PaymentReceipt";

import "./CaptureModal.scss";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    transaction: Transaction;
    biller: Biller;
    isLoading: boolean;
    actions: any;
    errors: string[];
    captureReceipt: PaymentReceipt;
}

const CaptureModal = ({
    showModal, closeModal, //logic renders
    transaction, biller, //data
    isLoading, actions, errors, captureReceipt //state
}: Props) => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const maxCaptureAmountCents = (transaction?.paymentProcessedTxnModel?.totalAmount ?? 0) - (transaction?.paymentSource?.capturedAmount ?? 0);
    const maxCaptureAmountFormatted = currencyUtil.formatApiValue(maxCaptureAmountCents, transaction.paymentProcessedTxnModel.currencyCode);

    const initialFormValues = {
        amount: maxCaptureAmountFormatted,
        reason: ""
    };

    const txnCurrency = currencyUtil.getCurrency(transaction.paymentProcessedTxnModel.currencyCode);
    const currencyAlphaCode = txnCurrency.alphaCode;
    const currencySymbol = currencyUtil.getSymbol(txnCurrency.code);

    function getAmount(amount: number  | undefined) {
        return currencyUtil.formatApiValue(
            amount ?? 0,
            transaction.paymentProcessedTxnModel.currencyCode
        );
    }

    function closeThisModal(formProps?: any) {
        if (formProps) {
            formProps.resetForm();
        }

        closeModal();
    }

    function closeSuccessModal() {
        setShowSuccessModal(false);
    }

    function handleSubmit(values: any, formProps: any) {
        actions.capturePayment({
            txnHistoryId: transaction.paymentProcessedTxnModel.txnHistoryId,
            childMerchantNumber: transaction.paymentSource?.merchantNumber,
            amount: currencyUtil.convertToApiValue(values.amount)
        })
            .then(() => {
                closeThisModal(formProps);
                setShowSuccessModal(true);
            })
            .catch((err: any) => {
                console.error(err);
                formProps.setSubmitting(false);
            });
    }

    // ---------- Render ----------

    const modalContent =
        !transaction || !transaction.paymentProcessedTxnModel.originalAmount ? (
            <LoadingIndicator />
        ) : (
            <Form
                initialValues={initialFormValues}
                initialValidation={{
                    amount: validate()
                        .required()
                        .when(x => currencyUtil.convertToApiValue(x)! > 0, "Must be positive")
                        .when(
                            x => currencyUtil.convertToApiValue(x)! <= maxCaptureAmountCents,
                            `Must not exceed ${maxCaptureAmountFormatted}`
                        )
                }}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <DescriptionList
                        spaceBetween
                        hideBlankValue
                        items={[
                            { name: "Merchant number", value: transaction.paymentSource?.merchantNumber },
                            { name: labels.billerName, value: biller.billName },
                            { name: labels.billerCode, value: biller.billerCode },
                            {
                                name: biller.acceptedCrn1?.crnName || labels.crn1,
                                value: transaction.paymentProcessedTxnModel.crn1
                            },
                            {
                                name: biller.acceptedCrn2?.crnName || labels.crn2,
                                value: transaction.paymentProcessedTxnModel.crn2
                            },
                            {
                                name: biller.acceptedCrn3?.crnName || labels.crn3,
                                value: transaction.paymentProcessedTxnModel.crn3
                            }
                        ]}
                        divider
                    />

                    {transaction.paymentProcessedTxnModel.cardDetails && (
                        <DescriptionList
                            spaceBetween
                            hideBlankValue
                            items={[
                                {
                                    name: "Card number",
                                    value: transaction.paymentProcessedTxnModel.cardDetails.cardNumber
                                },
                                {
                                    name: `Amount (${currencyAlphaCode})`,
                                    value: getAmount(transaction.paymentProcessedTxnModel.originalAmount)
                                },
                                {
                                    name: `Surcharge amount (${currencyAlphaCode})`,
                                    value: getAmount(transaction.paymentProcessedTxnModel.surchargeAmount)
                                },
                                {
                                    name: `Total amount (${currencyAlphaCode})`,
                                    value: getAmount(transaction.paymentProcessedTxnModel.totalAmount)
                                }
                            ]}
                            divider
                        />
                    )}

                    <CurrencyField
                        name="amount"
                        label={`Capture amount (${currencyAlphaCode})`}
                        currencySymbol={currencySymbol}
                    />

                    <p style={{ margin: "2.5rem 0 0" }}>
                        <Icon alert inline /> A captured transaction cannot be reversed. A refund can be processed
                        against the capture transaction.
                    </p>

                    <FormError errors={errors} />
                </Modal.Body>

                <Modal.Footer buttons divider>
                    <SubmitButton disabled={isLoading}>
                        Capture payment
                    </SubmitButton>
                    <Button
                        onClick={() => {
                            closeThisModal();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        );

    return (
        <>
            <Modal show={showModal} onHide={closeThisModal} className="transaction-capture-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Capture transaction</Modal.Title>
                </Modal.Header>

                {modalContent}
            </Modal>

            <CaptureResultModal
                showModal={showSuccessModal}
                closeModal={closeSuccessModal}
                receipt={captureReceipt}
                biller={biller}
            />
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        captureReceipt: state.transactions.payments.captureReceipt,
        isLoading: state.transactions.payments.isLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.transactions.payments.errors;
}

function mapErrorToString(error: LabelledApiError) {
    const paramLabels = {
        Amount: "Amount"
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(CaptureModal),
    mapStoreToErrors,
    mapErrorToString
);
