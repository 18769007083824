import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";

import * as billpayUtil from "@premier/utils/billpay";
import currencyUtil from "@premier/utils/currency";
import textUtil from "@premier/utils/text";
import windowUtil from "@premier/utils/window";
import { PageSection, LoadingIndicator, CardContainer, Button } from "@premier/ui";
import * as paramUtil from "@premier/utils/param";
import { PlatformRoutesConfiguration } from "components/Routing";
import { PageHeader, withError } from "components/Common";
import { RefundModal, CaptureModal, SendReceiptModal, TransactionStatusIcon } from "components/Transactions";
import SecurityUtil, { User } from "@premier/utils/security";
import { userRoles } from "components/Routing";
import {
    PaymentDetailsCategory, TransactionResultCategory, PaymentAmountsCategory, CardDetailsCategory, BankDetailsCategory,
    PaymentSourceCategory, AuthenticationResultCategory, FraudScreeningResultCategory, LeastCostRoutingCategory
} from "components/Transactions";
import * as paymentActions from "components/Transactions/_actions/paymentActions";
import "./TransactionDetails.scss";
import useInvoices from "components/DataVault/_hooks/useInvoices";
import { RootState } from "store/store";
import { MessagingConfig } from "packages/utils/models";
import { Transaction } from "models/Transaction";
import { Biller } from "models/Biller";
import { PaymentReceipt } from "models/PaymentReceipt";

type Props = {
    transaction: Transaction;
    biller: Biller | {billerCode: string | undefined} | null;
    messaging: MessagingConfig;
    authenticatedUser: User;
    actions: any;
    isLoading: boolean;
}

const TransactionDetails = ({
    transaction, biller, messaging, authenticatedUser, //state
    actions, isLoading, //logic renders
}: Props) => {
    useInvoices();
    const [noTransactionFound, setNoTransactionFound] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);
    const [showCaptureModal, setShowCaptureModal] = useState(false);

    useEffect(() => {
        actions.getTransactionDetails(transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal)
            .catch((err: any) => {
                console.error(err);
                setNoTransactionFound(true);
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal]);

    function showRefund() {
        return SecurityUtil.hasAccess([userRoles.processRefund], authenticatedUser)
            && transaction.paymentProcessedTxnModel.responseCode === "0"
            && transaction.paymentProcessedTxnModel.cardDetails
            && (transaction.paymentProcessedTxnModel.txnFinancialType === "PAYMENT"
                || transaction.paymentProcessedTxnModel.txnFinancialType === "CAPTURE")
            && !transaction.paymentSource?.completelyRefunded;
    }

    function showCapture() {
        return transaction.paymentProcessedTxnModel.responseCode === "0"
            && transaction.paymentProcessedTxnModel.cardDetails
            && transaction.paymentProcessedTxnModel.txnFinancialType === "PREAUTH"
            && !transaction.paymentSource?.preAuthSentAsVerify
            && !transaction.paymentSource?.fullyCompleted;
    }

    function showSendReceipt() {
        const config = billpayUtil.getMessagingConfigForTransaction(transaction, messaging);

        // @ts-ignore
        return (config.emailEnabled || config.smsEnabled) && isRecentTxn();
    }

    function isRecentTxn() {
        const cutoffDate = new Date();
        cutoffDate.setMonth(cutoffDate.getMonth() - 18);
        let processedDate = new Date(transaction.paymentProcessedTxnModel.processedDate ?? "");

        if (!_.isDate(processedDate))
        {
            processedDate = new Date();
        }

        return !transaction.paymentProcessedTxnModel.isExternal && processedDate > cutoffDate;
    }

    function closeRefundModal(forceTransactionRefresh: boolean) {
        if (forceTransactionRefresh) {
            actions.getTransactionDetails(transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal)
                .catch((err: any) => {
                    console.error(err);
                    setNoTransactionFound(true);
                });
        }
        setShowRefundModal(false);
    }

    function closeCaptureModal() {
        actions.getTransactionDetails(transaction.paymentProcessedTxnModel.txnHistoryId, transaction.paymentProcessedTxnModel.isExternal)
            .catch((err: any) => {
                console.error(err);
                setNoTransactionFound(true);
            });

        setShowCaptureModal(false);
    }

    function closeReceiptModal() {
        setShowSendReceiptModal(false);
    }

    function mapToPaymentReceipt(transaction: Transaction) : PaymentReceipt {
        return {
            txnFinancialType: transaction.paymentProcessedTxnModel.txnFinancialType ?? "",
            responseCode: transaction.paymentProcessedTxnModel.responseCode ?? "",
            acqResponseCode: transaction.paymentProcessedTxnModel.acqResponseCode ?? "",
            authorizationResult: transaction.paymentProcessedTxnModel.authorizationResult ?? "",
            txnHistoryId: transaction.paymentProcessedTxnModel.txnHistoryId ?? 0,
            receiptNumber: transaction.paymentProcessedTxnModel.receiptNumber ?? "",
            rrn: transaction.paymentProcessedTxnModel.rrn ?? "",
            processedDate: new Date(transaction.paymentProcessedTxnModel.processedDate ?? ""),
            settlementDate: new Date(transaction.paymentProcessedTxnModel.settlementDate ?? ""),
            billerCode: transaction.paymentProcessedTxnModel.billerCode ?? "",
            crn1: transaction.paymentProcessedTxnModel.crn1 ?? "",
            crn2: transaction.paymentProcessedTxnModel.crn2 ?? "",
            crn3: transaction.paymentProcessedTxnModel.crn3 ?? "",
            merchantReference: transaction.paymentProcessedTxnModel.merchantReference ?? "",
            originalAmount: transaction.paymentProcessedTxnModel.originalAmount ?? 0,
            surchargeAmount: transaction.paymentProcessedTxnModel.surchargeAmount ?? 0,
            totalAmount: transaction.paymentProcessedTxnModel.totalAmount ?? 0,
            currencyCode: transaction.paymentProcessedTxnModel.currencyCode ?? 0,
            cardDetails: {
                cardNumber: transaction.paymentProcessedTxnModel.cardDetails?.cardNumber ?? "",
                expiryDate: {
                    month: transaction.paymentProcessedTxnModel.cardDetails?.expiryDate?.month ?? 0,
                    year: transaction.paymentProcessedTxnModel.cardDetails?.expiryDate?.year ?? 0,
                },
                cardIssuingCountryName: transaction.paymentProcessedTxnModel.cardDetails?.cardIssuingCountryName ?? "",
                cvcPresent: transaction.paymentProcessedTxnModel.cardDetails?.cvcPresent ?? false,
                cardTypeCode: transaction.paymentProcessedTxnModel.cardDetails?.cardTypeCode ?? "",
                cardSubType: transaction.paymentProcessedTxnModel.cardDetails?.cardSubType ?? "",

            },
            isExternal: transaction.paymentProcessedTxnModel.isExternal ?? false,
        };
    }

    const loaded = !isLoading && !noTransactionFound && transaction && transaction.paymentProcessedTxnModel;
    const printWindowUrl = process.env.PUBLIC_URL + PlatformRoutesConfiguration.transactionRoute?.transactionDetailsPrint.generatePath(transaction.paymentProcessedTxnModel.txnHistoryId);

    return (
        <div className="transaction-details-page">
            <PageSection>
                <PageHeader
                    backButton
                    icon={loaded && <TransactionStatusIcon transaction={transaction} />}
                    title={loaded && currencyUtil.convertToDisplayString(transaction.paymentProcessedTxnModel.totalAmount!, transaction.paymentProcessedTxnModel.currencyCode)}
                    subtitle={loaded && `Receipt no. ${textUtil.insertSpaces(transaction.paymentProcessedTxnModel.receiptNumber)}`}
                >
                    {loaded && (<>
                        {showRefund() && (
                            <Button primary onClick={() => { setShowRefundModal(true); }}>Process refund</Button>
                        )}
                        {showCapture() && (
                            <Button primary onClick={() => { setShowCaptureModal(true); }}>Process capture</Button>
                        )}
                        {showSendReceipt() && (
                            <Button onClick={() => { setShowSendReceiptModal(true); }}>Send receipt</Button>
                        )}
                    </>)}
                </PageHeader>

                {isLoading && (
                    <CardContainer header="Payment details">
                        <LoadingIndicator />
                    </CardContainer>
                )}

                {!isLoading && noTransactionFound && (
                    <p>Transaction not found or an error occurred.</p>
                )}

                {loaded && (<>
                    <div className="transaction-details">
                        <PaymentDetailsCategory transaction={transaction} />

                        <TransactionResultCategory transaction={transaction} />

                        <PaymentAmountsCategory transaction={transaction} />

                        {transaction.paymentProcessedTxnModel.cardDetails && transaction.paymentProcessedTxnModel.cardDetails.cardTypeCode && (
                            <CardDetailsCategory transaction={transaction} />
                        )}

                        {transaction.paymentProcessedTxnModel.bankDetails && (
                            <BankDetailsCategory transaction={transaction} />
                        )}

                        <PaymentSourceCategory transaction={transaction} />

                        {transaction.paymentSource && (
                            <LeastCostRoutingCategory transaction={transaction} />
                        )}

                        <AuthenticationResultCategory transaction={transaction} />

                        <FraudScreeningResultCategory transaction={transaction} />
                    </div>
                    {isRecentTxn() === false &&
                        <div className="top-margin"><i>Note: Receipts cannot be sent for transactions that are more than 18 months old.</i></div>
                    }
                    {isRecentTxn() &&
                        <Button subtle className="btn-receipt" onClick={() => { windowUtil.openPrintReceiptWindow(printWindowUrl); }}>Print receipt</Button>
                    }
                </>)}
            </PageSection>

            <SendReceiptModal showModal={showSendReceiptModal} biller={biller} closeModal={closeReceiptModal} transaction={mapToPaymentReceipt(transaction)} />
            <RefundModal showModal={showRefundModal} closeModal={closeRefundModal} transaction={transaction} biller={biller} />
            <CaptureModal showModal={showCaptureModal} closeModal={closeCaptureModal} transaction={transaction} biller={biller} />
        </div>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const [txnHistoryId, isExternal] = paramUtil.splitAndMap(ownProps.match.params.id,
        paramUtil.asInteger,
        (flag) => flag === "external"
    );

    let transaction: Transaction = {
        paymentProcessedTxnModel: {
            txnHistoryId: txnHistoryId,
            isExternal: isExternal
        }
    };

    const invoiceBillers: Biller[] = state.dataVault?.invoice?.invoiceBillers?.data || [];
    const billers: Biller[] = state.accounts.users.billers || [];

    // If (or as soon as) the redux store has the requested transaction then use that
    if (state.transactions.payments.transactionDetails
        && state.transactions.payments.transactionDetails.paymentProcessedTxnModel.txnHistoryId === txnHistoryId
        && state.transactions.payments.transactionDetails.paymentProcessedTxnModel.isExternal === isExternal) {
        transaction = state.transactions.payments.transactionDetails;
    }

    // search the list of billers and invoice billers for the biller
    const biller = transaction.paymentProcessedTxnModel
        ? (_.find([...billers, ...invoiceBillers], x => x.billerCode === transaction.paymentProcessedTxnModel.billerCode) || { billerCode: transaction.paymentProcessedTxnModel.billerCode })
        : null;

    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
        transaction: transaction,
        biller: biller,
        messaging: state.accounts.users.merchant.messaging,
        isLoading: state.transactions.payments.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(paymentActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.accounts.users.errors;
}

export default withError(connect(mapStateToProps, mapDispatchToProps)(TransactionDetails), mapStoreToErrors, null);
