import React, { KeyboardEvent } from 'react';
import classNames from 'classnames';
import './Switch.scss';

export enum Sizes {
    Small,
    Normal,
}

type Props = {
    on?: boolean,
    disabled?: boolean,
    noHover?: boolean,
    noBottomMargin?: boolean,
    className?: string,
    onChange?: (value: boolean) => void,
    size?: Sizes;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;  // eg. aria-label, style

/** A toggle switch button with no label. Functionally similar to a Checkbox. */
const Switch = ({
    on, disabled, className, onChange, noHover, noBottomMargin, size,
    ...otherProps
}: Props) => {

    function handleKeyPress(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === ' ')
            toggle(e);
    }

    function toggle(e: React.MouseEvent | KeyboardEvent) {
        e.preventDefault();

        if (!disabled && onChange)
            onChange(!on);
    }

    return (
        <div className={classNames('switch', className, {
            'on': on,
            'disabled': disabled,
            'noHover': noHover,
            'no-bottom-margin': noBottomMargin,
            'small': size === Sizes.Small
        })}>
            <div
                className='control'
                tabIndex={0}
                onClick={(e) => toggle(e)}
                onKeyPress={handleKeyPress}
                role='checkbox'
                aria-checked={on}
                {...otherProps}
            >
                <div className='button' />
            </div>
            {otherProps.children &&
                <label className='control-label'>
                    {otherProps.children}
                </label>}
        </div>
    );
};

export default Switch;