import { useContext, useRef, useState, useEffect, useMemo } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { Button, Dialog, Row, SingleColumnFormContainer, Icon, ErrorText } from "@premier/ui";
import { Input, FormGroup, FormikRichTextEditor } from "@linkly/formik-ui";
import { FormContext } from '@premier/form';
// @ts-ignore
import branding from 'constants/branding';
import { MenuPage } from 'models/BrandingStyle';
import * as Yup from "yup";
import _ from "lodash";
import "./EditPageContentDialog.scss";

type ErrorType = {
    message: string;
    field: string;
    code?: string;
    label?: string;
    parameter?: string;
};

type Props = {
    show: boolean;
    onClose: () => void;
    handleOpen: () => void;
    title: string;
    page?: MenuPage;
    index?: number;
    errors: ErrorType[];
};

type FormValues = {
    pageHeading?: string;
    menuLabel?: string;
    pageContent?: string;
    title: string;
};

const EditPageContentDialog = ({
    show,
    onClose,
    handleOpen,
    title,
    page,
    index,
    errors
}: Props) => {
    const context = useContext(FormContext);
    const formRef = useRef<FormikProps<FormValues>>(null);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const previousFormRef = useRef<FormValues | null>(null);

    const initialFormValues = useMemo<FormValues>(() => ({
        pageHeading: page?.draftTitle ?? page?.title,
        menuLabel: page?.draftLinkName ?? page?.linkName,
        pageContent: page?.draftContents ?? page?.contents,
        title: title,
    }), [page, title]);

    const currentContextFormValues = useMemo<FormValues>(() => ({
        'menuLabel': context?.getValue(`menu.pages[${index}].linkName`),
        'pageHeading': context?.getValue(`menu.pages[${index}].title`),
        'pageContent': context?.getValue(`menu.pages[${index}].contents`),
        'title': title,
    }), [context, index, title]);

    useEffect(() => {
        // when choose continue editing, set the previousFormRef to changed form values
        if (formRef?.current?.values && !_.isEqual(formRef.current.values, initialFormValues) && _.isEqual(formRef.current.values.title, initialFormValues.title)) {
            previousFormRef.current = formRef.current.values;
        }
        // when re-open the dialog, if the form values is different to initial form values, set the previousFormRef to current context form values
        else if (!formRef?.current?.values && !_.isEqual(currentContextFormValues, initialFormValues)) {
            previousFormRef.current = currentContextFormValues;
        } else {
            previousFormRef.current = null;
            formRef.current?.resetForm({ values: initialFormValues });
        }
    }, [formRef?.current?.values, initialFormValues, currentContextFormValues, title]);

    const handleClose = (isDirty: boolean) => {
        if (isDirty || (!_.isEqual(formRef?.current?.values, initialFormValues) && (!_.isEqual(formRef?.current?.values, currentContextFormValues)))) {
            setShowWarningDialog(true);
            onClose();
        } else {
            onClose();
        }
    };

    const handleWarningDialogClose = () => {
        setShowWarningDialog(false);
        previousFormRef.current = null;
        context.setValue(`menu.pages[${index}].draftTitle`, initialFormValues.pageHeading);
        context.setValue(`menu.pages[${index}].draftLinkName`, initialFormValues.menuLabel);
        context.setValue(`menu.pages[${index}].draftContents`, initialFormValues.pageContent);
        onClose();
    };
    const handleContinueEditing = () => {
        setShowWarningDialog(false);
        handleOpen();
    };

    const ValidationSchema = Yup.object().shape({
        menuLabel: Yup.string().required('Menu label is required'),
    });

    return (
        <>
            <Dialog
                show={show}
                onClose={() => handleClose(formRef?.current?.dirty ?? false)}
                title={`Edit page content for ${title}`}
                enforceFocus={false}
                closeButton
            >
                <Formik
                    innerRef={formRef}
                    validationSchema={ValidationSchema}
                    initialValues={previousFormRef.current ?? initialFormValues}
                    onSubmit={(formValues: FormValues) => {
                        if (index !== -1) {
                            context.setValue(`menu.pages[${index}].draftTitle`, formValues.pageHeading ?? page?.title);
                            context.setValue(`menu.pages[${index}].draftLinkName`, formValues.menuLabel ?? page?.linkName);
                            context.setValue(`menu.pages[${index}].draftContents`, formValues.pageContent ?? page?.contents);
                            onClose();
                        }
                    }}
                >
                    {({ dirty }) => (
                        <Form>
                            <SingleColumnFormContainer>
                                <Row>
                                    <FormGroup mandatory label="Menu label" className="col-sm-12" name="menuLabel">
                                        <Field as={Input} name="menuLabel" />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup label="Page heading" className="col-sm-12" name="pageHeading">
                                        <Field as={Input} name="pageHeading" />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup label="Page content" className="col-sm-12" name="pageContent">
                                        <Field as={FormikRichTextEditor} name="pageContent" toolbar={branding.designerEditorToolbar} plugins={branding.designerEditorPlugins} />
                                    </FormGroup>
                                </Row>
                                <div className="dialog-actions">
                                    <Button type="submit"
                                        primary>
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() => handleClose(dirty)}
                                    >Cancel</Button>
                                </div>
                                <div className="error-list">
                                    {errors?.length
                                        ? errors.map((err: { field: string; message: string }, i: number) =>
                                            err.field.startsWith(`menu.pages[${index}]`)
                                                ? <ErrorText key={i}>{err.message}</ErrorText>
                                                : null
                                        )
                                        : null}
                                </div>
                            </SingleColumnFormContainer>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                show={showWarningDialog}
                closeButton
                onClose={handleContinueEditing}
                icon={<Icon alert />}
                title='Changes to design are not saved'
                footerButtons={<>
                    <Button primary onClick={handleContinueEditing}>Continue editing</Button>
                    <Button onClick={handleWarningDialogClose}>Discard changes</Button>
                </>}
            >Leaving this page without saving will discard your unsaved changes.</Dialog>
        </>
    );
};

export default EditPageContentDialog;
