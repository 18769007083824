import withDesignerControl from "./withDesignerControl";
import { defaultFontColor, defaultFontFamily, defaultHeadingSize, defaultLabelAlign } from "../constants";
import { HeaderAlign } from "../enums";
import { TemplatesProps } from "../types";

const Heading = ({ attributes }: TemplatesProps) => {
    const fontColorStyle = attributes?.fontColor ?? defaultFontColor;
    const fontFamilyStyle = attributes?.fontFamily ?? defaultFontFamily;
    const fontSizeStyle = attributes?.fontSize ?? defaultHeadingSize;
    const textAlignStyle = (attributes?.labelAlign ?? defaultLabelAlign) as HeaderAlign;

    return (
        <div
            className="eform-form eform-ctrl eform-row formContentElement"
            style={{ textAlign: textAlignStyle }}>
            <div
                className="eform-ctrl-heading-heading"
                style={{ color: fontColorStyle, fontFamily: fontFamilyStyle, fontSize: fontSizeStyle }}
            >
                {attributes?.headingTitle}
            </div>
            <div
                className="eform-ctrl-heading-subheading"
                style={{ color: fontColorStyle, fontFamily: fontFamilyStyle }}
            >
                {attributes?.subHeadingTitle}
            </div>
        </div>
    );
}

export default withDesignerControl(Heading);
