import _ from "lodash";
import { Option } from "packages/formik-ui/src/FormikDropdown";
import { Align, ControlCategory, ControlProperty, ControlType, DateFormat, FontFamily, HeaderAlign, MaximumType, Special, TimeFormat, ValidationFormat } from "./enums";
import { controlTypeProperties, controlTypeSpecials, days, defaultButtonColor, defaultButtonText, defaultFontColor, defaultFontFamily, defaultHeadingSize, defaultTextSize, genders, months } from "./constants";
import { ControlAttributes, controlTypesWithSpecials, ControlTypesWithSpecials, FieldOption } from "./types";
import { CountryModel, TimeZoneModel } from "packages/webapi-client";

export const getControlCategoryOptions = (): Option[] => {
    return Object.values(ControlCategory).map(c => ({ value: c, label: getControlCategoryLabel(c) }));
};

const getControlCategoryLabel = (controlCategory: ControlCategory) => {
    switch (controlCategory) {
        case ControlCategory.Basic:
            return "Basic";
        case ControlCategory.Customer:
            return "Customer";
        case ControlCategory.Payment:
            return "Payment";
        default:
            return "Unknown";
    }
};

export const toControlTypeEnum = (controlType: string) => {
    switch (controlType) {
        case "control_address":
            return ControlType.Address;
        case "control_amount":
            return ControlType.Amount;
        case "control_checkbox":
            return ControlType.Checkbox;
        case "control_crn1":
            return ControlType.Crn1;
        case "control_crn2":
            return ControlType.Crn2;
        case "control_crn3":
            return ControlType.Crn3;
        case "control_datetime":
            return ControlType.DateTime;
        case "control_dropdown":
            return ControlType.DropDown;
        case "control_email":
            return ControlType.Email;
        case "control_heading":
            return ControlType.Heading;
        case "control_longanswer":
            return ControlType.LongAnswer;
        case "control_name":
            return ControlType.Name;
        case "control_number":
            return ControlType.Number;
        case "control_phonenumber":
            return ControlType.PhoneNumber;
        case "control_radio":
            return ControlType.Radio;
        case "control_shortanswer":
            return ControlType.ShortAnswer;
        case "control_submit":
            return ControlType.Submit;
        case "control_text":
            return ControlType.Text;
        default:
            return ControlType.Text;
    }
};

export const hasControlProperty = (controlType: ControlType, controlProperty: ControlProperty) => {
    return controlTypeProperties[controlType].includes(controlProperty);
};

export const hasAnyControlProperty = (controlType: ControlType, controlProperties: ControlProperty[]) => {
    return controlProperties.some(p => hasControlProperty(controlType, p));
};

export const hasSpecial = (controlType: ControlType, special: Special) => {
    if (controlTypesWithSpecials.includes(controlType as ControlTypesWithSpecials)) {
        return controlTypeSpecials[controlType as ControlTypesWithSpecials].includes(special);
    }

    return false;
};

export const getFontSizeOptions = (): Option[] => {
    return _.range(8, 73).map(i => ({ value: i, label: i.toString() }));
};

export const getFontFamilyOptions = (): Option[] => {
    return Object.values(FontFamily).map(f => ({ value: f, label: f }));
};

export const getValidationFormatOptions = (): Option[] => {
    return Object.values(ValidationFormat).map(v => ({ value: v, label: getValidationFormatLabel(v) }));
};

const getValidationFormatLabel = (validationFormat: ValidationFormat) => {
    switch (validationFormat) {
        case ValidationFormat.None:
            return "None";
        case ValidationFormat.Alphabetic:
            return "Alphabetic";
        case ValidationFormat.AlphaNumeric:
            return "Alphanumeric";
        case ValidationFormat.Numeric:
            return "Numeric";
        case ValidationFormat.Email:
            return "Email";
        default:
            return "Unknown";
    }
};

export const getLabelAlignmentOptions = (): Option[] => {
    return Object.values(Align).map(a => ({ value: a, label: getLabelAlignmentLabel(a) }));
};

const getLabelAlignmentLabel = (alignment: Align) => {
    switch (alignment) {
        case Align.Left:
            return "Left";
        case Align.Top:
            return "Top";
        case Align.Right:
            return "Right";
        default:
            return "Unknown";
    }
};

export const getHeaderAlignmentOptions = (): Option[] => {
    return Object.values(HeaderAlign).map(a => ({ value: a, label: getHeaderAlignmentLabel(a) }));
};

const getHeaderAlignmentLabel = (alignment: HeaderAlign) => {
    switch (alignment) {
        case HeaderAlign.Left:
            return "Left";
        case HeaderAlign.Center:
            return "Center";
        case HeaderAlign.Right:
            return "Right";
        default:
            return "Unknown";
    }
};

export const getSpecialOptions = (controlType: ControlType): Option[] => {
    return Object.values(Special).filter(s => hasSpecial(controlType, s)).map(s => ({ value: s, label: getSpecialLabel(s) }));
};

const getSpecialLabel = (special: Special) => {
    switch (special) {
        case Special.None:
            return "None";
        case Special.Countries:
            return "Countries";
        case Special.Last100Years:
            return "Last 100 Years";
        case Special.Gender:
            return "Gender";
        case Special.Days:
            return "Days";
        case Special.Months:
            return "Months";
        case Special.TimeZones:
            return "Time Zones";
        default:
            return "Unknown";
    }
};

const spreadColumns = [1, 2, 3, 4];

export const getSpreadColumnsOptions = (): Option[] => {
    return spreadColumns.map(c => ({ value: c, label: c.toString() }));
};

export const getDateFormatOptions = (): Option[] => {
    return Object.values(DateFormat).map(d => ({ value: d, label: getDateFormatLabel(d) }));
};

const getDateFormatLabel = (dateFormat: DateFormat) => {
    switch (dateFormat) {
        case DateFormat.DayMonthYear:
            return "Day Month Year";
        case DateFormat.MonthDayYear:
            return "Month Day Year";
        default:
            return "Unknown";
    }
};

export const getTimeFormatOptions = (): Option[] => {
    return Object.values(TimeFormat).map(t => ({ value: t, label: getTimeFormatLabel(t) }));
};

const getTimeFormatLabel = (timeFormat: TimeFormat) => {
    switch (timeFormat) {
        case TimeFormat.Hour24:
            return "24 hour";
        case TimeFormat.AmPm:
            return "AM/PM";
        default:
            return "Unknown";
    }
};

export const getMaximumTypeOptions = (): Option[] => {
    return Object.values(MaximumType).map(m => ({ value: m, label: getMaximumTypeLabel(m) }));
};

const getMaximumTypeLabel = (maximumType: MaximumType) => {
    switch (maximumType) {
        case MaximumType.NoLimit:
            return "No Limit";
        case MaximumType.Words:
            return "Words";
        case MaximumType.Letters:
            return "Letters";
        default:
            return "Unknown";
    }
};

export const generateNewControlId = () => {
    return Math.random().toString(36).substring(7);
};

export const getLast100Years = () => {
    const currentYear = new Date().getFullYear();
    return _.range(currentYear - 100, currentYear + 1).sort((a, b) => b - a).map(y => y.toString());
};

export const getYearsFrom1920 = () => {
    const end = new Date().getFullYear() + 100;
    return _.range(1920, end + 1).map(y => y.toString());
};

export const validateEmailFormat = (value: string, setError?: React.Dispatch<React.SetStateAction<string>>) => {
    const validEmailPattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = !value || validEmailPattern.test(value);
    setError && setError(valid ? "" : "Value must be a valid email address");

    return valid;
}

export const validateAmountFormat = (value: string, setError: React.Dispatch<React.SetStateAction<string>>) => {
    const validAmountPattern = /^\s*(-?(|([0-9]+([",][0-9]+)*(\.[0-9]+)?)|([0-9]+(\.[0-9]+)*(,[0-9]+)?)))\s*$/;
    const valid = !value || validAmountPattern.test(value);
    setError(valid ? "" : "Value must be in Amount  format");

    return valid;
};

export const validateInputByValidationFormat = (value: string, setError: React.Dispatch<React.SetStateAction<string>> | undefined, validationFormat: string | undefined) => {
    let valid = true;
    const validAlphaNumericPattern = /^[a-zA-Z0-9]*$/;
    const validAlphabeticPattern = /^[a-zA-Z\s]*$/;
    const validNumericPattern = /^(\d+[.]?)*$/;

    if (!value) {
        valid = false;
        return valid;
    }
    if (validationFormat === ValidationFormat.AlphaNumeric && !validAlphaNumericPattern.test(value)) {
        setError && setError("Value must be in AlphaNumeric format");
        valid = false;
    } else if (validationFormat === ValidationFormat.Alphabetic && !validAlphabeticPattern.test(value)) {
        setError && setError("Value must be in Alphabetic format");
        valid = false;
    } else if (validationFormat === ValidationFormat.Numeric && !validNumericPattern.test(value)) {
        setError && setError("Value must be in Numeric format");
        valid = false;
    } else if (validationFormat === ValidationFormat.Email) {
        valid = validateEmailFormat(value, setError)
    } else {
        setError && setError("");
    }

    return valid;
};

export const validateRequiredInput = (value: string, setError?: React.Dispatch<React.SetStateAction<string>>) => {
    if (!value) {
        setError && setError("Value is required");
    }

    return !!value;
};

export const validateRequiredInputArray = (values: string[], setError: React.Dispatch<React.SetStateAction<string>>) => {
    if (!values || values.length === 0) {
        setError("At least one option must be selected");
    }

    return values.length > 0;
};

export const validateInputLength = (maximumType: MaximumType, maximum: number, value: string, setError: React.Dispatch<React.SetStateAction<string>>) => {
    let valid = true;

    if (maximumType === MaximumType.Words) {
        let count = value.trim().split(/\s+/).length;

        if (count > maximum) {
            setError(`Value is above maximum words ${maximum}`);
            valid = false;
        }
    } else if (maximumType === MaximumType.Letters) {
        if (value.length > maximum) {
            setError(`Value is above maximum letters ${maximum}`);
            valid = false;
        }
    }

    return valid;
};

export const validateNumber = (maximum: number, minimum: number, value: string, setError: React.Dispatch<React.SetStateAction<string>>) => {
    let valid = true;

    if (value) {
        if (!isFinite(Number(value))) {
            valid = false;
            setError("Value is not a number");
        } else if (maximum && isFinite(maximum) && Number(value) > maximum) {
            valid = false;
            setError(`Value is above maximum ${maximum}`);
        } else if (minimum && isFinite(minimum) && Number(value) < minimum) {
            valid = false;
            setError(`Value is below minimum ${minimum}`);
        }
    }

    return valid;
};

export const getControlTypeDefaultAttributes = (controlType: ControlType, merchantCountryCode?: number | undefined, countries?: CountryModel[]): ControlAttributes => {
    const commonDefaults = {
        labelAlign: Align.Left,
        fontSize: defaultTextSize,
        fontFamily: defaultFontFamily,
        fontColor: defaultFontColor,
    }

    switch (controlType) {
        case ControlType.Address:
            return {
                ...commonDefaults,
                label: "Address",
                country: countries?.find(c => c.countryCode === merchantCountryCode)?.name ?? "",
                required: false,
                encrypt: true,
            };
        case ControlType.Amount:
            return {
                ...commonDefaults,
                label: "Amount",
                placeholderText: "Enter amount",
                required: true,
                display: true,
                encrypt: true,
            };
        case ControlType.Checkbox:
            return {
                ...commonDefaults,
                label: "Untitled question",
                options: ["Option 1", "Option 2", "Option 3"],
                special: Special.None,
                spreadColumns: 1,
                required: false,
                encrypt: true,
            };
        case ControlType.Crn1:
        case ControlType.Crn2:
        case ControlType.Crn3:
            return {
                ...commonDefaults,
                label: "Untitled question",
                placeholderText: "Enter text",
                required: false,
                display: true,
                encrypt: true,
            };
        case ControlType.DateTime:
            return {
                ...commonDefaults,
                label: "Date",
                encrypt: true,
                allowTime: true,
                dateFormat: DateFormat.DayMonthYear,
                timeFormat: TimeFormat.Hour24,
            };
        case ControlType.DropDown:
            return {
                ...commonDefaults,
                label: "Untitled question",
                options: ["Option 1", "Option 2", "Option 3"],
                special: Special.None,
                required: false,
                encrypt: true,
            };
        case ControlType.Email:
            return {
                ...commonDefaults,
                label: "Email",
                placeholderText: "Enter email",
                required: false,
                encrypt: true,
            };
        case ControlType.Heading:
            return {
                ...commonDefaults,
                headingTitle: "Heading",
                fontSize: defaultHeadingSize,
            };
        case ControlType.LongAnswer:
            return {
                ...commonDefaults,
                label: "Untitled question",
                placeholderText: "Enter text",
                rows: 5,
                maximumType: "nolimit",
                validationFormat: "none",
                required: false,
                encrypt: true,
            };
        case ControlType.Name:
            return {
                ...commonDefaults,
                label: "Name",
                title: true,
                middleName: true,
                required: false,
                encrypt: true,
            };
        case ControlType.Number:
            return {
                ...commonDefaults,
                label: "Number",
                placeholderText: "Enter number",
                required: false,
                encrypt: true,
            };
        case ControlType.PhoneNumber:
            return {
                ...commonDefaults,
                label: "Phone number",
                required: false,
                encrypt: true,
            };
        case ControlType.Radio:
            return {
                ...commonDefaults,
                label: "Untitled question",
                options: ["Option 1", "Option 2", "Option 3"],
                special: Special.None,
                spreadColumns: 1,
                required: false,
                encrypt: true,
            };
        case ControlType.ShortAnswer:
            return {
                ...commonDefaults,
                label: "Untitled question",
                placeholderText: "Enter text",
                validationFormat: ValidationFormat.None,
                required: false,
                encrypt: true,
            };
        case ControlType.Submit:
            return {
                // Don't need common defaults here
                buttonText: defaultButtonText,
                buttonColor: defaultButtonColor,
            };
        case ControlType.Text:
            return {
                ...commonDefaults,
                text: "Enter your text here",
            };
        default:
            return commonDefaults;
    };
};

export const getSpecialOptionsByControlType = (controlType: ControlType, special: Special, countries: CountryModel[], timeZones: TimeZoneModel[]): FieldOption[] => {
    let options: FieldOption[] = [];

    if (controlType === ControlType.Checkbox || controlType === ControlType.DropDown || controlType === ControlType.Radio) {
        if (special !== Special.None) {
            if (special === Special.Gender) {
                options = genders.map((x, i) => ({ text: x, order: i }));
            } else if (special === Special.Days) {
                options = days.map((x, i) => ({ text: x, order: i }));
            } else if (special === Special.Months) {
                options = months.map((x, i) => ({ text: x, order: i }));
            } else if (special === Special.Last100Years) {
                options = getLast100Years().map((x, i) => ({ text: x.toString(), order: i }));
            } else if (special === Special.Countries) {
                options = countries?.map((x, i) => ({ text: x.name ?? "", order: i })) ?? [];
            } else if (special === Special.TimeZones) {
                options = timeZones?.map((x, i) => ({ text: x.description ?? x.location ?? "", order: i })) ?? [];
            }
        }
    }

    return options;
};

export const matchesAnySpecialOptions = (options: string[], countries: CountryModel[], timeZones: TimeZoneModel[]) => {
    return options.every(o => genders.some(g => g === o)) ||
        options.every(o => days.some(d => d === o)) ||
        options.every(o => months.some(m => m === o)) ||
        options.every(o => getLast100Years().some(y => y === o)) ||
        options.every(o => countries.some(c => c.name === o)) ||
        options.every(o => timeZones.some(t => t.description === o || t.location === o));
};
