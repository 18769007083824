import React, { useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import Input from "./components/Input";
import { validateAmountFormat, validateRequiredInput } from "components/EForms/helpers";
import classNames from "classnames";

const Amount = ({ name, attributes }: TemplatesProps) => {
    const [valid, setValid] = useState(true);

    const inputValidation = (value: string, setError: React.Dispatch<React.SetStateAction<string>>, attributes: any) => {
        let validationResult: boolean;

        if (attributes.required) {
            validationResult = validateRequiredInput(value, setError);

            if (validationResult) {
                validationResult = validateAmountFormat(value, setError);
            }
        } else {
            validationResult = validateAmountFormat(value, setError);
        }

        setValid(validationResult);
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-amount eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <Input attributes={attributes} type="number" inputValidation={inputValidation} />
        </div>
    );
};

export default withDesignerControl(Amount);
