import { Dialog, Icon } from '@premier/ui';
import currencyUtil from '@premier/utils/currency';

import './PaymentPlanCancelDialog.scss'

interface PaymentPlanCancelDialogProps {
    crn1Label : string | undefined,
    crn2Label : string | undefined,
    crn3Label : string | undefined,
    crn1 : string | undefined,
    crn2 : string | undefined,
    crn3 : string | undefined,
    originalTotalAmount : number,
    paymentPlanOptionName : string,
    totalAmountPaid : number
    [rest: string]: any;
}

const PaymentPlanCancelDialog : React.FC<PaymentPlanCancelDialogProps> = (
    { crn1Label, crn2Label, crn3Label, crn1, crn2, crn3, originalTotalAmount, paymentPlanOptionName, totalAmountPaid, ...rest}) => {
    return (<Dialog title='Cancel payment plan?' icon={<Icon alert />} {...rest}>
        <p>
            {`The following is the summary of this payment plan as of now:`}
        </p>
        <p>
            <b>{crn1Label}</b> {crn1}
        </p>
        {(crn2Label || crn2) && <p>
            <b>{crn2Label}</b> {crn2}
        </p>}
        {(crn3Label || crn3) &&<p>
            <b>{crn3Label}</b> {crn3}
        </p>}
        <p>
            <b>Total amount</b> {`${currencyUtil.convertToDisplayString(originalTotalAmount)}`}
        </p>
        <p>
            <b>Payment plan option</b> {`${paymentPlanOptionName}`}
        </p>
        <p>
            <b>Amount collected so far</b> {`${currencyUtil.convertToDisplayString(totalAmountPaid)}`}
        </p>
        <p>
            {`No future payment will be received from this payment plan after cancellation.`}
        </p>
    </Dialog>)
}

export default PaymentPlanCancelDialog;