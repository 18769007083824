import { Fragment, ReactNode, useMemo, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import useSpecials from "../hooks/useSpecials";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import { validateRequiredInputArray } from "../helpers";
import classNames from "classnames";

import "./Checkboxes.scss";

const Checkboxes = ({ id, attributes, onControlSelectionChanged }: TemplatesProps) => {
    const [valid, setValid] = useState(true);
    const { options } = useSpecials(attributes);
    const [otherDisabled, setOtherDisabled] = useState(true);
    const [selected, setSelected] = useState<string[]>(attributes.selected ? (Array.isArray(attributes.selected) ? attributes.selected : [attributes.selected]) : []);
    const [error, setError] = useState("");
    const [touched, setTouched] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        setTouched(true);

        if (e.target.checked) {
            setSelected((prev) => ([...prev, e.target.value]));
        } else {
            setSelected((prev) => prev.filter(x => x !== e.target.value));
        }
    };

    // Notify listeners that selection has changed
    useMemo(() => {
        onControlSelectionChanged && onControlSelectionChanged(id, selected);
    }, [selected]);

    useMemo(() => {
        let validationResult: boolean = true;

        if (touched && attributes.required) {
            validationResult = validateRequiredInputArray(selected, setError);
        }

        setValid(validationResult);
    }, [touched, attributes.required, selected]);

    const getColumnContents = (options: string[], columnIndex: number, selected: string | string[] | undefined) => {
        const results: ReactNode[] = [];

        for (let i = 0; i < options.length; i++) {
            if (i % (attributes.spreadColumns ?? 1) === columnIndex) {
                results.push(
                    <div className="eform-ctrl-checkbox-item" key={i}>
                        <input
                            id={`${options[i]}_${id}`}
                            type="checkbox"
                            className="jsCheckboxItem"
                            defaultChecked={attributes.selected?.includes(options[i])}
                            checked={attributes.selected?.includes(options[i])}
                            value={options[i]}
                            onChange={handleChange}
                        />
                        <label htmlFor={`${options[i]}_${id}`} style={{ fontFamily: attributes.fontFamily }}>{options[i]}</label>
                    </div>
                );
            }
        }

        return results.map((result, index) => <Fragment key={`checkbox_fragment_${index}}`}>{result}</Fragment>);
    };

    const columns = useMemo(() => {
        const result: ReactNode[] = [];
        const optionsArray = options?.map(x => x.label) ?? attributes.options ?? [];

        for (let i = 0; i < (attributes.spreadColumns ?? 1); i++) {
            result.push(
                <div key={`checkbox_column_${i}`} className={`column_${i}`}>
                    {getColumnContents(optionsArray, i, attributes.selected)}
                </div>
            )
        }

        return result;
    }, [attributes.options, attributes.spreadColumns, attributes.selected, options]);

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-checkbox eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={id} attributes={attributes} />
            <div className="jsContent eform-col-right">
                <div className="checkboxes-container">
                    {columns}
                </div>
                {attributes.allowOtherOption &&
                    <div className="eform-ctrl-checkbox-item">
                        <input
                            type="checkbox"
                            className={attributes.labelAlign === "top" ? "" : "other-checkbox"}
                            defaultChecked={attributes.selected?.includes("Other")}
                            value="Other"
                            onChange={(e) => { handleChange(e); setOtherDisabled(!e.currentTarget.checked); }}
                        />
                        <input
                            className="eform-ctrl-input-other eform-ctrl-input"
                            placeholder="Other"
                            type="text"
                            disabled={otherDisabled}
                        />
                    </div>}
            </div>
            {error && <div className="errors" style={{ fontFamily: attributes.fontFamily }}><div>{error}</div></div>}
        </div>
    );
};

export default withDesignerControl(Checkboxes);
