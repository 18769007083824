import { useState } from "react";
import currencyUtil from "@premier/utils/currency";
import dateUtil from "@premier/utils/date";
import textUtil from "@premier/utils/text";
import { Modal, Icon, LoadingIndicator, DescriptionList, Button, SuccessModal } from "@premier/ui";
import { SendReceiptForm } from "components/Transactions";
// @ts-ignore
import labels from "constants/labels";
import { PaymentReceipt } from "models/PaymentReceipt";
import { Biller } from "models/Biller";
import { getCrnDetails } from "packages/utils/crnHelpers";

import "./RefundResultModal.scss";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    receipt: PaymentReceipt;
    biller: Biller;
}

const RefundResultModal = ({
    showModal, //logic render
    closeModal, //function
    receipt, biller //data
}: Props) => {

    const [showReceiptSentModal, setShowReceiptSentModal] = useState(false);

    const currencyAlphaCode = receipt ? currencyUtil.getCurrency(receipt.currencyCode).alphaCode : "";
    const success = receipt && receipt.responseCode === "0";

    function closeThisModal(formProps?: any) {
        if (formProps)
            formProps.resetForm();
        closeModal();
    }

    function closeReceiptSentModal() {
        setShowReceiptSentModal(false);
    }

    function handleReceiptSent() {
        closeModal();
        setShowReceiptSentModal(true);
    }


    return (<>
        <Modal show={showModal} onHide={closeThisModal} className="transaction-refund-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {success ? <Icon tick /> : <Icon alert />}
                    Refund {success ? "successful" : "unsuccessful"}
                </Modal.Title>
            </Modal.Header>

            {(!receipt)
                ? <LoadingIndicator />
                : (<>
                    <Modal.Body>
                        {!success && receipt.authorizationResult ? <p className="bank-error">{receipt.authorizationResult}</p> : ""}

                        <DescriptionList spaceBetween hideBlankValue items={[
                            { name: "Receipt number", value: textUtil.insertSpaces(receipt.receiptNumber) },
                            { name: "Refund date", value: dateUtil.convertToDateTimeString(receipt.processedDate) },
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            { name: labels.billerName, value: biller.billName },
                            { name: labels.billerCode, value: receipt.billerCode },
                            ...getCrnDetails(biller, receipt.crn1, receipt.crn2, receipt.crn3),
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            { name: `Refund amount (${currencyAlphaCode})`, value: currencyUtil.formatApiValue(receipt.totalAmount, receipt.currencyCode) },
                        ]} divider />

                        <DescriptionList spaceBetween hideBlankValue items={[
                            { name: "Card number", value: receipt.cardDetails.cardNumber },
                        ]} divider />

                        <SendReceiptForm receipt={receipt} biller={biller} onSent={handleReceiptSent} divider />
                    </Modal.Body>

                    <Modal.Footer buttons>
                        <Button subtle onClick={() => { closeThisModal(); }}>Thanks, I'm done</Button>
                    </Modal.Footer>
                </>)
            }
        </Modal>

        <SuccessModal show={showReceiptSentModal} onClose={closeReceiptSentModal}>
            Sent successfully
        </SuccessModal>
    </>);
};

export default RefundResultModal;
