import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import countryUtil from '@premier/utils/country'
import * as billpayUtil from '@premier/utils/billpay';
import labels from 'constants/labels';

import { userRoles } from 'components/Routing';
import { PageSection, SingleColumnFormContainer, IconText, SuccessModal } from '@premier/ui';
import { Form, InputField, SubmitButton, DropdownField, EmailAddressField, ContactTimeField, validate, TextareaField, PhoneNumberField } from '@premier/form';
import { ContactFooter, ChangeContactDetailsForm, ChangeBankAccountForm, FundsSettlementForm, FeesChargedForm, ChargebackForm } from 'components/Account';
import { withError, FormError, PageHeader } from 'components/Common';


import links from 'constants/links';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as accountActions from 'components/Account/_actions/accountActions';

/** Enum sent with submitting a contact us request.
  * @enum {string}
  */
export const EnquiryTypeEnum = {
    changeContactDetails: 'CHANGE_CONTACT_DETAILS',
    changeBankDetails: 'CHANGE_BANK_DETAILS',
    chargeback: 'CHARGEBACK',
    fundSettlement: 'FUND_SETTLEMENT',
    feesCharged: 'FEES_CHARGED',
    technical: 'TECHNICAL',
    other: 'OTHER'
};

const enquiryTypeData = [
    { name: 'Change contact details', id: EnquiryTypeEnum.changeContactDetails, roles: [userRoles.adminMenu] },
    { name: 'Change bank account details', id: EnquiryTypeEnum.changeBankDetails, roles: [userRoles.adminMenu] },
    { name: 'Chargeback', id: EnquiryTypeEnum.chargeback },
    { name: 'Fund settlement', id: EnquiryTypeEnum.fundSettlement },
    { name: 'Fees charged', id: EnquiryTypeEnum.feesCharged },
    { name: 'Technical', id: EnquiryTypeEnum.technical },
    { name: 'Other', id: EnquiryTypeEnum.other }
];

const ContactUsPage = ({
    accountActions, //API actions
    errors, //form
    merchantCountryCode, authenticatedUser, //state values
}) => {
    const [showingModal, setShowingModal] = React.useState(false);
    const [launchedNewTab, setLaunchedNewTab] = React.useState(false);
    if (!authenticatedUser) {
        if (!launchedNewTab) {
            window.open(links.unauthenticatedContactUs);
            setLaunchedNewTab(true);

            if (window.history.length > 1)
                window.history.back();
            else
                window.close();
        }

        //protect the page from accidentally rendering.
        return <Navigate to={PlatformRoutesConfiguration.accountRoute.logon.generatePath()} />;
    }

    function handleSubmit(values) {
        var contactUsMessage = {
            contactType: values.enquiryType,
            contactName: values.customerName,
            contactEmail: values.emailAddress,
            phoneNumber: billpayUtil.formatPhoneObjectToApiString(values.phoneNumberField),
            preferredContactDay: values.contactTime.day,
            preferredContactAmPm: values.contactTime.time,
            comment: values.additionalInfo
        };

        if (values.enquiryType === EnquiryTypeEnum.changeContactDetails) {
            if (values.useSameAddress) {
                values.businessPostalAddress = values.businessAddress;
            }
            contactUsMessage = Object.assign({}, contactUsMessage, {
                siteAddress1: values.businessAddress.addressLine1,
                siteAddress2: values.businessAddress.addressLine2,
                siteSuburb: values.businessAddress.suburb,
                siteState: values.businessAddress.state,
                sitePostCode: values.businessAddress.postCode,

                postalAddress1: values.businessPostalAddress.addressLine1,
                postalAddress2: values.businessPostalAddress.addressLine2,
                postalSuburb: values.businessPostalAddress.suburb,
                postalState: values.businessPostalAddress.state,
                postalPostCode: values.businessPostalAddress.postCode,

                phone1: billpayUtil.formatPhoneObjectToApiString(values.businessContactDetails.phoneNumber1),
                phone2: billpayUtil.formatPhoneObjectToApiString(values.businessContactDetails.phoneNumber2),
                mobile: billpayUtil.formatPhoneObjectToApiString(values.businessContactDetails.phoneNumberMobile),
                email: values.businessContactDetails.emailAddress,
                url: values.businessContactDetails.url
            });
        }

        if (values.enquiryType === EnquiryTypeEnum.changeBankDetails) {
            contactUsMessage = Object.assign({}, contactUsMessage, {
                bsb: values.changeBankAccountForm.bankAccountInfo.bsbNumber,
                account: values.changeBankAccountForm.bankAccountInfo.accountNumber,
                accountName: values.changeBankAccountForm.bankAccountInfo.accountName
            });
        }

        if (values.enquiryType === EnquiryTypeEnum.fundSettlement) {
            contactUsMessage = Object.assign({}, contactUsMessage, {
                amount: values.fundsSettlementForm.fundsAmount,
                noOfTransactions: values.fundsSettlementForm.numberOfTransactions,
                dateFrom: _.get(values.fundsSettlementForm.dateRange, '0'),
                dateTo: _.get(values.fundsSettlementForm.dateRange, '1')
            });
        }

        if (values.enquiryType === EnquiryTypeEnum.feesCharged) {
            contactUsMessage = Object.assign({}, contactUsMessage, {
                bsb: values.feesChargedForm.bankAccountDetails.bsbNumber,
                account: values.feesChargedForm.bankAccountDetails.accountNumber,
                amount: values.feesChargedForm.feesAmount,
                feeType: values.feesChargedForm.feeType,
                dateFrom: _.get(values.feesChargedForm.dateRange, '0'),
                dateTo: _.get(values.feesChargedForm.dateRange, '1')
            });
        }

        if (values.enquiryType === EnquiryTypeEnum.chargeback) {
            contactUsMessage = Object.assign({}, contactUsMessage, {
                amount: values.chargebackForm.chargebackAmount,
                caseNumber: values.chargebackForm.caseNumber,
                dateFrom: _.get(values.chargebackForm.dateRange, '0'),
                dateTo: _.get(values.chargebackForm.dateRange, '1')
            });
        }
        accountActions.submitContactForm(contactUsMessage).then(() => {
            setShowingModal(true);
        });
    }

    function getEnquiryOptions(allEnquiryTypes) {
        let enquiryTypes = allEnquiryTypes.filter(item => {
           return !item.roles || item.roles.some(role => authenticatedUser?.features?.indexOf(role) >= 0)
        });
        return enquiryTypes.map(x => ({
            label: x.name,
            value: x.id
        }));
    }

    return (
        <>
            <PageSection>
                <PageHeader title='Contact us' />
            </PageSection>

            <Form
                name='contactForm'
                initialValues={{
                    customerName: `${authenticatedUser.firstName} ${authenticatedUser.lastName}`.trim(),
                    emailAddress: authenticatedUser.emailAddress,
                    phoneNumberField: Object.assign({
                        iddCode: countryUtil.getIddCode(merchantCountryCode)
                    }, billpayUtil.formatPhoneApiStringToObject(authenticatedUser.phoneNumber)),
                    contactTime: {
                        day: 'Monday',
                        time: 'Morning'
                    },
                    useSameAddress: true
                }}
                initialValidation={{
                    customerName: validate().required(),
                    emailAddress: validate().required().email(),
                    phoneNumberField: validate().requiredPhone().phone()
                }}
                onSubmit={handleSubmit}
                render={formContext => {
                    return (
                        <>
                            <PageSection>
                                <SingleColumnFormContainer>
                                    <DropdownField
                                        label='Choose enquiry type'
                                        options={getEnquiryOptions(enquiryTypeData)}
                                        name='enquiryType'
                                    />
                                    {formContext.getValue('enquiryType') && (
                                        <>
                                            <InputField name='customerName' label='Contact name' mandatory />
                                            <PhoneNumberField name='phoneNumberField' label={labels.phoneNumber} mandatory />
                                            <EmailAddressField name='emailAddress' label={labels.emailAddress} mandatory />
                                            <ContactTimeField name='contactTime' label='Preferred contact time' />
                                        </>
                                    )}
                                </SingleColumnFormContainer>
                            </PageSection>

                            {formContext.getValue('enquiryType') === EnquiryTypeEnum.changeContactDetails && (
                                <ChangeContactDetailsForm />
                            )}

                            {formContext.getValue('enquiryType') === EnquiryTypeEnum.changeBankDetails && (
                                <ChangeBankAccountForm />
                            )}

                            {formContext.getValue('enquiryType') === EnquiryTypeEnum.fundSettlement && (
                                <FundsSettlementForm />
                            )}

                            {formContext.getValue('enquiryType') === EnquiryTypeEnum.feesCharged && (
                                <FeesChargedForm />
                            )}

                            {formContext.getValue('enquiryType') === EnquiryTypeEnum.chargeback && (
                                <ChargebackForm />
                            )}

                            {formContext.getValue('enquiryType') && (
                                <PageSection header='Additional information'>
                                    <SingleColumnFormContainer>
                                        <TextareaField
                                            name='additionalInfo'
                                            label={
                                                <IconText info>
                                                    Please do not provide card numbers here
                                                </IconText>
                                            }
                                            labelText='Additional information'
                                        />
                                    </SingleColumnFormContainer>

                                    <FormError errors={errors} />
                                    <SubmitButton>Submit</SubmitButton>
                                </PageSection>
                            )}
                            <ContactFooter />
                        </>
                    );
                }}
            />

            {showingModal && (
                <SuccessModal
                    show={showingModal}
                    disableAutoClose
                    title='Your enquiry has been successfully submitted'
                    onClose={() => { setShowingModal(false); }}
                >
                    We will contact you within the next 3 business days.
                </SuccessModal>
            )}
        </>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchantCountryCode: _.get(state.accounts, 'users.merchant.countryCode'),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch)
    };
}

function mapStoreToErrors(state) {
    return state.accounts.users.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ContactUsPage),
    mapStoreToErrors
);
