import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import errorUtil from "@premier/utils/error";

import { ButtonContainer, Icon, LoadingIndicator } from "@premier/ui";
import { SubmitButton, Form } from "@premier/form";
import { FloatingContainer, FormHeader, FormError, withError } from "components/Common";
import { NewPasswordFields } from "components/Account";

import { PlatformRoutesConfiguration } from "components/Routing";
import * as accountActions from "components/Account/_actions/accountActions";
import { accountApi } from "api";
import { RootState } from "store/store";
import { LabelledApiError } from "models";
import { getPlatform } from "platforms/current/util";
import { useSelector } from 'react-redux';
import { useApiCall, APICallRequestState } from "components/Common";

// @ts-ignore
import labels from "constants/labels";

import "./ChangePasswordPage.scss";

type Props = {
    actions: any;
    isLoading: boolean;
    errors: string[];
}

const ChangePasswordPage = ({
    actions, //API actions
    isLoading, //state
    errors //form
}: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const lastLocationStringFromArray = Array.isArray(queryParams.lastLocation) && queryParams.lastLocation.length > 0 ? queryParams.lastLocation[0] : "";
    const lastLocationString = typeof queryParams.lastLocation === "string" ? queryParams.lastLocation : lastLocationStringFromArray;
    const lastLocation = queryParams.lastLocation ? lastLocationString : "";
    const last = queryParams && decodeURI(lastLocation);
    const [isVerified, setIsVerified] = useState<boolean | undefined>(undefined);
    const requiredUserAction = useSelector((state: RootState) => state.accounts.users.requiredUserAction);
    const authenticatedUser = useSelector((state: RootState) => state.accounts.users.authenticatedUser);
    const merchant = useSelector((state: RootState) => state.accounts.users.merchant);

    const [sessionFromCookie, sessionFromCookieStatus] = useApiCall(() => {
        return accountApi.getResetPasswordSessionId();
    }, []);

    const [sessionId, setSessionId] = useState<string | null>(
        typeof queryParams.sessionId === "string" ? queryParams.sessionId : null
    );

    useEffect(() => {
        if (sessionFromCookieStatus === APICallRequestState.SUCCESSFUL && sessionFromCookie?.resetPasswordSessionId && !sessionId) {
            setSessionId(sessionFromCookie?.resetPasswordSessionId)
        }
    }, [sessionFromCookieStatus, sessionId, sessionFromCookie]);


    function handleSubmit(values: any, { setValue }: { setValue: (name: string, newValue: string) => void }) {
        actions.changePassword({ newPassword: values.newPasswordForm.newPassword, sessionId: sessionId }).then(() => {
            if (last) {
                navigate(last);
            } else {
                navigate(getPlatform() !== "stationery_shop" ? PlatformRoutesConfiguration.accountRoute.landingPage.generatePath() :
                    PlatformRoutesConfiguration.stationeryRoute?.homePage.generatePath()!);
            }
        }).catch(() => {
            setValue('newPasswordForm.newPassword', '');
            setValue('newPasswordForm.confirmPassword', '');
        });
    }
    
    useEffect(() => {
        if (requiredUserAction === "MULTI_FACTOR_AUTHENTICATION") {
            navigate(getPlatform() !== "stationery_shop" ? PlatformRoutesConfiguration.accountRoute.landingPage.generatePath() :
                PlatformRoutesConfiguration.stationeryRoute?.homePage.generatePath()!);
        } else if (requiredUserAction === "ENTER_NEW_PASSWORD" || requiredUserAction === "ACCEPT_TERMS_AND_CONDITIONS") {
            setIsVerified(true);
        } else if (requiredUserAction === "UPDATE_PASSWORD") {
            if (queryParams.sessionId) {
                setIsVerified(true);
            }
            else if (sessionFromCookieStatus === APICallRequestState.SUCCESSFUL) {
                if (sessionFromCookie?.resetPasswordSessionId) {
                    setIsVerified(true);
                } else {
                    if (authenticatedUser.username && merchant.merchantNumber) {
                        actions.resetPasswordWithoutRecaptcha({ username: authenticatedUser.username, merchantNumber: merchant.merchantNumber }).then(() => {
                            navigate(PlatformRoutesConfiguration.accountRoute.forgotPasswordConfirmation.generatePath());
                        }).catch(() => {
                            setIsVerified(false);
                        });
                    }
                }
            }
        }
        else {
            setIsVerified(undefined);
        }
    }, [requiredUserAction, navigate, authenticatedUser, sessionId, sessionFromCookieStatus, actions, merchant, queryParams.sessionId, sessionFromCookie?.resetPasswordSessionId]);

    useEffect(() => {
        const verifyResetLink = async () => {
            if (queryParams.sessionId) {
                actions.verifyResetPassword(queryParams.sessionId as string)
                    .catch((error: any) => {
                        setIsVerified(false);
                    });
            }
        }
        verifyResetLink();
    }, []);

    return (
        <FloatingContainer className="change-password-page">
            <FormHeader title="Change password" description="Help us identify you" />

            {isVerified === undefined &&

                <div className="message-box">
                    <LoadingIndicator inline hidden={isVerified !== undefined} />
                    <span className="message-item">Please Wait...</span>
                </div>
            }
            {isVerified === true &&

                <Form onSubmit={handleSubmit} inlineLabelsUpMd>
                    <NewPasswordFields changePasswordRequired />

                    <FormError errors={errors} />

                    <ButtonContainer>
                        <SubmitButton disabled={isLoading}>Submit</SubmitButton>
                    </ButtonContainer>
                </Form>
            }
            {isVerified === false &&
                <>
                    <div className="message-box">
                        <Icon alert />
                        <span className="message-item">
                            {errors?.[0] === "This link has expired or is invalid." ? errors[0] : "Something went wrong"}
                        </span>
                    </div>
                </>
            }
        </FloatingContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: !!state.accounts.users.isLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

function mapStoreToErrors(state: RootState) {
    return state.accounts.users.errors;
}

function mapErrorToString(error: LabelledApiError) {
    const paramLabels = {
        NewPassword: labels.newPassword
    };

    return errorUtil.getMessage(error, paramLabels);
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage),
    mapStoreToErrors,
    mapErrorToString
);
