import { userRoles } from 'components/Routing';
import { BrandingPage, PreviewBrandingPage, EditBrandingPage } from 'components/Settings';
import { RouteConfigurationMap } from 'components/Routing';
import { EditEFormPage, EFormsPage } from "components/EForms";

export const designerRoute : RouteConfigurationMap = {
    /** Designer **/
    root: {
        path:               '/designer/',
        generatePath: () => '/designer/',
    },
    branding: {
        path:               '/designer/paymentpage',
        element: <BrandingPage />,
        generatePath: () => '/designer/paymentpage',
        roles:              [userRoles.internet],
        title: 'Payment Page',
    },
    previewMerchantBranding: {
        path:               '/designer/paymentpage/preview',
        element: <PreviewBrandingPage />,
        generatePath: () => '/designer/paymentpage/preview',
        roles:              [userRoles.internet],
        title: 'Payment Page',
    },
    previewBillerBranding: {
        path:               '/designer/paymentpage/:billerCode/preview',
        element: <PreviewBrandingPage />,
        generatePath: (billerCode: string) => `/designer/paymentpage${billerCode ? `/${billerCode}` : ''}/preview`,
        roles:              [userRoles.internet],
        title: 'Payment Page',
    },
    editMerchantBranding: {
        path:               '/designer/paymentpage/edit',
        element: <EditBrandingPage />,
        generatePath: () => '/designer/paymentpage/edit',
        roles:              [userRoles.internet],
        title: 'Set your brand style',
    },
    editBillerBranding: {
        path:               '/designer/paymentpage/:billerCode/edit',
        element: <EditBrandingPage />,
        generatePath: (billerCode: string) => `/designer/paymentpage${billerCode ? `/${billerCode}` : ''}/edit`,
        roles:              [userRoles.internet],
        title: 'Set your brand style',
    },

    /** eForms **/
    eForms: {
        path:              '/designer/eforms',
        element:           <EFormsPage />,
        generatePath:      () => '/designer/eforms',
        roles:             [userRoles.eForms],
        title:             'eForms',
    },
    editEForm: {
        path:              '/designer/eforms/:formId',
        element:           <EditEFormPage />,
        generatePath:      (formId: number) => `/designer/eforms/${formId}`,
        roles:             [userRoles.eForms],
        title:             'Edit eForm',
    },
}