import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
import { LoadingIndicator, PageSection, BackButton, IconText, SuccessModal, Divider } from "@premier/ui";

import { ScheduleDetailsForm, AddPaymentMethodCard, ConfirmScheduleDialog } from "components/Transactions";
import { AddPaymentMethodModal } from "components/DataVault";
import { PageHeader, useApiCall, APICallRequestState, withError, FormError } from "components/Common";
import * as scheduleActions from "components/Transactions/_actions/scheduleActions";
import * as tokenActions from "components/DataVault/_actions/tokenActions";
import * as customerActions  from "components/DataVault/_actions/customerActions";
import * as commonActions from "components/Common/_actions/actions";
import { PlatformRoutesConfiguration } from "components/Routing";
import "./NewEditSchedulePage.scss";
import { useParams } from "react-router-dom";
import { tokenApi } from "api";
import { RootState } from "store/store";
import { Biller } from "models/Biller";
import { Token } from "models/Token";
import { Customer } from "packages/utils/models";
import { Schedule } from "models/Schedule";
import { billerApi } from "api";
import { isValidExpiryDate } from '@premier/utils/date';

type Props = {
    scheduleFrequencies: any;
    scheduleId: number;
    schedule: Schedule;
    token: Token;
    linkedCustomerId: number | null;
    customer: Customer;
    associatedMerchantNumber: number;
    hasLoaded: boolean;
    actions: any;
    tokenActions: any;
    customerActions: any;
    commonActions: any;
}

type CrnList = {
    reference1: string;
    reference2: string;
    reference3: string;
}

const NewEditSchedulePage = ({
    scheduleFrequencies, scheduleId, schedule, token, linkedCustomerId, customer, associatedMerchantNumber, hasLoaded,  //data
    actions, tokenActions, customerActions, commonActions, //api actions
}: Props) => {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scheduleFound, setScheduleFound] = useState(!scheduleId);
    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [createIsSuccessful, setCreateIsSuccessful] = useState(false);
    const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false);
    const [linkedPaymentMethod, setLinkedPaymentMethod] = useState<Token | null>(null);
    const [reloadCustomerPaymentMethods, setReloadCustomerPaymentMethods] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [newSchedule, setNewSchedule] = useState(null);
    const [redirectToManageSchedulesPage, setRedirectToManageSchedulesPage] = useState(false);
    const [redirectToViewSchedulePage, setRedirectToViewSchedulePage] = useState(false);
    const pageType = scheduleId ? "Edit" : "New";
    const [tokenPaymentMethods, setTokenPaymentMethods] = useState<Token[]>([]);
    const [tokenLinkedCustomerId, setTokenLinkedCustomerId] = useState(null);
    const [billerCrnList, setBillerCrnList] = useState<CrnList | null>(null);
    const tokenId = useParams().tokenId;
    const [tokenPaymentMethod, tokenPaymentMethodStatus] = useApiCall(async () => {
        if (tokenId) {
            const dto = await tokenApi.getToken(tokenId);
            return { data: { data: dto }, status: 200 };
        }
    }, []);

    useEffect(() => {
        if (tokenPaymentMethodStatus === APICallRequestState.SUCCESSFUL) {
            setTokenPaymentMethods([{
                dataVaultId: tokenPaymentMethod.dataVaultId,
                dataVaultType: token?.dataVaultType,
                expiryDate: tokenPaymentMethod.expiryDate,
                maskedCardNumber: tokenPaymentMethod.maskedCardNumber,
                cardholderName: tokenPaymentMethod.cardholderName,
                deAccountNumber: tokenPaymentMethod.deAccountNumber,
                deBsbNumber: tokenPaymentMethod.deBsbNumber,
                accountName: tokenPaymentMethod.accountName,
                type: tokenPaymentMethod.type,
                cardTypeCode: tokenPaymentMethod.cardTypeCode,
                customerEmail: token?.customerEmail,
                isCustomerEmailVerified: token?.isCustomerEmailVerified,
            }]);
            setBillerCrnList({
                reference1: tokenPaymentMethod.crn1,
                reference2: tokenPaymentMethod.crn2,
                reference3: tokenPaymentMethod.crn3,
            });
            setTokenLinkedCustomerId(tokenPaymentMethod.customerV2Id);
        }
    }, [tokenPaymentMethodStatus]);

    useEffect(() => {
        actions.getScheduleFrequencies();
        if (scheduleId) {
            actions.getSchedule(scheduleId);
        }
    }, [actions]);

    //#region ----- Linked Customer -----
    useEffect(() => {
        if (linkedCustomerId)
            customerActions.getCustomer(linkedCustomerId);
    }, [schedule, linkedCustomerId]);

    useEffect(() => {
        if (!reloadCustomerPaymentMethods || !linkedCustomerId)
            return;

        customerActions.getCustomer(linkedCustomerId);
        setReloadCustomerPaymentMethods(false);
    }, [reloadCustomerPaymentMethods]);
    //#endregion

    //#region ----- Linked Payment Method -----
    useEffect(() => {
        if (schedule) {
            setScheduleFound(true);
            tokenActions.getToken(schedule.dataVaultId);
        }
    }, [schedule]);

    useEffect(() => {
        setLinkedPaymentMethod(token);
    }, [token]);

    useEffect(() => {
        // Upon unmount, clear the token from Redux so it doesn't affect other pages.
        return () => {
            dispatch(tokenActions.clearTokenCache());
        };
    }, []);

    function handleShowPaymentMethodModal() {
        commonActions.clearErrors();
        setShowPaymentMethodModal(true);
    }

    function handleClosePaymentMethodModal() {
        commonActions.clearErrors();
        setShowPaymentMethodModal(false);
        setReloadCustomerPaymentMethods(true);
    }

    function handleTokenAdded(paymentMethod: Token) {
        setLinkedPaymentMethod(paymentMethod);
        setShowPaymentMethodModal(false);

        if (linkedCustomerId) {
            customerActions.linkToken(linkedCustomerId, [paymentMethod.dataVaultId]);
            setReloadCustomerPaymentMethods(true);
        }
    }

    function handleTokenSelected(paymentMethod: Token) {
        setLinkedPaymentMethod(paymentMethod);
    }
    //#endregion

    //#region ----- Schedule Details Form -----
    const handleSubmit = async (values: any) => {
        setNewSchedule(null);

        if (!_.get(linkedPaymentMethod, "dataVaultId")) {
            setValidationErrors(["A payment method must be added"]);
            return;
        }

        if (linkedPaymentMethod?.expiryDate) {
            if (typeof linkedPaymentMethod.expiryDate !== 'string' && linkedPaymentMethod.expiryDate.month && linkedPaymentMethod.expiryDate.year) {
                if (!isValidExpiryDate(linkedPaymentMethod.expiryDate.month, linkedPaymentMethod.expiryDate.year)) {
                    setValidationErrors(["Expiry date is invalid"]);
                    return;
                }
            }
        }

        const newSchedule = {
            subscriptionId: scheduleId,
            customerId: linkedCustomerId ?? tokenLinkedCustomerId,
            ...values,
            dataVaultId: linkedPaymentMethod?.dataVaultId,
        };
        var billerCode=newSchedule.scheduleDetailsBillerCodeForm.billerCode;

        const res= await billerApi.getSurchargeRules(billerCode);

        if(res?.val?.length && !res.val.some((r: any) => r.cardTypeCode === linkedPaymentMethod?.cardTypeCode)){
            setValidationErrors(["Payment method is not supported"]);
            return;
        }

        setValidationErrors([]);

        if (scheduleId) {
            actions.updateSchedule(newSchedule)
                .then(() => setShowUpdateSuccessModal(true));
        } else {

            if (linkedPaymentMethod?.cardTypeCode !== "BA" && !linkedPaymentMethod?.cardholderName) {
                const populateCardholderName = {
                    ...linkedPaymentMethod, // Spread all properties from linkedPaymentMethod
                    cardholderName: linkedPaymentMethod?.accountName, // Override cardholderName
                  } as Token | null;
                setLinkedPaymentMethod(populateCardholderName);
            }

            setNewSchedule(newSchedule);
            setShowConfirmDialog(true);
        }
    }

    function handleCancel() {
        if (scheduleId)
            setRedirectToViewSchedulePage(true);
        else
            setRedirectToManageSchedulesPage(true);
    }
    //#endregion

    function handleConfirm() {
        setCreateIsSuccessful(false);
        setIsSubmitting(true);

        actions.addSchedule(newSchedule)
            .then(() => {
                setIsSubmitting(false);
                setShowConfirmDialog(true);
                setCreateIsSuccessful(true);
            })
            .catch(() => {
                setIsSubmitting(false);
                setNewSchedule(null);
                setShowConfirmDialog(false);
            });
    }

    function handleContinue() {
        setIsSubmitting(true);
        window.location.reload();
    }

    function handleFinish() {
        setRedirectToManageSchedulesPage(true);
    }

    function handleUpdateSuccess() {
        setShowUpdateSuccessModal(false);
        setRedirectToViewSchedulePage(true);
    }

    if (redirectToManageSchedulesPage) {
        if (linkedCustomerId) {
            return <Navigate to={PlatformRoutesConfiguration.customerRoute?.viewCustomer.generatePath(linkedCustomerId)!} />;
        } else if (tokenId) {
            return <Navigate to={PlatformRoutesConfiguration.tokenRoute?.viewToken.generatePath(tokenId)!} />;
        } else {
            return <Navigate to={PlatformRoutesConfiguration.transactionRoute?.manageSchedules.generatePath()!} />;
        }
    }

    if (redirectToViewSchedulePage)
        return <Navigate to={PlatformRoutesConfiguration.transactionRoute?.scheduleDetails.generatePath(scheduleId)!} />;

    if (!hasLoaded)
        return <LoadingIndicator />;

    if (!scheduleFound)
        return <>
            <BackButton to={PlatformRoutesConfiguration.transactionRoute?.manageSchedules.generatePath()} />
            <IconText alert>Schedule not found or an error occurred.</IconText>
        </>;

    return (<>
        <PageSection className="new-edit-schedule-form">
            <PageHeader
                backButton
                title={pageType + " subscription"}
            />

            <h2 className="details-section-header">Subscription details</h2>
            {(tokenId === undefined || billerCrnList) ?
                <ScheduleDetailsForm
                    schedule={schedule}
                    billerCrnList={billerCrnList}
                    scheduleFrequencies={scheduleFrequencies}
                    merchantNumber={associatedMerchantNumber}
                    canSave={!!linkedPaymentMethod}
                    canSelectMerchant={!schedule && !customer}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                >
                    {(biller: Biller) => <>
                        <Divider />
                        <AddPaymentMethodCard
                            selectedPaymentMethod={linkedPaymentMethod}
                            paymentMethods={customer ? (customer.tokens ?? []) :
                                (tokenPaymentMethodStatus === APICallRequestState.SUCCESSFUL && tokenPaymentMethods.length > 0 ? tokenPaymentMethods : [])}
                            onAddClick={handleShowPaymentMethodModal}
                            onChangeClick={handleShowPaymentMethodModal}
                            onPaymentMethodSelected={handleTokenSelected}
                        />
                        <FormError errors={validationErrors} />

                        <AddPaymentMethodModal
                            show={showPaymentMethodModal}
                            parentBiller={biller}
                            onCancel={handleClosePaymentMethodModal}
                            onAdd={handleTokenAdded}
                            customerId={linkedCustomerId}
                        />

                        {newSchedule && <>
                            <ConfirmScheduleDialog
                                biller={biller}
                                show={showConfirmDialog}
                                schedule={newSchedule}
                                paymentMethod={linkedPaymentMethod}
                                scheduleFrequencies={scheduleFrequencies}
                                isSuccessful={createIsSuccessful}
                                isSubmitting={isSubmitting}
                                onSubmit={createIsSuccessful ? handleContinue : handleConfirm}
                                onCancel={createIsSuccessful ? handleFinish : () => setShowConfirmDialog(false)}
                            />
                        </>}
                    </>
                    }
                </ScheduleDetailsForm>
                : <LoadingIndicator />
            }

        </PageSection>

        {scheduleId &&
            <SuccessModal show={showUpdateSuccessModal} onClose={handleUpdateSuccess}>
                Changes saved successfully
            </SuccessModal>
        }
    </>);
};

function mapStateToProps(state: RootState, ownProps: any) {
    const scheduleId = ownProps.match.params.id;
    const schedule = scheduleId ? state.transactions.schedule.details : null;
    const scheduleFrequencies = state.transactions.schedule.scheduleFrequencies;
    const linkedCustomerId = Number(_.get(schedule, "data.customerId", ownProps.match.params.customerId));
    const customer = linkedCustomerId ? state.dataVault.customer.details : null;
    const associatedMerchantNumber =  _.get(schedule, "data.childMerchantNumber") || _.get(customer, "childMerchantNumber") || _.get(state.accounts.users.merchant, "merchantNumber");

    return {
        scheduleId,
        schedule: _.get(schedule, "data"),
        scheduleFrequencies: scheduleFrequencies.data,
        token: state.dataVault.token.details,
        linkedCustomerId: linkedCustomerId ? Number(linkedCustomerId) : null,
        customer,
        associatedMerchantNumber,
        hasLoaded: scheduleFrequencies.hasLoaded && (!scheduleId || schedule.hasLoaded) && (!linkedCustomerId || !_.isEmpty(state.dataVault.customer.details))
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators(scheduleActions, dispatch),
        tokenActions: bindActionCreators(tokenActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

function mapStoreToError(state: RootState) {
    return state.transactions.schedule.details.errors;
}

export default withError(
    connect(mapStateToProps, mapDispatchToProps)(NewEditSchedulePage),
    mapStoreToError
);
