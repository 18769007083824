import React, { ChangeEvent, MouseEvent, useMemo } from 'react';
import { UiOption } from 'models';
import withSelectorGroup from './withSelectorGroup';
import RadioInput from './RadioInput';

type Props<TValue extends string | number> = {
    /** The radio group name. You can only select one radio in a group. */
    name: string,
    options: UiOption<TValue>[],
    selectedOption?: UiOption<TValue>,
    selectedValue?: TValue,
    onChange?: (selectedOption: UiOption<TValue>) => void,

    // Also see SelectorGroupComponentProps in withSelectorGroup.tsx
};

const RadioGroup = <TValue extends string | number>({
    name,
    options, selectedOption, selectedValue, onChange,
    ...otherProps
}: Props<TValue>) => {

    if(selectedOption && selectedValue)
        throw new Error('You cannot set both selectedOption and selectedValue');

    const [selected, setSelected] = React.useState<UiOption<TValue> | null>(null);

    useMemo(() => {
        setSelected(options.find(o => o.value === selectedValue) ?? null);
    }, [options, selectedValue]);

    function handleRadioClick(e: MouseEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) {
        var sel = options.filter(o => e.currentTarget.value == o.value)[0];  // eslint-disable-line eqeqeq
        setSelected(sel);
        onChange && onChange(sel);
    }

    function getIsChecked(option: UiOption<TValue>) {
        return option === selectedOption ||
               option.value === selectedValue ||
               option === selected;
    }

    return (<>
        {options.map(option => {
            return (
                <RadioInput
                    checked={getIsChecked(option)}
                    name={name}
                    value={option.value}
                    key={name + '_' + option.value}
                    disabled={option.disabled}
                    onClick={handleRadioClick}
                    onChange={handleRadioClick}
                    {...otherProps}
                >
                    {option.label}
                </RadioInput>
            );
        })}
    </>);
};

export default withSelectorGroup(RadioGroup, 'radiogroup');
