import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import { EFormsContextProvider } from "../contexts/EFormsContext";
import eFormsApi from "api/eFormsApi";
import { APICallRequestState, ClickableButton, Image, PageHeader, useApiCall } from "components/Common";
import { Button, Checkbox, Dialog, Icon, LoadingIndicator, PaddedContainer, PageSection, ServerError, SingleColumnFormContainer, SuccessModal, Tab, Tabs } from "packages/ui";
import { Field, FormikProvider, useFormik } from "formik";
import { FormGroup, Input } from "packages/formik-ui";
import * as Yup from "yup";
import EditControlPanel from "../components/EditControlPanel";
import { generateNewControlId, getControlTypeDefaultAttributes, toControlTypeEnum } from "../helpers";
import EditFormStyle, { EditFormStyleFormValues } from "../components/EditFormStyle";
import { ControlAttributes, Control } from "../types";
import { Biller, FormAttributes, FormControlModel } from "packages/webapi-client";
import EFormsHppPreview from "../components/EFormsHppPreview";
import { defaultBackgroundColor } from "../constants";
import { ControlType, HppPreviewMode } from "../enums";
import { printImage } from "../../../util/imageUtil";
import { setFormikErrors } from "../../../util/formikUtil";
// @ts-ignore
import { PlatformRoutesConfiguration } from 'components/Routing';
import moment from "moment";
import DeleteEFormDialogs from "../components/DeleteEFormDialogs";
import DuplicateEFormDialogs from "../components/DuplicateEFormDialogs";

import "./EditEFormPage.scss";
import FormikCheckbox from "packages/formik-ui/src/FormikCheckbox";

type Props = {
    billers: Biller[];
}

type FormValues = {
    title: string;
    attributes: FormAttributes;
    publish: boolean;
    visible: boolean;
};

type PublishFormValues = {
    makeFormPublic: boolean;
};

const tabKeys = {
    formContent: "formContent",
    formStyle: "formStyle",
};

const fields = {
    title: "Form name"
};

const EditEFormPage = ({ billers }: Props) => {
    const { formId } = useParams();
    const [refreshCount, setRefreshCount] = useState(0);
    const [eForm, eFormStatus] = useApiCall(() => eFormsApi.getEForm(Number(formId)), [formId, refreshCount]);
    const [activeTab, setActiveTab] = useState<string | null>(tabKeys.formContent);
    const [errors, setErrors] = useState<string[]>([]);
    const [showEditControlPanel, setShowEditControlPanel] = useState(false);
    const [controls, setControls] = useState<Control[]>([]);
    const [editorControl, setEditorControl] = useState<Control>();
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showRestoreModal, setShowRestoreModal] = useState(false);
    const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);
    const [showPublishSuccessModal, setShowPublishSuccessModal] = useState(false);
    const [showRestoreSuccessModal, setShowRestoreSuccessModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    const [focusedId, setFocusedId] = useState<string>("");
    const navigate = useNavigate();

    const publishFormFormik = useFormik<PublishFormValues>({
        initialValues: {
            makeFormPublic: false
        },
        onSubmit: async (formValues: PublishFormValues) => {
            // Call the other form
            formik.setFieldValue("publish", true);
            formik.setFieldValue("visible", formValues.makeFormPublic);
            formik.handleSubmit();
        }
    });

    const formik = useFormik<FormValues>({
        initialValues: {
            title: "",
            attributes: {},
            publish: false,
            visible: false,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().label(fields.title).trim().required(),
        }),
        validateOnChange: false,
        onSubmit: async (formValues: FormValues, helpers) => {
            try {
                setErrors([]);
                const formControls: FormControlModel[] = controls.map(c => {
                    return {
                        controlType: c.controlType,
                        attributes: c.attributes,
                    };
                });

                const response = await eFormsApi.saveEForm(Number(formId), formValues.title, formValues.attributes, formControls, formValues.publish, formValues.visible);

                if (response.status === 200) {
                    if (formValues.publish) {
                        setShowPublishModal(false);
                        setShowPublishSuccessModal(true);
                    } else {
                        setShowSaveSuccessModal(true);
                    }

                    publishFormFormik.setFieldValue("makeFormPublic", false);
                    setRefreshCount(refreshCount + 1);
                } else {
                    if (response.data.errors && response.data.errors?.length > 0) {
                        setFormikErrors<FormValues>(helpers, response.data.errors, fields);
                    } else {
                        setErrors(["An error occurred while saving the eForm."]);
                    }
                }

            } catch {
                setErrors(["An error occurred while saving the eForm."]);
            }

            formik.setFieldValue("publish", false);
            formik.setFieldValue("visible", false);
        },
    });

    const placeSubmitControlLast = (controls: Control[]) => {
        // Always have the submit button on the bottom
        const submitControl = controls.find(c => c.controlType === ControlType.Submit);

        if (submitControl) {
            controls = controls.filter(c => c.id !== submitControl.id);
            controls.push(submitControl);
        }

        return controls.map((c, i) => {
            c.order = i;
            return c;
        });
    };

    useMemo(() => {
        if (eForm && eForm.controls) {
            const controls = eForm.controls.map((c, i) => {
                const controlAttributes: ControlAttributes = c.attributes ?? {};

                return {
                    id: generateNewControlId(),
                    controlType: toControlTypeEnum(c.controlType ?? ""),
                    attributes: controlAttributes,
                    order: i
                }
            });

            // Add submit button control if it doesn't exist
            if (!controls.find(c => c.controlType === ControlType.Submit)) {
                const defaultAttributes = getControlTypeDefaultAttributes(ControlType.Submit);

                controls.push({
                    id: generateNewControlId(),
                    controlType: ControlType.Submit,
                    attributes: defaultAttributes,
                    order: controls.length
                });
            }

            setControls(placeSubmitControlLast(controls));
        }
    }, [eForm]);

    useMemo(() => {
        if (eFormStatus === APICallRequestState.SUCCESSFUL) {
            if (!eForm) {
                navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
            }

            formik.setValues({
                title: eForm?.title ?? "",
                attributes: eForm?.attributes ?? {},
                publish: false,
                visible: false
            });
        } else if (eFormStatus === APICallRequestState.ERROR) {
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }
    }, [eForm, eFormStatus]);

    const handleFormStyleChange = (values: EditFormStyleFormValues) => {
        if (eForm && eForm.attributes) {
            eForm.attributes.backgroundColor = values.backgroundColor;
            eForm.attributes.fontFamily = values.fontType;
            eForm.attributes.fontSize = values.fontSize;
            eForm.attributes.fontColor = values.fontColor;
            eForm.attributes.labelAlign = values.labelAlign;
        }

        setControls(controls => controls.map(c => {
            if (c.attributes) {
                c.attributes.fontFamily = values.fontType;
                c.attributes.fontSize = values.fontSize;
                c.attributes.fontColor = values.fontColor;
                c.attributes.labelAlign = values.labelAlign;
            }

            return c;
        }));
    };

    const handleDeleteAll = () => {
        setControls(controls.filter(c => c.controlType === ControlType.Submit));
    };

    const upsertControl = (controls: Control[], control: Control) => {
        // This line of code is a bit long so needs some explaining. It removes the control from the list of controls, then adds it back in at the correct order, then sorts the controls by order.
        return placeSubmitControlLast([...controls.filter(x => x.id !== control.id), control].sort((a, b) => a.order - b.order)).sort((a, b) => a.order - b.order)
    };

    const handleSave = (control: Control, originalControlType: ControlType | undefined, billerCode: string, submitType: string, customUrl: string, successMessage: string, showBillerCode: boolean, showCrn1: boolean, showCrn2: boolean, showCrn3: boolean) => {
        const billerCodeChanged = eForm?.attributes?.billerCode !== billerCode;

        if (eForm && eForm.attributes) {
            eForm.attributes.billerCode = billerCode;
            eForm.attributes.submitType = submitType;
            eForm.attributes.customUrl = customUrl;
            eForm.attributes.successMessage = successMessage;
            eForm.attributes.showBillerCode = showBillerCode;
            eForm.attributes.showCrn1 = showCrn1;
            eForm.attributes.showCrn2 = showCrn2;
            eForm.attributes.showCrn3 = showCrn3;
        }

        const biller = billers.find(x => x.billerCode === billerCode);
        let removedCrn1 = false;
        let removedCrn2 = false;
        let removedCrn3 = false;

        // If the biller code has changed, we need to update the CRN controls with the new biller's CRN names.
        // In some cases, the CRN controls may need to be removed if the biller does not use them.
        if (billerCodeChanged) {
            if (biller?.acceptedCrn1 && biller.acceptedCrn1.isActive) {
                const crn1Control = controls.find(x => x.controlType === ControlType.Crn1);
                if (crn1Control) {
                    crn1Control.attributes.label = biller.acceptedCrn1.crnName;
                    setControls(controls => upsertControl(controls, crn1Control));
                }
            } else {
                removedCrn1 = true;
                setControls(controls => controls.filter(x => x.controlType !== ControlType.Crn1));
            }

            if (biller?.acceptedCrn2 && biller.acceptedCrn2.isActive) {
                const crn2Control = controls.find(x => x.controlType === ControlType.Crn2);
                if (crn2Control) {
                    crn2Control.attributes.label = biller.acceptedCrn2.crnName;
                    setControls(controls => upsertControl(controls, crn2Control));
                }
            } else {
                removedCrn2 = true;
                setControls(controls => controls.filter(x => x.controlType !== ControlType.Crn2));
            }

            if (biller?.acceptedCrn3 && biller.acceptedCrn3.isActive) {
                const crn3Control = controls.find(x => x.controlType === ControlType.Crn3);
                if (crn3Control) {
                    crn3Control.attributes.label = biller.acceptedCrn3.crnName;
                    setControls(controls => upsertControl(controls, crn3Control));
                }
            } else {
                removedCrn3 = true;
                setControls(controls => controls.filter(x => x.controlType !== ControlType.Crn3));
            }
        }

        const crnControlTypes = [ControlType.Crn1, ControlType.Crn2, ControlType.Crn3];

        // We need to use originalControlType not control.controlType
        if (!originalControlType || !crnControlTypes.includes(originalControlType) || (originalControlType === ControlType.Crn1 && !removedCrn1) || (originalControlType === ControlType.Crn2 && !removedCrn2) || (originalControlType === ControlType.Crn3 && !removedCrn3)) {
            setControls(controls => upsertControl(controls, control));
        }

        setShowEditControlPanel(false);
        formik.setFieldValue("attributes.billerCode", billerCode);
        formik.setFieldValue("attributes.submitType", submitType);
        formik.setFieldValue("attributes.customUrl", customUrl);
        formik.setFieldValue("attributes.successMessage", successMessage);
        formik.setFieldValue("attributes.showBillerCode", showBillerCode);
        formik.setFieldValue("attributes.showCrn1", showCrn1);
        formik.setFieldValue("attributes.showCrn2", showCrn2);
        formik.setFieldValue("attributes.showCrn3", showCrn3);

        setFocusedId(control.id);
    };

    const handleRestore = async () => {
        try {
            setErrors([]);
            formik.setSubmitting(true);
            const response = await eFormsApi.restoreEForm(Number(formId));

            if (response.status === 200) {
                setShowRestoreModal(false);
                setShowRestoreSuccessModal(true);
                setRefreshCount(refreshCount + 1);
            } else {
                setErrors(["An error occurred while restoring the eForm."]);
            }
        } catch {
            setErrors(["An error occurred while restoring the eForm."]);
        }
        formik.setSubmitting(false);
    };

    const handleControlClick = (control: Control) => {
        setEditorControl(control);
        setShowEditControlPanel(true);
    };

    const handleEditorClose = () => {
        setEditorControl(undefined);
        setShowEditControlPanel(false);
    };

    const handleControlEditClicked = (id: string) => {
        const control = controls.find(c => c.id === id);

        if (control) {
            handleControlClick(control);
        }
    };

    const handleControlDuplicateClicked = (id: string) => {
        const control = controls.find(c => c.id === id);

        if (control) {
            const newControlOrder = [
                ...controls.filter(c => c.order <= control.order),
                { ...control, id: generateNewControlId() },
                ...controls.filter(c => c.order > control.order),
            ];

            // Re-order controls
            newControlOrder.forEach((c, i) => c.order = i);

            setControls(newControlOrder);
        }
    };

    const handleControlRemoveClicked = (id: string) => {
        setControls(controls.filter(c => c.id !== id));
    };

    const handlePreviewClick = () => {
        window.open(eForm?.previewUrl, "_blank");
    };

    const handleDuplicateSuccessModalClose = (formId: number) => {
        navigate(PlatformRoutesConfiguration.designerRoute!.editEForm.generatePath(formId));
    };

    const handleDeleteSuccessModalClose = () => {
        navigate(PlatformRoutesConfiguration.designerRoute!.eForms.generatePath());
    };

    const handleBillerCodeChanged = (newBillerCode: string) => {
        // The biller code has changed so we need to remove any CRNs that are no longer supported
        const biller = billers.find(x => x.billerCode === newBillerCode);

        if (!biller?.acceptedCrn2 || !biller.acceptedCrn2.isActive) {
            setControls(controls => controls.filter(x => x.controlType !== ControlType.Crn2));
        }

        if (!biller?.acceptedCrn3 || !biller.acceptedCrn3.isActive) {
            setControls(controls => controls.filter(x => x.controlType !== ControlType.Crn3));
        }
    };

    // Triggers an actual download for the user rather than loading it within the browser
    const downloadQrCode = () => {
        const a = document.createElement("a");
        a.href = "data:image/png;base64," + eForm?.qrCodeBase64;
        a.download = `qr_code_${eForm?.attributes?.billerCode ?? "_payment_page"}.png`;
        a.click();
    };

    const handleControlSelectionChanged = (id: string, selected: string | string[]) => {
        const control = controls.find(c => c.id === id);

        if (control) {
            control.attributes.selected = selected;
        }
    };

    const handleClosePublishModal = () => {
        publishFormFormik.setFieldValue("makeFormPublic", false);
        setShowPublishModal(false);
    };

    return <EFormsContextProvider>
        <PageSection noDivider>
            <PageHeader title="Edit eForm" backButton={{ onClick: () => navigate(PlatformRoutesConfiguration.designerRoute!.eForms.generatePath()) }} />
            {(!eForm?.published || (eForm?.updatedAt && eForm?.publishedAt && (moment(eForm?.updatedAt).toDate() > moment(eForm?.publishedAt).toDate()))) && <PaddedContainer blueBorder lessMargin>
                <Icon info /> The following settings haven't been published yet, please click "Publish" to publish this eForm.
            </PaddedContainer>}
            <PaddedContainer title={<h3>eForm URL</h3>} button={<ClickableButton clickableButtonId="copy-eform-url" label="Copy URL" text={eForm?.paymentUrl ?? ""} link={false} />} noDivider>
                <p>
                    The eForm URL is: <a href={eForm?.paymentUrl} target="_blank" rel="noreferrer">{eForm?.paymentUrl}</a>
                </p>
                <p>
                    To view and download the QR code for this payment page, please click <Button onClick={() => setShowQrCodeModal(true)} link>here</Button>.
                </p>
            </PaddedContainer>
        </PageSection>
        {eFormStatus === APICallRequestState.LOADING || eFormStatus === APICallRequestState.PENDING ?
            <LoadingIndicator /> :
            <>
                <PageSection noMarginTop>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <h3>Form detail</h3>
                            <SingleColumnFormContainer>
                                <FormGroup name="title" label="Form name" mandatory>
                                    <Field name="title" as={Input} />
                                </FormGroup>
                            </SingleColumnFormContainer>
                        </form>
                    </FormikProvider>
                </PageSection>
                <PageSection noPaddingBottom>
                    <Tabs activeKey={activeTab!} onSelect={(tabKey) => setActiveTab(tabKey)}>
                        <Tab eventKey={tabKeys.formContent} title="Form content">
                            <PaddedContainer lessMargin>
                                {showEditControlPanel ?
                                    <EditControlPanel
                                        formAttributes={eForm?.attributes}
                                        control={editorControl}
                                        controls={controls}
                                        onSave={handleSave}
                                        onCancel={handleEditorClose}
                                        onBillerCodeChanged={handleBillerCodeChanged}
                                    /> :
                                    <>
                                        <div className="hpp-preview-container">
                                            <p>Select your added component to edit</p>
                                            <EFormsHppPreview
                                                controls={controls}
                                                mode={HppPreviewMode.Content}
                                                focusedId={focusedId}
                                                style={{ backgroundColor: eForm?.attributes?.backgroundColor ?? defaultBackgroundColor }}
                                                onControlEditClicked={handleControlEditClicked}
                                                onControlDuplicateClicked={handleControlDuplicateClicked}
                                                onControlRemoveClicked={handleControlRemoveClicked}
                                                onControlSelectionChanged={handleControlSelectionChanged}
                                            />
                                        </div>
                                        <Button onClick={() => { setEditorControl(undefined); setShowEditControlPanel(true); }}>Add new component</Button>
                                    </>}
                            </PaddedContainer>
                        </Tab>
                        <Tab eventKey={tabKeys.formStyle} title="Form style">
                            <EditFormStyle form={eForm} controls={controls} onChange={handleFormStyleChange} onDeleteAll={handleDeleteAll} />
                        </Tab>
                    </Tabs>
                </PageSection>
                <PageSection>
                    <ServerError errors={errors} />
                    <Button onClick={() => setShowPublishModal(true)} disabled={formik.isSubmitting} primary>Publish</Button>
                    <Button onClick={() => formik.handleSubmit()} disabled={formik.isSubmitting}>Save</Button>
                    <Button onClick={handlePreviewClick} disabled={formik.isSubmitting}>Preview</Button>
                    <Button onClick={() => setShowDuplicateModal(true)} disabled={formik.isSubmitting}>Duplicate</Button>
                    <Button onClick={() => setShowRestoreModal(true)} disabled={formik.isSubmitting || !eForm?.published}>Restore</Button>
                    <Button onClick={() => setShowDeleteModal(true)} disabled={formik.isSubmitting} subtle center>Delete eForm</Button>
                </PageSection>
            </>}
        <Dialog
            show={showRestoreModal}
            icon={<Icon alert />}
            title="Restore to previous eForm?"
            footerButtons={<>
                <Button onClick={handleRestore} disabled={formik.isSubmitting}>Discard changes</Button>
                <Button onClick={() => setShowRestoreModal(false)} disabled={formik.isSubmitting}>Cancel</Button>
            </>}
            onClose={() => setShowRestoreModal(false)}
            closeButton
        >
            All the changes you have made since last published version will be discarded.
        </Dialog>
        <Dialog
            show={showPublishModal}
            title="Publish eForm"
            footerButtons={<>
                <Button onClick={() => publishFormFormik.handleSubmit()} disabled={formik.isSubmitting} primary>Publish form</Button>
                <Button onClick={handleClosePublishModal} disabled={formik.isSubmitting}>Cancel</Button>
            </>}
        >
            Are you sure you want to save and publish the current form?
            <ul>
                <li>Please click on the Publish form button to save & publish the form.</li>
                <li>Click on the Cancel if you do not wish to proceed.</li>
            </ul>
            {!eForm?.visible &&
                <div className="visibility-form">
                    <FormikProvider value={publishFormFormik}>
                        <form>
                            <FormGroup name="makeFormPublic">
                                <Field id="makeFormPublic" name="makeFormPublic" label="Make form public" as={FormikCheckbox} />
                            </FormGroup>
                        </form>
                    </FormikProvider>
                </div>}
        </Dialog>
        <Dialog
            show={showQrCodeModal}
            title="QR code for eForm"
            footerButtons={<>
                <Button onClick={downloadQrCode} disabled={formik.isSubmitting} primary>Download QR code</Button>
                <Button onClick={() => eForm && eForm.qrCodeBase64 && printImage(eForm.qrCodeBase64)} disabled={formik.isSubmitting}>Print image</Button>
            </>}
            onClose={() => setShowQrCodeModal(false)}
            closeButton
        >
            The following is the QR code for {eForm?.title} eForm. You can download and use it in your material to facilitate with the collection of form submissions.
            <div className="qr-code-container">
                <Image base64String={eForm?.qrCodeBase64} altText="eForm QR Code" />
            </div>
        </Dialog>
        <SuccessModal show={showSaveSuccessModal} onClose={() => setShowSaveSuccessModal(false)} title="eForm saved successfully!" />
        <SuccessModal show={showPublishSuccessModal} onClose={() => setShowPublishSuccessModal(false)} title="eForm published successfully!" />
        <SuccessModal show={showRestoreSuccessModal} onClose={() => setShowRestoreSuccessModal(false)} title="eForm restored successfully!" />
        <DeleteEFormDialogs
            formId={eForm?.formId ?? 0}
            show={showDeleteModal}
            onDeleting={() => setErrors([])}
            onDeleted={() => setShowDeleteModal(false)}
            onError={(error) => setErrors([error])}
            onCancel={() => setShowDeleteModal(false)}
            onDeleteSuccessModalClose={handleDeleteSuccessModalClose}
        />
        <DuplicateEFormDialogs
            formId={eForm?.formId ?? 0}
            show={showDuplicateModal}
            onDuplicating={() => setErrors([])}
            onDuplicated={() => setShowDuplicateModal(false)}
            onError={(error) => setErrors([error])}
            onCancel={() => setShowDuplicateModal(false)}
            onDuplicateSuccessModalClose={handleDuplicateSuccessModalClose}
        />
    </EFormsContextProvider>;
};

function mapStateToProps(state: RootState) {
    return {
        billers: state.accounts.users.billers,
    };
}

export default connect(mapStateToProps)(EditEFormPage);
