import { ReactNode } from "react";
import { useField } from "formik";
import classNames from "classnames";
import CheckboxRadioWrapper from "./CheckboxRadioWrapper"

type Props = {
    name: string,
    // This is mandatory because you can't click on the option without it, think of it as the name of the widget
    id: string,
    label: ReactNode,
    mandatory?: boolean,
    error?: string,
    className?: string,
};

/** A single checkbox with label + error message */
const FormikCheckbox = ({
    name,
    label,
    error,
    mandatory,
    id,
    className,
    ...otherProps
}: Props) => {
    const [field] = useField(name);

    return (
        <CheckboxRadioWrapper label={label} mandatory={mandatory} error={error} id={id} className={className}>
            <input {...otherProps}
                id={id}
                name={name}
                type="checkbox"
                className={classNames("form-check-input", {
                    "has-error": !!error
                })}
                checked={field.value}
            />
        </CheckboxRadioWrapper>
    );
};

export default FormikCheckbox;
