import React, { ReactNode } from 'react';
import classNames from 'classnames/dedupe';
import { Link as ReactLink, LinkProps as ReactLinkProps } from 'react-router-dom';
import './Link.scss';

const allVisualTypes: {prop: keyof Props, className: string}[] = [
    { prop: 'button', className: 'btn' },
    { prop: 'primary', className: 'btn btn-primary' },
    { prop: 'subtle', className: 'btn btn-subtle' },
    { prop: 'left', className: 'left' },
    { prop: 'center', className: 'center' },
    { prop: 'right', className: 'right' },
    { prop: 'arrow', className: 'arrow' },
];

type Props = Omit<ReactLinkProps, 'to'> & {
    to?: ReactLinkProps['to'] | string,

    /** Style it as a secondary button */
    button?: boolean,
    /** Style it as a primary button */
    primary?: boolean,
    /** Style it as a subtle button */
    subtle?: boolean,
    /** Only applicable if styled as a subtle button */
    left?: boolean,
    /** Only applicable if styled as a subtle button */
    center?: boolean,
    /** Only applicable if styled as a subtle button */
    right?: boolean,
    /** Styled as a big bold black text with right arrow */
    arrow?: boolean,

    disabled?: boolean,
    /** Open link in a new window */
    newWindow?: boolean,

    /** Remove the bottom margin */
    noMarginBottom?: boolean,

    className?: string,
    children: ReactNode,
};

/**
 * A link that takes you to another page when clicked. Other than its default style, it can also be styled as a button.
 * Link has an arrow style (that looks similar to Accordion) that Button doesn't have.
 * While Link and Button can look exactly the same, a Button does an action when clicked (including opening a Modal),
 * where a Link only takes you to another page.
 */
const Link = (props: Props) => {
    const { button, primary, subtle, left, center, right, arrow,  // Link visual types
        to, className, disabled, newWindow, noMarginBottom,
        ...otherProps} = props;

    const linkProps: Partial<ReactLinkProps> = {};

    if (newWindow) {
        linkProps.target = "_blank";
        linkProps.rel = 'noopener noreferrer';
    }

    const visualClasses = allVisualTypes.filter(x => props[x.prop]).map(x => x.className);

    const cssClasses = classNames(
        visualClasses,
        {'disabled': disabled},
        {'no-margin-bottom': noMarginBottom},
        className);

    if(typeof to === 'string' && to.toLowerCase().indexOf('http') === 0) {
        return <a className={cssClasses} href={to ?? ""} {...linkProps} {...otherProps}>{props.children}</a>
    }

    return <ReactLink className={cssClasses} to={to ?? ""} {...linkProps} {...otherProps}>{props.children}</ReactLink>
};

export default Link;