import { ControlAttributes } from "components/EForms/types";
import { ChangeEvent, useState } from "react";
import { validateRequiredInput } from "components/EForms/helpers";
import classNames from "classnames";
import Input from "./Input";
import { OtherOptionPlaceholder } from "../../constants";

import "./Label.scss";

type DropdownComponentProps = {
    attributes: ControlAttributes;
    labeledInput?: boolean;
    label?: string;
    options: { value: string, label: string }[];
    defaultValue?: string;
    mandatory?: boolean;
    showErrors?: boolean;
    onChange?(value: string, valid: boolean): void;
}

const DropdownComponent = ({ attributes, labeledInput, label, options, defaultValue, mandatory = true, showErrors = true, onChange }: DropdownComponentProps) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? "");
    const [otherInputFieldDisabled, setOtherInputFieldDisabled] = useState(defaultValue !== OtherOptionPlaceholder);
    const [error, setError] = useState("");

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        let validationResult = true;
        setError("");
        setSelectedValue(e.target.value);

        if (attributes.required) {
            validationResult = validateRequiredInput(e.target.value, setError);
        }

        const shouldDisableOtherInput = !(attributes.allowOtherOption && e.target.value === OtherOptionPlaceholder)
        setOtherInputFieldDisabled(shouldDisableOtherInput);

        onChange && onChange(e.target.value, validationResult);
    };

    return (
        <>
            <div className={labeledInput ? "" : `jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                {labeledInput && label &&
                    <label style={{ fontFamily: attributes.fontFamily }}>
                        {label}
                    </label>}
                <select className={classNames("eform-ctrl-input jsElementAttributeInput", { "not-mandatory": !mandatory })} value={selectedValue} onChange={handleChange}>
                    <option value=""></option>
                    {[
                        ...options,
                        ...(attributes.allowOtherOption ? [{ value: OtherOptionPlaceholder, label: OtherOptionPlaceholder }] : [])
                    ].map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>

                {attributes.allowOtherOption &&
                    <div className="eform-ctrl-dropdown-othersection eform-col-3">
                        <Input disabled={otherInputFieldDisabled} attributes={{ ...attributes, ...(attributes.allowOtherOption ? { placeholderText: OtherOptionPlaceholder } : {}) }} />
                    </div>
                }

            </div>
            {showErrors && error && <div className="errors" style={{ fontFamily: attributes.fontFamily }}><div>{error}</div></div>}
        </>
    );
};

export default DropdownComponent;
