import { EFormsApiFactory, FormAttributes, FormControlModel } from "packages/webapi-client";
import { config } from "./util";

const eFormsApi = EFormsApiFactory(config);

class EFormsApi {
    static getEForms(page: number, pageSize: number) {
        return eFormsApi.eFormsGetEForms(page, pageSize);
    }

    static getEForm(formId: number) {
        return eFormsApi.eFormsGetEForm(formId);
    }

    static setVisibility(formId: number, visible: boolean) {
        return eFormsApi.eFormsSetEFormVisibility(formId, { formId, visible });
    }

    static createEForm(title: string) {
        return eFormsApi.eFormsCreateEForm({ title: title });
    }

    static saveEForm(formId: number, title: string, attributes: FormAttributes, controls: FormControlModel[], publish: boolean, visible: boolean) {
        return eFormsApi.eFormsSaveEForm({ formId, title, attributes, controls, publish, visible });
    }

    static restoreEForm(formId: number) {
        return eFormsApi.eFormsRestoreEForm(formId);
    }

    static getReportableEForms() {
        return eFormsApi.eFormsGetReportableEForms();
    }

    static getSubmittedEForms(formId: number, startDate: string, endDate: string, includeDownloaded: boolean) {
        return eFormsApi.eFormsGetSubmittedEForms(formId, startDate, endDate, includeDownloaded);
    }

    static downloadEFormSubmission(formId: number, version: number, submissionDate: string) {
        return eFormsApi.eFormsGetSubmissionReport(formId, version, submissionDate);
    }

    static duplicateEForm(formId: number) {
        return eFormsApi.eFormsDuplicateEForm(formId);
    }

    static deleteEForm(formId: number) {
        return eFormsApi.eFormsDeleteEForm(formId);
    }
}

export default EFormsApi;
