import { FC, useContext, useEffect, useState, useRef } from "react";
import _ from "lodash";
import { CurrencyField, Form, FormContext, InputField, DropdownField, validate } from "@premier/form";
import { ResponsiveTable } from "@premier/ui";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import { useInvoiceSettings } from "components/Settings/_hooks/useInvoicesSettings";
import InvoiceMaxItemsMessage from "./InvoiceMaxItemsMessage";
import InvoiceItemsErrors from "./InvoiceItemsErrors";
import IsFormDirty from "./IsFormDirty";
import currencyUtil from "@premier/utils/currency";
import { itemTemplateString, maxCurrency, silentValidation, replaceTemplateWithIndex } from "./invoiceUtil";

import "./InvoiceItemsForm.scss";

const InvoiceItemsFormCopy = {
    maxItemsMessage: "You have reached the maximum number of invoice items.",
};

const labels = {
    description: "Description",
    unitPrice: "Unit price",
    quantity: "Quantity",
    tax: "Tax",
    amount: "Amount (AUD)",
};

export const fieldNames = {
    description: "description",
    unitPrice: "unitPrice",
    quantity: "quantity",
    tax: "tax",
    amount: "amount",
};

interface ItemRecord extends ResponsiveTableRecordPartial {
    id: string;
    description?: string;
    unitPrice?: number;
    quantity?: string;
    tax?: string;

    noBatchActions: boolean;
}

const createNewItemRecord = (id: string) => ({
    id,
    description: null,
    unitPrice: null,
    quantity: null,
    tax: null,
    amount: null,

    noBatchActions: true,
});

interface Props {
    name: string;
    maxItems?: number;
    subtotalExTax?: string;
    updateTotals: () => void;
    setIsDirty?: (value: boolean) => void;
}

const InvoiceItemsForm: FC<Props> = ({ name, maxItems, subtotalExTax, updateTotals, setIsDirty }) => {
    const invoiceSettings = useInvoiceSettings();
    const { values, setValue, setFormValues, setValidation } = useContext(FormContext);
    const [tableData, setTableData] = useState<ItemRecord[]>([]);
    const [totalsHash, setTotalsHash] = useState<string>("");
    const newItemAdded = useRef(false);

    // Automatically add a new row when there is no blank row (unless max rows has been reached)
    // Use useEffect() combine with useRef() here instead of useMemo() because useMemo() will be executed during the render phase, which will cause warnings when setState is called inside it.
    useEffect(() => {
        if (newItemAdded.current) {
            newItemAdded.current = false;
            return;
        }

        const newTableData: ItemRecord[] = [];
        let hasBlankRecord = false;

        for (const prop in values[name]) {
            const element = values[name][prop];
            if (element !== undefined) {
                newTableData.push(Object.assign({}, { ...element, id: prop }));

                if (element.noBatchActions) {
                    hasBlankRecord = true;
                }
            }
        }

        // Add a blank item if there are no blank items
        if (!hasBlankRecord && (!maxItems || newTableData.length < maxItems)) {
            newItemAdded.current = true;
            const newRecordId = `${_.uniqueId("element_")}`;
            setValue(`${name}.${newRecordId}`, createNewItemRecord(newRecordId));
        }

        // Update the table data if the context values change
        setTableData(newTableData);
    }, [values, maxItems, name, setTableData, setValue]);

    // Sets value to show the checkbox when a record is altered
    const updateItemRecord = (item: ItemRecord) => () => {
        if (item.noBatchActions) {
            // Add the checkbox now this row has data
            setValue(`${name}.${item.id}.noBatchActions`, false);

            // Add validation to this row
            setValidation(
                `${name}.${item.id}.${fieldNames.description}`,
                validate()
                    .required(`Description of ${itemTemplateString} is required.`)
                    .maxLength(200, `Description of ${itemTemplateString} exceeds 200 characters.`)
            );
            setValidation(
                `${name}.${item.id}.${fieldNames.unitPrice}`,
                validate()
                    .required(`Unit price of ${itemTemplateString} is required.`)
                    .lessThanOrEqual(
                        maxCurrency,
                        `Unit price of ${itemTemplateString} exceeds ${currencyUtil.formatWithPrefix(maxCurrency)}.`
                    )
            );
            setValidation(
                `${name}.${item.id}.${fieldNames.quantity}`,
                validate().required(`Quantity of ${itemTemplateString} is required.`)
            );
            setValidation(
                `${name}.${item.id}.${fieldNames.tax}`,
                validate().required(`Tax of ${itemTemplateString} is required.`)
            );
            setValidation(
                `${name}.${item.id}.${fieldNames.amount}`,
                validate().lessThanOrEqual(
                    maxCurrency,
                    `Amount of ${itemTemplateString} exceeds ${currencyUtil.formatWithPrefix(maxCurrency)}.`
                )
            );
        }
    };

    // Hash the important fields and update the totals when the hash changes
    const hashAndUpdateTotals = () => {
        const data = [];
        for (const prop in values[name]) {
            if (
                values[name][prop] &&
                values[name][prop][fieldNames.description] &&
                values[name][prop][fieldNames.unitPrice] &&
                values[name][prop][fieldNames.quantity] &&
                values[name][prop][fieldNames.tax]
            ) {
                data.push(values[name][prop][fieldNames.unitPrice]);
                data.push(values[name][prop][fieldNames.quantity]);
                data.push(values[name][prop][fieldNames.tax]);
            }
        }

        const newHash = data.join("");
        if (newHash !== totalsHash) {
            setTotalsHash(newHash);
            updateTotals();
        }
    };

    // Callback for delete batch action
    const removeItems = (items: ItemRecord[]) => {
        const newValues = { ...values };
        items.forEach((item) => {
            newValues[name][item.id] = undefined;

            setValidation(`${name}.${item.id}.${fieldNames.description}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.unitPrice}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.quantity}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.tax}`, undefined);
            setValidation(`${name}.${item.id}.${fieldNames.amount}`, undefined);
        });
        setFormValues(newValues);

        hashAndUpdateTotals();
    };

    // Methods for calculating the Amount column
    const calculateAmount = (unitPriceValue: number, quantityValue: string) =>
        parseFloat(quantityValue || "0") * (unitPriceValue || 0);

    // Convert error messages to readable errors
    const validationErrorMessage = (errors: any) => {
        const errorMessages = [];

        for (let i = 0; i < tableData.length; i++) {
            const data = tableData[i];
            const error = errors[data.id];
            if (error && !data.noBatchActions) {
                errorMessages.push(
                    ...[
                        error[fieldNames.description],
                        error[fieldNames.unitPrice],
                        error[fieldNames.quantity],
                        error[fieldNames.tax],
                        error[fieldNames.amount],
                    ].map((e) => replaceTemplateWithIndex(i, e))
                );
            }
        }

        return errorMessages.filter((em) => !!em) as string[];
    };

    const validation = {};

    const render = (context: any) => {
        // Handler for unit/price and quantity fields
        const onUnitPriceOrQuantityChange = (
            item: ItemRecord,
            context: any,
            unitPriceValue?: number,
            quantityValue?: string
        ) => {
            // First update as normal
            updateItemRecord(item)();

            // Calculate and set the new amount
            const unitPrice = unitPriceValue ?? context.getValue(`${item.id}.${fieldNames.unitPrice}`);
            const quantity = quantityValue ?? context.getValue(`${item.id}.${fieldNames.quantity}`);
            const amount = calculateAmount(unitPrice, quantity);
            context.setFormValues({
                ...context.values,
                [item.id]: {
                    ...context.values[item.id],
                    [fieldNames.unitPrice]: unitPrice,
                    [fieldNames.quantity]: quantity,
                    [fieldNames.amount]: amount,
                },
            });

            // Validate the new amount
            silentValidation(context, `${item.id}.${fieldNames.amount}`, amount);
        };

        // Any error or info messages that should appear below the responsive table
        const messages = (
            <>
                <InvoiceItemsErrors errors={validationErrorMessage(context.errors)} />
                <InvoiceMaxItemsMessage
                    message={InvoiceItemsFormCopy.maxItemsMessage}
                    show={tableData.filter((td) => !td.noBatchActions).length === maxItems}
                />
            </>
        );

        return (
            <>
                {setIsDirty && (
                    <IsFormDirty
                        initialValues={{}}
                        setDirty={setIsDirty}
                        customIsDirty={(item) => item && !item.noBatchActions}
                    />
                )}
                <ResponsiveTable
                    data={tableData}
                    selectable
                    columns={[
                        {
                            label: labels.description,
                            getter: (item) => {
                                return (
                                    <InputField
                                        noLabels
                                        key={`${item.id}.${fieldNames.description}`}
                                        name={`${item.id}.${fieldNames.description}`}
                                        onChange={updateItemRecord(item)}
                                        onBlur={hashAndUpdateTotals}
                                        data-testid="item-description"
                                        validateOnChange
                                    />
                                );
                            },
                        },
                        {
                            label: labels.unitPrice,
                            getter: (item) => {
                                return (
                                    <CurrencyField
                                        noLabels
                                        key={`${item.id}.${fieldNames.unitPrice}`}
                                        name={`${item.id}.${fieldNames.unitPrice}`}
                                        onChange={(up, ctx) => onUnitPriceOrQuantityChange(item, ctx, up, undefined)}
                                        onBlur={hashAndUpdateTotals}
                                        className="invoice-items__unit-price"
                                        data-testid="item-unitprice"
                                        validateOnChange
                                        {...{ compact: true }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.quantity,
                            getter: (item) => {
                                return (
                                    <InputField
                                        noLabels
                                        key={`${item.id}.${fieldNames.quantity}`}
                                        name={`${item.id}.${fieldNames.quantity}`}
                                        onChange={(q, ctx) => onUnitPriceOrQuantityChange(item, ctx, undefined, q)}
                                        onBlur={hashAndUpdateTotals}
                                        className="invoice-items__quantity"
                                        data-testid="item-quantity"
                                        {...{ decimal: true }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.tax,
                            getter: (item) => {
                                return (
                                    <DropdownField
                                        noLabels
                                        key={`${item.id}.tax`}
                                        name={`${item.id}.${fieldNames.tax}`}
                                        onChange={() => {
                                            updateItemRecord(item)();
                                            hashAndUpdateTotals();
                                        }}
                                        onBlur={hashAndUpdateTotals}
                                        {...{
                                            options: invoiceSettings.settings?.taxRates?.map((tr) => ({
                                                value: tr.taxName,
                                                label: tr.taxName,
                                            })),
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            label: labels.amount,
                            getter: (item) => {
                                return (
                                    <CurrencyField
                                        noLabels
                                        key={`${item.id}.${fieldNames.amount}`}
                                        name={`${item.id}.${fieldNames.amount}`}
                                        className="invoice-items__amount"
                                        data-testid="item-amount"
                                        {...{ compact: true, disabled: true }}
                                    />
                                );
                            },
                        },
                    ]}
                    batchActions={[
                        {
                            label: "Delete",
                            batchLabel: "Delete selected",
                            tableButtonProps: {
                                // @ts-ignore
                                iconType: "delete",
                                className: "invoice-items__delete-selected",
                                "data-testid": "invoice-items-delete",
                            },
                            handleClick: removeItems,
                        },
                    ]}
                    batchInfo={messages}
                />
                <div className="invoice-items__max-items">{messages}</div>
            </>
        );
    };

    return (
        <div className="invoice-items">
            <Form name={name} initialValidation={validation} {...{ render }}></Form>
            <div className="invoice-items__totals">
                <div>
                    <div className="invoice-items__total-label">
                        <strong>Subtotal (excl. tax)</strong>
                    </div>
                    <div className="invoice-items__total-value">{subtotalExTax}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceItemsForm;
