import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Link, ButtonContainer } from '@premier/ui';
import { FloatingContainer } from 'components/Common';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { RootState } from 'store/store';

type Props = {
    resetPasswordSuccess: boolean;
}

const ForgotPasswordConfirmationPage = ({
    resetPasswordSuccess //logic render
}: Props) => {

    if(!resetPasswordSuccess)  // If !resetPasswordSuccess, user may have loaded the page from history, redirect immediately
        return <Navigate to={PlatformRoutesConfiguration.accountRoute.logon.generatePath()} />;

    return (
        <FloatingContainer>
            <h1>Please check your email</h1>

            <p>If your username and merchant number are valid, an email with further instructions has been sent to the email address associated with this account.</p>
            <p>If you cannot find the email, please check your junk or spam folder.</p>

            <ButtonContainer>
                <Link button to={PlatformRoutesConfiguration.accountRoute.logoff.generatePath()}>Back to log on</Link>
            </ButtonContainer>
        </FloatingContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        resetPasswordSuccess: state.accounts.users.resetPasswordSuccess
    };
}

export default connect(mapStateToProps)(ForgotPasswordConfirmationPage);