import React, { useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from 'components/Common/_actions/actions';

// Clear the Redux Cache if we visit another page and it isn't on the same path
// This is a legacy requirement where visiting another page would cause a cache clear for all reducers except
// the logged in user.

interface Props {
    rootPath : string;
    children: ReactElement;
}
const ReduxCacheClearOnRedirect : React.FC<Props> = ({rootPath, children}) => {
    const previousPath = useSelector((state : any) => state.app?.history?.previousPath, () => false);
    const dispatch = useDispatch();

    useEffect(() => {
        // If the last visited URL has a different root path assigned to this component, this means
        // we are on a different feature (eg. last visited was /feature1/hello but the root path for this feature
        // is /feature2/ so clear cache)
        if(dispatch && typeof previousPath === 'string' && !previousPath.includes(rootPath)){
            dispatch(commonActions.clearSensitiveData());
        }

    }, [dispatch, rootPath, previousPath]);
    // @ts-ignore
    return children;
}

export default ReduxCacheClearOnRedirect;