import { useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store/store";
import { APICallRequestState, PageHeader, useApiCall } from "components/Common";
import { Button, Dropdown, FormGroup, PageSection, SingleColumnFormContainer, SuccessModal, ErrorModal, Tab, Tabs, RichTextEditor, RichTextEditorSelection, ServerError, Dialog, Icon } from "packages/ui";
import { messagingApi, requestApi, utilitiesApi } from "api";
import { UiOption } from "packages/ui/models/UiOption";
import { PlatformRoutesConfiguration } from "components/Routing";
import { actionTypeValueToEnumName } from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { useMessagingSettings } from "components/Settings/_hooks/useMessagingSettings";
import { PaymentRequestDeliveryMethodEnum } from "platforms/base/constants/billpay";
import { Biller } from "models/Biller";

import "./MessagingRequestTemplatePage.scss";

type Props = {
    billers: Biller[];
}

const tabKeys = {
    emailTab: "email",
    smsTab: "sms",
};

const MessagingRequestTemplatePage = ({ billers }: Props) => {
    const { merchantNumber, billerCode, actionId } = useParams();
    const navigate = useNavigate();
    const { settings } = useMessagingSettings();

    const dirty = useRef(false);
    const emailEditorSelection = useRef<RichTextEditorSelection>();
    const smsEditorSelection = useRef<RichTextEditorSelection>();

    const [activeTab, setActiveTab] = useState<string>(tabKeys.emailTab);
    const [selectedActionOption, setSelectedActionOption] = useState<UiOption<string>>();
    const [selectedTemplateDefinitionOption, setSelectedTemplateDefinitionOption] = useState<UiOption<string>>();
    const [emailTemplateBody, setEmailTemplateBody] = useState("");
    const [smsTemplateBody, setSmsTemplateBody] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showDirtyWarning, setShowDirtyWarning] = useState(false);
    const [serverErrors, setServerErrors] = useState<string[]>();
    const [hasEmailServerErrors, setHasEmailServerErrors] = useState(false);
    const [hasSmsServerErrors, setHasSmsServerErrors] = useState(false);

    const [actions, actionsStatus] = useApiCall(() => {
        if (merchantNumber && billerCode) {
            return utilitiesApi.getBillerActions(merchantNumber, billerCode);
        }
    }, [merchantNumber, billerCode]);

    useMemo(() => {
        if (actionsStatus === APICallRequestState.ERROR) {
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }
    }, [actionsStatus]);

    useMemo(() => {
        navigate(PlatformRoutesConfiguration.messagingRoute?.editTemplate.generatePath(merchantNumber, billerCode, selectedActionOption?.value)!);
    }, [selectedActionOption]);

    const [templateDefinitions] = useApiCall(() => {
        if (selectedActionOption) {
            return messagingApi.getTemplateDefinitions(Number(selectedActionOption.value));
        }
    }, [selectedActionOption]);

    const [template, templateStatus] = useApiCall(() => {
        if (merchantNumber && billerCode && selectedActionOption) {
            return requestApi.getPaymentRequestTemplate(merchantNumber, billerCode, Number(selectedActionOption?.value));
        }
    }, [merchantNumber, billerCode, selectedActionOption]);

    const actionOptions = useMemo(() => {
        if (!actions) {
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }

        return actions?.map((a) => ({ label: a.actionName ?? "", value: a.action?.toString() ?? "" })) ?? [];
    }, [actions]);

    const defaultActionOption = useMemo(() => {
        const action = actionOptions?.find(a => a.value === actionId);

        if (!action) {
            navigate(PlatformRoutesConfiguration.accountRoute.notFound.generatePath());
        }

        setSelectedActionOption(action);
        return action;
    }, [actionOptions, actionId]);

    // For drop down: transform the designer field name to include an asterisk if the element is the pay now link.
    const transformDesignerFieldName = (designerFieldName: string, htmlElementId: string | undefined) => {
        return htmlElementId?.toUpperCase() === "PAYLINK" ? `${designerFieldName}*` : designerFieldName;
    };

    // For rich text editor span: transform the designer field html to include an asterisk if the element is the pay now link.
    const transformDesignerFieldHtml = (designerFieldHtml: string, htmlElementId: string | undefined) => {
        return htmlElementId?.toUpperCase() === "PAYLINK" ? designerFieldHtml.replace("</span>", "*</span>") : designerFieldHtml;
    };

    const templateDefinitionOptions = useMemo(() => {
        // Whenever the list of template definitions changes, select the first one by default.
        const firstOption = templateDefinitions?.[0] ? { label: templateDefinitions[0].designerFieldName ?? "", value: templateDefinitions[0].fieldId?.toString() ?? "" } : undefined;
        setSelectedTemplateDefinitionOption(firstOption);

        const biller = billers?.find(b => b.billerCode === billerCode);

        // Filter out Reference 2 and Reference 3 fields if the biller does not accept them.
        return templateDefinitions
            ?.filter(t => biller?.acceptedCrn3 || (t.htmlElementId?.toUpperCase() !== "CRN3" && t.htmlElementId?.toUpperCase() !== "CRN3LBL"))
            ?.filter(t => biller?.acceptedCrn2 || (t.htmlElementId?.toUpperCase() !== "CRN2" && t.htmlElementId?.toUpperCase() !== "CRN2LBL"))
            ?.map(t => ({ label: (t.designerFieldName ? transformDesignerFieldName(t.designerFieldName, t.htmlElementId) : null) ?? "", value: t.fieldId?.toString() ?? "" })) ?? [];
    }, [templateDefinitions, billers, billerCode]);

    const loading = useMemo(() => templateStatus === APICallRequestState.LOADING || templateStatus === APICallRequestState.PENDING, [templateStatus]);

    useMemo(() => {
        setEmailTemplateBody(template?.emailTemplateBody ?? "");
        setSmsTemplateBody(template?.smsTemplateBody ?? "");
    }, [template]);

    const isSmsEnabled = useMemo(() => {
        return (settings?.smsEnabled && (template?.deliveryMethod === PaymentRequestDeliveryMethodEnum.EMAIL_AND_SMS || template?.deliveryMethod === PaymentRequestDeliveryMethodEnum.SMS_ONLY)) ?? false;
    }, [settings?.smsEnabled, template?.deliveryMethod]);

    const handleAddElementClick = () => {
        const definition = templateDefinitions?.find(t => t.fieldId === Number(selectedTemplateDefinitionOption?.value));

        if (definition && definition.designerFieldHtml) {
            if (activeTab === tabKeys.emailTab && emailEditorSelection.current) {
                emailEditorSelection.current.setContent(transformDesignerFieldHtml(definition.designerFieldHtml, definition.htmlElementId));
                emailEditorSelection.current.editor.focus();
                emailEditorSelection.current.editor.setDirty(true);
            } else if (activeTab === tabKeys.smsTab && smsEditorSelection.current) {
                smsEditorSelection.current.setContent(transformDesignerFieldHtml(definition.designerFieldHtml, definition.htmlElementId));
                smsEditorSelection.current.editor.focus();
                smsEditorSelection.current.editor.setDirty(true);
            }
        }
    };

    const handleSaveClick = async () => {
        setServerErrors([]);
        setHasEmailServerErrors(false);
        setHasSmsServerErrors(false);
        setSubmitting(true);

        try {
            if (merchantNumber && billerCode && selectedActionOption) {
                const response = await requestApi.updatePaymentRequestTemplate(merchantNumber, billerCode, Number(selectedActionOption.value), emailTemplateBody, smsTemplateBody);

                if (response.status === 200) {
                    dirty.current = false;
                    setShowSuccess(true);
                } else {
                    if (response.data.errors && response.data.errors.length > 0) {
                        setServerErrors(response.data.errors.map(e => e.message ?? ""));
                        setHasEmailServerErrors(response.data.errors.some(e => e.label === "EmailTemplateBody"));
                        setHasSmsServerErrors(response.data.errors.some(e => e.label === "SmsTemplateBody"));
                    } else {
                        setShowError(true);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }

        setSubmitting(false);
    };

    /**
     * @description Handles the user's 'Cancel' and or 'Discard changes' click event giving them the option to cancel the page redirect if form is dirty.
     * @param (boolean) ignoreDirty override to ignore if the form is dirty 
     */
    const handleGoBack = (ignoreDirty: boolean = false) => {
        
        if (dirty.current && ignoreDirty === false) {
            setShowDirtyWarning(true);
            return;
        } 
        
        navigate(PlatformRoutesConfiguration.messagingRoute?.editRequests.path!);
    };

    const payNowLinkText = () => {
        switch (actionTypeValueToEnumName(Number(selectedActionOption?.value))) {
            case "TokeniseOnly":
                return "Register Now Link";
            case "UpdateToken":
                return "Update Now Link";
            default:
                return "Pay Now Link";
        }
    };

    const templateDefinitionsInfoText = () => {
        const payLinkMandatoryText = `The ${payNowLinkText()} is a mandatory message element.`;

        return `The new element is added after your text cursor in the area below.${settings?.barSmsLinks ? "" : ` ${payLinkMandatoryText}`}`;
    }

    const addElementPanel = () => <div className="add-element-panel">
        <SingleColumnFormContainer>
            <FormGroup label="Select and add message elements" info={templateDefinitionsInfoText()} fieldId="elementsDropDown">
                <Dropdown
                    options={templateDefinitionOptions}
                    placeholder={`Choose from ${templateDefinitionOptions.length} elements`}
                    value={selectedTemplateDefinitionOption}
                    onChange={(option) => option && setSelectedTemplateDefinitionOption(option)}
                />
            </FormGroup>
        </SingleColumnFormContainer>
        <FormGroup label={<>&nbsp;</>} fieldId="add-element-button">
            <Button onClick={handleAddElementClick} disabled={loading}>Add element</Button>
        </FormGroup>
    </div>;

    return <>
        <PageSection noDivider noPaddingBottom>
            <PageHeader title={`Update request content for ${selectedActionOption?.label}`} backButton={{ onClick: handleGoBack }} />
            <SingleColumnFormContainer>
                <FormGroup label="Request type" info="Switching the type allows you to edit content for different requests" fieldId="requestType" className="request-type-form-group">
                    <Dropdown
                        options={actionOptions}
                        defaultValue={defaultActionOption}
                        value={selectedActionOption}
                        onChange={(option) => { option && setSelectedActionOption(option) }}
                    />
                </FormGroup>
            </SingleColumnFormContainer>
        </PageSection>
        <PageSection>
            {isSmsEnabled ?
                <Tabs activeKey={activeTab} onSelect={(eventKey) => eventKey && setActiveTab(eventKey)}>
                    <Tab title="Email" eventKey={tabKeys.emailTab}>
                        {addElementPanel()}
                        <FormGroup label="Email message" fieldId="emailMessage">
                            <RichTextEditor
                                hasError={hasEmailServerErrors}
                                initialValue={template?.emailTemplateBody}
                                value={emailTemplateBody}
                                onChange={(value) => setEmailTemplateBody(value)}
                                onDirty={() => dirty.current = true}
                                onSelectionChange={(selection) => emailEditorSelection.current = selection}
                                disabled={loading}
                            />
                        </FormGroup>
                    </Tab>
                    <Tab title="SMS" eventKey={tabKeys.smsTab}>
                        {addElementPanel()}
                        <FormGroup label="SMS message" fieldId="smsMessage">
                            <RichTextEditor
                                hasError={hasSmsServerErrors}
                                initialValue={template?.smsTemplateBody}
                                value={smsTemplateBody}
                                onChange={(body) => setSmsTemplateBody(body)}
                                onDirty={() => dirty.current = true}
                                onSelectionChange={(selection) => smsEditorSelection.current = selection}
                                disabled={loading}
                            />
                        </FormGroup>
                    </Tab>
                </Tabs> :
                <>
                    {addElementPanel()}
                    <FormGroup label="Email message" fieldId="emailMessage">
                        <RichTextEditor
                            hasError={hasEmailServerErrors}
                            initialValue={template?.emailTemplateBody}
                            value={emailTemplateBody}
                            onChange={(value) => setEmailTemplateBody(value)}
                            onDirty={() => dirty.current = true}
                            onSelectionChange={(selection) => emailEditorSelection.current = selection}
                            disabled={loading}
                        />
                    </FormGroup>
                </>}
            <ServerError errors={serverErrors} />
            <div>
                <Button onClick={handleSaveClick} disabled={submitting || loading} primary>Save</Button>
                <Button onClick={() => handleGoBack(false)}>Cancel</Button>
            </div>
        </PageSection>
        <SuccessModal
            show={showSuccess}
            title="Request content updated successfully"
            onClose={() => setShowSuccess(false)}
        />
        <ErrorModal
            show={showError}
            title="Request content could not be updated"
            onClose={() => setShowError(false)}
        />
        <Dialog
            show={showDirtyWarning}
            title="Changes to messaging settings are not saved"
            icon={<Icon alert />}
            footerButtons={<>
                <Button onClick={() => setShowDirtyWarning(false)} disabled={false} primary>Continue editing</Button>
                <Button onClick={(e) => handleGoBack(true)}>Discard changes</Button>
            </>}
        >
            Leaving this page without saving will discard your unsaved changes.
        </Dialog>
    </>;
};

function mapStateToProps(state: RootState) {
    return {
        billers: state.accounts.users.billers,
    };
}

export default connect(mapStateToProps)(MessagingRequestTemplatePage);
