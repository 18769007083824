
import { useState } from "react";
import { SavedEFormModel } from "packages/webapi-client";
import { Field, useFormik, FormikProvider } from "formik";
import { FormGroup, FormikColourField, FormikDropdown } from "@linkly/formik-ui";
import * as Yup from "yup";
import { getFontFamilyOptions, getFontSizeOptions, getLabelAlignmentOptions } from "../helpers";
import { PaddedContainer, Row, Button, Dialog, Icon } from "@premier/ui";
import regexUtil from "@premier/utils/regEx";
import EFormsHppPreview from "./EFormsHppPreview";
import { ControlType, HppPreviewMode } from "../enums";
import { Control } from "../types";

export type EditFormStyleFormValues = {
    backgroundColor: string;
    fontSize?: number;
    fontType?: string;
    fontColor?: string;
    labelAlign?: string;
}

type Props = {
    form?: SavedEFormModel | null | undefined;
    controls: Control[];
    onChange: (e: EditFormStyleFormValues) => void;
    onDeleteAll: () => void;
};

const EditFormStyle = ({ form, controls, onChange, onDeleteAll }: Props) => {
    const [showDeleteAllDialog, setShowDeleteAllDialog] = useState(false);
    const [formStyle, setFormStyle] = useState<EditFormStyleFormValues>({
        backgroundColor: form?.attributes?.backgroundColor ?? "#FFFFFF",
        fontType: form?.attributes?.fontFamily ?? "Arial",
        fontSize: undefined,
        fontColor: undefined,
        labelAlign: undefined,
    });

    const ValidationSchema = Yup.object().shape({
        backgroundColor: Yup.string()
            .matches(regexUtil.colourHexRegex, "Invalid hex colour")
            .required("Background color is required"),
        fontColor: Yup.string()
            .matches(regexUtil.colourHexRegex, "Invalid hex colour")
            .required("Font color is required"),
    });

    const applyToAllStyles = () => {
        if (formik.values) {
            setFormStyle(formik.values);
            onChange(formik.values);
        }
    };

    const deleteAll = () => {
        onDeleteAll();
        setShowDeleteAllDialog(false);
    };

    const handleDeleteAllModalOpen = () => {
        // Only show delete all dialog if there are form elements that are not the submit button
        if (controls.filter(c => c.controlType !== ControlType.Submit).length > 0) {
            setShowDeleteAllDialog(true);
        }
    };

    const formik = useFormik({
        initialValues: {
            backgroundColor: form?.attributes?.backgroundColor ?? "#FFFFFF",
            fontSize: form?.attributes?.fontSize,
            fontType: form?.attributes?.fontFamily,
            fontColor: form?.attributes?.fontColor ?? "#000000",
            labelAlign: form?.attributes?.labelAlign,
        },
        onSubmit: (formValues: EditFormStyleFormValues) => { },
        validationSchema: ValidationSchema,
    });

    return (
        <>
            <Row>
                <div className="col-lg-8">
                    <PaddedContainer className="preview">
                        <EFormsHppPreview controls={controls} mode={HppPreviewMode.Style} style={formStyle} />
                    </PaddedContainer>
                </div>
                <div className="col-lg-4">
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <FormGroup label="Background Color" className="col-sm-12" name="backgroundColor">
                                    <Field name="backgroundColor" as={FormikColourField} />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup label="Font size" className="col-sm-12" name="fontSize">
                                    <Field as={FormikDropdown} name="fontSize" options={getFontSizeOptions()} />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup label="Font" className="col-sm-12" name="fontType">
                                    <Field as={FormikDropdown} name="fontType" options={getFontFamilyOptions()} isCustomStyles />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup label="Font Color" className="col-sm-12" name="fontColor">
                                    <Field name="fontColor" as={FormikColourField} />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup label="Label Align" className="col-sm-12" name="labelAlign">
                                    <Field as={FormikDropdown} name="labelAlign" options={getLabelAlignmentOptions()} />
                                </FormGroup>
                            </Row>
                            <Row className="col-sm-12">
                                <Button onClick={applyToAllStyles} primary>Apply to all</Button>
                                <Button onClick={handleDeleteAllModalOpen} type="submit">Delete all</Button>
                            </Row>
                        </form>
                    </FormikProvider>
                </div>
            </Row>
            <Dialog
                show={showDeleteAllDialog}
                icon={<Icon alert />}
                title="Delete all form elements"
                footerButtons={<>
                    <Button onClick={deleteAll} primary>Proceed</Button>
                    <Button onClick={() => setShowDeleteAllDialog(false)}>Cancel</Button>
                </>}
                onClose={() => setShowDeleteAllDialog(false)}
                closeButton
            >
                Are you sure you want to delete all form elements?
                <ul>
                    <li>Click Proceed if you want to continue.</li>
                    <li>Click Cancel if you do not wish to proceed.</li>
                </ul>
            </Dialog>
        </>
    );
};

export default EditFormStyle;
