import { useMemo, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import Input from "./components/Input";
import { validateRequiredInput } from "components/EForms/helpers";
import classNames from "classnames";

const PhoneNumber = ({ name, attributes }: TemplatesProps) => {
    const [area, setArea] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [areaValid, setAreaValid] = useState(true);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);

    const valid = useMemo(() => areaValid && phoneNumberValid, [areaValid, phoneNumberValid]);

    const errors = useMemo(() => {
        const errors: string[] = [];

        if (!areaValid) {
            errors.push("Area is required.");
        }

        if (!phoneNumberValid) {
            errors.push("Phone Number is required.");
        }

        return errors;
    }, [areaValid, phoneNumberValid]);

    const inputValidation = (value: string) => {
        let validationResult: boolean = true;

        if (attributes.required) {
            validationResult = validateRequiredInput(value);
        }

        return validationResult;
    };

    const handleAreaChanged = (value: string) => {
        setArea(value);
        setAreaValid(inputValidation(value));
        setPhoneNumberValid(inputValidation(phoneNumber));
    };

    const handlePhoneNumberChanged = (value: string) => {
        setPhoneNumber(value);
        setAreaValid(inputValidation(area));
        setPhoneNumberValid(inputValidation(value));
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-short eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <div className={`jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                <div className="eform-row">
                    <div className="eform-col-4">
                        <Input
                            type="tel"
                            label="Area"
                            attributes={attributes}
                            labeledInput={true}
                            showErrors={false}
                            onValueChange={handleAreaChanged}
                            onBlur={handleAreaChanged}
                        />
                    </div>
                    <div className="eform-col-8">
                        <Input
                            type="tel"
                            label="Phone Number"
                            attributes={attributes}
                            labeledInput={true}
                            showErrors={false}
                            onValueChange={handlePhoneNumberChanged}
                            onBlur={handlePhoneNumberChanged}
                        />
                    </div>
                </div>
            </div>
            {errors.length > 0 && <div className="errors" style={{ fontFamily: attributes.fontFamily }}>{errors.map(e => <div>{e}</div>)}</div>}
        </div>
    );
};

export default withDesignerControl(PhoneNumber);
