import { TemplatesLabelProps } from "components/EForms/types";

import "./Label.scss";

const Label = ({ attributes: { label, subLabel, hoverText, fontColor, fontFamily, fontSize, labelAlign, required } }: TemplatesLabelProps) => {
    const fontColorStyle = fontColor ?? "#FFFFFF";
    const fontFamilyStyle = fontFamily ?? "Arial";
    const fontSizeStyle = fontSize ?? 16;
    const alignStyle = labelAlign === "right" ? "right" : "left";

    return (
        <div
            className={`jsLabel ${labelAlign !== "top" ? "eform-col-4" : "eform-ctrl-labelalign-top"} ${labelAlign === "left" ? "eform-ctrl-labelalign-left" : "eform-ctrl-labelalign-right"}`}
        >
            <div
                className={`jsQuestionLabelAlign eform-ctrl-labelwrap`}
                style={{ textAlign: alignStyle }}
            >
                <div
                    className={`eform-editablelabel eform-ctrl-label ${required ? "eform-required" : ""} `}
                    style={{ color: fontColorStyle, fontSize: fontSizeStyle, fontFamily: fontFamilyStyle }}
                >
                    {label ?? <>&nbsp;</>}
                </div>
                {hoverText && (
                    <div className="eform-help">
                        <span className="ui-icon ui-icon-s ui-icon-s-help"></span>
                        <div className="tooltip">{hoverText}</div>
                    </div>
                )}
            </div>
            <div
                className="jsQuestionLabelAlign eform-editablelabel"
                style={{ textAlign: alignStyle, fontFamily: fontFamilyStyle }}
            >
                {subLabel ?? <>&nbsp;</>}
            </div>
        </div>
    );
};

export default Label;