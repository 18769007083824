import { ReactNode, useMemo, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import useSpecials from "../hooks/useSpecials";

import "./RadioButtons.scss";

const RadioButtons = ({ id, name, attributes, onControlSelectionChanged }: TemplatesProps) => {
    const { options } = useSpecials(attributes);
    const [selected, setSelected] = useState<string>(Array.isArray(attributes.selected) ? attributes.selected[0] : attributes.selected as string);
    const [otherDisabled, setOtherDisabled] = useState(true);

    useMemo(() => {
        onControlSelectionChanged && onControlSelectionChanged(id, selected);
    }, [selected]);

    const getColumnContents = (options: string[], columnIndex: number, name: string, selected: string | undefined) => {
        const results: ReactNode[] = [];

        for (let i = 0; i < options.length; i++) {
            if (i % (attributes.spreadColumns ?? 1) === columnIndex) {
                results.push(
                    <div key={`radio_button_${columnIndex}_${i}`} className="eform-ctrl-radio-item">
                        <input
                            type="radio"
                            id={options[i]}
                            name={name}
                            value={options[i]}
                            onChange={(e) => { setSelected(e.currentTarget.value); setOtherDisabled(e.target.checked); }}
                            checked={options[i] === selected}
                        />
                        <label htmlFor={options[i]} style={{ fontFamily: attributes.fontFamily }}>{options[i]}</label>
                    </div>
                );
            }
        }

        return results;
    };

    const columns = useMemo(() => {
        const result: ReactNode[] = [];
        const optionsArray = options?.map(x => x.label) ?? attributes.options ?? [];

        for (let i = 0; i < (attributes.spreadColumns ?? 1); i++) {
            result.push(
                <div key={`radio_button_column_${i}`} className={`column_${i}`}>
                    {getColumnContents(optionsArray, i, name, selected)}
                </div>
            )
        }

        return result;
    }, [options, attributes.options, attributes.spreadColumns, selected, name]);

    return (
        <div className="eform-form eform-ctrl eform-ctrl-radio eform-row formContentElement">
            <Label name={name} attributes={attributes} />
            <div className="radio-buttons-container">
                {columns}
            </div>
            {attributes.allowOtherOption &&
                <div className="eform-ctrl-radio-item">
                    <input
                        type="radio"
                        name={name}
                        className={attributes.labelAlign === "top" ? "" : "other-radio-button"}
                        defaultChecked={attributes.selected?.includes("Other")}
                        onChange={(e) => setOtherDisabled(!e.target.checked)}
                    />
                    <input
                        className="eform-ctrl-input-other eform-ctrl-input"
                        placeholder="Other"
                        type="text"
                        disabled={otherDisabled}
                    />
                </div>}
        </div>
    );
};

export default withDesignerControl(RadioButtons);
