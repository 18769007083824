import { ReactNode } from "react";
import classNames from "classnames";
import Icon, { IconTypeProps }  from "./Icon";
import "./Button.scss";

export type ButtonProps = {
    type?: "button" | "submit" | "reset" | undefined;
    /** The button text can be specified as a label, or as children */
    label?: ReactNode;
    /** The icon type (if any, eg. 'info') to put in front of the label */
    iconType?: keyof IconTypeProps;

    default?: boolean;
    /** Style the button as a primary (yellow) button */
    primary?: boolean;
    /** Style the button as a light (white transparent) button */
    light?: boolean;
    /** Style the button as a subtle (link-like) button */
    subtle?: boolean;
    /** Style the button as a subtle-light (white link-like) button */
    subtleLight?: boolean;
    /** Style the button as a link */
    link?: boolean;

    /** (For subtle button only) Always left-align the text */
    left?: boolean;
    /** (For subtle button only) Right-align the text */
    right?: boolean;
    /** (For subtle button only) Always center-align the text */
    center?: boolean;
    loading?: boolean;
    disabled?: boolean;
    /** Make the button full width */
    block?: boolean;

    /** No bottom margin */
    noMarginBottom?: boolean;

    /** No right margin */
    noMarginRight?: boolean;

    /** No minimum width */
    noMinWidth?: boolean;

    /** Zero min width */
    zeroMinWidth?: boolean;

    className?: string;
    children?: ReactNode;
} & JSX.IntrinsicElements["button"];

/**
 * A clickable button. Other than its default style, it can be styled as primary, subtle or even as a link.
 * There is no visual difference between a Button and a Link in this project,
 * but a Button does an action when clicked (including opening a Modal), where a Link only takes you to another page.
 */
const Button = ({
    type, label, iconType,
    primary, light, subtle, subtleLight, link, block,
    left, right, center, loading, disabled, noMarginBottom, noMarginRight, noMinWidth, zeroMinWidth,
    className, children,
    ...otherProps
}: ButtonProps) => {

    const cssClass = classNames(
        "btn",
        {
            "btn-primary": primary,
            "btn-light": light,
            "btn-subtle": subtle,
            "btn-subtle-light": subtleLight,
            "btn-link": link,
            "btn-block": block,
            "left": left,
            "right": right,
            "center": center,
            "no-margin-bottom": noMarginBottom,
            "no-margin-right": noMarginRight,
            "zero-min-width": zeroMinWidth,
            "no-min-width": noMinWidth,
        },
        className
    );

    return (
        <button type={type || "button"} className={cssClass} disabled={loading || disabled} {...otherProps}>
            {iconType && <Icon type={iconType} className="button-icon"></Icon>}
            {label || children}
        </button>
    );
};

export default Button;
