import { useMemo, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { getYearsFrom1920 } from "../helpers";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import Dropdown from "./components/DropdownComponent";
import { amPm, daysOfMonth, hours12, hours24, minutes, months } from "../constants";
import classNames from "classnames";
import { DateFormat, TimeFormat } from "../enums";

const DateTime = ({ name, attributes }: TemplatesProps) => {
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [hour, setHour] = useState("");
    const [minute, setMinute] = useState("");
    const [amPmValue, setAmPmValue] = useState("");

    const [dayValid, setDayValid] = useState(true);
    const [monthValid, setMonthValid] = useState(true);
    const [yearValid, setYearValid] = useState(true);
    const [hourValid, setHourValid] = useState(true);
    const [minuteValid, setMinuteValid] = useState(true);
    const [amPmValid, setAmPmValid] = useState(true);

    const valid = useMemo(() => dayValid && monthValid && yearValid && hourValid && minuteValid && amPmValid, [dayValid, monthValid, yearValid, hourValid, minuteValid, amPmValid]);

    const errors = useMemo(() => {
        const errors = [];
        if (!dayValid) {
            errors.push("Day is required");
        }
        if (!monthValid) {
            errors.push("Month is required");
        }
        if (!yearValid) {
            errors.push("Year is required");
        }
        if (!hourValid) {
            errors.push("Hour is required");
        }
        if (attributes.allowTime) {
            if (!minuteValid) {
                errors.push("Minute is required");
            }
            if (!amPmValid) {
                errors.push("AM/PM is required");
            }
        }
        return errors;
    }, [dayValid, monthValid, yearValid, hourValid, minuteValid, amPmValid, attributes.allowTime]);

    const handleDayChange = (value: string, valid: boolean) => {
        setDay(value);

        setDayValid(valid);
        setMonthValid(!!month);
        setYearValid(!!year);
        setHourValid(!!hour);
        setMinuteValid(!!minute);
        setAmPmValid(!!amPmValue);
    };

    const handleMonthChange = (value: string, valid: boolean) => {
        setMonth(value);

        setDayValid(valid);
        setMonthValid(valid);
        setYearValid(!!year);
        setHourValid(!!hour);
        setMinuteValid(!!minute);
        setAmPmValid(!!amPmValue);
    };

    const handleYearChange = (value: string, valid: boolean) => {
        setYear(value);

        setDayValid(!!day);
        setMonthValid(!!month);
        setYearValid(valid);
        setHourValid(!!hour);
        setMinuteValid(!!minute);
        setAmPmValid(!!amPmValue);
    };

    const handleHourChange = (value: string, valid: boolean) => {
        setHour(value);

        setDayValid(!!day);
        setMonthValid(!!month);
        setYearValid(!!year);
        setHourValid(valid);
        setMinuteValid(!!minute);
        setAmPmValid(!!amPmValue);
    };

    const handleMinuteChange = (value: string, valid: boolean) => {
        setMinute(value);

        setDayValid(!!day);
        setMonthValid(!!month);
        setYearValid(!!year);
        setHourValid(!!hour);
        setMinuteValid(valid);
        setAmPmValid(!!amPmValue);
    };

    const handleAmPmChange = (value: string, valid: boolean) => {
        setAmPmValue(value);

        setDayValid(!!day);
        setMonthValid(!!month);
        setYearValid(!!year);
        setHourValid(!!hour);
        setMinuteValid(!!minute);
        setAmPmValid(valid);
    };

    const colClasses = useMemo(() => {
        // 3 columns (3 blanks just in case)
        if (!attributes.allowTime)  {
            return ["eform-col-4", "eform-col-4", "eform-col-4", "", "", ""];
        }

        // 5 columns (1 blank just in case)
        if (attributes.allowTime && attributes.timeFormat === TimeFormat.Hour24) {
            return ["eform-col-2", "eform-col-3", "eform-col-3", "eform-col-2", "eform-col-2", ""];
        }

        return ["eform-col-2", "eform-col-2", "eform-col-2", "eform-col-2", "eform-col-2", "eform-col-2"];
    }, [attributes.allowTime, attributes.timeFormat])

    const dayDropDown = () => {
        return <Dropdown attributes={attributes} labeledInput={true} label="Day" options={daysOfMonth.map(m => ({ label: m.toString(), value: m.toString() }))} onChange={handleDayChange} showErrors={false} />;
    };

    const monthDropDown = () => {
        return <Dropdown attributes={attributes} labeledInput={true} label="Month" options={months.map(m => ({ label: m, value: m }))} onChange={handleMonthChange} showErrors={false} />;
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-datetime eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <div className={`jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                <div className="eform-row eform-ctrl-address-row">
                    <div className={colClasses[0]}>
                        {attributes.dateFormat === DateFormat.DayMonthYear ? dayDropDown() : monthDropDown()}
                    </div>
                    <div className={colClasses[1]}>
                        {attributes.dateFormat === DateFormat.DayMonthYear ? monthDropDown() : dayDropDown()}
                    </div>
                    <div className={colClasses[2]}>
                        <Dropdown attributes={attributes} labeledInput={true} label="Year" options={getYearsFrom1920().map(m => ({ label: m, value: m }))} onChange={handleYearChange} showErrors={false} />
                    </div>
                    {attributes.allowTime && <>
                        <div className={colClasses[3]}>
                            <Dropdown
                                attributes={attributes}
                                labeledInput={true}
                                label="Hour"
                                options={(attributes.timeFormat === TimeFormat.AmPm ? hours12 : hours24).map(m => ({ label: m, value: m }))}
                                onChange={handleHourChange}
                                showErrors={false}
                            />
                        </div>
                        <div className={colClasses[4]}>
                            <Dropdown attributes={attributes} labeledInput={true} label="Minute" options={minutes.map(m => ({ label: m, value: m }))} onChange={handleMinuteChange} showErrors={false} />
                        </div>
                        {attributes.timeFormat === TimeFormat.AmPm &&
                            <div className={colClasses[5]}>
                                <Dropdown attributes={attributes} labeledInput={true} label="AM/PM" options={amPm.map(m => ({ label: m, value: m }))} onChange={handleAmPmChange} showErrors={false} />
                            </div>}
                    </>}
                </div>
            </div>
            {errors.length > 0 && <div className="errors" style={{ fontFamily: attributes.fontFamily }}>{errors.map(e => <div>{e}</div>)}</div>}
        </div>
    );
};

export default withDesignerControl(DateTime);
