import React, { useState, useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '@premier/ui';
import { PlatformRoutesConfiguration, userRoles } from 'components/Routing';
import { LoggedOnDetailsRequiredUserActionEnum } from '@premier/webapi-client';
import SecurityUtil from '@premier/utils/security'
import { RootState } from 'store/store';

interface AuthenticatedRouteProps {
    requiredUserRoles : userRoles[] | userRoles[][],
    children: ReactElement
}

enum ACCESS_RESULT {
    UNINITIALIZED,
    NOT_AUTHENTICATED, // Not logged in
    NOT_AUTHORIZED, // Does not have user permissions to access feature
    CUSTOM_REDIRECT_REQUIRED, // User is redirected somewhere else (eg. force password change) before they can log in (even though they are already authenticated/authorized)
    SUCCESS
}

const AuthenticatedRoute : React.FC<AuthenticatedRouteProps> = ({ requiredUserRoles, children }) => {
    const location = useLocation();
    const [ authenticatedUser ] = useState(useSelector((state: RootState) => state.accounts.users.authenticatedUser));
    const [ requiredUserAction ] = useState<LoggedOnDetailsRequiredUserActionEnum>(useSelector((state: RootState) => state.accounts.users.requiredUserAction));
    const [ accessResult, setAccessResult ] = useState(ACCESS_RESULT.UNINITIALIZED);
    const [ customRedirectURL, setCustomRedirectURL ] = useState("");

    useEffect(() => {
        if(accessResult === ACCESS_RESULT.UNINITIALIZED) {
            if (!authenticatedUser) {
                setAccessResult(ACCESS_RESULT.NOT_AUTHENTICATED);
            } else if (requiredUserRoles?.length > 0 && !SecurityUtil.hasAccess(requiredUserRoles, authenticatedUser)) {
                setAccessResult(ACCESS_RESULT.NOT_AUTHORIZED);
            } else if (requiredUserAction === "ACCEPT_TERMS_AND_CONDITIONS" || requiredUserAction === "UPDATE_PASSWORD" || requiredUserAction === "MULTI_FACTOR_AUTHENTICATION"|| requiredUserAction === "ENTER_NEW_PASSWORD") {
                // The general logic is that if we are on custom redirect page already, don't do anything otherwise
                // force a redirect so the merchant will do the required action.
                // If they want to log off, then don't do anything to stop that.
                let forceRedirect = false;
                if (location.pathname !== PlatformRoutesConfiguration.accountRoute?.logoff.generatePath())
                    switch (requiredUserAction) {
                        case "ACCEPT_TERMS_AND_CONDITIONS":
                            let acceptTermsURL = PlatformRoutesConfiguration.accountRoute?.usagePolicy.generatePath();
                            if (location.pathname !== acceptTermsURL && location.pathname !== acceptTermsURL) {
                                setCustomRedirectURL(acceptTermsURL);
                                forceRedirect = true;
                            }
                            break;
                        case "UPDATE_PASSWORD":
                            let changePasswordPathURL = PlatformRoutesConfiguration.accountRoute?.changePassword.generatePath();
                            setCustomRedirectURL(changePasswordPathURL);
                            forceRedirect = true;
                            break;
                        case "MULTI_FACTOR_AUTHENTICATION":
                            let multiFactorAuthenticationPathURL = PlatformRoutesConfiguration.accountRoute?.multiFactorAuthentication.generatePath();
                            if (location.pathname !== multiFactorAuthenticationPathURL && location.pathname !== multiFactorAuthenticationPathURL) {
                                setCustomRedirectURL(multiFactorAuthenticationPathURL);
                                forceRedirect = true;
                            }
                            break;
                        case "ENTER_NEW_PASSWORD":
                            let enterNewPasswordPathURL = PlatformRoutesConfiguration.accountRoute?.changePassword.generatePath();
                            if (location.pathname !== enterNewPasswordPathURL && location.pathname !== enterNewPasswordPathURL) {
                                setCustomRedirectURL(enterNewPasswordPathURL);
                                forceRedirect = true;
                            }
                            break;
                    }

                if(forceRedirect) {
                    setAccessResult(ACCESS_RESULT.CUSTOM_REDIRECT_REQUIRED);
                } else {
                    setCustomRedirectURL("");
                    setAccessResult(ACCESS_RESULT.SUCCESS);
                }
            }
            else {
                setAccessResult(ACCESS_RESULT.SUCCESS)
            }
        }
    }, [authenticatedUser, requiredUserAction, requiredUserRoles, accessResult, location])

    const notAuthenticatedRedirectedPath = location?.pathname ? "?lastLocation=" + location.pathname : "";

    switch(accessResult) {
        case ACCESS_RESULT.UNINITIALIZED:
            return <LoadingIndicator />;
        case ACCESS_RESULT.NOT_AUTHENTICATED:
            return <Navigate to={PlatformRoutesConfiguration.accountRoute?.logon.path ? PlatformRoutesConfiguration.accountRoute?.logon.path + notAuthenticatedRedirectedPath : ""} />
        case ACCESS_RESULT.NOT_AUTHORIZED:
            return <Navigate to={PlatformRoutesConfiguration.accountRoute?.unauthorized.path ?? ""} />
        case ACCESS_RESULT.CUSTOM_REDIRECT_REQUIRED:
            return <Navigate to={customRedirectURL} />
        case ACCESS_RESULT.SUCCESS:
            return children;
    }
}

export default AuthenticatedRoute;