import _ from 'lodash';

import httpRequest from './httpRequest';
import dateUtil from '@premier/utils/date';
import * as billpayUtil from '@premier/utils/billpay';
import mapErrors from './mapErrors';
import { PaymentTypeKey, defaultPageSize } from 'constants/billpay';
import { config } from './util';
import { DataVaultApiFactory } from '@premier/webapi-client';

const api = DataVaultApiFactory(config);
class TokenApi {

    static getTokens(resultsPerPage = defaultPageSize, pageIndex = 0, filter = {}, sort = {}) {
        function mapSortField(field) {
            switch (field) {
                case 'accountNumber':
                    return 'maskedCardNumber';
                default:
                    return field || null;
            }
        }

        return new Promise((resolve, reject) => {
            var model = {
                request: this.mapSearchFilterFields(filter), // _.pickBy(request, (value) => value),
                sortField: mapSortField(sort.field),
                order: sort.descending ? 'DESCENDING' : 'ASCENDING',
                pageIndex: pageIndex,
                pageSize: resultsPerPage
            };

            httpRequest
                .post(`/DataVault/SearchTokens`, model)
                .then(response => {
                    var tokens = response.list;
                    var resultCount = response.totalCount;
                    resolve({ tokens, resultCount });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static mapSearchFilterFields(filter) {
        function mapExpiryDate(expiryDate) {
            const dateFilter = _.omitBy(expiryDate, _.isEmpty);
            return _.isEmpty(dateFilter) ? null : dateFilter;
        }

        let createdFrom, createdTo;
        if (_.get(filter, 'tokenUpdatedDateRange.dates')) {
            createdFrom = dateUtil.convertToApiValue(filter.tokenUpdatedDateRange.dates[0]);
            createdTo = dateUtil.convertToApiValue(dateUtil.roundEndOfMinute(filter.tokenUpdatedDateRange.dates[1]));
        }

        const request = {...filter,
            maskedCardNumber: billpayUtil.maskedCardNumberToApiString(filter.maskedCardNumber),
            expiryDate: mapExpiryDate(filter.expiryDate),
            createdFrom: createdFrom,
            createdTo: createdTo,
            expiredOnly: !!filter.expiredOnly,
            customerPhoneNumber: billpayUtil.formatPhoneObjectToApiString(filter.customerPhoneNumber)
        };

        return _.pickBy(request, (value) => value);
    }

    static getToken(tokenId) {
        function toDto(tokenId) {
            return { dataVaultId: tokenId };
        }

        function fromDto(response) {
            return mapTokenFromDto(response);
        }

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/DataVault/GetToken', toDto(tokenId))
                .then(response => {
                    let token = fromDto(response);
                    resolve(token);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static addToken(tokenData) {
        return new Promise((resolve, reject) => {
            httpRequest
                .post('/DataVault/AddToken', mapTokenToDto(tokenData))
                .then(response => {
                    let token = mapTokenFromDto(response);
                    resolve(token);
                })
                .catch(err => {
                    reject(mapErrors(err, mapTokenErrorsFromDto(tokenData)));
                });
        });
    }

    static updateToken(tokenData) {
        function toDto(tokenData) {
            return {
                dataVaultId: tokenData.dataVaultId,
                ...mapTokenToDto(tokenData)
            };
        }

        function fromDto(response) {
            return mapTokenFromDto(response);
        }

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/DataVault/UpdateToken', toDto(tokenData))
                .then(response => {
                    let token = fromDto(response);
                    resolve(token);
                })
                .catch(err => {
                    reject(mapErrors(err, mapTokenErrorsFromDto(tokenData)));
                });
        });
    }

    static deleteToken(tokenId) {
        function toDto(tokenId) {
            return { dataVaultId: tokenId };
        }

        return new Promise((resolve, reject) => {
            httpRequest
                .post('/DataVault/DeleteToken', toDto(tokenId))
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static resendVerificationEmail(request) {
        return api.dataVaultSendDDRVerificationEmail(request);
    }

    static uploadBatch(files) {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles(`/DataVault/UploadBatchFile`, files, {})
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static downloadTokensExport(format, filter = {}) {
        return new Promise((resolve, reject) => {
            var dto = {
                format: format,
                search: {
                    request: this.mapSearchFilterFields(filter),
                }
            };

            httpRequest
                .post(`/DataVault/DownloadDataVaultReport`, dto)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

/** For add/update token & webCustomerApi.addTokenForCustomer */
export function mapTokenToDto(tokenData) {
    //Note tokenDetailsBillerCodeForm does not exist when called from EditTokenModalContent
    // MerchantNo, BillerCode & CRNs are not editable from that form, so no issue.
    var accountType         = tokenData.paymentTypeInput.accountType;
    var card                = tokenData.paymentTypeInput.card;
    var bankAccountDetails  = tokenData.paymentTypeInput.bankAccountDetails;
    var crnList             = _.get(tokenData, 'tokenDetailsBillerCodeForm.billerCrnList');

    var dto = {
        childMerchantNumber: _.get(tokenData, 'tokenDetailsBillerCodeForm.merchantNumber'),
        billerCode: _.get(tokenData, 'tokenDetailsBillerCodeForm.billerCode'),
        crn1: _.get(crnList, 'crn1', tokenData.crn1),
        crn2: _.get(crnList, 'crn2', tokenData.crn2),
        crn3: _.get(crnList, 'crn3', tokenData.crn3),
        tokenType: accountType,
        accountName: accountType === PaymentTypeKey.CARD ? card.cardholderName : bankAccountDetails.accountName,
        cardNumber: accountType === PaymentTypeKey.CARD ? card.cardNumber || tokenData.maskedCardNumber : null,
        expiryDate: accountType === PaymentTypeKey.CARD ? card.expiryDate : null,
        deBsbNumber: accountType === PaymentTypeKey.BANK_ACCOUNT ? bankAccountDetails.bsbNumber : null,
        deAccountNumber: accountType === PaymentTypeKey.BANK_ACCOUNT ? bankAccountDetails.accountNumber : null,
        customerIdentified: tokenData.customerIdentified,
        customerId: tokenData.customerId,
    };

    return _.pickBy(dto, (value) => value);
}

function mapTokenErrorsFromDto(tokenData) {
    var accountType = tokenData.paymentTypeInput.accountType;
    var billerCrnList = _.get(tokenData, 'tokenDetailsBillerCodeForm.billerCrnList');
    var card = tokenData.paymentTypeInput.card;

    return (parameter) => {
        if (parameter === "childMerchantNumber")
            return "tokenDetailsBillerCodeForm.merchantNumber";
        if (parameter === "billerCode")
            return "tokenDetailsBillerCodeForm.billerCode";
        if (parameter === "tokenType")
            return "paymentTypeInput.accountType";
        if (billerCrnList) {
            if (parameter === "crn1")
                return "tokenDetailsBillerCodeForm.billerCrnList.crn1";
            if (parameter === "crn2")
                return "tokenDetailsBillerCodeForm.billerCrnList.crn2";
            if (parameter === "crn3")
                return "tokenDetailsBillerCodeForm.billerCrnList.crn3";
        }
        if (accountType === PaymentTypeKey.CARD) {
            if (parameter === "accountName")
                return "paymentTypeInput.card.cardholderName";
            if (parameter === "cardNumber")
                return card.cardNumber ? "paymentTypeInput.card.cardNumber" : "maskedCardNumber";
            if (parameter === "expiryDate")
                return "paymentTypeInput.card.expiryDate";
            if (parameter === "cardNumber")
                return "paymentTypeInput.card.cardNumber";
        } else {
            if (parameter === "accountName")
                return "paymentTypeInput.bankAccountDetails.accountName";
        }
        if (accountType === PaymentTypeKey.BANK_ACCOUNT) {
            if (parameter === "deBsbNumber")
                return "paymentTypeInput.bankAccountDetails.bsbNumber";
            if (parameter === "deAccountNumber")
                return "paymentTypeInput.bankAccountDetails.accountNumber";
        }
    }
}

/** For get/update token */
function mapTokenFromDto(response) {
    let token = {...response};

    if (token.cardTypeCode !== 'BA') {
        token.type = token.type || PaymentTypeKey.CARD;

        if (/^\d{6}\.{3}\d{3}$/.test(token.accountNumber)) {
            token.maskedCardNumber = token.accountNumber;
        } else {
            token.cardNumber = token.accountNumber;
        }
        delete token.accountNumber;

        token.cardholderName = token.accountName;
        delete token.accountName;
    } else {
        token.type = token.type || PaymentTypeKey.BANK_ACCOUNT;
    }

    return token;
}

export default TokenApi;