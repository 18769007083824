import { useState } from "react";
import { Tab } from "react-bootstrap";
import { IconText, Row, Tabs } from "@premier/ui";
import { HppPreview } from ".";
import { Merchant } from "models";
import { Biller } from "packages/webapi-client";

import "./PreviewTabs.scss";

type Props = {
    style: any;
    compact?: boolean;
    merchant?: Merchant;
    biller?: Biller;
}

const PreviewTabs = ({ style, compact, merchant, biller }: Props) => {

    const pages = [
        { key: "payment", title: "Payment page", Component: HppPreview },
        //{key: 'receipt', title: 'Receipt', Component: ReceiptPreview},  // TODO: Reenable after we make relevant changes to Receipt page
    ];

    const [tab, setTab] = useState(pages[0].key);

    return (
        <div className="preview-tabs">
            <Tabs onSelect={key => setTab(key ?? "")}>

                {pages.map(page => (
                    <Tab eventKey={page.key} title={page.title} key={page.key}>
                        <Row>
                            {!compact && (
                                <div className="col-lg-8">
                                    <IconText desktop>View on desktop</IconText>
                                    <page.Component style={style} refreshTrigger={tab} merchant={merchant} biller={biller} />
                                </div>
                            )}

                            <div className={compact ? "col-12" : "col-lg-4"}>
                                {!compact && (
                                    <IconText mobile>View on mobile</IconText>
                                )}
                                <page.Component mobile style={style} refreshTrigger={tab} merchant={merchant} biller={biller} />
                            </div>
                        </Row>
                    </Tab>
                ))}

            </Tabs>
        </div>
    );
};

export default PreviewTabs;