import React from 'react';
import classNames from 'classnames';

import { cardNetwork, cardNetworks } from '@premier/cards';
import './CardLogo.scss';

import visaLogo from 'images/card-logo-visa.svg';
import mastercardLogo from 'images/card-logo-mastercard.svg';
import amexLogo from 'images/card-logo-amex.svg';
import dinersLogo from 'images/card-logo-diners.svg';
import jcbLogo from 'images/card-logo-jcb.svg';
import cupLogo from 'images/card-logo-unionpay.svg';
import paypalLogo from 'images/card-logo-paypal.svg';
import klarnaLogo from 'images/card-logo-klarna.svg';

type Props = {
    network?: {
        code?: string,
        serverCode?: string,
        name?: string
    } | null,

    /** CardTypeCode from the API. Same as network.serverCode */
    cardTypeCode?: string,

    /** Fade it out */
    unselected?: boolean,
};

const CardLogo = ({
    unselected, network, cardTypeCode
}: Props) => {

    if (!network?.code && (cardTypeCode || network?.serverCode)) {
        network = cardNetworks.find(x => x.serverCode === (cardTypeCode || network?.serverCode));
    }

    if (!network?.code) {
        return null;
    }

    var imgSrc = ((code) => {
        switch (code) {
            case cardNetwork.visa.code: return visaLogo;
            case cardNetwork.mastercard.code: return mastercardLogo;
            case cardNetwork.amex.code: return amexLogo;
            case cardNetwork.diners.code: return dinersLogo;
            case cardNetwork.jcb.code: return jcbLogo;
            case cardNetwork.unionPay.code: return cupLogo;
            case cardNetwork.paypal.code: return paypalLogo;
            case cardNetwork.klarna.code: return klarnaLogo;
            default: return null;
        }
    })(network?.code);

    if (!imgSrc) {
        return null;
    }

    var className = classNames('card-logo', { unselected: unselected });

    return <img src={imgSrc} alt={network?.name} className={className} />;
};

export default CardLogo;