import { useMemo, useRef, useState } from "react";
import { Button } from "packages/ui";
import { TemplatesProps } from "../types";
import { HppPreviewMode } from "../enums";
import { Draggable } from "react-beautiful-dnd";
import { FaArrowsAltV } from "react-icons/fa";

const withDesignerControl = (WrappedComponent: React.ComponentType<TemplatesProps>) =>
    (props: TemplatesProps) => {
        const ref = useRef<HTMLDivElement | null>(null);
        const [focused, setFocused] = useState(false);

        useMemo(() => {
            if (props.focusedId === props.id) {
                // Needs to be in a timeout otherwise ref.current is null
                setTimeout(() => {
                    // Scroll to the control if we just saved it from the panel
                    ref.current?.scrollIntoView({block: "start"});
                }, 100);
            }
        }, [props.focusedId, props.id, ref]);

        const getControl = () => <>
            <WrappedComponent {...props} />
            {props.mode === HppPreviewMode.Content && focused &&
                <div className="eform-ctrl-actions">
                    <Button
                        onClick={() => props.onEditClicked && props.id && props.onEditClicked(props.id)}
                        subtle
                        zeroMinWidth
                        noMarginRight
                        noMarginBottom
                    >
                        Edit
                    </Button>
                    {!props.hideDuplicate && <Button
                        onClick={() => props.onDuplicateClicked && props.id && props.onDuplicateClicked(props.id)}
                        subtle
                        zeroMinWidth
                        noMarginRight
                        noMarginBottom
                    >
                        Duplicate
                    </Button>}
                    {!props.hideRemove && <Button
                        onClick={() => props.onRemoveClicked && props.id && props.onRemoveClicked(props.id)}
                        subtle
                        zeroMinWidth
                        noMarginRight
                        noMarginBottom
                    >
                        Remove
                    </Button>}
                </div>}
        </>;

        return (
            <div ref={ref}>
                {props.dragDropDisable ?
                    <div
                        className="eform-ctrl-container"
                        onMouseEnter={() => setFocused(true)}
                        onMouseLeave={() => setFocused(false)}
                    >
                        {getControl()}
                    </div> :
                    <Draggable
                        draggableId={`draggable_${props.id}`}
                        key={`draggable_${props.id}`}
                        index={props.order}
                        isDragDisabled={props.dragDropDisable}
                    >
                        {(provided) => (
                            <div
                                className="eform-ctrl-container"
                                onMouseEnter={() => setFocused(true)}
                                onMouseLeave={() => setFocused(false)}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                            >
                                {!props.dragDropDisable &&
                                    <div
                                        className="control-draggable-icon"
                                        title="Click and drag to re-order"
                                        {...provided.dragHandleProps}
                                    >
                                        <FaArrowsAltV />
                                    </div>}
                                {getControl()}
                            </div>
                        )}
                    </Draggable>}
            </div>
        );
    };

export default withDesignerControl;
