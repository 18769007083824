import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Button, Dialog, Icon, LoadingIndicator, PaddedContainer, PageSection, Switch } from "@premier/ui";
import { PageHeader, useApiCall, APICallRequestState, ProductTooltip } from "components/Common";
import BrandingLandingForMerchant from "../components/BrandingLandingForMerchant";
import BrandingLandingByBiller from "../components/BrandingLandingByBiller";
import PaymentPageUrlSection from "../components/PaymentPageUrlSection";
import * as designerActions from "components/Settings/_actions/designerActions";
import * as accountActions from "components/Account/_actions/accountActions";
import { billerApi } from "api";
import { Biller } from "packages/webapi-client/api";
import { RootState } from "store/store";

type Props = {
    designerActions: any;
    accountActions: any;
    byBiller: boolean;
}

const BrandingPage = ({
    designerActions, accountActions, byBiller
}: Props) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [billers, billersStatus] = useApiCall<Biller[]>(() => billerApi.getBillersV2(false), []);

    function handleByBillerChange() {
        if (byBiller)  // Confirm when turning off
            setShowConfirmDialog(true);
        else
            handleConfirmChange();
    }

    function handleConfirmChange() {
        closeDialog();
        const value = !byBiller;
        designerActions.setByBiller(value).then(() => {
            accountActions.setBrandingByBillerLocally(value);
        });
    }

    function closeDialog() {
        setShowConfirmDialog(false);
    }

    return (
        <div className="branding-page">
            <PageSection>
                <PageHeader title={<>{"Payment page"}<ProductTooltip productTooltipModule={"PAYMENT_PAGE_DESIGNER"} /></>} />

                { billersStatus === APICallRequestState.SUCCESSFUL ?
                    <>
                        {((billers && billers.length > 1) || byBiller) && (
                            <PaddedContainer title="Apply brand style by biller code" switchOnRight noDivider>
                                <p>You can apply different brand styles by biller code</p>
                                <Switch on={byBiller} onChange={handleByBillerChange} />
                            </PaddedContainer>
                        )}
                    </>
                    :
                    <LoadingIndicator />
                }

                <PaymentPageUrlSection hideEditBrandingRedirect />

                { billersStatus === APICallRequestState.SUCCESSFUL ?
                    byBiller
                        ? <BrandingLandingByBiller billers={billers ?? []} />
                        : <BrandingLandingForMerchant biller={billers ? billers[0] : undefined} />
                    : <LoadingIndicator />
                }

            </PageSection>

            <Dialog
                show={showConfirmDialog}
                onClose={closeDialog}
                icon={<Icon info />}
                title="You are going to turn off this function"
                footerButtons={<>
                    <Button primary onClick={handleConfirmChange}>Confirm</Button>
                    <Button onClick={closeDialog}>Cancel</Button>
                </>}
            >
                This will apply your default branding style to all your biller codes.
            </Dialog>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        byBiller: state.accounts.users.merchant.brandingByBiller
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        designerActions: bindActionCreators(designerActions, dispatch),
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingPage);
