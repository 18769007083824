import React, { useState } from "react";
import { ControlAttributes } from "components/EForms/types";
import classNames from "classnames";

import "./Label.scss";

type InputProps = {
    attributes: ControlAttributes;
    type?: string;
    labeledInput?: boolean;
    label?: string;
    mandatory?: boolean;
    showErrors?: boolean;
    disabled?: boolean;
    onValueChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    inputValidation?: (value: string, setError: React.Dispatch<React.SetStateAction<string>>, attributes: ControlAttributes) => void;
}

const Input = ({ attributes, type, labeledInput, label, mandatory = true, showErrors = true, disabled, onValueChange, onBlur, inputValidation }: InputProps) => {
    const [inputValue, setInputValue] = useState(attributes?.prefilledText ?? "");
    const [error, setError] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        const value = e.target.value;
        setInputValue(value);
        inputValidation && inputValidation(value, setError, attributes);
        onValueChange && onValueChange(value);
    };

    const handleBlur = () => {
        setError("");
        inputValidation && inputValidation(inputValue, setError, attributes);
        onBlur && onBlur(inputValue);
    };

    return (
        <>
            <div className={labeledInput ? "" : `jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                {labeledInput && label &&
                    <label style={{ fontFamily: attributes.fontFamily }}>
                        {label}
                    </label>}
                <input
                    className={classNames("eform-ctrl-input jsElementAttributeInput", { "not-mandatory": !mandatory })}
                    data-attribute="PrefilledText"
                    type={type ?? "text"}
                    placeholder={attributes?.placeholderText ?? ""}
                    maxLength={attributes?.maximumCharacters}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={inputValue}
                    disabled={disabled}
                />
            </div>
            {showErrors && error && <div className="errors" style={{ fontFamily: attributes.fontFamily }}><div>{error}</div></div>}
        </>
    );
};

export default Input;