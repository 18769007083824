import textUtil from "@premier/utils/text";
import * as billpayUtil from "@premier/utils/billpay";
import { getCardInfo } from "@premier/cards";
import { CardLogo } from "@premier/ui";
import { WalletLogo } from "components/Common";
// @ts-ignore
import { WalletTypes } from "constants/billpay";
import BaseTransactionDetailCategory from "./BaseTransactionDetailCategory";
import { Transaction } from "models/Transaction";

type Props = {
    transaction: Transaction;
}

const CardDetailsCategory = ({ transaction }: Props) => {
    //TODO 75130976456 AMEX Wallet

    function formatCardExpiryDate() {
        if (transaction?.paymentProcessedTxnModel.cardDetails?.expiryDate?.month === 0 &&
            transaction?.paymentProcessedTxnModel.cardDetails.expiryDate.year === 0) {
            return "00/00";
        }
        if (transaction?.paymentProcessedTxnModel.cardDetails?.expiryDate?.month === 0) {
            return "Unknown";
        }
        if (transaction.paymentSource?.walletType === WalletTypes.APPLEPAY) {
            return "--/--";
        }
        return transaction.paymentProcessedTxnModel.cardDetails?.expiryDate ? billpayUtil.formatExpiry(transaction.paymentProcessedTxnModel.cardDetails.expiryDate) : "";
    }

    const cardInfo = getCardInfo(transaction.paymentProcessedTxnModel.cardDetails?.cardNumber, transaction.paymentProcessedTxnModel.cardDetails?.cardTypeCode);
    const cardDetails = [];

    cardDetails.push({ name: "Cardholder name", value: transaction.paymentProcessedTxnModel.accountName });
    cardDetails.push({ name: "Card number", value: transaction.paymentProcessedTxnModel.cardDetails?.cardNumber });

    if (transaction.paymentProcessedTxnModel.cardDetails?.expiryDate) {
        cardDetails.push({ name: "Expiry date", value: formatCardExpiryDate() });
    }

    if (cardInfo.network?.cardCvnEnteredDisabled ?? false) {
        cardDetails.push({ name: cardInfo.cvcLabel + " entered", value: transaction.paymentProcessedTxnModel.cardDetails?.cvcPresent ? "Yes" : "No" });
    }

    cardDetails.push({ name: "Card type", value: <CardLogo network={cardInfo.network} /> });

    if (transaction.paymentProcessedTxnModel.cardDetails?.cardTypeCode && (!cardInfo.network || !cardInfo.network.cardSubTypeDisabled)) {
        cardDetails.push({ name: "Card sub-type", value: textUtil.toInitialCapital(transaction.paymentProcessedTxnModel.cardDetails?.cardSubType || "Unknown") });
    }

    if (transaction.paymentProcessedTxnModel.cardDetails?.cardIssuingCountryName) {
        cardDetails.push({ name: "Card issuing country", value: transaction.paymentProcessedTxnModel.cardDetails.cardIssuingCountryName });
    }

    if (transaction.paymentSource?.walletType) {
        cardDetails.push({ name: "Wallet", value: <WalletLogo wallet={transaction.paymentSource?.walletType} /> });
    }

    return <BaseTransactionDetailCategory title="Card details" items={cardDetails} />;
};

export default CardDetailsCategory;
