import React from 'react'
import { absolute } from '@premier/utils/url';
import { RouteConfigurationMap } from 'components/Routing'
import text from 'platforms/current/constants/text';

import { NotFoundPage } from 'components/Common';
// necessary for routing
import LoginPage from 'components/Account/pages/LoginPage';
import LogoutPage from 'components/Account/pages/LogoutPage';
import UserAgreementPage from 'components/Account/pages/UserAgreementPage';

//password pages
import ForgotPasswordConfirmationPage from 'components/Account/pages/ForgotPassword/ForgotPasswordConfirmationPage';
import ChangePasswordPage from 'components/Account/pages/ForgotPassword/ChangePasswordPage';
import ForgotPasswordPage from 'components/Account/pages/ForgotPassword/ForgotPasswordPage';

import HomePage from 'components/Account/pages/HomePage';
import NoticePage from 'components/Account/pages/NoticePage';
import ProfilePage from 'components/Account/pages/ProfilePage';

import UnauthorizedPage from 'components/Account/pages/UnauthorizedPage';
import ContactUsPage from 'components/Account/pages/ContactUsPage';
import ContactPage from 'components/Account/pages/ContactPage';
import DevelopersPage from 'components/Account/pages/DevelopersPage';
import MultiFactorAuthenticationPage from 'components/Account/pages/ForgotPassword/MultiFactorAuthenticationPage';

export const accountRoute : RouteConfigurationMap = {
    root: {
        path: '/',
        element: <LoginPage />,
        generatePath: () => '/',
        title: text.loginHeader,
        publicPath: true,
    },
    notFound: {
        path: '*',
        element: <NotFoundPage />,
        generatePath: () => '/404',
        title: '404',
        publicPath: true,
    },
    logon: {
        path:  '/logon',
        element: <LoginPage />,
        generatePath: () => '/logon',
        title: text.loginHeader,
        publicPath: true,
    },
    logoff: {
        path:               '/logoff',
        generatePath: () => '/logoff',
        element: <LogoutPage />,
        publicPath: true,
    },
    forgotPassword: {
        path:               '/forgot-password',
        element: <ForgotPasswordPage />,
        generatePath: () => '/forgot-password',
        title: 'Forgot password?',
        publicPath: true,
    },
    forgotPasswordConfirmation: {
        path:               '/forgot-password/reset-password-success',
        element: <ForgotPasswordConfirmationPage />,
        generatePath: () => '/forgot-password/reset-password-success',
        title: 'Please check your email',
        publicPath: true,
    },
    changePassword: {
        path:               '/forgot-password/change-password',
        element: <ChangePasswordPage />,
        generatePath: () => '/forgot-password/change-password',
        title: 'Change password',
        publicPath: true,
    },
    multiFactorAuthentication: {
        path:               '/multi-factor-authentication',
        element: <MultiFactorAuthenticationPage />,
        generatePath: () => '/multi-factor-authentication',
        title: 'Multi-factor Authentication',
        publicPath: true,
    },
    home: {
        path:               '/home',
        element: <HomePage />,
        generatePath: () => '/home',
        title: 'Welcome'
    },
    noticeDetails: {
        path:                         '/home/notice/:id',
        element: <NoticePage />,
        generatePath: (id: number) => `/home/notice/${id}`,
        title: 'Message details',
    },
    profile: {
        path:               '/account/my-profile',
        element: <ProfilePage />,
        generatePath: () => '/account/my-profile',
        title: 'My profile',
    },
    unauthorized: {
        path:               '/unauthorized',
        element: <UnauthorizedPage />,
        generatePath: () => '/unauthorized',
        title: 'Unauthorized access',
        publicPath: true
    },
    contactUs: {
        path:               '/contact-us',
        element: <ContactUsPage />,
        generatePath: () => '/contact-us',
        title: 'Contact us',
    },
    contact: {
        path:               '/contact',
        element: <ContactPage />,
        generatePath: () => '/contact',
        title: 'Contact us',
        publicPath: true,
    },
    usagePolicy: {
        path:               '/usage-policy',
        element: <UserAgreementPage />,
        generatePath: () => '/usage-policy',
        publicPath: true
    },
    developerCentre: {
        path:               '/developers',
        element: <DevelopersPage />,
        generatePath: () => '/developers',
        title: 'Developer Centre',
        publicPath: true,
    },
    developerReference: {
        path:               absolute(process.env.REACT_APP_SITE_DOMAIN || '', 'developers/v3'),
        generatePath: () => absolute(process.env.REACT_APP_SITE_DOMAIN || '', 'developers/v3'),
        publicPath: true,
    },
    /**
     * landingPage should only be used when you are overwriting the default
     * page the user logs into.  Do NOT use landingPage.path for route matching.
     */
    landingPage: {
        path:               '/home',
        generatePath: () => '/home',
        roles:              []
    }
};