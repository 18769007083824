import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import fileUtil from '@premier/utils/file';
import { searchableTransactionDateRange } from '@premier/utils/date';

import { PageSection, Row, LoadingIndicator } from '@premier/ui';
import { Form, DropdownField, DateRangePresetField, DateRangeEnum, RadioField, SubmitButton, CheckboxGroupField, validate } from '@premier/form';
import { PageHeader, FormError, MerchantDropdownLegacy } from 'components/Common';
import { SettlementExportOptionLabel } from 'constants/billpay';

import * as settlementActions from 'components/Reports/_actions/settlementActions';
import * as commonActions from 'components/Common/_actions/actions';

import './ExportSettlements.scss';
import labels from 'constants/labels';

const ExportSettlements = ({
    settlementActions, commonActions, //API actions
    exportTypes, exportColumns, exportOptions, merchant, //Export state values
    isLoading, //logic render
    errors //form
}) => {
    const REPORT_CODE_SUMMARY = 'summary';
    const DEFAULT_COLUMN_PRESET = SettlementExportOptionLabel.STANDARD;
    const [exportError, setExportError] = useState(null);

    useEffect(() => {
        settlementActions.getSettlementExportTypes();
    }, [settlementActions]);


    function handleExportFormatChange(reportCode, ctx) {
        if(reportCode && reportCode !== REPORT_CODE_SUMMARY) {
            var apiCalls = [
                settlementActions.getSettlementExportColumns(reportCode),
                settlementActions.getSettlementExportOptions(reportCode),
            ];

            Promise.all(apiCalls).then(([exportColumns, exportOptions]) => {
                if(ctx.values.columnPreset) {  // A preset was selected - Reselect columns for this reportCode
                    ctx.setValue('columnCodesToInclude', getPresetColumnCodes(ctx.values.columnPreset, exportOptions));
                } else {  // Custom column selection - Deselect columns not available for this reportCode
                    let commonSelectedValues = _.intersection(ctx.values.columnCodesToInclude, exportColumns.map(x => x.columnCode));
                    ctx.setValue('columnCodesToInclude', commonSelectedValues);
                }
            });
        }
    }

    function generateExportOptions() {
        var options = _.sortBy(exportOptions || [], x => x.defaultColumns.length)
            .map(x => ({
                value: x.optionDesc,
                label: `${x.optionDesc} (${x.defaultColumns.length})`
            }));
        return options.concat([{value: '', label: 'Custom'}]);
    }

    function getPresetColumnCodes(presetName, currentExportOptions = exportOptions) {
        return _.get(_.find(currentExportOptions, {optionDesc: presetName}), 'defaultColumns', []).map(x => x.columnCode);
    }

    function handleColumnPresetChange(val, ctx) {
        if(val) {
            ctx.setValue('columnCodesToInclude', getPresetColumnCodes(val));
        } else {  // Custom - Clear checkboxes
            ctx.setValue('columnCodesToInclude', []);
        }
    }

    function handleColumnsChange(val, ctx) {
        var presetValues = generateExportOptions().filter(x => x.value).map(x => x.value);

        // Select the right preset value based on the column selections (most likely would be 'Custom' but not always)
        var matchedPreset = '';
        for(let presetName of presetValues) {
            var cols = getPresetColumnCodes(presetName);
            if(_.isEmpty(_.xor(val, cols))) {
                matchedPreset = presetName;
                break;
            }
        }
        ctx.setValue('columnPreset', matchedPreset);
    }

    function handleSubmit(values) {
        commonActions.clearErrors();  // Since this may call one API or another, this clears errors from the other API (if any)

        var action = values.reportCode === REPORT_CODE_SUMMARY
            ? settlementActions.downloadSettlementSummary
            : settlementActions.downloadSettlementExport;

        action(values).then(result => {
            fileUtil.download(result.fileData, result.fileName);
            setExportError(null);
        })
        .catch(exportError => {
            setExportError(exportError || 'An error occurred');
        });
    }


    return (
        <div className='export-settlements-page'>
            <PageSection className='header'>
                <PageHeader backButton title='Export settlements'
                    subtitle='Set up your report by selecting the fields below'
                />
            </PageSection>

            <div className='form-container'>
                <Form
                    initialValues={{
                        childMerchantNumber: merchant.merchantNumber,
                        dateRange: {presetValue: DateRangeEnum.YESTERDAY},
                        columnPreset: DEFAULT_COLUMN_PRESET,
                        columnCodesToInclude: [],
                    }}
                    initialValidation={{
                        reportCode: validate().required('Please select a format'),
                        columnCodesToInclude: validate().requiredIf(
                            values => values.reportCode !== REPORT_CODE_SUMMARY,
                            'Please select some items to export'
                        ),
                    }}
                    onSubmit={handleSubmit}
                    render={(context) => (<>
                        <PageSection>
                            <Row>
                                <MerchantDropdownLegacy
                                    className='col-lg-6'
                                    name='childMerchantNumber'
                                    useDefaultValue={true}
                                />

                                <DateRangePresetField className='col-lg-6'
                                    colClass='col-xl-12'
                                    name='dateRange'
                                    label={labels.date}
                                    presetOptions={[
                                        { value: DateRangeEnum.YESTERDAY,   label: 'Yesterday' },
                                        { value: DateRangeEnum.LAST_7DAYS,  label: 'Last 7 days' },
                                        { value: DateRangeEnum.LAST_30DAYS, label: 'Last 30 days' },
                                    ]}
                                    minDate={searchableTransactionDateRange[0]}
                                    maxDate={searchableTransactionDateRange[1]}
                                    isStatic={false}
                                />
                            </Row>
                        </PageSection>

                        <PageSection>
                            <RadioField inlineUpSm className='export-format'
                                name='reportCode'
                                label='Export format'
                                options={[{value: REPORT_CODE_SUMMARY, label: 'Summary report'}].concat(
                                    _.get(exportTypes, 'items', []).map(x => ({value: x.key, label: x.description}))
                                )}
                                onChange={handleExportFormatChange}
                            />
                        </PageSection>

                        {isLoading && (<LoadingIndicator />)}

                        {context.values.reportCode !== REPORT_CODE_SUMMARY && exportColumns && (<>
                            <PageSection>
                                <DropdownField
                                    name='columnPreset'
                                    label='Export options'
                                    options={generateExportOptions()}
                                    onChange={handleColumnPresetChange}
                                />
                                <p className='export-options-desc'>Selected items will be exported in report</p>
                                <CheckboxGroupField inlineUpSm className='column-checkboxes'
                                    name='columnCodesToInclude'
                                    options={(exportColumns || []).map(x => ({value: x.columnCode, label: x.columnDesc}))}
                                    onChange={handleColumnsChange}
                                />
                            </PageSection>
                        </>)}

                        <PageSection>
                            <FormError apiErrors={errors || exportError} />
                            <SubmitButton>Export</SubmitButton>
                        </PageSection>
                    </>)}
                />
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        isLoading: state.reports.settlement.isLoading,
        errors: state.reports.settlement.errors,
        exportTypes: state.reports.settlement.exportTypes,
        exportColumns: state.reports.settlement.exportColumns,
        exportOptions: state.reports.settlement.exportOptions,
        merchant: state.accounts.users.merchant
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settlementActions: bindActionCreators(settlementActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportSettlements);