import { ColourInput } from "@premier/ui";
import { useField } from "formik";

type Props = {
    name: string;
}

const FormikColourField = ({ name }: Props) => {
    const [field, meta, helpers] = useField<string>(name);

    return <ColourInput
        value={field.value}
        error={meta.error}
        onChange={(value) => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
    />
};

export default FormikColourField;
