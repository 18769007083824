import { ApiResult, mapResultErrors } from './mapErrors';
import { config, resultHandler, emptyResultHandler } from './util';
import { MessagingApiFactory, MessagingSettingsModel , MessageEmailConfigurationModel } from '@premier/webapi-client';

const messagingApi = MessagingApiFactory(config);

class MessagingApi {
    static getSettings(): Promise<ApiResult<MessagingSettingsModel>> {
        return messagingApi.messagingGetMessagingSettings().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static updateSettings(settings: MessagingSettingsModel): Promise<ApiResult<void>> {
        return messagingApi.messagingUpdateMessagingSettings(settings).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static updateEmailMessageConfiguration(Emailsettings : MessageEmailConfigurationModel){
        return messagingApi.messagingUpdateMessageConfiguration(Emailsettings);
    }

    static getEmailMessageConfiguration() {
        return messagingApi.messagingGetMessageConfiguration();
    }

    static getTemplateDefinitions(actionId: number) {
        return messagingApi.messagingGetTemplateDefinitions(actionId);
    }
}
export default MessagingApi;
