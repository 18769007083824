import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";

const Submit = ({ attributes }: TemplatesProps) => {
    return (
        <div className="eform-form eform-ctrl eform-ctrl-submit formContentElement">
            {attributes.clearForm && <button type="button" className="eform-ctrl-submit-btn eform-ctrl-submit-clearbtn">
                Clear Form
            </button>}
            {attributes.printForm && <button type="button" className="eform-ctrl-submit-btn eform-ctrl-submit-printbtn">
                Print Form
            </button>}
            <button type="button" className="eform-ctrl-submit-btn eform-ctrl-submit-submitbtn" style={{backgroundColor: attributes.buttonColor}}>
                {attributes.buttonText ?? "Submit"}
            </button>
        </div>
    );
};

export default withDesignerControl(Submit);
