function imageToPrint(base64String: string) {
    return "<html><head><script>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</script></head><body onload='step1()'>\n" +
        "<img src='data:image/png;base64," + base64String + "' /></body></html>";
};

function printImage(base64String: string) {
    const pagelink = "about:blank";
    const newWindow = window.open(pagelink, "_new");

    if (newWindow) {
        newWindow.document.open();
        newWindow.document.write(imageToPrint(base64String ?? ""));
        newWindow.document.close();
    }
};

export { printImage };
