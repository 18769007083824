import { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
// @ts-ignore
import { PaymentRequestDeliveryMethodEnum, ExpirePaymentLinkEnum } from "constants/billpay";
// @ts-ignore
import labels from "constants/labels";
import { Form, EmailAddressField, PhoneNumberField, DatePickerField, CheckboxField, validate } from "@premier/form";
import { Divider } from "@premier/ui";
import { MerchantBillerDetailsForm } from "components/Transactions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import { RootState } from "store/store";
import { Phone } from "models";
import _ from "lodash";
import { PhoneNumberOnChangeEvent } from "packages/form/fields/PhoneNumberField";

const paymentRequestConfigSelector = (state: RootState) => state.paymentRequest.requestConfig.data;

type Props = {
    /** The sub-form name */
    name?: string;
    billerCode?: string | undefined;
    /** Show the Save payment method checkbox */
    showSavePaymentMethod?: boolean;
    /**Allow subforms to show the biller selector & CRN fields */
    allowBillerSelection?: boolean;
    hideCrnFields?: boolean;
    /**QR request still needs to maintain the same form context object structure. See param name and its impact on the form context object structure. */
    qrCodeRequest?: boolean;
    merchantNumber?: string;
    paymentRequestActions: any;
    requestConfig: any;
}

type RequestConfig = {
    deliveryMethod: number;
    expirePaymentLink: number;
    dueDateMandatory: boolean;
    expiryDateMandatory: boolean;
}

/** For entering payment request details */
const PaymentRequestForm = ({
    paymentRequestActions, requestConfig, // API/Redux
    name = "paymentRequest", merchantNumber, billerCode, //data
    showSavePaymentMethod, allowBillerSelection, hideCrnFields, qrCodeRequest//logic renders
}: Props) => {
    const config = useSelector(paymentRequestConfigSelector);
    const [email, setEmail] = useState<string>();
    const [mobile, setMobile] = useState<Phone>();
    const [formContext, setFormContext] = useState<any>();
    const contextRef = useRef<any>();

    useEffect(() => {
        if (!formContext || !_.isEqual(contextRef.current?.values, formContext.values)) {
            setFormContext(contextRef.current);
        }
    }, [formContext, contextRef.current?.values]);

    useEffect(() => {
        if (billerCode) {
            paymentRequestActions.getRequestConfig(merchantNumber, billerCode);
        } else {
            paymentRequestActions.clearRequestConfig();
        }
    }, [merchantNumber, billerCode]);

    // Here we need to reset email & mobile validation everytime the requestConfig or email/mobile changes.
    useEffect(() => {
        resetValidation(requestConfig, formContext, email, mobile);
    }, [requestConfig, formContext, email, mobile]);

    const resetValidation = (requestConfig: RequestConfig, formContext: any, email: string | undefined, mobile: Phone | undefined) => {
        if (requestConfig && formContext) {
            // First clear errors and existing validation rules
            formContext.clearErrors();
            formContext.removeValidation("dueDate");
            formContext.removeValidation("expiryDate");
            formContext.removeValidation("mobile");
            formContext.removeValidation("email");

            // Now set validation rules depending on config
            if (requestConfig.dueDateMandatory) {
                formContext.setValidation("dueDate", validate().required());
            }

            if (requestConfig.expiryDateMandatory) {
                formContext.setValidation("expiryDate", validate().required());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.SMS_ONLY) {
                formContext.setValidation("mobile", validate().requiredPhone());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.EMAIL_ONLY) {
                formContext.setValidation("email", validate().required().email());
            }

            if (requestConfig.deliveryMethod === PaymentRequestDeliveryMethodEnum.EMAIL_AND_SMS) {
                formContext.setValidation("mobile", validate().requiredPhoneIf(() => !email?.trim()));
                formContext.setValidation("email", validate().requiredIf(() => !mobile?.phoneNumber?.trim() || config.barSMSLinks).email());
            }
        }
    };

    const renderForm = (context: any) => {
        // We need to do this so that this component always has the most up to date formContext
        if (!formContext || !_.isEqual(context.values, formContext.values)) {
            contextRef.current = context;
        }

        return <>
            { !qrCodeRequest &&
                <>
                    {allowBillerSelection && (
                        <MerchantBillerDetailsForm hideMerchantField={true} hideCrnFields={hideCrnFields} />
                    )}

                    {requestConfig?.deliveryMethod !== PaymentRequestDeliveryMethodEnum.SMS_ONLY && (
                        <EmailAddressField name="email" label={labels.email} onChange={(email) => setEmail(email)} />
                    )}
                    {requestConfig?.deliveryMethod !== PaymentRequestDeliveryMethodEnum.EMAIL_ONLY && (
                        <PhoneNumberField name="mobile" label={labels.mobile} onChange={(mobile: PhoneNumberOnChangeEvent) => setMobile(mobile)} />
                    )}
                    <DatePickerField
                        name="dueDate"
                        label={labels.dueDate}
                        mode="single"
                        mandatory={config && config.dueDateMandatory}
                    />
                    {config && config.expirePaymentLink === ExpirePaymentLinkEnum.CUSTOM_EXPIRY_DATE && (
                        <DatePickerField
                            name="expiryDate"
                            label={labels.expiryDate}
                            mode="single"
                            mandatory={config && config.expiryDateMandatory}
                        />
                    )}
                </>
            }

            { qrCodeRequest &&
                <>
                    Your customer can make payment via scanning the generated QR code.
                </>
            }

            {showSavePaymentMethod && (
                <>
                    <Divider />
                    <CheckboxField name="savePaymentMethod">Save payment method</CheckboxField>
                    <Divider />
                </>
            )}
        </>;
    };

    return (
        <Form
            name={name}
            initialValidation={{
                email: validate().email(),
                mobile: validate().phone(),
            }}
            render={renderForm}
        ></Form>
    );
};

function mapStateToProps(state: RootState) {
    return {
        requestConfig: state.paymentRequest.requestConfig.data
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestForm);
