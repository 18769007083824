/**
 * @description Input of type password with the ability to view input as plain text 
 *  via a click event on the appended eye closed/open icon.
 * 
 *  Dependencies on in house:
 *      - FieldValidation: \backoffice\src\packages\form\validation\fieldValidation.js
 *      - Form: \backoffice\\src\packages\form\Form.js
 */
import { FC, useState, JSX, useContext, useEffect, ChangeEvent, FocusEvent } from "react";
import { uniqueId } from "lodash";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import FormGroup from './FormGroup';
import "./PasswordFieldShowHide.scss";
import FormContext from "../FormContext";
import withField from './withField';
import { FormProps } from "./FormProps";

interface PasswordFieldShowHideProps {
    /** Input field label - required  */
    label: string;
    /** Input filed name - required */
    name: string;
    formProps: FormProps;
    /** otherProps - any */
    [otherProps : string]: any;
}

const PasswordFieldShowHide: FC<PasswordFieldShowHideProps> = ({
    label,
    name,
    formProps,
    ...otherProps
}) => {
    const context = useContext(FormContext);
    const [fieldError, setFieldError] = useState<undefined | string>(undefined);
    const [reveal, setReveal] = useState(false);
    const id = uniqueId(`${name}_`);
    const { groupProps } = otherProps;

    /**
     * @description On component render:
     *  - Register with the Form / Validator
     *  - Unregister as the component unmounts with the Form / Validator.
     */
    useEffect(() => {
        
        context.registerField(name, { label: label });
        return () => {
            context.unRegisterField(name);
        };
    }, [label]);

    /**
     * @description As the form context changes, listen for the currently resisted field's error. 
     */
    useEffect(() => {
        //  .getError(...) returns null or string.
        const error: string | null = context.getError(name);
        
        //  If error is null, its because all the business rules defined in the form config have been met.
        setFieldError(error ?? undefined);
    }, [context]);
    
    /**
     * @description performs common validation wrapped in try catch operation.
     * 
     * @param {string | undefined} value input value.
     */
    const handleCommonValidation = (value?: string): void => {

        context.setValue(name, value);
        try{
            context.validateField(name, value);
        } catch(e){
            // The in house validation service throws a JS Error at this point. 
            // Absorb the error to allow the form to surface the validation error message.
            // console.log(`ERROR: ${e}`)
        }
    };

    /**
     * @description Handle updates to the currently registered input element and validate on updated.
     * @param {ChangeEvent<HTMLInputElement>} event HTML Input element change object.
     */
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {

        event.preventDefault();

        const { target: { value }} = event;
        handleCommonValidation(value);
    };

    /**
     * @description Handle updates to the currently registered input element and validate on blur.
     * @param {FocusEvent<HTMLInputElement>} event HTML Input element blur object
     */
    const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {

        event.preventDefault();

        const { target: { value }} = event;
        handleCommonValidation(value);
    };

    /**
     * @description Renders the appropriate SVG icon based on the current boolean value of reveal. 
     * @returns 
     */
    const PasswordRevealer = (): JSX.Element => {
        return (<InputGroup.Text className={`textbox ${fieldError !== undefined ? 'has-error' : ''}`}>
            {reveal 
                ? <FaEye className="reveal-icon" onClick={() => setReveal(false)} /> 
                : <FaEyeSlash className="reveal-icon" onClick={() => setReveal(true)} />
            }
        </InputGroup.Text>);
    };

    return (
        <FormGroup
            name={name}
            label={label}
            fieldId={id}
            {...groupProps}
            className={`password-field-hide-show ${fieldError !== undefined ? 'has-error' : ''}`}
        >
            <InputGroup hasValidation>
                <FormControl
                    className={`textbox ${fieldError !== undefined ? 'has-error' : ''}`}
                    name={name}
                    type={reveal ? "text" : "password"}
                    id={id}
                    value={formProps?.value || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e)}
                    {...otherProps as any}
                />
                <InputGroup.Append><PasswordRevealer /></InputGroup.Append>
                
                {fieldError !== undefined &&
                    <Form.Control.Feedback type="invalid">
                        <span className="sr-only">field input error: {name} {fieldError}</span>
                        {fieldError}
                    </Form.Control.Feedback>
                }
            </InputGroup>
        </FormGroup>
    );
};

export default withField(PasswordFieldShowHide);
