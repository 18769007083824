import { BsThreeDots } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";

import "./ThreeDotMenu.scss";

type Props = {
    items: ThreeDotMenuItem[];
}

type ThreeDotMenuItem = {
    title: string;
    onClick: () => void;
}

const ThreeDotMenu = ({ items = [] }: Props) => {
    return (
        <Dropdown className="three-dot-menu-dropdown" onClick={((e: React.MouseEvent | KeyboardEvent) => e.preventDefault())}>
            <Dropdown.Toggle className="three-dot-menu-button">
                <BsThreeDots />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {items.map((item, index) => (
                    <Dropdown.Item key={index} onClick={item.onClick}><div className="btn-link">{item.title}</div></Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default ThreeDotMenu;
