import base from 'platforms/base/constants/mediaPaths';

export default {...base,
    batchFileSpec: `${process.env.PUBLIC_URL}/media/documents/BPOINT_Batch_File_Specification_2.0_20190701.pdf`,
    batchApcaFileSpec: `${process.env.PUBLIC_URL}/media/documents/BPOINT_Batch_APCA_Format_1.1.pdf`,
    fraudControlFileFormat: `${process.env.PUBLIC_URL}/media/documents/FraudControlFileFormat.pdf`,
    iServicesCrnUploadFormat: `${process.env.PUBLIC_URL}/media/documents/BPOINT_iServices_CRN_Upload.pdf`,
    testingBpointPhoneInternetDDCC: `${process.env.PUBLIC_URL}/media/documents/Testing(Phone,Internet,DDCC).pdf`,
    exportFileLoadFormatv2: `${process.env.PUBLIC_URL}/media/documents/BPOINT Internet - Export File Load Format v2.0.pdf`,
    directDebitCsvFormat: `${process.env.PUBLIC_URL}/media/documents/BPOINT Direct Debit CC - CSV File Format.pdf`,
    directDebitVposFormat: `${process.env.PUBLIC_URL}/media/documents/BPOINT Direct Debit CC - VPOS File Format.pdf`,
    fraudControlFileFormatAndSample: `${process.env.PUBLIC_URL}/media/documents/BPOINT Fraud Control - File Format and Sample.pdf`,
    prefillingFields: `${process.env.PUBLIC_URL}/media/documents/BPOINT Internet - Pre-filling Fields.pdf`,
    paymentLinkGeneratorBatchCSVFormat: `${process.env.PUBLIC_URL}/media/documents/BPOINT Payment Link Generator Batch - CSV File Format.pdf`,
};