import { connect } from "react-redux";
import { Link, ButtonContainer, Divider } from "@premier/ui";
import { FormHeader, HelpText, FloatingContainer } from "components/Common";
import { RootState } from "store/store";
import text from "platforms/current/constants/text";
import { PlatformRoutesConfiguration } from "components/Routing";
import "./ContactPage.scss";

type Props = {
    legacyBackOfficeUrl: string;
}

const ContactPage = ({
    legacyBackOfficeUrl
}: Props) => {

    return (
        <FloatingContainer>
            <FormHeader title="Contact Us" description="Get dedicated BPOINT Support" />
            <div className="contact-context">
                Call {text.supportPhoneNumber}<br /><br />
                Email support at <a href={`mailto:${text.supportEmail}`}>{text.supportEmail}</a>
                <Divider />
            </div>

            <ButtonContainer>
                <Link button primary to={PlatformRoutesConfiguration.accountRoute.logon.generatePath()}>Log on</Link>
            </ButtonContainer>

            <div className="switch-to-old-url-md">
                <a href={legacyBackOfficeUrl} >Log on to previous back office</a>
            </div>
        </FloatingContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        legacyBackOfficeUrl: state.accounts.users.configuration?.legacyBackOfficeUrl
    };
}

export default connect(mapStateToProps)(ContactPage);