import { useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import DropdownComponent from "./components/DropdownComponent";
import useSpecials from "../hooks/useSpecials";
import classNames from "classnames";

type Props = {
    onChange?(value: string, valid: boolean): void;
} & TemplatesProps;

const DropDown = ({ id, name, attributes, onChange, onControlSelectionChanged }: Props) => {
    const { options } = useSpecials(attributes);
    const [valid, setValid] = useState(true);

    const handleChange = (value: string, valid: boolean) => {
        setValid(valid);
        onChange && onChange(value, valid);
        onControlSelectionChanged && onControlSelectionChanged(id, value);
    }

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-email eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <DropdownComponent
                attributes={attributes}
                options={options}
                defaultValue={Array.isArray(attributes.selected) ? attributes.selected[0] : attributes.selected as string}
                onChange={handleChange}
            />
        </div>
    );
};

export default withDesignerControl(DropDown);
