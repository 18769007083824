type Props = {
    base64String?: string;
    altText: string;
}

const Image = ({base64String, altText}: Props) => {
    return <img src={`data:image/png;base64, ${base64String}`} alt={altText} />;
}

export default Image;
