import { useState } from "react";
import { Button, Icon } from '@premier/ui';
import windowUtil from "@premier/utils/window";

import "./ClickableButton.scss";

type Props = {
    clickableButtonId: string;
    text: string;
    label?: string;
    noMarginBottom?: boolean;
    link?: boolean;
    disabled?: boolean;
}

const ClickableButton = ({ clickableButtonId, text, label, noMarginBottom = false, link = true, disabled }: Props) => {
    const [showCopied, setShowCopied] = useState(false);

    function showApiPasswordCopiedTextMessage() {
        if (text) {
            windowUtil.copyTextToClipboard(text);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 3000);
        }
    }

    return (
        showCopied
            ? <div className="copied-icon-container">
                <div className="copied-icon">
                    <Icon tick inline /> Copied
                </div>
            </div>
            : <Button
                link={link}
                label={label ?? text}
                title="Click to Copy"
                id={clickableButtonId}
                onClick={(e) => { e.preventDefault(); showApiPasswordCopiedTextMessage(); }}
                noMarginBottom={noMarginBottom}
                disabled={disabled}
            />
    );
};

export default ClickableButton;