import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { Button, ErrorModal, SuccessModal } from "@premier/ui";
import { tokenApi } from 'api';
import { ResendVerificationEmailRequest } from '@premier/webapi-client';
import { useState } from 'react';

import './EmailVerified.scss';

type Props = {

    email: string | undefined,
    isVerified: boolean | undefined,
    dataVaultId: number | undefined,
    childMerchantNumber: string | undefined,
};

const EmailVerified = ({email, isVerified, dataVaultId, childMerchantNumber}: Props) => {

    const [showSendSuccessModal, setShowSendSuccessModal] = useState(false);
    const [showSendErrorModal, setShowSendErrorModal] = useState(false);

    const resendEmail = async () => {
        const request: ResendVerificationEmailRequest = {
            dataVaultId: dataVaultId,
            childMerchantNumber: childMerchantNumber
        }
        try {
            const response = await tokenApi.resendVerificationEmail(request);

            if (response.status === 200)
                setShowSendSuccessModal(true);        
            else
                setShowSendErrorModal(true);
        }
        catch (error) {
            setShowSendErrorModal(true);
        }
    };

    if ((email ?? "").trim() === "" || (childMerchantNumber ?? "").trim() === "") {
        return (<></>);
    }

    return (
        <div className="email-verified">
            {email}

            {!isVerified?
                <>
                    <div className="icon-button">
                        <FaExclamationTriangle className='icon' color="orange" />
                        <Button noMarginBottom subtle onClick={ resendEmail }>Resend verification email</Button>
                    </div>

                    <SuccessModal 
                        show={showSendSuccessModal} 
                        title="Email sent successfully"
                        onClose={() => setShowSendSuccessModal(false)}
                    />
                    
                    <ErrorModal
                        disableAutoClose
                        show={showSendErrorModal}
                        title="Email could not be sent"
                        onClose={() => setShowSendErrorModal(false)} >
                    </ErrorModal>
                </>
                : 
                <FaCheckCircle className='icon' color="green" />
            }            
        </div>
    );
};

export default EmailVerified;