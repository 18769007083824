import { useState, useEffect, useContext, useMemo } from "react";
// @ts-ignore
import labels from "constants/labels";

import currencyUtil from "@premier/utils/currency";
import { CardInfo, cardNetworks, getCardInfo, isToken } from "@premier/cards";
import { CardLogo, Tooltip, TooltipTrigger, Icon } from "@premier/ui";

import Form from "../Form";
import FormContext from "../FormContext";
import InputField from "../fields/InputField";
import CardExpiryField from "../fields/CardExpiryField";
import CardVerificationField from "../fields/CardVerificationField";
import { validate } from "../validation/fieldValidation";
import tokenApi from "api/tokenApi";
import { PaymentTypeKey, TOKEN_LENGTH } from "platforms/base/constants/billpay";
import { useLocation } from "react-router-dom";
import { PlatformRoutesConfiguration } from "components/Routing";

import { APICallRequestState, useApiCall } from "components/Common";
import { utilitiesApi } from "api";

import "./CardInputForm.scss";

const defaultCardInfo = getCardInfo();

type Props = {
    /** The sub-form name. Default='card'. Try not to overwrite it (ie. leave it undefined) unless you have multiple of this in a Form. */
    name?: string;
    /** used to hide the CSV box, and make the expiry date span across */
    hideCvn?: boolean;
    maskedCardNumber?: string;
    /** Make card number and expiry date mandatory */
    required?: boolean;
    /** used to disable the cvn field, when order type is Recurring or Mail/Fax */
    disableCvn?: boolean;
    /* Merchant number to get biller details */
    merchantNumber?: string;
    /* Biller code to get accepted cards for biller */
    billerCode?: string;
}

/** For entering/editing credit card details */
const CardInputForm = ({ name = "card", hideCvn, maskedCardNumber, required, disableCvn, merchantNumber, billerCode }: Props) => {

    const context = useContext(FormContext);

    const [selectedNetworkCode, setSelectedNetworkCode] = useState<string | undefined | null>(null);
    const [cardParameters, setCardParameters] = useState(defaultCardInfo);
    const [showAccountNumber, setShowAccountNumber] = useState(false);
    const [isBankAccount, setIsBankAccount] = useState(false);
    const isNewPaymentPage = useLocation().pathname.startsWith(PlatformRoutesConfiguration.transactionRoute!.newPayment.path);

    useEffect(() => {
        context.setValidation(`${name}${name ? "." : ""}cvn`,
            validate().when(val => {
                if (!val || !cardParameters.network) return true;
                return val.length === cardParameters.cvcLength;
            }, `Invalid ${cardParameters.cvcLabel}`)
        );
    }, [cardParameters]);

    // The field name is cvn but the request type name is cvc so we need to
    // manually set the error message if we get a CVC error from the backend
    useEffect(() => {
        if (context?.errors?.card?.cvc?.length > 0) {
            context.setError("card.cvn", context.errors.card.cvc);
            context.validate();
        }
    }, [context?.errors?.card?.cvc]);

    useEffect(() => {
        async function innerAsync() {
            await handleCardNumberChanged(context.values.cardNumber);
        }

        innerAsync();
    }, [maskedCardNumber]);

    // Handle card number changes whenever the form value changes
    useEffect(() => {
        async function innerAsync() {
            if (context.values.card?.cardNumber) {
                await handleCardNumberChanged(context.values.card?.cardNumber);
            } else {
                setShowAccountNumber(false);
                setIsBankAccount(false);
            }
        }

        innerAsync();
    }, [context?.values?.card?.cardNumber]);

    // clear CVN field when it's disabled
    useEffect(() => {
        if (disableCvn) {
            context.setValue("card.cvn", null);
        }
    }, [disableCvn]);

    const selectedMerchantNumber = merchantNumber ?? context.getValue("billerCodeForm.merchantNumber");

    const [billers, billersStatus] = useApiCall(() => {
        return utilitiesApi.getBillersDetails(selectedMerchantNumber)
    }, [selectedMerchantNumber]);

    const acceptedCards = useMemo(() => {
        const selectedBillerCode = billerCode ?? context.getValue("billerCodeForm.billerCode");
        return billers?.find(b => b.billerCode === selectedBillerCode)?.acceptedCards
    }, [billers, billerCode, context]);

    const selectedCardHasSurcharges = acceptedCards?.find(s => s.surchargePercentagePoints !== undefined && s.surchargePercentagePoints !== null && s.surchargePercentagePoints > 0);

    const networks = useMemo(() => {
        const mappedCards = acceptedCards
            ? acceptedCards.map(n => cardNetworks.find(cn => cn.serverCode === n.cardTypeCode))
            : cardNetworks;

        return mappedCards;
    }, [acceptedCards]);

    async function handleCardNumberChanged(newValue: string) {
        const cardInfo = getCardInfo(newValue || maskedCardNumber);
        updateSelectedCardInfo(cardInfo);

        // Only try to fetch token details if value is indeed a token and is 16 digits
        // Currently this hide expiry date field feature only applys in /transactions/new-payment
        if (newValue?.length === TOKEN_LENGTH && isToken(newValue) && isNewPaymentPage) {
            const response = await tokenApi.getTokens(1, 0, { token: newValue });

            if (response.tokens && response.tokens.length > 0) {
                const item = response.tokens[0];
                const isBankAccount = item.type === PaymentTypeKey.BANK_ACCOUNT;
                context.setValue("card.accountNumber", isBankAccount ? `${item.deBsbNumber} - ${item.deAccountNumber}` : item.maskedCardNumber);
                setIsBankAccount(isBankAccount);
                setShowAccountNumber(true);

                if (!isBankAccount) {
                    context.setValue("card.cardholderName", null);
                    context.setValue("card.expiryDate.month", item.expiryDate?.month);
                    context.setValue("card.expiryDate.year", item.expiryDate?.year);
                } else {
                    context.setValue("card.cardholderName", item.accountName);
                    context.setValue("card.expiryDate.month", null);
                    context.setValue("card.expiryDate.year", null);
                }
            } else {
                setShowAccountNumber(false);
                setIsBankAccount(false);
            }
        } else {
            setShowAccountNumber(false);
            setIsBankAccount(false);
        }
    }

    function updateSelectedCardInfo(cardInfo: CardInfo) {
        const networkCode = cardInfo.network && cardInfo.network.code;
        const hasChanges =
            selectedNetworkCode !== networkCode ||
            cardInfo.cardLength !== cardParameters.cardLength ||
            cardInfo.cvcLength !== cardParameters.cvcLength ||
            cardInfo.cvcLabel !== cardParameters.cvcLabel;

        if (hasChanges) {
            //set the selected stuff.
            setSelectedNetworkCode(networkCode);
            setCardParameters(cardInfo);
        }
    }

    return (
        <div className="card-input-form">
            <Form
                name={name}
                initialValidation={{
                    cardNumber: validate().requiredIf(() => !maskedCardNumber),
                    // cvn validation is dynamic, done in useEffect above
                    cardholderName: validate().exceedsSequentialNumbers(12)
                }}
            >
                <InputField
                    digitsOnly
                    noSpinner
                    name="cardNumber"
                    label={<>
                        Card number
                        <span className="sr-only">
                            Accepted cards:
                            {networks.filter(n => !n?.hideForInput).map(n => ` ${n?.name}`)}
                        </span>
                    </>}
                    labelText="Card number"
                    maxLength={cardParameters.cardLength}
                    placeholder={maskedCardNumber}
                    help={
                        selectedCardHasSurcharges && (
                            <TooltipTrigger tipId="tip-surcharge">
                                Surcharge may apply <Icon question />
                            </TooltipTrigger>
                        )
                    }
                    helpWrapXs
                    onChange={handleCardNumberChanged}
                    mandatory={required}
                />

                {showAccountNumber ? <InputField name="accountNumber" label="Account number" plaintext /> : <></>}

                <div className="card-logos" aria-hidden>
                    {billersStatus !== APICallRequestState.LOADING && billersStatus !== APICallRequestState.PENDING && networks
                        .filter(c => !c?.hideForInput)
                        .map(n => (
                            <CardLogo
                                network={n}
                                unselected={selectedNetworkCode !== n?.code}
                                key={n?.code} />
                        ))}
                </div>


                {isBankAccount
                    ? <></>
                    : <div className="row">
                        <div className={[hideCvn ? "col-md-12" : "col-md-6", cardParameters.expiryDateRequired ? "" : ""].join(" ")}>
                            <CardExpiryField
                                required={isNewPaymentPage ? cardParameters.expiryDateRequired : required}
                                name={"expiryDate"}
                                label={labels.expiryDate}
                                placeholder="MM/YY" />
                        </div>

                        {!hideCvn && (
                            <div className="col-md-6">
                                <CardVerificationField
                                    name={"cvn"}
                                    label={cardParameters.cvcLabel}
                                    maxLength={cardParameters.cvcLength}
                                    disabled={disableCvn}
                                />
                            </div>
                        )}
                    </div>
                }

                <InputField
                    name="cardholderName"
                    label={isBankAccount ? "Account name" : "Cardholder name"}
                />

                <Tooltip id="tip-surcharge">
                    <h4>The following surcharges apply for card payments:</h4>

                    <table className="table surcharges">
                        <tbody>
                            {acceptedCards &&
                                acceptedCards.map(n => {
                                    return (
                                        <tr key={n.cardTypeCode}>
                                            <td>{n.displayName}</td>
                                            <td>{n.surchargePercentagePoints !== undefined && currencyUtil.convertToDisplayPercent(n.surchargePercentagePoints)}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </Tooltip>
            </Form>
        </div>
    );
};

export default CardInputForm;
