import menuLabels from "platforms/current/constants/menuLabels";
import { NavItem } from "models";
import { PlatformRoutesConfiguration } from "components/Routing";
import { AccountModel } from "@premier/webapi-client";
import SecurityUtil from "@premier/utils/security";
import { getPlatform } from "platforms/current/util";

export const loggedInItems: NavItem[] = [
    {
        text: menuLabels.home,
        route: PlatformRoutesConfiguration.accountRoute?.home
    }, {
        text: menuLabels.transactions,
        items: [
            {
                text: menuLabels.newPayment,
                route: PlatformRoutesConfiguration.transactionRoute?.newPayment
            },
            {
                text: menuLabels.manageTransactions,
                route: PlatformRoutesConfiguration.transactionRoute?.manageTransactions
            },
            {
                text: menuLabels.manageSchedules,
                route: PlatformRoutesConfiguration.transactionRoute?.manageSchedules
            },
            {
                text: menuLabels.paymentPlan,
                route: PlatformRoutesConfiguration.transactionRoute?.paymentPlan
            },
            {
                text: menuLabels.batchPayments,
                route: PlatformRoutesConfiguration.transactionRoute?.batchPayments
            },
            {
                text: menuLabels.declineManager,
                route: PlatformRoutesConfiguration.transactionRoute?.manageDeclines
            }
        ]
    }, {
        text: menuLabels.dataVault,
        items: [
            {
                text: menuLabels.manageCustomers,
                route: PlatformRoutesConfiguration.customerRoute?.manageCustomers
            },
            {
                text: menuLabels.manageTokens,
                route: PlatformRoutesConfiguration.tokenRoute?.manageTokens
            }
        ]
    }, {
        text: menuLabels.requests,
        items: [
            {
                text: menuLabels.newRequest,
                route: PlatformRoutesConfiguration.paymentRequestRoute?.newRequest
            },
            {
                text: menuLabels.manageRequests,
                route: PlatformRoutesConfiguration.paymentRequestRoute?.manageRequests
            },
            {
                text: menuLabels.batchRequests,
                route: PlatformRoutesConfiguration.batchPaymentRequestRoute?.manageBatchRequests
            }
        ]
    }, {
        text: menuLabels.reports,
        items: [
            {
                text: menuLabels.settlementReports,
                route: PlatformRoutesConfiguration.reportRoute?.settlementReports
            },
            {
                text: menuLabels.customReports,
                route: PlatformRoutesConfiguration.reportRoute?.customReports
            },
            {
                text: menuLabels.eFormSubmissions,
                route: PlatformRoutesConfiguration.reportRoute?.eFormSubmissions
            },
        ]
    }, {
        text: menuLabels.designer,
        items: [
            {
                text: menuLabels.paymentPage,
                route: PlatformRoutesConfiguration.designerRoute?.branding
            },
            {
                text: menuLabels.eForms,
                route: PlatformRoutesConfiguration.designerRoute?.eForms
            },
        ]
    }, {
        text: menuLabels.settings,
        items: [
            //TO BE RE-ENABLED AT A LATER DATE
            // {
            //     text: menuLabels.account,
            //     route: accountSettings.root
            // },
            {
                text: menuLabels.batchDeclineSettings,
                route: PlatformRoutesConfiguration.batchDeclineSettingsRoute?.settings
            },
            {
                text: menuLabels.businessDetails,
                route: PlatformRoutesConfiguration.businessDetailsSettingsRoute?.root
            },
            {
                text: menuLabels.paymentSettings,
                route: PlatformRoutesConfiguration.settingsRoute?.paymentSettings
            },
            {
                text: menuLabels.fraudSettings,
                route: PlatformRoutesConfiguration.fraudSettingsRoute?.root
            },
            {
                text: menuLabels.apiIntegration,
                route: PlatformRoutesConfiguration.apiIntegrationRoute?.root
            },
            {
                text: menuLabels.invoiceSettings,
                route: PlatformRoutesConfiguration.invoiceSettingsRoute?.root,
            },
            {
                text: menuLabels.iServicesSettings,
                route: PlatformRoutesConfiguration.iServicesSettingsRoute?.root
            },
            {
                text: menuLabels.manageUsers,
                route: PlatformRoutesConfiguration.usersRoute?.root
            },
            {
                text: menuLabels.messaging,
                route: PlatformRoutesConfiguration.messagingRoute?.root
            },
            {
                text: menuLabels.paymentPlanSettings,
                route: PlatformRoutesConfiguration.paymentPlanSettingsRoute?.root
            },
        ]
    }, {
        text: menuLabels.stationeryShop,
        items: [
            {
                text: menuLabels.stationeryProducts,
                route: PlatformRoutesConfiguration.stationeryRoute?.homePage
            },
            {
                text: menuLabels.stationeryOrders,
                route: PlatformRoutesConfiguration.stationeryRoute?.orders
            },
            {
                text: menuLabels.stationeryTerms,
                newWindow: true,
                route: PlatformRoutesConfiguration.stationeryRoute?.termsAndConditions
            },
        ]
    }, {
        text: menuLabels.feedback,
        route: PlatformRoutesConfiguration.feedbackRoute?.root,
    }, {
        text: menuLabels.contactUs,
        route: PlatformRoutesConfiguration.accountRoute?.contactUs,
        type: "public",
    }, {
        text: menuLabels.developerCentre,
        route: PlatformRoutesConfiguration.accountRoute?.developerCentre,
        type: "public"
    }, {
        text: menuLabels.developerReference,
        route: PlatformRoutesConfiguration.accountRoute?.developerReference,
        type: "public",
        newWindow: true
    }
];

export const userMenuItems = [{
    text: menuLabels.profile,
    route: PlatformRoutesConfiguration.accountRoute?.profile,
}, {
    text: menuLabels.logoff,
    route: PlatformRoutesConfiguration.accountRoute?.logoff,
}];

/**
 * To only show menu items that the user has access to.
 * Also filters out some menu items from StationeryShop.
 * Also assigns 'href' to menu items.
 * @param hasAnyReports whether the merchant, or child merchants have any reports, disables the reports tab
 */
export function filterMenuItems(items: NavItem[], user: AccountModel, hasAnyReports: boolean): NavItem[] {
    // Filter by permission
    let filteredItems = items.filter(x => SecurityUtil.hasAccess(x.route?.roles, user));
    if (getPlatform() !== "stationery_shop") {
        // Hide the CustomReports menu item if we have no reports
        if (!hasAnyReports) {
            filteredItems = filteredItems.filter(
                x => x.route !== PlatformRoutesConfiguration.reportRoute?.customReports
            );
        }

        // Hide Developer Reference if logged in
        if (user) {
            filteredItems = filteredItems.filter(
                x => x.route !== PlatformRoutesConfiguration.accountRoute?.developerReference
            );
        }
    }

    return filteredItems
        .filter(x => x.route?.path || x.items?.length)  // only return items with a link, or if it's a parent then only if it has children
        .map(x => ({
            ...x,
            items: x.items && filterMenuItems(x.items, user, hasAnyReports),
            href: x.route?.path,
        }));
}
