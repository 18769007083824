import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { RichTextEditor } from '@premier/ui';

export type FormikRichTextEditorProps = {
    name: string;
    value: string;
    toolbar?: string;
    plugins?: string;
};

const FormikRichTextEditor: React.FC<FormikRichTextEditorProps> = ({
    name, // data
    value,
    toolbar, 
    plugins
}) => {
    const [, , helpers] = useField(name);
    const { setValue } = helpers;

    const handleChange = (value: string | null) => {
        setValue(value);
    };

    return (
        <RichTextEditor
            hasError={false}
            value={value}
            onChange={(value) => { handleChange(value) }}
            disabled={false}
            toolbar={toolbar}
            plugins={plugins}
        />
    );
};

export default FormikRichTextEditor;
