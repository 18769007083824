import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import classNames from 'classnames';

import { setCurrency } from '@premier/utils/currency';

import { LoadingIndicator, CardContainer, LoadingOverlay } from '@premier/ui';
import { RouteContainer } from 'components/Routing';

import Header from 'components/Layout/Header/Header';
import { Footer, ScrollToTop, AppContainer } from 'components/Common';

import * as accountActions from 'components/Account/_actions/accountActions';
import * as shopActions from 'components/StationeryShop/_actions/stationeryShopActions';

import axiosInterceptors from './util/axiosInterceptors';

import 'css/base.scss';
import 'css/bootstrap/bootstrap.scss';
import 'css/styles/global.scss';
import './App.scss';

// this was importing the breakpoints from the scss file
// it broke with the react-scripts update
import /*layoutCss from */ 'css/constants/layout.scss';

const layoutCss = {
    breakpointXs: 0,
    breakpointSm: 600,
    breakpointMd: 864,
    breakpointLg: 1140,
    breakpointXl: 1440,
};

setDefaultBreakpoints([
    { xs: layoutCss.breakpointXs },
    { sm: layoutCss.breakpointSm },
    { md: layoutCss.breakpointMd },
    { lg: layoutCss.breakpointLg },
    { xl: layoutCss.breakpointXl },
]);

setCurrency('USD');

const App = (props) => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        axiosInterceptors.init();

        props.actions.init();
        props.actions.getPlatformConfiguration();

        props.actions
            .getSessionLogin()
            .then((result) => {
                setIsInitialized(true);
            })
            .catch((err) => {
                setIsInitialized(true);
            });

        props.shopActions.getCart();
    }, []);

    const isPrintView = window.location.pathname.endsWith('/print'); // This only works when print view opened in a new window (we don't currently need this to update on route changed, unless they're logged out when trying to print)

    return (
        <BreakpointProvider>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop>
                    <div className={classNames('app', { print: isPrintView })} aria-live='polite'>
                        <Header />
                        {isInitialized ?
                            <AppContainer>
                                <RouteContainer />
                            </AppContainer> :
                            <CardContainer header='Loading'>
                                <LoadingIndicator />
                            </CardContainer>
                        }
                        <Footer />
                    </div>
                    {props.blockUi && <LoadingOverlay text={props.blockUi} />}
                </ScrollToTop>
            </BrowserRouter>
        </BreakpointProvider>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
        requiredUserAction: state.accounts.users.requiredUserAction,
        blockUi: state.app.blockUi,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch),
        shopActions: bindActionCreators(shopActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
