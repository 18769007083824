import { createContext, ReactNode, useState } from "react";
import { CountryModel, TimeZoneModel } from "packages/webapi-client";
import { utilitiesApi } from "api";
import { useApiCall } from "components/Common";

type EFormsContextType = {
    countries: CountryModel[];
    timeZones: TimeZoneModel[];
    controlFocused: boolean;
    setControlFocused: (value: boolean) => void;
};

const EFormsContext = createContext<EFormsContextType>({
    countries: [],
    timeZones: [],
    controlFocused: false,
    setControlFocused: () => { },
});

const EFormsContextProvider = ({ children }: { children: ReactNode }) => {
    const [countries] = useApiCall(() => utilitiesApi.getCountries(), []);
    const [timeZones] = useApiCall(() => utilitiesApi.getTimeZones(), []);
    const [controlFocused, setControlFocused] = useState(false);

    return (
        <EFormsContext.Provider
            value={{
                countries: countries ?? [],
                timeZones: timeZones ?? [],
                controlFocused,
                setControlFocused,
            }}>
            {children}
        </EFormsContext.Provider>
    )
};

export { EFormsContext, EFormsContextProvider };
