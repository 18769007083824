import React from 'react';
import PropTypes from 'prop-types';

import labels from 'constants/labels';
import { Accordion, Divider } from '@premier/ui';
import { InputField, DropdownField, RadioField, DatePickerField, DateRangePresetField, MaskedCardNumberField, CheckboxGroupField, PhoneNumberField, CurrencyRangeField } from '@premier/form';
import { withAdvancedFilter } from 'components/Common';


const TransactionFilter = ({
    context, // from withAdvancedFilter
    onAutoUpdate, //functions
    validDateRange,
    responseTypeOptions, billerOptions, transactionTypeOptions, accountTypeOptions, txnTypesOptions,txnSubTypesOptions, txnSourcesOptions, txnSubSourcesOptions,  // backend enums/options
}) => {

    function handleAmountChange(val) {
        // clear the amount filter, as we should only have 1 set at a time
        if (val && (val.min || val.max))
            context.setValue("amount", '');
    }

    function handleTransactionTypesChange(val) {
        // clear the transaction type filter, as we should only have 1 set at a time
        if (val)
            context.setValue("transactionType", '');
    }

    return (
        <div className='transaction-filter'>
            <h1>Filter transactions</h1>

            <Accordion title='By date' enableDownMd>
                <DateRangePresetField
                    name='transactionDateRange'
                    label='Transaction date'
                    enableTime
                    minDate={validDateRange[0]}
                    maxDate={validDateRange[1]}
                    onAutoUpdate={val => onAutoUpdate(context.values)}
                    isStatic={false}
                />

                <div className='row'>
                    <div className='col-lg-6'>
                        <DatePickerField name='settlementDate' label='Settlement date' />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By amount' enableDownMd>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CurrencyRangeField name='amountRange' label={labels.amount} onChange={handleAmountChange} />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By transaction details' enableDownMd>
                <div className='row'>
                    <div className='col-lg-6 col-xl-4'>
                        <DropdownField name='billerCode' label={labels.billerCode} options={billerOptions} />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='crn1' label='Customer reference' />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='merchantReference' label={labels.merchantReference} />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='receiptNumber' label='Receipt number' />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='transactionId' label='Transaction number' />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='rrn' label='RRN' />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <MaskedCardNumberField name='maskedCardNumber' label={labels.maskedCardNumber} />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='token' label={labels.token} />
                    </div>
                    <div className='col-lg-6 col-xl-4'>
                        <InputField name='chequeNo' label='Cheque number' />
                    </div>
                </div>
            </Accordion>
            <Divider />

            <Accordion title='By transaction type' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='transactionTypes' options={transactionTypeOptions} hasAllOption ariaLabel='Transaction type' onChange={handleTransactionTypesChange} />
            </Accordion>
            <Divider />

            <Accordion title='By transaction source' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='txnSources' options={txnSourcesOptions} hasAllOption ariaLabel='Transaction source' />
            </Accordion>
            <Divider />

            <Accordion title='By transaction sub source' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='txnSubSources' options={txnSubSourcesOptions} hasAllOption ariaLabel='Transaction sub source' />
            </Accordion>
            <Divider />

            <Accordion title='By order type' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='txnTypes' options={txnTypesOptions} hasAllOption ariaLabel='Order type' />
            </Accordion>
            <Divider />

            <Accordion title='By payment type' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='txnSubTypes' options={txnSubTypesOptions} hasAllOption ariaLabel='Payment type' />
            </Accordion>
            <Divider />

            <Accordion title='By account type' enableDownMd>
                <CheckboxGroupField inlineXl5 noLabels name='cardTypes' options={accountTypeOptions} hasAllOption ariaLabel='Account type' />
            </Accordion>
            <Divider />

            <Accordion title='By response type' enableDownMd>
                <RadioField inlineXl5 name='searchTransactionsResponseType' noLabels options={responseTypeOptions} ariaLabel='Response type' />
            </Accordion>
            <Divider />

            <Accordion title='By customer' enableDownMd>
                <div className='row'>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerUniqueId' label={labels.customerCode} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerName' label={labels.name} />
                    </div>
                    <div className='col-lg-4 col-xl-4'>
                        <InputField name='customerEmailAddress' label={labels.email} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-xl-8'>
                        <PhoneNumberField name='customerPhoneNumber' label={labels.phoneNumber} lessGapDownMd />
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

TransactionFilter.propTypes = {
    validDateRange: PropTypes.array,
    billerOptions: PropTypes.array,
    transactionTypeOptions: PropTypes.array,
    txnTypesOptions: PropTypes.array,
    txnSubTypesOptions: PropTypes.array,
    txnSourcesOptions: PropTypes.array,
    txnSubSourcesOptions: PropTypes.array,
    accountTypeOptions: PropTypes.array,

    onAutoUpdate: PropTypes.func.isRequired,

    // The rest of the props are in advancedFilterComponent.propTypes in withAdvancedFilter.js
};

export default withAdvancedFilter(TransactionFilter);
