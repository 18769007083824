import { SubmitFeedbackPage } from 'components/Feedback';
import { RouteConfigurationMap } from 'components/Routing';

export const feedbackRoute : RouteConfigurationMap = {
    root: {
        path:               '/feedback/',
        element: <SubmitFeedbackPage />,
        title: 'Feedback',
        generatePath: () => '/feedback/',
    },
};
