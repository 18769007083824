import { useContext, useState } from "react";
import PropTypes from 'prop-types';
import { CheckboxField, ColourField, FormGroup } from '@premier/form';
import { Row, Button } from '@premier/ui';
import EditPageContentDialog from './EditPageContentDialog';
import { FormContext } from '@premier/form';

const EditBrandingMenuSection = ({ style, errors }) => {
    const [showEditPageContentDialog, setShowEditPageContentDialog] = useState(false);
    const [editPageContentTitle, setEditPageContentTitle] = useState("");
    const [pageType, setPageType] = useState("");
    const context = useContext(FormContext);

    const handleEditPageContent = (title) => {
        setShowEditPageContentDialog(true);
        setEditPageContentTitle(title);
    };

    const getPage = () => {
        const index = style?.menu?.pages?.findIndex(page => page.pageType === pageType);

        if (index === -1 && pageType) {
            const newPage = { pageType: pageType, content: "" };
            context.setValue('menu.pages', [...context.getValue('menu.pages'), newPage]);
            return { page: newPage, index: style?.menu?.pages.length };
        }
        const page = index !== -1 ? style.menu.pages[index] : undefined;
        return { page, index };
    };

    const { page, index } = getPage();

    return (<>
        <FormGroup name='pages' label='Select pages to enable'>
            <Row >
                <div className='col-sm-6'>
                    <CheckboxField name='menu.showTerms'>Terms</CheckboxField>
                </div>
                {style?.menu?.showTerms && <div className='col-sm-6'>
                    <Button link onClick={() => { handleEditPageContent('Terms'); setPageType('Terms'); }}>Edit page content</Button>
                </div>}
            </Row>
            <Row >
                <div className='col-sm-6'>
                    <CheckboxField name='menu.showPrivacy'>Privacy</CheckboxField>
                </div>
                {style?.menu?.showPrivacy && <div className='col-sm-6'>
                    <Button link onClick={() => { handleEditPageContent('Privacy'); setPageType('Privacy'); }}>Edit page content</Button>
                </div>}
            </Row>
            <Row >
                <div className='col-sm-6'>
                    <CheckboxField name='menu.showExtraInfo'>Extra info</CheckboxField>
                </div>
                {style?.menu?.showExtraInfo && <div className='col-sm-6'>
                    <Button link onClick={() => { handleEditPageContent('Extra info'); setPageType('ExtraInfo'); }}>Edit page content</Button>
                </div>}
            </Row>
        </FormGroup>
        {(style?.menu?.showTerms || style?.menu?.showPrivacy || style?.menu?.showExtraInfo) &&
            <>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.fontColour' label='Font colour' />
                    </div>
                    <div className='col-sm-6'>
                        <ColourField name='menu.backColour' label='Background colour' />
                    </div>
                </Row>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.underlineColour' label='Selected item underline colour' />
                    </div>
                    <div className='col-sm-6'>
                        <ColourField name='menu.mobileColour' label='Mobile menu colour' />
                    </div>
                </Row>
                <FormGroup name='borders' label='Select borders to enable'>
                    <Row>
                        <div className='col-sm-6'>
                            <CheckboxField name='menu.showTopBorder'>Top</CheckboxField>
                        </div>
                        <div className='col-sm-6'>
                            <CheckboxField name='menu.showBottomBorder'>Bottom</CheckboxField>
                        </div>
                    </Row>
                </FormGroup>
                <Row>
                    <div className='col-sm-6'>
                        <ColourField name='menu.borderColour' label='Border colour' />
                    </div>
                </Row>
            </>
        }
        <EditPageContentDialog show={showEditPageContentDialog} onClose={() => setShowEditPageContentDialog(false)} handleOpen={() => setShowEditPageContentDialog(true)} title={editPageContentTitle} page={page} index={index} errors={errors} />
    </>
    );
};

EditBrandingMenuSection.propTypes = {
    style: PropTypes.any,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string,
            field: PropTypes.string,
            code: PropTypes.string,
            label: PropTypes.string,
            parameter: PropTypes.string,
        })
    ),
};

export default EditBrandingMenuSection;
