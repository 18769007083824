import { Align, ControlProperty, ControlType, FontFamily, Special } from "./enums";
import { ControlTypeProperties, ControlTypeSpecials } from "./types";

export const defaultTextSize = 16;
export const defaultHeadingSize = 27;
export const defaultFontFamily = FontFamily.Helvetica;
export const defaultFontColor = "#000000";
export const defaultLabelAlign = Align.Left;
export const defaultButtonColor = "#f68b1f";
export const defaultButtonText = "Submit";
export const defaultBackgroundColor = "#FFFFFF";

export const genders = ["Male", "Female"];
export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const daysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const hours12 = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
export const hours24 = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
export const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
export const amPm = ["AM", "PM"];

export const submitTypes = {
    creditCard: "creditCard",
    dataVault: "dataVault",
    successScreen: "successScreen",
    customUrl: "customUrl",
};

export const OtherOptionPlaceholder = "Other";

export const controlTypeProperties: ControlTypeProperties = {
    [ControlType.Address]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.Country,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Amount]: [
        ControlProperty.Display,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour

    ],
    [ControlType.Checkbox]: [
        ControlProperty.Required,
        ControlProperty.AllowOtherOption,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.Special,
        ControlProperty.Options,
        ControlProperty.SpreadColumns,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour

    ],
    [ControlType.Crn1]: [
        ControlProperty.Required,
        ControlProperty.Display,
        ControlProperty.Encrypt,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Crn2]: [
        ControlProperty.Required,
        ControlProperty.Display,
        ControlProperty.Encrypt,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Crn3]: [
        ControlProperty.Required,
        ControlProperty.Display,
        ControlProperty.Encrypt,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.DateTime]: [
        ControlProperty.Required,
        ControlProperty.AllowTime,
        ControlProperty.Encrypt,
        ControlProperty.DateFormat,
        ControlProperty.TimeFormat,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.DropDown]: [
        ControlProperty.Required,
        ControlProperty.AllowOtherOption,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.Special,
        ControlProperty.Options,
        ControlProperty.Selected,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Email]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.MaximumCharacters,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Heading]: [
        ControlProperty.Heading,
        ControlProperty.SubHeading,
        ControlProperty.LabelAlign,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.LongAnswer]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.Type,
        ControlProperty.Maximum,
        ControlProperty.Rows,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Name]: [
        ControlProperty.Required,
        ControlProperty.Title,
        ControlProperty.MiddleName,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Number]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.Minimum,
        ControlProperty.Maximum,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.PhoneNumber]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Radio]: [
        ControlProperty.Required,
        ControlProperty.AllowOtherOption,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.Special,
        ControlProperty.Options,
        ControlProperty.Selected,
        ControlProperty.SpreadColumns,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.ShortAnswer]: [
        ControlProperty.Required,
        ControlProperty.Encrypt,
        ControlProperty.Text,
        ControlProperty.SubLabel,
        ControlProperty.PlaceholderText,
        ControlProperty.PrefilledText,
        ControlProperty.Type,
        ControlProperty.MaximumCharacters,
        ControlProperty.LabelAlign,
        ControlProperty.HoverText,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ],
    [ControlType.Submit]: [
        ControlProperty.ClearForm,
        ControlProperty.PrintForm,
        ControlProperty.ButtonText,
        ControlProperty.ButtonColor,
    ],
    [ControlType.Text]: [
        ControlProperty.Text,
        ControlProperty.FontSize,
        ControlProperty.FontFamily,
        ControlProperty.FontColour
    ]
};

export const controlTypeSpecials: ControlTypeSpecials = {
    [ControlType.Checkbox]: [
        Special.None,
        Special.Gender,
        Special.Days,
        Special.Months,
    ],
    [ControlType.DropDown]: [
        Special.None,
        Special.Countries,
        Special.Last100Years,
        Special.Gender,
        Special.Days,
        Special.Months,
        Special.TimeZones,
    ],
    [ControlType.Radio]: [
        Special.None,
        Special.Gender,
        Special.Days,
        Special.Months,
    ],
};

// Only one of each type of the following controls can be added to the eform
export const uniqueControlTypes: ControlType[] = [
    ControlType.Amount,
    ControlType.Crn1,
    ControlType.Crn2,
    ControlType.Crn3,
    ControlType.Submit,
];