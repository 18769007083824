import * as billpayUtil from '@premier/utils/billpay';
import countryUtil from '@premier/utils/country';
import { AccountApiFactory, AccountModelFeaturesEnum, BusinessDetails as ApiBusinessDetails, BusinessDetailsApiFactory, ContactUsModel, LoggedOnDetails, PasswordInput, ResetPasswordInput, TermsModel, UpdateUserProfileRequest, UserCredentials, UserProfileApiFactory, UserProfileModel, MultiFactorAuthenticationSettings, MultiFactorAuthenticationValidationModel } from '@premier/webapi-client';
import { BusinessDetails, UserProfile } from 'models';
import { config, resultHandler, emptyResultHandler } from './util';
import { ApiResult, mapResultErrors } from './mapErrors';

const accountApi = AccountApiFactory(config);
const userProfileApi = UserProfileApiFactory(config);
const businessDetailsApi = BusinessDetailsApiFactory(config);

function applyEnvironmentVariables(apiResult: ApiResult<LoggedOnDetails>) {
    if (process.env.NODE_ENV === 'development' && apiResult.ok && apiResult.val.user) {
        if (process.env.REACT_APP_PERM) {
            // Overwrite user permissions for development purpose
            apiResult.val.user.features = process.env.REACT_APP_PERM.split(',') as AccountModelFeaturesEnum[];
        }
    }

    return apiResult;
}

class AccountApi {
    static loginUser(user: UserCredentials): Promise<ApiResult<LoggedOnDetails>> {
        return accountApi.accountLogOn(user)
            .then(...resultHandler).then((r) => mapResultErrors(r))
            .then(r => applyEnvironmentVariables(r));
    }

    static logoutUser(): Promise<ApiResult<void>> {
        return accountApi.accountLogOff().then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static resetPassword(userDetails: ResetPasswordInput): Promise<ApiResult<void>> {
        return accountApi.accountResetPassword(userDetails).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static resetPasswordWithoutRecaptcha(userDetails: ResetPasswordInput): Promise<ApiResult<void>> {
        return accountApi.accountResetPasswordWithoutRecaptcha(userDetails).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static changePassword(passwordDetails: PasswordInput): Promise<ApiResult<LoggedOnDetails>> {
        return accountApi.accountUpdatePassword(passwordDetails).then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static verifyResetPassword(sessionId: string) {
        return accountApi.accountVerifyPasswordResetLink(sessionId)
            .then(...resultHandler).then((r) => mapResultErrors(r))
            .then(r => applyEnvironmentVariables(r));
    }

    static updateProfile(userDetails: UserProfile): Promise<ApiResult<UserProfileModel>> {
        const { newPassword, currentPassword, ...otherFields } = userDetails;

        const userModel: UpdateUserProfileRequest = {
            userProfile: otherFields,
            password: {
                currentPassword,
                newPassword: newPassword || undefined
            }
        };

        return userProfileApi.userProfileUpdateUserProfile(userModel).then(...resultHandler)
            .then((r) =>
                mapResultErrors(r,
                field => {
                    if (field === 'password.currentPassword')
                        return 'currentPassword';
                    if (field === 'password.newPassword')
                        return 'newPassword';
                    if (field.startsWith('userProfile.'))
                        return field.substring('userProfile.'.length);
                    return field;
                }
            ));
    }

    static getSessionUser(): Promise<ApiResult<LoggedOnDetails>> {
        return accountApi.accountGetLoggedOn().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static getResetPasswordSessionId() {
        return accountApi.accountGetResetPasswordSessionId();
    }

    static submitContactForm(formData: ContactUsModel): Promise<ApiResult<void>> {
        return accountApi.accountContactUs(formData).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static getTerms(): Promise<ApiResult<TermsModel>> {
        return accountApi.accountGetTerms().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static acceptTerms(termsId: number): Promise<ApiResult<void>> {
        return accountApi.accountAcceptTerms(termsId).then(...emptyResultHandler).then((r) => mapResultErrors(r));
    }

    static getBusinessDetails(): Promise<ApiResult<ApiBusinessDetails>> {
        return businessDetailsApi.businessDetailsGetBusinessDetails().then(...resultHandler).then((r) => mapResultErrors(r));
    }

    static updateBusinessDetails(businessDetailsData: BusinessDetails): Promise<ApiResult<void>> {
        let dto: ApiBusinessDetails = mapBusinessDetailFormToDto(businessDetailsData);
        return businessDetailsApi.businessDetailsUpdateBusinessDetails(dto)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r, mapBusinessDetailsErrorsFromDto));
    }

    static getHCaptchaSiteKey(platform?: string) {
        return accountApi.accountGetHCaptchaSiteKey(platform ?? "");
    }

    static getMFAConfiguration(merchantNumber: string) {
        return accountApi.accountGetMultiFactorAuthenticationSettings(merchantNumber);
    }

    static updateMFAConfiguration(multiFactorAuthenticationSettings: MultiFactorAuthenticationSettings) {
        return accountApi.accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings);
    }

    static validateMfaCode(multiFactorAuthenticationValidationModel: MultiFactorAuthenticationValidationModel) {
        return accountApi.accountValidateMfaCode(multiFactorAuthenticationValidationModel);
    }

    static resendMfaCode(userName: string, merchantNumber: string) {
        return accountApi.accountResendMfaCode(userName, merchantNumber);
    }
}

function mapBusinessDetailFormToDto(businessDetailsData: BusinessDetails): ApiBusinessDetails {
    let state;
    if (businessDetailsData.address.stateId) {
        state = countryUtil.getStateOptions(businessDetailsData.address.countryCode, false)
            ?.find((o:any) => o.value === businessDetailsData.address.stateId)
            ?.label;
    }
    let country = countryUtil.getCountry(businessDetailsData.address.countryCode);

    return {
        tradingName: businessDetailsData.tradingName,
        companyId1: businessDetailsData.companyId1,
        companyId2:  businessDetailsData.companyId2,
        addressLine1: businessDetailsData.address.addressLine1,
        addressLine2: businessDetailsData.address.addressLine2,
        country: country?.name,
        countryCode: businessDetailsData.address.countryCode,
        suburb: businessDetailsData.address.suburb,
        state: state,
        postcode: businessDetailsData.address.postcode,
        phoneNumber: billpayUtil.formatPhoneObjectToApiString(businessDetailsData.phoneNumber),
        mobileNumber:  billpayUtil.formatPhoneObjectToApiString(businessDetailsData.mobileNumber),
        url: businessDetailsData.url,
        email: businessDetailsData.email,
    };
}

function mapBusinessDetailsErrorsFromDto(parameter: string) {
    if (parameter === "addressLine1")
        return "address.addressLine1";
    if (parameter === "addressLine2")
        return "address.addressLine2";
    if (parameter === "country")
        return "address.country";
    if (parameter === "suburb")
        return "address.suburb";
    if (parameter === "state")
        return "address.state";
    if (parameter === "postcode")
        return "address.postcode";

    return parameter;
}

export default AccountApi;
