import { ControlProperty, ControlType, HppPreviewMode, Special } from "./enums";

export type Control = {
    id: string;
    category?: string;
    controlType: ControlType;
    attributes: ControlAttributes;
    order: number;
}

export type ControlAttributes = {
    allowOtherOption?: boolean;
    allowTime?: boolean;
    billerCode?: string;
    buttonColor?: string;
    buttonText?: string;
    clearForm?: boolean;
    country?: string;
    dateFormat?: string;
    defaultSelection?: string;
    display?: boolean;
    encrypt?: boolean;
    fontColor?: string;
    fontFamily?: string;
    fontSize?: number;
    headingTitle?: string;
    hoverText?: string;
    label?: string;
    labelAlign?: string;
    maximum?: number;
    maximumCharacters?: number;
    maximumType?: string;
    middleName?: boolean;
    minimum?: number;
    options?: string[];
    placeholderText?: string;
    prefilledText?: string;
    printForm?: boolean;
    required?: boolean;
    rows?: number;
    selected?: string | string[];
    special?: string;
    spreadColumns?: number;
    subHeadingTitle?: string;
    subLabel?: string;
    text?: string;
    timeFormat?: string;
    title?: boolean;
    validationFormat?: string;
}

export type ControlTypeProperties = {
    [key in ControlType]: ControlProperty[];
}


export const controlTypesWithSpecials = [ControlType.Checkbox, ControlType.DropDown, ControlType.Radio] as const;

export type ControlTypesWithSpecials = ControlType.Checkbox | ControlType.DropDown | ControlType.Radio

export type ControlTypeSpecials = {
    [key in ControlTypesWithSpecials]: Special[];
}

export type TemplatesLabelProps = {
    name: string;
    attributes: ControlAttributes;
}

export type TemplatesProps = {
    id: string;
    name: string;
    attributes: ControlAttributes;
    dragDropDisable?: boolean;
    hideDuplicate?: boolean;
    hideRemove?: boolean;
    mode: HppPreviewMode;
    order: number;
    focusedId?: string;
    onEditClicked?: (id: string) => void;
    onDuplicateClicked?: (id: string) => void;
    onRemoveClicked?: (id: string) => void;
    onControlSelectionChanged?: (id: string, selected: string | string[]) => void;
}

export type FieldOption = {
    text: string;
    order: number;
}
