import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from 'react-router-dom';

import { SingleColumnFormContainer, Button, SuccessModal, Icon, Dialog } from '@premier/ui';
import ClickableButton from 'components/Common/components/Controls/ClickableButton';
import { UserDetailsForm } from 'components/Settings';
import { ConfirmPasswordModal, PageHeader } from 'components/Common';
import { User } from "models/User";

import * as userActions from 'components/Settings/_actions/userActions';
import { PlatformRoutesConfiguration } from 'components/Routing';

import { RootState } from "store/store";

type Props = {
    userActions: any;
}

const NewUserPage = ({
    userActions //API actions
} : Props) => {

    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState<User>();
    const [savingUserDetails, setSavingUserDetails] = useState(false);
    const [newUserRedirect, setNewUserRedirect] = useState(false);
    const [cancelRedirect, setCancelRedirect] = useState(false);
    const [newUserId, setNewUserId] = useState(0);
    const [apiPassword, setApiPassword] = useState("");
    const [apiUser, setApiUser] = useState("");

    function handlePasswordConfirmed(values: any) {
        setSavingUserDetails(true);
        var model = {
            currentPassword: values.currentPassword,
            ...newUserDetails
        };

        userActions.addUser(model)
            .then((userDetails: User) => {
                setNewUserId(userDetails.userId);
                setApiPassword(userDetails.apiUserPassword);
                setApiUser(userDetails.userName);
                setShowPasswordDialog(false);
                setShowSuccessfulModal(true);
            })
            .catch((err: any) => {
                console.error(err);
                setSavingUserDetails(false);
                setShowPasswordDialog(false);
            });
    }

    function handleDetailsSave(values: User) {
        setNewUserDetails(values);
        setShowPasswordDialog(true);
    }

    function handlePasswordModalClosed() {
        setApiPassword("");
        setApiUser("");
        setNewUserDetails(undefined);
        setShowPasswordDialog(false);
    }

    function handleSuccessModalHide() {
        setShowSuccessfulModal(false);
        setNewUserRedirect(true);
    }

    function handleCancel() {
        setCancelRedirect(true);
    }

    if (newUserRedirect)
        return <Navigate to={PlatformRoutesConfiguration.usersRoute?.viewUser.generatePath(newUserId)!} />;

    if (cancelRedirect)
        return <Navigate to={PlatformRoutesConfiguration.usersRoute?.manageUsers.generatePath()!} />;


    return (<>
        <SingleColumnFormContainer>
            <PageHeader backButton title='New user' />
            <UserDetailsForm
                onSubmit={handleDetailsSave}
                onCancel={handleCancel}
            />
        </SingleColumnFormContainer>

        <ConfirmPasswordModal
            title='Confirm changes'
            show={showPasswordDialog}
            onClose={handlePasswordModalClosed}
            onSubmit={handlePasswordConfirmed}
            isProcessing={savingUserDetails}
        />

        {apiPassword ? <Dialog
            show={showSuccessfulModal}
            title="User added successfully"
            icon={<Icon tick />}
            closeButton
            onClose={handleSuccessModalHide}
        >
            <p>A new API user has been successfully created. Please find the details below and copy them in a secure place, they will not be shown again.</p>
            <p><b>API Username:</b> {apiUser}<br/>
            <b>Password:</b> <ClickableButton clickableButtonId="apiPasswordCopy" text={apiPassword} /></p>
        </Dialog> : <SuccessModal
            show={showSuccessfulModal}
            title='User added successfully'
            onClose={handleSuccessModalHide}
            footerButtons={
                <Button primary onClick={handleSuccessModalHide}>Ok, I understand</Button>
            }
        >
            <p>An email with further instructions has been sent to the new user.</p>
        </SuccessModal>}
    </>);
}


function mapStateToProps(state: RootState) {
    return {
        isLoading: state.settings.user.isLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserPage);