import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
// @ts-ignore
import labels from "constants/labels";
// @ts-ignore
import { PaymentDetailsTabKeys } from "constants/billpay";
// @ts-ignore
import errorMaps from "constants/errorMaps";
import countryUtil from "@premier/utils/country";
import { Form, DropdownField, InputField, CurrencyField, SubmitButton, validate, FormErrorList } from "@premier/form";
import { LoadingIndicator, ButtonContainer, Divider } from "@premier/ui";
import { PaymentDetailsForms, PaymentProcessingModal, MerchantBillerDetailsForm, PaymentRequestPendingModal, QrPaymentRequestPendingModal } from "components/Transactions";
import * as paymentActions from "components/Utilities/_actions/utilitiesActions";
import * as paymentRequestActions from "components/PaymentRequests/_actions/paymentRequestActions";
import PaymentRequestActionTypes from "components/PaymentRequests/_actions/paymentRequestActionTypes";
import { RootState } from "store/store";
import { Merchant } from "models";
import { OrderType } from "models/OrderType";
import { FieldError } from "api/mapErrors";
import { Biller } from "packages/webapi-client";

import "./NewPaymentForm.scss";

type Props = {
    loading: boolean;
    merchant: Merchant;
    billers: Biller[];
    paymentRequestId: string;
    orderTypes: OrderType[];
    defaultOrderType: OrderType;
    errors: FieldError[];
    paymentActions: any;
    paymentRequestActions: any;
}

const NewPaymentForm = ({
    loading, merchant, billers, paymentRequestId, orderTypes, defaultOrderType,  //state values
    errors, //forms
    paymentActions, paymentRequestActions, //actions
}: Props) => {
    const [selectedMerchant, setSelectedMerchant] = useState<string>();
    const [selectedBiller, setSelectedBiller] = useState<Biller>();
    const [currentAction, setCurrentAction] = useState(PaymentDetailsTabKeys.PAYMENT);

    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [showRequestPendingModal, setShowRequestPendingModal] = useState<boolean>();
    const [formContext, setFormContext] = useState<any>({});
    const paymentBeingProcessed = useRef({});

    const parentMerchant = {
        merchantNumber: merchant.merchantNumber,
        merchantName: merchant.merchantName,
        isParent: true
    };

    useEffect(() => {
        if (!orderTypes || orderTypes.length === 0)
            paymentActions.getProcessTransactionOrderTypes();

        setSelectedMerchant(parentMerchant.merchantNumber);
    }, []);

    function handleMerchantChanged(merchantDetails: any) {
        setSelectedMerchant(merchantDetails);
    }

    /**
     * @description Handles form submit's.
     * @param {any} values current value derived from the form's control elements.
     * @param {context} formContext legacy Form (react context)
     * @returns
     */
    function handleSubmit(values: any, formContext: any) {
        setFormContext(formContext);

        switch (currentAction) {
            case PaymentDetailsTabKeys.PAYMENT:
            {
                const paymentDetails = {
                    ...values,
                    billerCodeForm: {
                        billerCode: values.billerCodeForm.billerCode,
                        childMerchantNumber: values.billerCodeForm.merchantNumber,
                        billerCrnList: values.billerCodeForm.billerCrnList,
                    },
                    currencyCode: merchant.currency?.code
                };
                paymentBeingProcessed.current = paymentDetails;
                setIsProcessingPayment(true);
                break;
            }
            case PaymentDetailsTabKeys.REQUESTS:
            case PaymentDetailsTabKeys.QRREQUESTS:
            {
                const paymentDetails = {
                    ...values.paymentRequest,
                    orderType: values.orderType,
                    customerId: values.customerId,
                    merchantReference: values.merchantReference,
                    amount: values.amount,
                    currencyCode: merchant.currency?.code,
                    billerCodeForm: {
                        billerCode: values.billerCodeForm.billerCode,
                        childMerchantNumber: values.billerCodeForm.merchantNumber,
                        billerCrnList: values.billerCodeForm.billerCrnList,
                    }
                };

                const submissionCallback = currentAction === PaymentDetailsTabKeys.REQUESTS ? createPaymentRequest : createQrPaymentRequest;
                const action = values.paymentRequest.savePaymentMethod ? PaymentRequestActionTypes.PaymentAndTokenise : PaymentRequestActionTypes.PaymentOnly;
                submissionCallback(paymentDetails, action);
                break;
            }
            default:
                throw new Error("currentAction not set.");
        }
        setSelectedBiller(_.find(billers, { billerCode: values.billerCodeForm.billerCode }));
    }

    // Must wait until the paymentRequestId is fully set before attempting to open the modal
    // or else a race condition would occur within the modal where you have the payment request
    // with the payment receipt vs the new one generated by paymentRequestActions.create
    async function createPaymentRequest(paymentDetails: any, paymentMethod: any) {
        await paymentRequestActions.create(paymentDetails, paymentMethod);
        setShowRequestPendingModal(true);
    }

    // Same as above
    async function createQrPaymentRequest(paymentDetails: any, paymentMethod: any) {
        await paymentRequestActions.createQrRequest(paymentDetails, paymentMethod);
        setShowRequestPendingModal(true);
    }

    function handleProcessingPaymentModalClosed(reset: boolean) {
        paymentBeingProcessed.current = {};
        setIsProcessingPayment(false);
        if (reset) {
            ProcessFormReset();
        }
    }

    function handlePaymentRequestModalClosed(reset?: boolean) {
        setShowRequestPendingModal(false);
        if (reset) {
            ProcessFormReset();
        }
    }

    function ProcessFormReset() {
        formContext.resetForm();
        formContext.setValue("billerCodeForm.merchantNumber", parentMerchant.merchantNumber);
        formContext.setValue("billerCodeForm.billerCode", selectedBiller?.billerCode);
        formContext.setValue("card.cardNumber", null);
        formContext.setValue("card.expiryDate.month", null);
        formContext.setValue("card.expiryDate.year", null);
        formContext.setValue("card.accountNumber", null);
    }

    function handleTabChange(tabKey: string | null) {
        setCurrentAction(tabKey);
    }

    function handleSubmitButtonTextContent() {
        switch (currentAction) {
            case PaymentDetailsTabKeys.PAYMENT:
                return "Submit payment";
            case PaymentDetailsTabKeys.REQUESTS:
                return "Send request";
            case PaymentDetailsTabKeys.QRREQUESTS:
                return "Generate QR code";
            default:
                throw new Error("currentAction not set.");
        }
    }

    function HandleModalDisplay() {
        if (showRequestPendingModal && paymentRequestId) {
            // eslint-disable-next-line
            switch (currentAction) {
                case PaymentDetailsTabKeys.REQUESTS:
                    return (<PaymentRequestPendingModal
                        paymentRequestId={paymentRequestId}
                        biller={selectedBiller}
                        onClosed={handlePaymentRequestModalClosed}
                    />);
                case PaymentDetailsTabKeys.QRREQUESTS:
                    return (<QrPaymentRequestPendingModal
                        paymentRequestId={paymentRequestId}
                        biller={selectedBiller}
                        handleModalClose={handlePaymentRequestModalClosed}
                    />);
            }
        }

        if (isProcessingPayment) {
            return (<PaymentProcessingModal
                paymentRequest={paymentBeingProcessed.current}
                biller={selectedBiller}
                onClosed={handleProcessingPaymentModalClosed}
                customerEmail={formContext.getValue("customer.emailAddress")}
            />);
        }

        return null;
    }

    function mapErrors(errors: FieldError[]) {
        return errors?.map(e => ({
            ...e,
            field: e.field ? mapErrorsFromDto(e.field) : ""
        }));
    }

    function mapErrorsFromDto(parameter: string) {
        switch (parameter) {
            case "reference1":
                return "billerCodeForm.billerCrnList.crn1";
            case "reference2":
                return "billerCodeForm.billerCrnList.crn2";
            case "reference3":
                return "billerCodeForm.billerCrnList.crn3";
            default:
                return parameter;
        }
    }

    if (loading && !orderTypes.length)
        return <LoadingIndicator />;

    return (
        <div className="payment-form">
            <Form
                initialValues={{
                    billerCodeForm: {
                        merchantNumber: selectedMerchant,
                        billerCode: "",
                        billerCrnList: {
                            crn1: "",
                            crn2: "",
                            crn3: ""
                        },
                    },
                    merchantReference: "",
                    orderType: defaultOrderType,
                    amount: "",
                    card: {
                        cardNumber: "",
                        cardType: "",
                        cvn: "",
                        cardholderName: "",
                        accountNumber: "",
                    },
                    paymentRequest: {
                        mobile: { iddCode: countryUtil.getIddCode(merchant.countryCode) }
                    }
                }}
                initialValidation={{
                    amount: validate().required(),
                    orderType: validate().required()
                }}
                onSubmit={handleSubmit}
                errors={mapErrors(errors)}
                errorMaps={errorMaps}
                render={context => {
                    return (
                        <>
                            <h2>Enter payment details</h2>

                            <MerchantBillerDetailsForm
                                onMerchantChange={handleMerchantChanged}
                                showPaymentMethodButton
                            />

                            <InputField name="merchantReference" label={labels.merchantReference} />
                            <DropdownField
                                name="orderType"
                                label={labels.orderType}
                                options={orderTypes.map(x => ({ value: x.key, label: x.description }))}
                                defaultValue={defaultOrderType}
                            />
                            <CurrencyField
                                maxLength={11}
                                name="amount"
                                label={labels.amount}
                                className="no-number-spinner"
                            />

                            <Divider className="payment-method-divider" />

                            <h2>Payment</h2>
                            <PaymentDetailsForms
                                onTabChange={handleTabChange}
                            />

                            <FormErrorList />

                            <ButtonContainer>
                                <SubmitButton>
                                    {handleSubmitButtonTextContent()}
                                </SubmitButton>
                            </ButtonContainer>
                        </>
                    );
                }}
            />
            {HandleModalDisplay()}
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        loading: state.transactions.payments.isPaymentFormLoading,
        paymentRequestId: _.get(state.paymentRequest.create, "data.guid"),
        orderTypes: state.transactions.payments.newPaymentOrderTypes
            ? state.transactions.payments.newPaymentOrderTypes.items
            : [],
        defaultOrderType: state.transactions.payments.newPaymentOrderTypes
            ? state.transactions.payments.newPaymentOrderTypes.defaultKey
            : "",
        billers: state.accounts.users.activeBillers,
        merchant: state.accounts.users.merchant,
        errors: state.transactions.payments.errors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
        paymentRequestActions: bindActionCreators(paymentRequestActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentForm);
