import { useContext, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { useMemo } from "react";
import Label from "./components/Label";
import Input from "./components/Input";
import { validateRequiredInput } from "components/EForms/helpers";
import Dropdown from "./components/DropdownComponent";
import { TemplatesProps } from "../types";
import { EFormsContext } from "../contexts/EFormsContext";
import classNames from "classnames";

const Address = ({ name, attributes }: TemplatesProps) => {
    const { countries } = useContext(EFormsContext);
    const countryOptions = useMemo(() => countries?.map((x) => ({ value: x.name ?? "", label: x.name ?? "" })) ?? [], [countries]);

    const [addressLine1, setAddressLine1] = useState("");
    const [suburb, setSuburb] = useState("");
    const [state, setState] = useState("");
    const [postcode, setPostcode] = useState("");

    const [addressLine1Valid, setAddressLine1Valid] = useState(true);
    const [suburbValid, setSuburbValid] = useState(true);
    const [stateValid, setStateValid] = useState(true);
    const [postcodeValid, setPostcodeValid] = useState(true);

    const valid = useMemo(() => addressLine1Valid && suburbValid && stateValid && postcodeValid, [addressLine1Valid, suburbValid, stateValid, postcodeValid]);

    const errors = useMemo(() => {
        const errors = [];
        if (!addressLine1Valid) {
            errors.push("Address Line 1 is required");
        }
        if (!suburbValid) {
            errors.push("Suburb is required");
        }
        if (!stateValid) {
            errors.push("State is required");
        }
        if (!postcodeValid) {
            errors.push("Postcode is required");
        }
        return errors;
    }, [addressLine1Valid, suburbValid, stateValid, postcodeValid]);

    const inputValidation = (value: string) => {
        let validationResult: boolean = true;

        if (attributes.required) {
            validationResult = validateRequiredInput(value);
        }

        return validationResult;
    };

    const handleAddress1Changed = (value: string) => {
        setAddressLine1(value);

        setAddressLine1Valid(inputValidation(value));
        setSuburbValid(inputValidation(suburb));
        setStateValid(inputValidation(state));
        setPostcodeValid(inputValidation(postcode));
    };

    const handleSuburbChanged = (value: string) => {
        setSuburb(value);

        setAddressLine1Valid(inputValidation(addressLine1));
        setSuburbValid(inputValidation(value));
        setStateValid(inputValidation(state));
        setPostcodeValid(inputValidation(postcode));
    };

    const handleStateChanged = (value: string) => {
        setState(value);

        setAddressLine1Valid(inputValidation(addressLine1));
        setSuburbValid(inputValidation(suburb));
        setStateValid(inputValidation(value));
        setPostcodeValid(inputValidation(postcode));
    };

    const handlePostcodeChanged = (value: string) => {
        setPostcode(value);
        
        setAddressLine1Valid(inputValidation(addressLine1));
        setSuburbValid(inputValidation(suburb));
        setStateValid(inputValidation(state));
        setPostcodeValid(inputValidation(value));
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-address eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <div className={`jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                <div className="eform-ctrl-address-row">
                    <Input
                        attributes={attributes}
                        labeledInput={true}
                        label="Address Line 1"
                        onValueChange={handleAddress1Changed}
                        onBlur={handleAddress1Changed}
                    />
                </div>
                <div className="eform-ctrl-address-row">
                    <Input
                        attributes={attributes}
                        labeledInput={true}
                        label="Address Line 2"
                        mandatory={false}
                    />
                </div>
                <div className="eform-row eform-ctrl-address-row">
                    <div className="eform-col-4">
                        <Input
                            attributes={attributes}
                            labeledInput={true}
                            label="Suburb"
                            onValueChange={handleSuburbChanged}
                            onBlur={handleSuburbChanged}
                        />
                    </div>
                    <div className="eform-col-4">
                        <Input
                            attributes={attributes}
                            labeledInput={true}
                            label="State"
                            onValueChange={handleStateChanged}
                            onBlur={handleStateChanged}
                        />
                    </div>
                    <div className="eform-col-4">
                        <Input
                            attributes={attributes}
                            labeledInput={true}
                            label="Postcode"
                            onValueChange={handlePostcodeChanged}
                            onBlur={handlePostcodeChanged}
                        />
                    </div>
                </div>
                <div className="eform-ctrl-address-row">
                    <Dropdown
                        attributes={attributes}
                        labeledInput={true}
                        label="Country"
                        options={countryOptions}
                        defaultValue={attributes.country}
                        mandatory={false}
                        showErrors={false}
                    />
                </div>
            </div>
            {errors.length > 0 && <div className="errors" style={{ fontFamily: attributes.fontFamily }}>{errors.map(e => <div>{e}</div>)}</div>}
        </div>
    );
};

export default withDesignerControl(Address);
