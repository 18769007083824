import { useMemo, useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import Input from "./components/Input";
import { validateInputByValidationFormat, validateRequiredInput } from "components/EForms/helpers";
import classNames from "classnames";

const Name = ({ name, attributes }: TemplatesProps) => {
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");

    const [titleValid, setTitleValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [middleNameValid, setMiddleNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);

    const valid = useMemo(() => titleValid && firstNameValid && middleNameValid && lastNameValid, [titleValid, firstNameValid, middleNameValid, lastNameValid]);

    const errors = useMemo(() => {
        const errors: string[] = [];

        if (attributes.title && !titleValid) {
            errors.push("Title is required");
        }

        if (!firstNameValid) {
            errors.push("First name is required");
        }

        if (attributes.middleName && !middleNameValid) {
            errors.push("Middle name is required");
        }

        if (!lastNameValid) {
            errors.push("Last name is required");
        }

        return errors;
    }, [attributes.title, attributes.middleName, titleValid, firstNameValid, middleNameValid, lastNameValid]);

    const columnWidths = useMemo(() => {
        let firstName = 3;
        let middleName = 3;
        let lastName = 4;

        if (!attributes.title) {
            firstName += 1;
            middleName += 1;
        }

        if (!attributes.middleName) {
            firstName += 2;
            lastName += middleName >= 4 ? 2 : 1;
        }

        return { firstName, middleName, lastName };
    }, [attributes.title, attributes.middleName])

    const inputValidation = (value: string) => {
        let validationResult: boolean = true;

        if (attributes.required) {
            validationResult = validateRequiredInput(value);

            if (validationResult && attributes.validationFormat) {
                validationResult = validateInputByValidationFormat(value, undefined, attributes.validationFormat);
            }
        } else if (attributes.validationFormat) {
            validationResult = validateInputByValidationFormat(value, undefined, attributes.validationFormat);
        }

        return validationResult;
    };

    const handleTitleChanged = (value: string) => {
        setTitle(value);

        setTitleValid(inputValidation(value));
        setFirstNameValid(inputValidation(firstName));
        setMiddleNameValid(inputValidation(middleName));
        setLastNameValid(inputValidation(lastName));
    };

    const handleFirstNameChanged = (value: string) => {
        setFirstName(value);

        setTitleValid(inputValidation(title));
        setFirstNameValid(inputValidation(value));
        setMiddleNameValid(inputValidation(middleName));
        setLastNameValid(inputValidation(lastName));
    };

    const handleMiddleNameChanged = (value: string) => {
        setMiddleName(value);

        setTitleValid(inputValidation(title));
        setFirstNameValid(inputValidation(firstName));
        setMiddleNameValid(inputValidation(value));
        setLastNameValid(inputValidation(lastName));
    };

    const handleLastNameChanged = (value: string) => {
        setLastName(value);

        setTitleValid(inputValidation(title));
        setFirstNameValid(inputValidation(firstName));
        setMiddleNameValid(inputValidation(middleName));
        setLastNameValid(inputValidation(value));
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-short eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <div className={`jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                <div className="eform-row">
                    {attributes.title &&
                        <div className="eform-col-2">
                            <Input
                                attributes={attributes}
                                labeledInput={true}
                                label="Title"
                                showErrors={false}
                                onValueChange={handleTitleChanged}
                                onBlur={handleTitleChanged}
                            />
                        </div>}
                    <div className={`eform-col-${columnWidths.firstName}`}>
                        <Input
                            attributes={attributes}
                            labeledInput={true}
                            label="First name"
                            showErrors={false}
                            onValueChange={handleFirstNameChanged}
                            onBlur={handleFirstNameChanged}
                        />
                    </div>
                    {attributes.middleName &&
                        <div className={`eform-col-${columnWidths.middleName}`}>
                            <Input
                                attributes={attributes}
                                labeledInput={true}
                                label="Middle name"
                                showErrors={false}
                                onValueChange={handleMiddleNameChanged}
                                onBlur={handleMiddleNameChanged}
                            />
                        </div>}
                    <div className={`eform-col-${columnWidths.lastName}`}>
                        <Input
                            attributes={attributes}
                            labeledInput={true}
                            label="Last name"
                            showErrors={false}
                            onValueChange={handleLastNameChanged}
                            onBlur={handleLastNameChanged}
                        />
                    </div>
                </div>
            </div>
            {errors.length > 0 && <div className="errors" style={{ fontFamily: attributes.fontFamily }}>{errors.map(e => <div>{e}</div>)}</div>}
        </div>
    );
};

export default withDesignerControl(Name);
