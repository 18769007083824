import * as actions from './actionTypes';
import { accountApi, billerApi, configurationApi, utilitiesApi } from 'api';

import { setCurrency } from '@premier/utils/currency';
import { setCountryList, setCountry } from '@premier/utils/country';
import { setDateTimeFormat } from '@premier/utils/date';

//#region ---- Login / Logout ----

export function loginRequested() {
    return { type: actions.LOGIN_USER_REQUESTED };
}

export function loginUserSuccess(userData, initializationOptions) {
    // MFA flow does not provide userData.user or userData.merchant
    // MFA flow only provides userData.username and userData.merchantNumber
    return {
        type: actions.LOGIN_USER_SUCCESS,
        userDetails: {
            requiredUserAction: userData.requiredUserAction,
            user: userData.user ?? { username: userData.username },
            merchant: userData.merchant ?? { merchantNumber: userData.merchantNumber },
            billers: initializationOptions.billers
        }
    };
}

export function initRequested() {
    return { type: actions.INIT_REQUESTED };
}
export function initSuccess() {
    return { type: actions.INIT_SUCCESS };
}
export function initFailed(err) {
    return { type: actions.INIT_FAILED, err };
}

export function loginUserFailed(err) {
    return { type: actions.LOGIN_USER_FAILED, err };
}

export function logoutRequested() {
    return { type: actions.LOGOUT_USER_REQUESTED };
}

export function logoutUserSuccess() {
    return { type: actions.LOGOUT_USER_SUCCESS };
}

export function logoutUserFailed(err) {
    return { type: actions.LOGOUT_USER_FAILED, err };
}

export function platformConfigurationRequested() {
    return { type: actions.LOAD_CONFIG_REQUESTED };
}

export function platformConfigurationSuccess(configuration) {
    return { type: actions.LOAD_CONFIG_SUCCESS, configuration };
}

export function platformConfigurationFailed(err) {
    return { type: actions.LOAD_CONFIG_FAILED, err };
}

function initUser(getUserDataFunc) {
    return function(dispatch) {
        dispatch(loginRequested());
        return getUserDataFunc()
            .then(r => {
                if(!r.ok) {
                    dispatch(loginUserFailed(r.val));
                    throw new Error(r.val);
                }

                let userData = r.val;

                if (userData.requiredUserAction) {
                    return {
                        userData: userData
                    };
                }
                return Promise.all([
                    billerApi.getBillers(), //values[0]
                    utilitiesApi.getCountryList(),
                    utilitiesApi.getCurrencyList(),
                ]).then(([billers, countryList, _currencyList]) => {
                    setCurrency(userData.merchant.currency.symbol); // TODO: API to rename this to alphaCode
                    setCountryList(countryList);
                    setCountry(userData.merchant.countryCode);
                    setDateTimeFormat(
                        userData.merchant.dateFormat.specifier,
                        userData.merchant.timeFormat.specifier,
                        userData.merchant.dateFormat.display,
                        userData.merchant.timeFormat.display,
                        userData.merchant.timeZone.description,
                    );
                    return {
                        userData: userData,
                        billers: billers.val
                    };
                });
            }).then(result => {
                dispatch(
                    loginUserSuccess(result.userData, {
                        billers: result.billers
                    })
                );
            })
    };
}

export function loginUser(user) {
    return initUser(() => {
        return accountApi.loginUser(user);
    });
}

export function getSessionLogin() {
    return initUser(() => {
        return accountApi.getSessionUser();
    });
}

export function getPlatformConfiguration() {
    return function(dispatch) {
        dispatch(platformConfigurationRequested());
        return new Promise((resolve, reject) => {
            configurationApi
                .getPlatformConfiguration()
                .then(result => {
                    dispatch(platformConfigurationSuccess(result));
                })
                .catch(err => {
                    dispatch(platformConfigurationFailed(err));
                });
        });
    };
}

export function logoutUser() {
    return function(dispatch) {
        dispatch(logoutRequested());
        try {
            return accountApi.logoutUser().then(dispatch(logoutUserSuccess()));
        } catch(err) {
            dispatch(logoutUserFailed(err));
        }
    };
}
//#endregion

export function init() {
    return function(dispatch) {
        try {
            dispatch(initRequested());
            dispatch(initSuccess());
        } catch(err) {
            dispatch(initFailed(err));
        }
    };
}

//#region ---- Forgot Password ----
export function verifyResetPassword(passwordDetails) {
    return initUser(() => {
        return accountApi.verifyResetPassword(passwordDetails);
    });
}

export function resetPasswordRequested() {
    return { type: actions.RESET_PASSWORD_REQUESTED };
}
export function resetPasswordSuccess() {
    return { type: actions.RESET_PASSWORD_SUCCESS };
}
export function resetPasswordFailed(err) {
    return { type: actions.RESET_PASSWORD_FAILED, err };
}

export function resetPassword(userDetails) {
    return function(dispatch) {
        dispatch(resetPasswordRequested());
        return accountApi
            .resetPassword(userDetails)
            .then((res) => {
                if (res.ok) {
                    dispatch(resetPasswordSuccess(res.val));
                    return res.val;
                }
                dispatch(resetPasswordFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(resetPasswordFailed(err));
                throw err;
            });
    };
}

export function resetPasswordWithoutRecaptcha(userDetails) {
    return function(dispatch) {
        dispatch(resetPasswordRequested());
        return accountApi
            .resetPasswordWithoutRecaptcha(userDetails)
            .then((res) => {
                if (res.ok) {
                    dispatch(resetPasswordSuccess(res.val));
                    return res.val;
                }
                dispatch(resetPasswordFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(resetPasswordFailed(err));
                throw err;
            });
    };
}

export function changePasswordRequested() {
    return { type: actions.CHANGE_PASSWORD_REQUESTED };
}
export function changePasswordSuccess() {
    return { type: actions.CHANGE_PASSWORD_SUCCESS };
}
export function changePasswordFailed(err) {
    return { type: actions.CHANGE_PASSWORD_FAILED, err };
}

export function changePassword(passwordDetails) {
    return initUser(() => {
        return accountApi.changePassword(passwordDetails);
    });
}

//#endregion

//#region ---- Profile ----

export function updateProfileRequested() {
    return { type: actions.UPDATE_PROFILE_REQUESTED };
}
export function updateProfileSuccess(user) {
    return { type: actions.UPDATE_PROFILE_SUCCESS, user };
}
export function updateProfileFailed(err) {
    return { type: actions.UPDATE_PROFILE_FAILED, err };
}

export function updateProfile(userDetails) {
    return function(dispatch) {
        dispatch(updateProfileRequested());
        return accountApi
            .updateProfile(userDetails)
            .then(data => {
                if (data.ok) {
                    dispatch(updateProfileSuccess(data.val));
                    return data;
                }
                dispatch(updateProfileFailed(data.val));
                throw data.val;
            })
            .catch(err => {
                dispatch(updateProfileFailed(err));
                throw err;
            });
    };
}

export function submitContactFormRequested(formData) {
    return { type: actions.SEND_CONTACT_FORM_REQUESTED };
}
export function submitContactFormSuccess() {
    return { type: actions.SEND_CONTACT_FORM_SUCCESS };
}
export function submitContactFormFailed(err) {
    return { type: actions.SEND_CONTACT_FORM_FAILED, err };
}

export function submitContactForm(formData) {
    return function(dispatch) {
        dispatch(submitContactFormRequested(formData));
        return accountApi
            .submitContactForm(formData)
            .then((res) => {
                if (res.ok) {
                    dispatch(submitContactFormSuccess(res.val));
                    return res.val;
                }
                dispatch(submitContactFormFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(submitContactFormFailed(err));
                throw err;
            });
    };
}


export function getBillerCodesRequested() {
    return {type: actions.GET_BILLER_CODES_REQUESTED};
}
export function getBillerCodesSuccess(billerCodes) {
    return {type: actions.GET_BILLER_CODES_SUCCESS, billerCodes};
}
export function getBillerCodesFailed(err) {
    return {type: actions.GET_BILLER_CODES_FAILED, err};
}

export function getBillerCodes(merchantNumber) {
    return function(dispatch){
        dispatch(getBillerCodesRequested());

        return billerApi.getBillers(merchantNumber)
            .then(res => {
                if (res.ok) {
                    dispatch(getBillerCodesSuccess(res.val));
                    return res.val;
                }
                dispatch(getBillerCodesFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(getBillerCodesFailed(err));
                throw(err);
            })
    }
}


export function acceptTermsRequested() {
    return {type: actions.ACCEPT_TERMS_REQUESTED};
}
export function acceptTermsSuccess(terms) {
    return {type: actions.ACCEPT_TERMS_SUCCESS, terms};
}
export function acceptTermsFailed(err) {
    return {type: actions.ACCEPT_TERMS_FAILED, err};
}

/**
 * Accepts a given set of terms of service.
 * @param {number | string} termsId
 */
export function acceptTerms(termsId) {
    return function(dispatch){
        dispatch(acceptTermsRequested());

        return accountApi.acceptTerms(termsId)
            .then((res) => {
                if (res.ok) {
                    dispatch(acceptTermsSuccess(res.val));
                    return res.val;
                }
                dispatch(acceptTermsFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(acceptTermsFailed(err));
                throw(err);
            })
    }
}

export function getTermsRequested() {
    return {type: actions.GET_TERMS_REQUESTED};
}
export function getTermsSuccess(terms) {
    return {type: actions.GET_TERMS_SUCCESS, terms};
}
export function getTermsFailed(err) {
    return {type: actions.GET_TERMS_FAILED, err};
}

export function getTerms() {
    return function(dispatch){
        dispatch(getTermsRequested());

        return accountApi.getTerms()
            .then(terms => {
                if (terms.ok)
                    dispatch(getTermsSuccess(terms.val));
                else
                    dispatch(getTermsFailed(terms.val));
                return terms;
            })
            .catch(err => {
                dispatch(getTermsFailed(err));
                throw(err);
            })
    }
}

//#endregion

//#region ---- Business settings ----
export function getBusinessDetailsRequested() {
    return {type: actions.GET_BUSINESS_DETAILS_REQUESTED};
}
export function getBusinessDetailsSuccess(result) {
    return {type: actions.GET_BUSINESS_DETAILS_SUCCESS, result};
}
export function getBusinessDetailsFailed(err) {
    return {type: actions.GET_BUSINESS_DETAILS_FAILED, err};
}

export function getBusinessDetails() {
    return function(dispatch) {
        dispatch(getBusinessDetailsRequested());

        return accountApi.getBusinessDetails()
            .then((res) => {
                if (res.ok) {
                    dispatch(getBusinessDetailsSuccess(res.val));
                    return res.val;
                }
                dispatch(getBusinessDetailsFailed(res.val));
                throw res.val;
            }).catch(err => {
                dispatch(getBusinessDetailsFailed(err));
                throw err;
            });
    }
}

export function updateBusinessDetailsRequested() {
    return {type: actions.UPDATE_BUSINESS_DETAILS_REQUESTED};
}
export function updateBusinessDetailsSuccess() {
    return {type: actions.UPDATE_BUSINESS_DETAILS_SUCCESS};
}
export function updateBusinessDetailsFailed(err) {
    return {type: actions.UPDATE_BUSINESS_DETAILS_FAILED, err};
}

export function updateBusinessDetails(formData) {
    return function(dispatch) {
        dispatch(updateBusinessDetailsRequested());

        return accountApi.updateBusinessDetails(formData)
            .then((res) => {
                if (res.ok) {
                    dispatch(updateBusinessDetailsSuccess(res.val));
                    return res.val;
                }
                dispatch(updateBusinessDetailsFailed(res.val));
                throw res.val;
            })
            .catch(err => {
                dispatch(updateBusinessDetailsFailed(err));
                throw(err);
            });
    }
}
//#endregion

//#region --- Local actions (no API calls) ---

/** Updates the value of accounts.users.merchant.brandingByBiller in Redux */
export function setBrandingByBillerLocally(byBiller) {
    return function(dispatch) {
        dispatch({type: actions.SET_BRANDING_BY_BILLER, byBiller});
    }
}

//#endregion
