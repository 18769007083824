import { useMemo, useRef, useState } from "react";
import { APICallRequestState, PageHeader, useApiCall } from "components/Common";
import { BackToTop, Button, Icon, Link, LoadingIndicator, PaddedContainer, PageSection, PaginationControl, SuccessModal, Switch, ThreeDotMenu } from "packages/ui";
import eFormsApi from "api/eFormsApi";
import dateUtil from "@premier/utils/date";
import { SavedEFormModel } from "packages/webapi-client";
import NewEFormDialog from "../components/NewEFormDialog";
import { PlatformRoutesConfiguration } from "components/Routing";
import { GoLock, GoUnlock } from "react-icons/go";
import { defaultPageSize } from "platforms/base/constants/billpay";
import windowUtil from "packages/utils/window";
import DeleteEFormDialogs from "../components/DeleteEFormDialogs";
import DuplicateEFormDialogs from "../components/DuplicateEFormDialogs";
import { useNavigate } from "react-router-dom";

import "./EFormsPage.scss";

const EForms = () => {
    const navigate = useNavigate();
    const [refreshCount, setRefreshCount] = useState(0);
    const [showNewEFormDialog, setShowNewEFormDialog] = useState(false);
    const [showNewEFormSuccessDialog, setShowNewEFormSuccessDialog] = useState(false);
    const [newFormId, setNewFormId] = useState<number>();
    const [currentPage, setCurrentPage] = useState(1);
    const formIdToDelete = useRef<number>(0);
    const formIdToDuplicate = useRef<number>(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [eFormsData, eFormsDataStatus] = useApiCall(() => {
        return eFormsApi.getEForms(currentPage, defaultPageSize);
    }, [refreshCount, currentPage]);

    const [eForms, setEForms] = useState<SavedEFormModel[]>([]);

    useMemo(() => {
        if (eFormsData) {
            setEForms(eFormsData.data ?? []);
        }
    }, [eFormsData]);

    const handleFormVisibilityChange = async (formId: number) => {
        try {
            const eForm = eForms?.find(x => x.formId === formId);

            if (eForm) {
                eForm.visible = !eForm?.visible;
                setEForms(eForms => [...eForms.filter(e => e.formId !== eForm.formId), eForm].sort((a, b) => a.createdAt && b.createdAt && a.createdAt > b.createdAt ? -1 : 1));
                await eFormsApi.setVisibility(formId, eForm.visible);
            }
        }
        catch (error) {
            console.error("Error toggling form visibility", error);
        }
    };

    const handleNewEFormDialogClose = (showSuccess: boolean, reload: boolean, newFormId?: number) => {
        setShowNewEFormDialog(false);
        setShowNewEFormSuccessDialog(showSuccess);
        setRefreshCount(refreshCount + 1);
        setCurrentPage(1);
        setNewFormId(newFormId);
    };

    const handleCreateNewEFormClicked = () => {
        setShowNewEFormSuccessDialog(false);
        setShowNewEFormDialog(true);
    };

    const handlePageChanged = (page: number) => {
        setCurrentPage(page);
    };

    return <>
        <PageSection noDivider>
            <PageHeader title="eForms" subtitle="Collect information and payments from your customers via eForms">
                <Button onClick={() => setShowNewEFormDialog(true)} primary>Create new eForm</Button>
            </PageHeader>
            <div>
                <Icon info inline />
                To view the submission of the following forms, please go to <Link to={PlatformRoutesConfiguration.reportRoute!.eFormSubmissions.generatePath()}>eForm submission within Reports</Link>.
            </div>
        </PageSection>
        <PageSection>
            <>
                {eFormsDataStatus === APICallRequestState.LOADING || eFormsDataStatus === APICallRequestState.PENDING ?
                    <LoadingIndicator /> :
                    <PaddedContainer title={`You have ${eFormsData?.totalItems} eForms`} noDivider lessMargin>
                        <p>Select an eForm to edit it</p>
                        {eForms?.map((eForm) => (
                            <Link to={PlatformRoutesConfiguration.designerRoute?.editEForm.generatePath(eForm.formId)} className="eform-item-link" key={eForm.formId}>
                                <PaddedContainer noDivider greyBorder lessMargin>
                                    <div className="eform-item">
                                        <div>
                                            <p><h2>{eForm?.title}</h2></p>
                                            <p className="lock-icon-container">
                                                {eForm?.visible ? <><GoUnlock />Public</> : <><GoLock />Private</>}
                                            </p>
                                            <p>Created on {eForm.createdAt ? dateUtil.formatToDateString(eForm.createdAt) : ""}</p>
                                        </div>
                                        <div className="eform-item-actions">
                                            <Switch on={eForm?.visible} onChange={() => eForm?.formId && handleFormVisibilityChange(eForm.formId)} />
                                            <ThreeDotMenu
                                                items={[{
                                                    title: "Copy URL to form",
                                                    onClick: () => eForm?.paymentUrl && windowUtil.copyTextToClipboard(eForm?.paymentUrl)
                                                }, {
                                                    title: "Preview form",
                                                    onClick: () => eForm?.previewUrl && window.open(eForm?.previewUrl, "_blank")
                                                }, {
                                                    title: "Duplicate form",
                                                    onClick: () => { formIdToDuplicate.current = eForm.formId ?? 0; setShowDuplicateModal(true); }
                                                }, {
                                                    title: "Delete form",
                                                    onClick: () => { formIdToDelete.current = eForm.formId ?? 0; setShowDeleteModal(true); }
                                                }]}
                                            />
                                        </div>
                                    </div>
                                </PaddedContainer>
                            </Link>
                        ))}
                    </PaddedContainer>
                }
                <PaginationControl currentPage={currentPage} itemCount={eFormsData?.totalItems ?? 0} itemsPerPage={defaultPageSize} onPageChanged={handlePageChanged} />
            </>
        </PageSection>
        <NewEFormDialog show={showNewEFormDialog} onClose={handleNewEFormDialogClose} />
        <SuccessModal
            show={showNewEFormSuccessDialog}
            title="eForm saved successfully!"
            onClose={() => setShowNewEFormSuccessDialog(false)}
            footerButtons={<>
                <Button onClick={handleCreateNewEFormClicked} primary>Create a new eForm</Button>
                <Link to={PlatformRoutesConfiguration.designerRoute?.editEForm.generatePath(newFormId)} button>Continue editing</Link>
            </>}
            disableAutoClose
        />
        <DeleteEFormDialogs
            formId={formIdToDelete.current}
            show={showDeleteModal}
            onDeleted={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onDeleteSuccessModalClose={() => setRefreshCount(refreshCount + 1)}
        />
        <DuplicateEFormDialogs
            formId={formIdToDuplicate.current}
            show={showDuplicateModal}
            onDuplicated={() => setShowDuplicateModal(false)}
            onCancel={() => setShowDuplicateModal(false)}
            onDuplicateSuccessModalClose={(formId) => navigate(PlatformRoutesConfiguration.designerRoute!.editEForm.generatePath(formId))}
        />
        <BackToTop />
    </>;
};

export default EForms;
