import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Link } from '@premier/ui';

import labels from 'constants/labels';
import { PlatformRoutesConfiguration } from 'components/Routing';
import { loggedInItems, userMenuItems as userMenuTemplates, filterMenuItems } from 'components/Routing';
import MainNavigation from './MainNavigation';
import UserNavigation from './UserNavigation';
import logo from 'images/headerlogo.svg';

const Header = ({ authenticatedUser, merchant }) => {
    const [userMenuItems, setUserMenuItems] = useState([]);
    const [navigationMenuItems, setNavigationMenuItems] = useState([]);

    useEffect(() => {
        if (authenticatedUser?.userId) {
            setNavigationMenuItems(filterMenuItems(loggedInItems, authenticatedUser, merchant?.hasAnyReports ?? false))
            setUserMenuItems(filterMenuItems(userMenuTemplates, authenticatedUser, merchant?.hasAnyReports ?? false))
        } else {
           setUserMenuItems(filterMenuItems(loggedInItems));
            setNavigationMenuItems(filterMenuItems(userMenuTemplates));
        }
    }, [authenticatedUser])


    function getNavMenuItems(){
        return navigationMenuItems
            .filter(item => item.type === 'public')
            .map((item, index) => {
                return (
                    <li key={index} className="public-navigation-item">
                        <Link to={item.href} newWindow={item.newWindow}>{item.text}</Link>
                    </li>)
        });
    }

    return (
        <header>
            {navigationMenuItems?.length >= 0 && (<MainNavigation className="header-nav-menu" items={navigationMenuItems} />)}
            <div className="header-logo">
                <Link to={PlatformRoutesConfiguration.accountRoute.landingPage.generatePath()}>
                    <img className="img" src={logo} alt={labels.headerLogoAlt} />
                </Link>
            </div>

            <div className="right-menu-options">
                <ul className="public-navigation-items">
                    { navigationMenuItems?.length >= 0 && getNavMenuItems() }
                </ul>

                <div className="user-navigation-container">
                    { userMenuItems?.length >= 0 && <UserNavigation items={userMenuItems} user={authenticatedUser} /> }
                </div>
            </div>
        </header>
    );
}

function mapStateToProps(state, ownState){
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
        merchant: state.accounts.users.merchant,
    };
}

export default connect(mapStateToProps)(Header);