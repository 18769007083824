import React, { useState, useEffect, ReactNode } from 'react';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import './FormikDropdown.scss';

export type ValueType = string | number;

export type Option = {
    value: ValueType;
    label: string | ReactNode;
}

export type FormikDropdownProps = {
    name: string;
    options: Option[];
    /** A more compact version of the dropdown, reducing the width, but keeping the height */
    compact?: boolean;
    /** The text displayed when no option is selected (Default = Select...) */
    placeholder?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    className?: string;
    id?: string;
    isCustomStyles?: boolean;
    onValueChange?: (oldValue: ValueType | undefined, newValue: ValueType | undefined) => void;
};

const FormikDropdown: React.FC<FormikDropdownProps> = ({
    name, options = [],  // data
    compact, className,  // style
    disabled, isCustomStyles,  // UI state
    onValueChange
}) => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [field, meta, helpers] = useField(name);
    const [defaultValue] = useState(options?.filter(o => o.value === field.value)[0] ?? options[0]);
    const [selectedValue, setSelectedValue] = useState<Option | null>(defaultValue);
    const { setValue } = helpers;

    // This is to prevent ReactDropdown from attempting to load null default values
    // If options contains Null as value, then can set value to null
    useEffect(() => {
        if (!initialized && setValue && (options.some(option => option.value === null) || defaultValue?.value)) {
            setValue(defaultValue.value)
            setInitialized(true);
        }
    }, []);

    // when field.value changed by Formik, change the displayed value
    useEffect(() => {
        setSelectedValue(options?.find((o) => o.value === field.value) ?? null);
    }, [field.value, options]);

    const handleChange = (option: Option | null) => {
        setSelectedValue(option);
        setValue(option?.value);
        onValueChange && onValueChange(selectedValue?.value, option?.value);
    };

    //custom styles for dropdown
    const customStyles = {
        //style applies to each individual option within the dropdown list
        option: (provided: any, state: any) => ({
            ...provided,
            fontFamily: state.data.value,
        }),
        //style applies to the selected value
        singleValue: (provided: any, state: any) => ({
            ...provided,
            fontFamily: state.data.value,
        }),
    };

    return (
        <ReactSelect
            className={classNames('dropdown', className, {
                'compact': compact,
                'has-error': meta.touched && meta.error
            })}
            classNamePrefix='rselect'
            options={options}
            defaultValue={defaultValue}
            isDisabled={disabled}
            onChange={handleChange}
            value={selectedValue}
            onBlur={field.onBlur}
            styles={isCustomStyles ? customStyles : {}}
        />
    );
};

export default FormikDropdown;
