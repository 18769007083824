import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import { APICallRequestState, MerchantDropdown, PageHeader, useApiCall } from "components/Common";
import { Button, Dialog, Dropdown, FormGroup, Icon, Input, LoadingIndicator, PageSection, PaginationControl, ResponsiveTable, SuccessModal } from "packages/ui";
import { declineManagerApi } from "api";
import { MerchantModel } from "packages/webapi-client";
import FormikDropdown, { Option } from "packages/formik-ui/src/FormikDropdown";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
// @ts-ignore
import { defaultPageSize, defaultPageSizeOptions } from "constants/billpay";
import ManageActionsQuickConfigDialog from "../../pages/BatchDeclineSettings/ManageActionsQuickConfigDialog";
import { DeclineCodeActionType } from "api/declineManagerApi";
import { userRoles } from "components/Routing";
import { getMerchantOptions } from "packages/utils/billpay";
import "./ManageActions.scss";

type Props = {
    type: DeclineCodeActionType;
}

type CardActionItem = {
    responseCode: string;
    responseDescription: string;
    actionId: number;
    finalTrayId: number;
    index: number;
    editable: boolean;
} & ResponsiveTableRecordPartial;

type FormValues = {
    cardActionItems: CardActionItem[];
};

const ManageActions = ({ type }: Props) => {
    const merchant = useSelector((state: RootState) => state.accounts.users.merchant) as MerchantModel;
    const [selectedMerchant, setSelectedMerchant] = useState<Option | null>({ label: merchant.merchantName ?? "", value: merchant.merchantNumber ?? "" });
    const [quickConfigurationDialogShow, setQuickConfigurationDialogShow] = useState(false);
    const [saveSuccessDialogShow, setSaveSuccessDialogShow] = useState(false);
    const [saveErrorDialogShow, setSaveErrorDialogShow] = useState(false);
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [reloadCount, setReloadCount] = useState(0);
    const navigate = useNavigate();

    const [cardActions, cardActionsState] = useApiCall(() => {
        return declineManagerApi.getActions(selectedMerchant?.value as string, type);
    }, [selectedMerchant, reloadCount]);

    const [actions, actionsStatus] = useApiCall(() => {
        return declineManagerApi.getRetryActions();
    }, []);

    const [trays, traysStatus] = useApiCall(() => {
        return selectedMerchant ? declineManagerApi.retrieveMerchantTrays(selectedMerchant.value as string) : undefined;
    }, [selectedMerchant]);

    const gridLoading = useMemo(() => {
        return cardActionsState === APICallRequestState.LOADING || cardActionsState === APICallRequestState.PENDING ||
            actionsStatus === APICallRequestState.LOADING || actionsStatus === APICallRequestState.PENDING ||
            traysStatus === APICallRequestState.LOADING || traysStatus === APICallRequestState.PENDING;
    }, [cardActionsState, actionsStatus, traysStatus]);

    const cardActionItems = useMemo<CardActionItem[]>(() => {
        if (!cardActions) {
            return [];
        }

        return cardActions.map((c, i) => ({
            responseCode: c.responseCode ?? "",
            responseDescription: c.responseDescription ?? "",
            actionId: c.actionId ?? 0,
            finalTrayId: c.finalTrayId ?? 0,
            editable: c.editable ?? false,
            index: i,
        }));
    }, [cardActions]);

    useEffect(() => {
        if (cardActionsState === APICallRequestState.SUCCESSFUL && cardActionItems) {
            formik.resetForm({
                values: {
                    cardActionItems: cardActionItems,
                }
            });
        }

        formik.setFieldTouched("cardActionItems", false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardActionsState, cardActionItems]);

    const formik = useFormik<FormValues>({
        initialValues: {
            cardActionItems: [],
        },
        onSubmit: async (formValues: FormValues) => {
            try {
                await declineManagerApi.updateActions(selectedMerchant?.value as string, type, formValues.cardActionItems);
                setSaveSuccessDialogShow(true);
            }
            catch (error) {
                setSaveErrorDialogShow(true);
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
    });

    // Reset page number when merchant, search text or page size changes.
    useMemo(() => {
        setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMerchant, searchText, pageSize]);

    const cardActionItemsFiltered = useMemo<CardActionItem[]>(() => {
        return formik.values.cardActionItems.filter(c => !searchText || c.responseCode.toLowerCase().includes(searchText.trim().toLowerCase()) || c.responseDescription.trim().toLowerCase().includes(searchText.trim().toLowerCase()));
    }, [formik.values.cardActionItems, searchText]);

    const cardActionItemsPaged = useMemo<CardActionItem[]>(() => {
        return cardActionItemsFiltered.slice((page - 1) * pageSize, page * pageSize);
    }, [cardActionItemsFiltered, page, pageSize]);

    const retryActionOptions = useMemo<Option[]>(() => {
        if (!actions) {
            return [];
        }

        return actions.map(a => ({
            label: a.actionName ?? "",
            value: a.actionId ?? 0,
        }));
    }, [actions]);

    const trayOptions = useMemo<Option[]>(() => {
        if (!trays) {
            return [];
        }

        return trays
            .filter(t => t.trayType !== "Resolved")
            .map(t => ({
                label: t.nickname ?? "",
                value: t.trayID ?? 0,
            }));
    }, [trays]);

    const hasDeclineManagerEnabledChildMerchants = useMemo(() => {
        return getMerchantOptions(merchant, false, userRoles.declineManager).length > 1;
    }, [merchant]);

    const subtitle = useMemo(() => {
        const typeSubtitle = type === DeclineCodeActionType.CreditCard
            ? "Define the actions for card decline codes and destination trays."
            : "Define the actions for bank account decline codes and destination trays.";

        return hasDeclineManagerEnabledChildMerchants
            ? typeSubtitle
            : <><p>{`Merchant ${merchant.merchantNumber}`}</p><p>{typeSubtitle}</p></>;
    }, [hasDeclineManagerEnabledChildMerchants, merchant, type]);

    const handleQuickConfigurationDialogClosed = (showSuccess: boolean) => {
        setQuickConfigurationDialogShow(false);
        setSaveSuccessDialogShow(showSuccess);
        setReloadCount(reloadCount + 1);
    };

    const handleCancelClick = (e: React.MouseEvent) => {
        e.preventDefault();
        checkOnExit();
    };

    const checkOnExit = () => {
        if (formik.dirty) {
            setShowDiscardChangesDialog(true);
        } else {
            doExit();
        }
    };

    const doExit = () => {
        navigate(-1);
    };

    const handleMerchantChange = (option: Option | null) => {
        if (option?.value !== selectedMerchant?.value) {
            setSelectedMerchant(option)
        }
    };

    return (<>
        <PageSection noDivider>
            <PageHeader
                title={type === DeclineCodeActionType.CreditCard ? "Credit card decline codes" : "Bank account decline codes"}
                subtitle={subtitle}
                backButton={{ onClick: checkOnExit }}
            />
            {hasDeclineManagerEnabledChildMerchants && <MerchantDropdown onChange={handleMerchantChange} feature={userRoles.declineManager} useDefaultValue />}
        </PageSection>
        <PageSection>
            <PageHeader title="Quick configuration of all recommended codes" subtitle="Bulk-update all decline code options listed below. This will also overwrite any changes you have made earlier." useH2 hideTopDiv>
                <Button onClick={() => setQuickConfigurationDialogShow(true)}>Quick configuration</Button>
            </PageHeader>
        </PageSection>
        {gridLoading ?
            <LoadingIndicator /> :
            <PageSection>
                <div className="row">
                    <FormGroup fieldId="searchText" label="Find by code or description" className='col-lg-2'>
                        <Input value={searchText} onChange={(e) => setSearchText(e.currentTarget.value)} />
                    </FormGroup>
                    <FormGroup fieldId="itemsPerPage" label="Items per page" className='col-lg-2'>
                        <Dropdown
                            className='options-panel-dropdown'
                            value={pageSize}
                            onChange={(option) => setPageSize(option?.value as number)}
                            options={defaultPageSizeOptions.map((x: number) => ({ value: x, label: x.toString() }))}
                        />
                    </FormGroup>
                </div>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <FieldArray name="cardActionItems" validateOnChange={false}>
                            {() => {
                                return (
                                    <ResponsiveTable
                                        data={cardActionItemsPaged}
                                        columns={[
                                            { label: "Code", getter: item => item.responseCode, },
                                            { label: "Description", getter: item => item.responseDescription },
                                            { label: "Action", getter: item => <Field name={`cardActionItems.${item.index}.actionId`} as={FormikDropdown} options={retryActionOptions} disabled={!item.editable} />, className: "w-25" },
                                            { label: "Final tray", getter: item => <Field name={`cardActionItems.${item.index}.finalTrayId`} as={FormikDropdown} options={trayOptions} />, className: "w-25" },
                                        ]}
                                    />
                                )
                            }}
                        </FieldArray>
                        <PaginationControl
                            currentPage={page}
                            onPageChanged={newPage => setPage(newPage)}
                            itemCount={cardActionItemsFiltered.length}
                            itemsPerPage={pageSize}
                        />
                        <div className="clear">
                            <Button type="submit" disabled={formik.isSubmitting} primary>Save</Button>
                            <Button type="submit" onClick={handleCancelClick}>Cancel</Button>
                        </div>
                    </form>
                </FormikProvider>
            </PageSection>}
        <ManageActionsQuickConfigDialog
            show={quickConfigurationDialogShow}
            merchantNumber={selectedMerchant?.value as string}
            type={type}
            onClose={handleQuickConfigurationDialogClosed}
        />
        <SuccessModal
            show={saveSuccessDialogShow}
            onClose={() => setSaveSuccessDialogShow(false)}
            title="Decline codes rules applied and saved successfully!"
        />
        <Dialog
            show={saveErrorDialogShow}
            onClose={() => setSaveErrorDialogShow(false)}
            title="An error occurred while saving decline codes rules"
            icon={<Icon alert />}
            closeButton
        />
        <Dialog
            show={showDiscardChangesDialog}
            title="Your changes have not been saved"
            icon={<Icon alert />}
            footerButtons={<>
                <Button onClick={() => setShowDiscardChangesDialog(false)} disabled={formik.isSubmitting} primary>Continue editing</Button>
                <Button onClick={doExit}>Discard changes</Button>
            </>}
        >
            All your changes will be discarded if you leave without saving first.
        </Dialog>
    </>);
};

export default ManageActions;
