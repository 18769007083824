export default {
    preview: {
        footer: {
            logo: {
                url: 'https://www.bpoint.com.au/pay/Views/Bpoint/DefaultViews/images/bpoint_consumer_footer_logo.png',
                height: '15px',
            },
            copyrightText: '© Commonwealth Bank of Australia 2009-{CurrentYear} ABN 48 123 123 124',  // A copy of the Copyright in BillpayConfig.xml
        },
    },
    fonts: [
        { label: 'Arial', value: 'Arial, sans-serif' },
        { label: 'Arial Black', value: 'Arial Black, Arial, sans-serif' },
        { label: 'Calibri', value: 'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif' },
        { label: 'Century Gothic', value: 'Century Gothic, CenturyGothic, AppleGothic, sans-serif' },
        { label: 'Comic Sans MS', value: 'Comic Sans MS, Comic Sans, cursive, sans-serif' },
        { label: 'Consolas', value: 'Consolas, monaco, monospace' },
        { label: 'Courier New', value: 'Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace' },
        { label: 'Garamond', value: 'Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif' },
        { label: 'Georgia', value: 'Georgia, Times, Times New Roman, serif' },
        { label: 'Helvetica', value: 'Helvetica Neue, Helvetica, Arial, sans-serif' },
        { label: 'Impact', value: 'Impact, Charcoal, Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif' },
        { label: 'Sans Serif', value: 'MS Sans Serif, sans-serif' },
        { label: 'Serif', value: 'serif' },
        { label: 'Tahoma', value: 'Tahoma, Verdana, Segoe, sans-serif' },
        { label: 'Trebuchet MS', value: 'Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, sans-serif' },
        { label: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
    ],
    designerEditorToolbar: 'blocks fontfamily fontsize bold italic underline strikethrough subscript superscript forecolor backcolor removeformat bullist numlist outdent indent alignleft aligncenter alignright alignjustify image link unlink undo redo',
    designerEditorPlugins: 'link lists image',
};
