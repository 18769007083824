import React, { useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import { validateRequiredInput, validateInputLength } from "components/EForms/helpers";
import { MaximumType, } from "components/EForms/enums";
import classNames from "classnames";

const LongAnswer = ({ name, attributes }: TemplatesProps) => {
    const [inputValue, setInputValue] = useState(attributes?.prefilledText ?? "");
    const [error, setError] = useState("");
    const [valid, setValid] = useState(true);

    const inputValidation = (value: string, setError: React.Dispatch<React.SetStateAction<string>>, attributes: any) => {
        let validationResult: boolean;
        setError("");

        if (attributes.required) {
            validationResult = validateRequiredInput(value, setError);

            if (validationResult) {
                validationResult = validateInputLength(attributes.maximumType, attributes.maximum, value, setError);
            }
        } else {
            validationResult = validateInputLength(attributes.maximumType, attributes.maximum, value, setError);
        }

        setValid(validationResult);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
        inputValidation(e.target.value, setError, attributes);
    };

    const handleBlur = () => {
        inputValidation(inputValue, setError, attributes);
    };

    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-short eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <div className={`jsContent eform-col-right ${attributes.labelAlign !== "top" ? "eform-col-8" : ""}`}>
                <textarea
                    className="formElementTextArea eform-ctrl-input eform-ctrl-long-textarea jsElementAttributeInput"
                    data-attribute="PrefilledText"
                    placeholder={attributes?.placeholderText ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={inputValue}
                    rows={attributes?.rows}
                    maxLength={attributes.maximumType === MaximumType.Letters ? attributes.maximum : undefined}
                />
            </div>
            {error && <div className="errors" style={{ fontFamily: attributes.fontFamily }}><div>{error}</div></div>}
        </div>
    );
};

export default withDesignerControl(LongAnswer);
