import { useContext, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { Field, FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { Biller, FormAttributes, MerchantModel } from "packages/webapi-client";
import { FormGroup, FormikColourField, FormikDropdown, FormikError, FormikRadioButton, Input, TextArea } from "packages/formik-ui";
import FormikCheckbox from "packages/formik-ui/src/FormikCheckbox";
import { Option, ValueType } from "packages/formik-ui/src/FormikDropdown";
import { Accordion, Button, Dialog, PaddedContainer, PageSection, Row } from "packages/ui";
import { submitTypes, uniqueControlTypes } from "../constants";
import { ControlCategory, ControlProperty, ControlType, Special } from "../enums";
import { generateNewControlId, getControlCategoryOptions, getControlTypeDefaultAttributes, getDateFormatOptions, getFontFamilyOptions, getFontSizeOptions, getHeaderAlignmentOptions, getLabelAlignmentOptions, getMaximumTypeOptions, getSpecialOptions, getSpecialOptionsByControlType, getSpreadColumnsOptions, getTimeFormatOptions, getValidationFormatOptions, hasAnyControlProperty, hasControlProperty, matchesAnySpecialOptions, toControlTypeEnum } from "../helpers";
import { Control, FieldOption } from "../types";
import { EFormsContext } from "../contexts/EFormsContext";
import * as Yup from "yup";
import regexUtil from "packages/utils/regEx";

import "./EditControlPanel.scss";

type Props = {
    merchant: MerchantModel;
    billers: Biller[];
    formAttributes?: FormAttributes;
    control?: Control;
    controls: Control[];
    onSave: (control: Control, controlType: ControlType | undefined, billerCode: string, submitType: string, customUrl: string, successMessage: string, showBillerCode: boolean, showCrn1: boolean, showCrn2: boolean, showCrn3: boolean) => void;
    onCancel: () => void;
    onBillerCodeChanged: (newBillerCode: string) => void;
}

type FormValues = {
    billerCode: ValueType;
    category: ValueType;
    controlType: ValueType;
    required: boolean;
    display: boolean;
    encrypt: boolean;
    allowOtherOption: boolean;
    title: boolean;
    middleName: boolean;
    headingTitle: string;
    subHeadingTitle: string;
    label: string;
    subLabel: string;
    placeholderText: string;
    hoverText: string;
    prefilledText: string;
    maximumCharacters?: number;
    country?: string;
    rows?: number;
    minimum?: number;
    maximum?: number;
    maximumType?: string;
    validationFormat?: string;
    labelAlign: string;
    fontFamily: string;
    fontSize: number;
    fontColor: string;
    special: string;
    spreadColumns: number;
    options: FieldOption[];
    selected: string | string[];
    clearForm: boolean;
    printForm: boolean;
    buttonColor: string;
    buttonText: string;
    text: string;
    allowTime: boolean;
    dateFormat: string;
    timeFormat: string;
    submitType: string;
    showBillerCode: boolean;
    showCrn1: boolean;
    showCrn2: boolean;
    showCrn3: boolean;
    customUrl: string;
    successMessage: string;
}

type TypeOptions = {
    basic: Option[];
    customer: Option[];
    payment: Option[];
} & Record<string, Option[]>

const EditControlPanel = ({ merchant, billers, formAttributes, control, controls, onSave, onCancel, onBillerCodeChanged }: Props) => {
    const [showOptionsPanel, setShowOptionsPanel] = useState(false);
    const [defaultSelectionOptions, setDefaultSelectionOptions] = useState<Option[]>([]);
    const { countries, timeZones } = useContext(EFormsContext);
    const countryOptions = useMemo(() => countries?.map((x) => ({ value: x.name ?? "", label: x.name ?? "" })) ?? [], [countries]);
    const billerOptions = useMemo(() => billers.filter(x => x.merchantNumber === merchant.merchantNumber).map(x => ({ value: x.billerCode ?? "", label: x.billerCodeWithName ?? "" })), [billers, merchant]);
    const [showBillerCodeChangedModal, setShowBillerCodeChangedModal] = useState(false);
    let typeOptions: TypeOptions | undefined;

    const getBaseTypeOptions = () => {
        return {
            basic: [
                { value: ControlType.Checkbox, label: "Checkbox" },
                { value: ControlType.DateTime, label: "Date/Time" },
                { value: ControlType.DropDown, label: "Dropdown" },
                { value: ControlType.Heading, label: "Heading" },
                { value: ControlType.LongAnswer, label: "Long answer" },
                { value: ControlType.Number, label: "Number" },
                { value: ControlType.Radio, label: "Radio buttons" },
                { value: ControlType.ShortAnswer, label: "Short answer" },
                { value: ControlType.Text, label: "Text" },
            ],
            customer: [
                { value: ControlType.Address, label: "Address" },
                { value: ControlType.Email, label: "E-mail" },
                { value: ControlType.Name, label: "Name" },
                { value: ControlType.PhoneNumber, label: "Phone number" },
            ],
            payment: []
        }
    };

    const determineCategory = (type: ControlType) => {
        const options = typeOptions ?? getBaseTypeOptions();

        if (options.basic.some(x => x.value === type)) {
            return "basic";
        } else if (options.customer.some(x => x.value === type)) {
            return "customer";
        } else {
            return "payment";
        }
    };

    const controlDefaultAttributes = getControlTypeDefaultAttributes(typeOptions?.basic[0].value as ControlType, merchant.countryCode, countries);

    let formik = useFormik<FormValues>({
        initialValues: {
            billerCode: formAttributes?.billerCode ?? (billerOptions.length > 0 ? billerOptions[0].value : ""),
            category: (control?.controlType ? determineCategory(control?.controlType) : null) ?? getControlCategoryOptions()[0].value,
            controlType: control?.controlType ?? (typeOptions ? typeOptions.basic[0].value : ""),
            required: control?.attributes.required ?? controlDefaultAttributes.required!,
            display: control?.attributes.display ?? controlDefaultAttributes.display!,
            encrypt: control?.attributes.encrypt ?? controlDefaultAttributes.encrypt!,
            allowOtherOption: control?.attributes.allowOtherOption ?? controlDefaultAttributes.allowOtherOption!,
            title: control?.attributes.title ?? controlDefaultAttributes.title!,
            middleName: control?.attributes.middleName ?? controlDefaultAttributes.middleName!,
            headingTitle: control?.attributes.headingTitle ?? controlDefaultAttributes.headingTitle!,
            subHeadingTitle: control?.attributes.subHeadingTitle ?? controlDefaultAttributes.subHeadingTitle!,
            label: control?.attributes.label ?? controlDefaultAttributes.label!,
            subLabel: control?.attributes.subLabel ?? controlDefaultAttributes.subLabel!,
            placeholderText: control?.attributes.placeholderText ?? controlDefaultAttributes.placeholderText!,
            hoverText: control?.attributes.hoverText ?? controlDefaultAttributes.hoverText!,
            prefilledText: control?.attributes.prefilledText ?? controlDefaultAttributes.prefilledText!,
            labelAlign: control?.attributes.labelAlign ?? controlDefaultAttributes.labelAlign!,
            fontFamily: control?.attributes.fontFamily ?? controlDefaultAttributes.fontFamily!,
            fontSize: control?.attributes.fontSize ?? controlDefaultAttributes.fontSize!,
            fontColor: control?.attributes.fontColor ?? controlDefaultAttributes.fontColor!,
            special: control?.attributes.special ?? controlDefaultAttributes.special!,
            spreadColumns: control?.attributes.spreadColumns ?? controlDefaultAttributes.spreadColumns!,
            options: control?.attributes.options?.map((x, i) => ({ text: x, order: i })) ?? controlDefaultAttributes.options?.map((x, i) => ({ text: x, order: i })) ?? [],
            selected: control?.attributes.selected ?? controlDefaultAttributes.selected!,
            clearForm: control?.attributes.clearForm ?? controlDefaultAttributes.clearForm!,
            printForm: control?.attributes.printForm ?? controlDefaultAttributes.printForm!,
            buttonColor: control?.attributes.buttonColor ?? controlDefaultAttributes.buttonColor!,
            buttonText: control?.attributes.buttonText ?? controlDefaultAttributes.buttonText!,
            text: control?.attributes.text ?? controlDefaultAttributes.text!,
            country: control?.attributes.country ?? controlDefaultAttributes.country!,
            minimum: control?.attributes.minimum ?? controlDefaultAttributes.minimum!,
            maximum: control?.attributes.maximum ?? controlDefaultAttributes.maximum!,
            maximumCharacters: control?.attributes.maximumCharacters ?? controlDefaultAttributes.maximumCharacters!,
            maximumType: control?.attributes.maximumType ?? controlDefaultAttributes.maximumType!,
            rows: control?.attributes.rows ?? controlDefaultAttributes.rows!,
            validationFormat: control?.attributes.validationFormat ?? controlDefaultAttributes.validationFormat!,
            allowTime: control?.attributes.allowTime ?? controlDefaultAttributes.allowTime!,
            dateFormat: control?.attributes.dateFormat ?? controlDefaultAttributes.dateFormat!,
            timeFormat: control?.attributes.timeFormat ?? controlDefaultAttributes.timeFormat!,
            submitType: formAttributes?.submitType ?? submitTypes.successScreen,
            customUrl: formAttributes?.customUrl ?? "",
            successMessage: formAttributes?.successMessage ?? "",
            showBillerCode: formAttributes?.showBillerCode ?? false,
            showCrn1: formAttributes?.showCrn1 ?? true, // Default CRN1 to be displayed
            showCrn2: formAttributes?.showCrn2 ?? false,
            showCrn3: formAttributes?.showCrn3 ?? false,
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object().shape({
            buttonColor: Yup.string().label("Button colour").matches(regexUtil.colourHexRegex, "Invalid hex colour"),
            customUrl: Yup.string().label("Custom URL").url()
        }),
        validate: (values) => {
            const errors: FormikErrors<FormValues> = {};

            if (uniqueControlTypes.includes(values.controlType as ControlType)) {
                // Ensure that amount control is unique
                if (controls.some(x => !control?.id && x.controlType === values.controlType)) {
                    const controlTypeName = filteredTypeOptions.find(x => x.value === values.controlType)?.label;

                    errors.controlType = `${controlTypeName} control already exists`;
                }
            }

            return errors;
        },
        onSubmit: (formValues: FormValues) => {
            if (onSave) {
                onSave(
                    {
                        id: control?.id ?? generateNewControlId(),
                        controlType: control?.controlType === ControlType.Submit ? ControlType.Submit : toControlTypeEnum(formValues.controlType as string), // Do not allow submit button to change type
                        order: control?.order ?? Number.MAX_VALUE,
                        attributes: {
                            required: formValues.required,
                            encrypt: formValues.encrypt,
                            allowOtherOption: formValues.allowOtherOption,
                            title: formValues.title,
                            middleName: formValues.middleName,
                            buttonColor: formValues.buttonColor,
                            clearForm: formValues.clearForm,
                            country: formValues.country,
                            fontFamily: formValues.fontFamily,
                            fontColor: formValues.fontColor,
                            fontSize: formValues.fontSize,
                            headingTitle: formValues.headingTitle,
                            hoverText: formValues.hoverText,
                            label: formValues.label,
                            labelAlign: formValues.labelAlign,
                            maximum: formValues.maximum,
                            maximumCharacters: formValues.maximumCharacters,
                            maximumType: formValues.maximumType,
                            minimum: formValues.minimum,
                            placeholderText: formValues.placeholderText,
                            prefilledText: formValues.prefilledText,
                            printForm: formValues.printForm,
                            rows: formValues.rows,
                            special: formValues.special,
                            spreadColumns: formValues.spreadColumns,
                            subHeadingTitle: formValues.subHeadingTitle,
                            subLabel: formValues.subLabel,
                            validationFormat: formValues.validationFormat,
                            options: formValues.options.map(x => x.text),
                            selected: formValues.selected,
                            text: formValues.text,
                            billerCode: formValues.billerCode as string,
                            display: formValues.display,
                            allowTime: formValues.allowTime,
                            dateFormat: formValues.dateFormat,
                            timeFormat: formValues.timeFormat,
                            buttonText: formValues.buttonText,
                        },
                    },
                    control?.controlType,
                    formValues.billerCode as string,
                    formValues.submitType,
                    formValues.customUrl,
                    formValues.successMessage,
                    formValues.showBillerCode,
                    formValues.showCrn1,
                    formValues.showCrn2,
                    formValues.showCrn3);
            }
        }
    });

    const prevBillerCode = useRef(formAttributes?.billerCode ?? "");
    const biller = useMemo(() => billers.find(x => x.billerCode === formik.values.billerCode), [billers, formik.values.billerCode]);

    // Options for control type dropdown
    typeOptions = useMemo<TypeOptions>(() => {
        const biller = billers.find(x => x.billerCode === formik.values.billerCode);
        const paymentTypeOptions = [
            { value: ControlType.Amount, label: "Amount" },
        ];

        // We don't want the user to be able to choose CRN options if they already exist in the form
        const crn1AlreadyExists = controls.some(x => x.controlType === ControlType.Crn1);
        const crn2AlreadyExists = controls.some(x => x.controlType === ControlType.Crn2);
        const crn3AlreadyExists = controls.some(x => x.controlType === ControlType.Crn3);

        // Add CRN options in reverse order
        if (!!biller?.acceptedCrn3?.isActive && (control?.controlType === ControlType.Crn3 || !crn3AlreadyExists)) {
            paymentTypeOptions.unshift({ value: ControlType.Crn3, label: biller.acceptedCrn3.crnName ?? "CRN 3" });
        }

        if (!!biller?.acceptedCrn2?.isActive && (control?.controlType === ControlType.Crn2 || !crn2AlreadyExists)) {
            paymentTypeOptions.unshift({ value: ControlType.Crn2, label: biller.acceptedCrn2.crnName ?? "CRN 2" });
        }

        if (!!biller?.acceptedCrn1?.isActive && (control?.controlType === ControlType.Crn1 || !crn1AlreadyExists)) {
            paymentTypeOptions.unshift({ value: ControlType.Crn1, label: biller.acceptedCrn1.crnName ?? "CRN 1" });
        }

        return {
            ...getBaseTypeOptions(),
            payment: paymentTypeOptions,
        }
    }, [billers, controls, formik.values.controlType, formik.values.billerCode]);

    // Show options panel if the control type has options
    useMemo(() => {
        if (control?.attributes.options) {
            setShowOptionsPanel(control.attributes.options?.length > 0)
        }
    }, [control]);

    const filteredTypeOptions = useMemo(() => {
        const result = (typeOptions ? typeOptions[formik.values.category] : undefined) ?? [];

        // Set to first option if the current control type is not in the list
        if (formik.values.controlType && result.length > 0 && !result.map(x => x.value).includes(formik.values.controlType)) {
            formik.setFieldValue("controlType", result[0].value);
        }

        return result;
    }, [formik.values.category, formik.values.controlType, typeOptions]);

    // Set default attributes when control type changes and reset errors
    useMemo(() => {
        formik.setErrors({});

        if (!control) {
            const defaultAttributes = getControlTypeDefaultAttributes(formik.values.controlType as ControlType, merchant.countryCode, countries);

            formik.setValues({
                ...formik.values,
                labelAlign: defaultAttributes.labelAlign!,
                fontSize: defaultAttributes.fontSize!,
                fontFamily: defaultAttributes.fontFamily!,
                fontColor: defaultAttributes.fontColor!,
                label: defaultAttributes.label!,
                headingTitle: defaultAttributes.headingTitle!,
                placeholderText: defaultAttributes.placeholderText!,
                options: defaultAttributes.options?.map((x, i) => ({ text: x, order: i })) ?? [],
                special: defaultAttributes.special!,
                spreadColumns: defaultAttributes.spreadColumns!,
                rows: defaultAttributes.rows!,
                maximumType: defaultAttributes.maximumType!,
                validationFormat: defaultAttributes.validationFormat!,
                buttonColor: defaultAttributes.buttonColor!,
                country: defaultAttributes.country!,
                required: defaultAttributes.required!,
                display: defaultAttributes.display!,
                encrypt: defaultAttributes.encrypt!,
                text: defaultAttributes.text!,
                allowTime: defaultAttributes.allowTime!,
                dateFormat: defaultAttributes.dateFormat!,
                timeFormat: defaultAttributes.timeFormat!,
                title: defaultAttributes.title!,
                middleName: defaultAttributes.middleName!,
            });
        }
    }, [formik.values.controlType, merchant.countryCode, countries]);

    const filteredSpecialOptions = useMemo(() => getSpecialOptions(formik.values.controlType as ControlType), [formik.values.controlType]);

    const emptyOption = { value: "", label: <>&nbsp;</> };

    useMemo(() => {
        if (formik.values.controlType === ControlType.Checkbox || formik.values.controlType === ControlType.DropDown || formik.values.controlType === ControlType.Radio) {
            if (formik.values.special !== Special.None) {
                const specialOptions: FieldOption[] = getSpecialOptionsByControlType(formik.values.controlType, formik.values.special as Special, countries, timeZones);
                formik.setFieldValue("options", specialOptions);
                setDefaultSelectionOptions([emptyOption, ...specialOptions.map((x, i) => ({ value: x.text, label: x.text }))]);
                setShowOptionsPanel(false);
            } else {
                // Check if the currently saved options are special options. If yes, reset them to default placeholder options.
                const matchesSpecialOptions = matchesAnySpecialOptions(control?.attributes.options ?? [], countries, timeZones);
                let options: FieldOption[] = [];

                if (matchesSpecialOptions || (control?.attributes.options?.length ?? 0) === 0) {
                    options = [{ text: "Option 1", order: 1 }, { text: "Option 2", order: 2 }, { text: "Option 3", order: 3 }];
                } else {
                    options = control?.attributes.options?.map((x, i) => ({ text: x, order: i })) ?? [];
                }

                formik.setFieldValue("options", options);
                setDefaultSelectionOptions([emptyOption, ...options.map(x => ({ value: x.text, label: x.text }))]);
                setShowOptionsPanel(true);
            }
        } else {
            setShowOptionsPanel(false);
        }
    }, [formik.values.controlType, formik.values.special, countries, timeZones, control?.attributes.options]);

    useMemo(() => {
        // Compare defaultSelectionOptions with options. Update defaultSelectionOptions if they are different.
        if (defaultSelectionOptions.length !== formik.values.options.length || defaultSelectionOptions.some((x, i) => x.value !== formik.values.options[i].text)) {
            setDefaultSelectionOptions([emptyOption, ...formik.values.options.map(x => ({ value: x.text, label: x.text }))]);
        }
    }, [formik.values.options]);

    const controlType = useMemo(() => {
        // We need to manually return ControlType.Submit here as formik won't have the correct value.
        if (control?.controlType === ControlType.Submit) {
            return ControlType.Submit;
        }

        return toControlTypeEnum(formik.values.controlType as string);
    }, [formik.values.controlType]);

    const labelAlignmentOptions = useMemo(() => formik.values.controlType === ControlType.Heading ? getHeaderAlignmentOptions() : getLabelAlignmentOptions(), [formik.values.controlType]);

    // Set label of CRN based on biller's CRN settings
    useMemo(() => {
        const biller = billers.find(x => x.billerCode === formik.values.billerCode);

        if (formik.values.controlType === ControlType.Crn1 && biller?.acceptedCrn1 && biller.acceptedCrn1.isActive) {
            formik.setFieldValue("label", biller.acceptedCrn1.crnName ?? "CRN 1");
        }

        if (formik.values.controlType === ControlType.Crn2 && biller?.acceptedCrn2 && biller.acceptedCrn2.isActive) {
            formik.setFieldValue("label", biller.acceptedCrn2.crnName ?? "CRN 2");
        }

        if (formik.values.controlType === ControlType.Crn3 && biller?.acceptedCrn3 && biller.acceptedCrn3.isActive) {
            formik.setFieldValue("label", biller.acceptedCrn3.crnName ?? "CRN 3");
        }
    }, [formik.values.billerCode, formik.values.controlType, billers]);

    const isNewControl = useMemo(() => {
        return controls.every(x => x.id !== control?.id);
    }, [control, controls]);

    // Notify user when biller code is changed
    const handleBillerCodeChanged = (oldValue: ValueType | undefined, newValue: ValueType | undefined) => {
        // Don't record value if it is falsy
        if (oldValue) {
            prevBillerCode.current = oldValue as string;
        }

        let unsupportedCrnsFound = false;
        const biller = billers.find(x => x.billerCode === newValue);

        if (!biller?.acceptedCrn2 && controls.find(c => c.controlType === ControlType.Crn2)) {
            unsupportedCrnsFound = true;
        }

        if (!biller?.acceptedCrn3 && controls.find(c => c.controlType === ControlType.Crn3)) {
            unsupportedCrnsFound = true;
        }

        // Show modal to warn user that changing biller code will remove reference fields
        if (oldValue !== newValue && unsupportedCrnsFound) {
            setShowBillerCodeChangedModal(true);
        }
    };

    // Revert biller code change
    const handleBillerCodeChangeCancelClicked = () => {
        setShowBillerCodeChangedModal(false);

        // Reset biller code to the previous value
        // Needs a delay to let the biller code drop down time to load before setting value
        const billerCode = prevBillerCode.current;
        setTimeout(() => formik.setFieldValue("billerCode", billerCode), 100);
    };

    const handleBillerCodeChangeConfirmClicked = () => {
        setShowBillerCodeChangedModal(false);
        onBillerCodeChanged(formik.values.billerCode as string);
    };

    return <PaddedContainer blueBorder lessMargin>
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <PageSection header={<h3>{isNewControl ? "Add new" : "Edit"} component</h3>}>
                    <Row>
                        {control?.controlType !== ControlType.Submit &&
                            <FormGroup name="category" label="Category" className="col-md-6">
                                <Field name="category" as={FormikDropdown} options={getControlCategoryOptions()} />
                            </FormGroup>}
                        {formik.values.category === ControlCategory.Payment && <>
                            {control?.controlType !== ControlType.Submit && <FormGroup name="billerCode" label="Biller" className="col-md-6">
                                <Field name="billerCode" as={FormikDropdown} options={billerOptions} onValueChange={handleBillerCodeChanged} />
                                <FormikError name="billerCode" />
                            </FormGroup>}
                        </>}
                        {control?.controlType !== ControlType.Submit &&
                            <FormGroup name="controlType" label="Type" className="col-md-6">
                                <Field name="controlType" as={FormikDropdown} options={filteredTypeOptions} />
                            </FormGroup>}
                    </Row>
                    {hasAnyControlProperty(controlType, [ControlProperty.Required, ControlProperty.Encrypt, ControlProperty.AllowOtherOption, ControlProperty.AllowTime, ControlProperty.Title, ControlProperty.MiddleName, ControlProperty.ClearForm, ControlProperty.PrintForm]) &&
                        <Row>
                            <FormGroup name="attributes" label="Attributes" className="col-md-12">
                                <Row>
                                    {hasControlProperty(controlType, ControlProperty.Required) &&
                                        <div className="col-md-3">
                                            <Field id="required" name="required" as={FormikCheckbox} label="Mandatory" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.Display) &&
                                        <div className="col-md-3">
                                            <Field id="display" name="display" as={FormikCheckbox} label="Display" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.Encrypt) &&
                                        <div className="col-md-3">
                                            <Field id="encrypt" name="encrypt" as={FormikCheckbox} label="Encrypt input" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.AllowOtherOption) &&
                                        <div className="col-md-3">
                                            <Field id="allowOtherOption" name="allowOtherOption" as={FormikCheckbox} label="Allow other option" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.AllowTime) &&
                                        <div className="col-md-3">
                                            <Field id="allowTime" name="allowTime" as={FormikCheckbox} label="Allow time" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.Title) &&
                                        <div className="col-md-3">
                                            <Field id="title" name="title" as={FormikCheckbox} label="Title" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.MiddleName) &&
                                        <div className="col-md-3">
                                            <Field id="middleName" name="middleName" as={FormikCheckbox} label="Middle name" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.ClearForm) &&
                                        <div className="col-md-3">
                                            <Field id="clearForm" name="clearForm" as={FormikCheckbox} label="Clear form" />
                                        </div>}
                                    {hasControlProperty(controlType, ControlProperty.PrintForm) &&
                                        <div className="col-md-3">
                                            <Field id="printForm" name="printForm" as={FormikCheckbox} label="Print form" />
                                        </div>}
                                </Row>
                            </FormGroup>
                        </Row>}
                    <Row>
                        {hasControlProperty(controlType, ControlProperty.Heading) &&
                            <FormGroup name="headingTitle" label="Heading" className="col-md-6">
                                <Field name="headingTitle" as={Input} />
                            </FormGroup>}
                        {hasControlProperty(controlType, ControlProperty.SubHeading) &&
                            <FormGroup name="subHeadingTitle" label="Sub-heading" className="col-md-6">
                                <Field name="subHeadingTitle" as={Input} />
                            </FormGroup>}
                    </Row>
                    <Row>
                        {hasControlProperty(controlType, ControlProperty.Text) &&
                            (controlType === ControlType.Text ?
                                <FormGroup name="text" label="Text" className="col-md-12">
                                    <Field name="text" as={TextArea} rows={10} />
                                </FormGroup> :
                                <FormGroup name="label" label="Label" className="col-md-6">
                                    <Field name="label" as={Input} />
                                </FormGroup>)}
                        {hasControlProperty(controlType, ControlProperty.SubLabel) &&
                            <FormGroup name="subLabel" label="Sub-label" className="col-md-6">
                                <Field name="subLabel" as={Input} />
                            </FormGroup>}
                    </Row>
                    <Row>
                        {hasControlProperty(controlType, ControlProperty.PlaceholderText) &&
                            <FormGroup name="placeholderText" label="Placeholder text" className="col-md-12">
                                <Field name="placeholderText" as={Input} />
                            </FormGroup>}
                    </Row>
                    <Row>
                        {hasControlProperty(controlType, ControlProperty.Special) &&
                            <FormGroup name="special" label="Special" className="col-md-6">
                                <Field name="special" as={FormikDropdown} options={filteredSpecialOptions} />
                            </FormGroup>}
                        {hasControlProperty(controlType, ControlProperty.Selected) &&
                            <FormGroup name="selected" label="Default selection" className="col-md-6">
                                <Field name="selected" as={FormikDropdown} options={defaultSelectionOptions} />
                            </FormGroup>}
                        {hasControlProperty(controlType, ControlProperty.SpreadColumns) &&
                            <FormGroup name="spreadColumns" label="Spread columns" className="col-md-6">
                                <Field name="spreadColumns" as={FormikDropdown} options={getSpreadColumnsOptions()} />
                            </FormGroup>}
                    </Row>
                    <Row>
                        {hasControlProperty(controlType, ControlProperty.DateFormat) &&
                            <FormGroup name="dateFormat" label="Date format" className="col-md-6">
                                <Field name="dateFormat" as={FormikDropdown} options={getDateFormatOptions()} />
                            </FormGroup>}
                        {hasControlProperty(controlType, ControlProperty.TimeFormat) &&
                            <FormGroup name="timeFormat" label="Time format" className="col-md-6">
                                <Field name="timeFormat" as={FormikDropdown} options={getTimeFormatOptions()} />
                            </FormGroup>}
                    </Row>
                    <Row>
                        {control?.controlType === ControlType.Submit && <>
                            <FormGroup name="buttonText" label="Button text" className="col-md-6">
                                <Field name="buttonText" as={Input} />
                            </FormGroup>
                            <FormGroup name="buttonColor" label="Button colour" className="col-md-6">
                                <Field name="buttonColor" as={FormikColourField} />
                            </FormGroup>
                            <FormGroup name="submitType" label="Go to" className="col-md-12">
                                <div className="go-to-panel">
                                    <Field id="submitTypePaymentPage" name="submitType" as={FormikRadioButton} label="Payment page" value={submitTypes.creditCard} />
                                    <Field id="submitTypeRegistrationPage" name="submitType" as={FormikRadioButton} label="Registration page" value={submitTypes.dataVault} />
                                    <Field id="submitTypeSuccessScreen" name="submitType" as={FormikRadioButton} label="Success screen" value={submitTypes.successScreen} />
                                    <Field id="submitTypeCustomUrl" name="submitType" as={FormikRadioButton} label="Custom URL" value={submitTypes.customUrl} />
                                </div>
                            </FormGroup>
                            {(formik.values.submitType === submitTypes.creditCard || formik.values.submitType === submitTypes.dataVault) &&
                                <FormGroup name="paymentRegistrationPageConfiguration" label={formik.values.submitType === submitTypes.creditCard ? "Payment page configuration" : "Registration page configuration"} className="col-md-12">
                                    <Row>
                                        {formik.values.submitType === submitTypes.creditCard &&
                                            <div className="col-md-3">
                                                <Field id="showBillerCode" name="showBillerCode" as={FormikCheckbox} label="Show biller code" />
                                            </div>
                                        }
                                        {biller?.acceptedCrn1 && biller.acceptedCrn1.isActive &&
                                            <div className="col-md-3">
                                                <Field id="showCrn1" name="showCrn1" as={FormikCheckbox} label={`Show ${biller.acceptedCrn1.crnName ?? "CRN1"}`} />
                                            </div>
                                        }
                                        {biller?.acceptedCrn2 && biller.acceptedCrn2.isActive &&
                                            <div className="col-md-3">
                                                <Field id="showCrn2" name="showCrn2" as={FormikCheckbox} label={`Show ${biller.acceptedCrn2.crnName ?? "CRN2"}`} />
                                            </div>
                                        }
                                        {biller?.acceptedCrn3 && biller.acceptedCrn3.isActive &&
                                            <div className="col-md-3">
                                                <Field id="showCrn3" name="showCrn3" as={FormikCheckbox} label={`Show ${biller.acceptedCrn3.crnName ?? "CRN3"}`} />
                                            </div>
                                        }
                                    </Row>
                                </FormGroup>}
                            {formik.values.submitType === submitTypes.successScreen &&
                                <FormGroup name="successMessage" label="Success message" className="col-md-12">
                                    <Field name="successMessage" as={TextArea} rows={10} />
                                </FormGroup>}
                            {formik.values.submitType === submitTypes.customUrl &&
                                <FormGroup name="customUrl" label="Custom URL" className="col-md-12">
                                    <Field name="customUrl" as={Input} />
                                </FormGroup>}
                        </>}
                    </Row>
                    {showOptionsPanel &&
                        <Row>
                            <FormGroup name="options" label="Options" className="col-md-12">
                                <FieldArray name="options">
                                    {({ push, remove, move }) => <div className="options-panel">
                                        {formik.values.options.map((option, index) => (<div key={`options.${index}.key`} className="options-panel-item">
                                            <Field name={`options.${index}.text`} as={Input} />
                                            <Button
                                                onClick={() => push(index)}
                                                noMarginBottom
                                                noMarginRight
                                                noMinWidth
                                                center
                                            >
                                                &#43;
                                            </Button>
                                            <Button
                                                onClick={() => formik.values.options.length > 1 && remove(index)}
                                                noMarginBottom
                                                noMarginRight
                                                noMinWidth
                                            >
                                                &#8722;
                                            </Button>
                                            <Button
                                                onClick={() => move(index, index - 1)}
                                                noMarginBottom
                                                noMarginRight
                                                noMinWidth
                                            >
                                                &#8593;
                                            </Button>
                                            <Button
                                                onClick={() => move(index, index + 1)}
                                                noMarginBottom
                                                noMinWidth
                                            >
                                                &#8595;
                                            </Button>
                                        </div>))}
                                    </div>}
                                </FieldArray>
                            </FormGroup>
                        </Row>}
                </PageSection>
                {control?.controlType !== ControlType.Submit && <PageSection noPaddingBottom>
                    <Accordion title="Advanced settings">
                        {formik.values.controlType !== ControlType.LongAnswer &&
                            <Row>
                                {hasControlProperty(controlType, ControlProperty.Minimum) &&
                                    <FormGroup name="minimum" label="Minimum" className="col-md-6">
                                        <Field name="minimum" as={Input} type="number" />
                                    </FormGroup>}
                                {hasControlProperty(controlType, ControlProperty.Maximum) &&
                                    <FormGroup name="maximum" label="Maximum" className="col-md-6">
                                        <Field name="maximum" as={Input} />
                                    </FormGroup>}
                            </Row>}
                        <Row>
                            {hasControlProperty(controlType, ControlProperty.PrefilledText) &&
                                <FormGroup name="prefilledText" label="Pre-filled text" className="col-md-6">
                                    {formik.values.controlType === ControlType.LongAnswer ?
                                        <Field name="prefilledText" as={TextArea} rows={10} /> :
                                        <Field name="prefilledText" as={Input} type={formik.values.controlType === ControlType.Number ? "number" : "text"} />}
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.Country) &&
                                <FormGroup name="country" label="Default country" className="col-md-6">
                                    <Field name="country" as={FormikDropdown} options={countryOptions} />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.HoverText) &&
                                <FormGroup name="hoverText" label="Help text" className="col-md-6">
                                    <Field name="hoverText" as={Input} />
                                </FormGroup>}
                            {formik.values.controlType === ControlType.LongAnswer && hasControlProperty(controlType, ControlProperty.Maximum) && <>
                                <FormGroup name="maximum" label="Maximum" className="col-md-3">
                                    <Field name="maximum" as={Input} />
                                </FormGroup>
                                <FormGroup name="maximumType" label="&nbsp;" className="col-md-3">
                                    <Field name="maximumType" as={FormikDropdown} options={getMaximumTypeOptions()} />
                                </FormGroup>
                                <FormGroup name="rows" label="Rows" className="col-md-6">
                                    <Field name="rows" as={Input} />
                                </FormGroup></>}
                            {hasControlProperty(controlType, ControlProperty.Type) &&
                                <FormGroup name="validationFormat" label="Input type" className="col-md-6">
                                    <Field name="validationFormat" as={FormikDropdown} options={getValidationFormatOptions()} />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.MaximumCharacters) &&
                                <FormGroup name="maximumCharacters" label="Max characters" className="col-md-6">
                                    <Field name="maximumCharacters" as={Input} />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.LabelAlign) &&
                                <FormGroup name="labelAlign" label="Label align" className="col-md-6">
                                    <Field name="labelAlign" as={FormikDropdown} options={labelAlignmentOptions} />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.FontSize) &&
                                <FormGroup name="fontSize" label="Font size" className="col-md-6">
                                    <Field name="fontSize" as={FormikDropdown} options={getFontSizeOptions()} />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.FontFamily) &&
                                <FormGroup name="fontFamily" label="Font family" className="col-md-6">
                                    <Field name="fontFamily" as={FormikDropdown} options={getFontFamilyOptions()} isCustomStyles />
                                </FormGroup>}
                            {hasControlProperty(controlType, ControlProperty.FontColour) &&
                                <FormGroup name="fontColor" label="Font colour" className="col-md-6">
                                    <Field name="fontColor" as={FormikColourField} />
                                </FormGroup>}
                        </Row>
                    </Accordion>
                </PageSection>}
                <PageSection noPaddingBottom>
                    <Button type="submit">Save component</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </PageSection>
            </form>
        </FormikProvider>
        <Dialog
            show={showBillerCodeChangedModal}
            title="Biller code changed"
            footerButtons={<>
                <Button onClick={handleBillerCodeChangeConfirmClicked} disabled={formik.isSubmitting} primary>Proceed</Button>
                <Button onClick={handleBillerCodeChangeCancelClicked}>Cancel</Button>
            </>}
        >
            Due to changing biller code, you are about to remove some Reference fields. Are you sure you want to continue?
        </Dialog>
    </PaddedContainer>;
}

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.activeBillers,
    };
}

export default connect(mapStateToProps)(EditControlPanel);
