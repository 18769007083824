import { FormikHelpers } from "formik";
import { ApiError } from "packages/webapi-client";
import mapErrors from "api/mapErrors";

const setFormikErrors = <T>(formikHelpers: FormikHelpers<T>, errors: ApiError[], fields: Record<string, string>) => {
    const mappedErrors = mapErrors(errors, undefined, fields);

    mappedErrors.forEach(error => {
        if (error.field) {
            formikHelpers.setFieldError(error.field, error.message);
        }
    });
};

export { setFormikErrors };
