import { useMemo, useState } from "react";
import moment from "moment";
import { Field, FormikProvider, useFormik } from "formik";
import { Link, PageSection, PaginationControl, RadioGroup, ResponsiveTable, Row, ServerError, TitleDropdown, UiOption } from "packages/ui";
import { FormGroup, FormikDatePicker } from "packages/formik-ui";
import { ResponsiveTableRecordPartial } from "packages/ui/src/ResponsiveTableTypes";
import { APICallRequestState, PageHeader, useApiCall } from "components/Common";
import FormikCheckbox from "packages/formik-ui/src/FormikCheckbox";
import EFormsApi from "api/eFormsApi";
import dateUtil from "packages/utils/date";
import fileUtil from "packages/utils/file";

import "./EFormSubmissions.scss";

type FormValues = {
    dateRange: Date[];
    includeDownloaded: boolean;
}

type Report = {
    formId: number;
    version: number;
    submissionDate: string;
    numSubmissions: number;
    numDownloads: number;
    publishedDate: string;
} & ResponsiveTableRecordPartial;

const dateRangeOptions = [
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "last7Days" },
    { label: "Last 30 days", value: "last30Days" },
];

const EFormSubmissions = () => {
    const [formId, setFormId] = useState<UiOption<number>>();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDateRangeOption, setSelectedDateRangeOption] = useState<string | number>();
    const [exporting, setExporting] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const yesterday = moment().subtract(1, "d");
    const oneMonthAgo = moment().subtract(1, "d").subtract(1, "M").toDate();
    const itemsPerPage = 10;

    const formik = useFormik<FormValues>({
        initialValues: {
            dateRange: [oneMonthAgo, yesterday.toDate()],
            includeDownloaded: false,
        },
        onSubmit: (values) => {
        },
    });

    const [reportableEForms] = useApiCall(EFormsApi.getReportableEForms, []);

    const [submittedEForms, submittedEFormsStatus] = useApiCall(() => {
        if (formId && formik.values.dateRange.length >= 2) {
            return EFormsApi.getSubmittedEForms(Number(formId?.value), dateUtil.convertToDateString(formik.values.dateRange[0]), dateUtil.convertToDateString(formik.values.dateRange[1]), formik.values.includeDownloaded);
        }
    }, [formId, formik.values.dateRange, formik.values.includeDownloaded]);

    const submittedEFormsData = useMemo<Report[]>(() => {
        return submittedEForms
            ?.filter((r, i) => i >= (currentPage - 1) * itemsPerPage && i < currentPage * itemsPerPage)
            ?.map(r => ({
                formId: r.formId ?? 0,
                version: r.version ?? 0,
                submissionDate: r.submittedAt ? dateUtil.convertToDateString(r.submittedAt) : "",
                numSubmissions: r.numberOfSubmissions ?? 0,
                numDownloads: r.numberOfDownloads ?? 0,
                publishedDate: r.publishedAt ? dateUtil.convertToDateString(r.publishedAt) : "",
            })) ?? [];
    }, [currentPage, submittedEForms]);

    useMemo(() => {
        // Select the correct radio button when the date range changes
        if (formik.values.dateRange.length >= 2) {
            const startDate = formik.values.dateRange[0];

            if (yesterday.diff(startDate, "d") === 0) {
                setSelectedDateRangeOption("yesterday");
            } else if (yesterday.diff(startDate, "d") === 7) {
                setSelectedDateRangeOption("last7Days");
            } else if (yesterday.diff(startDate, "d") === 30) {
                setSelectedDateRangeOption("last30Days");
            } else {
                setSelectedDateRangeOption("");
            }
        }
    }, [formik.values.dateRange]);

    const handleDateRangeChanged = (option: UiOption<string | number>) => {
        setSelectedDateRangeOption(option?.value ?? "");

        // Set the date range based on the selected radio button option
        switch (option?.value) {
            case "yesterday":
                formik.setFieldValue("dateRange", [yesterday.toDate(), yesterday.toDate()]);
                break;
            case "last7Days":
                // We calculate the date range based on yesterday, so we need to subtract 8 days to get the correct range
                formik.setFieldValue("dateRange", [moment().subtract(8, "d").toDate(), yesterday.toDate()]);
                break;
            case "last30Days":
                // We calculate the date range based on yesterday, so we need to subtract 31 days to get the correct range
                formik.setFieldValue("dateRange", [moment().subtract(31, "d").toDate(), yesterday.toDate()]);
                break;
        }
    };

    const handleExport = async (formId: number, version: number, submissionDate: string) => {
        if (!exporting) {
            setExporting(true);

            try {
                const response = await EFormsApi.downloadEFormSubmission(formId, version, submissionDate);

                if (response.status === 200) {
                    const filename = fileUtil.getFileNameFromResponse(response);
                    fileUtil.download(response.data, filename, null);
                } else {
                    setErrors(["Failed to download submission report"]);
                }
            } catch (error) {
                setErrors(["Failed to download submission report"]);
            }

            setExporting(false);
        }
    };

    return <>
        <PageSection noDivider>
            <PageHeader title="eForm submissions" />
            <TitleDropdown
                value={formId}
                onChange={option => setFormId(option ?? undefined)}
                options={reportableEForms?.map(x => ({ label: x.formTitle, value: x.formId ?? 0 })) ?? []}
                icon="note"
            />
        </PageSection>
        <PageSection noDivider noMarginTop>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <FormGroup name="dateRange" label="Date" className="col-md-6">
                            <Field name="dateRange" as={FormikDatePicker} mode="range" maxDate={yesterday.toDate()} onBlur={formik.handleSubmit} />
                            <div className="date-range-options">
                                <RadioGroup selectedValue={selectedDateRangeOption} options={dateRangeOptions} onChange={handleDateRangeChanged} inlineLg3 />
                            </div>
                        </FormGroup>
                        <FormGroup name="includeDownloaded" label="Submissions to display" className="col-md-6">
                            <Field id="includedDownloaded" name="includeDownloaded" label="Include downloaded" as={FormikCheckbox} onBlur={formik.handleSubmit} />
                        </FormGroup>
                    </Row>
                </form>
            </FormikProvider>
        </PageSection>
        <PageSection noMarginTop>
            <ResponsiveTable
                isLoading={submittedEFormsStatus === APICallRequestState.LOADING || submittedEFormsStatus === APICallRequestState.PENDING}
                data={submittedEFormsData}
                columns={[
                    { label: "Submission date", getter: r => r.submissionDate },
                    { label: "Number of submissions", getter: r => r.numSubmissions },
                    { label: "Number of downloads", getter: r => r.numDownloads },
                    { label: "Form published on", getter: r => r.publishedDate },
                    { label: "", getter: r => <Link onClick={() => handleExport(r.formId, r.version, r.submissionDate)} disabled={exporting}>Export</Link> }
                ]}
            />
            <ServerError errors={errors} />
            <PaginationControl
                currentPage={currentPage}
                onPageChanged={newPage => setCurrentPage(newPage)}
                itemCount={submittedEForms?.length ?? 0}
                itemsPerPage={itemsPerPage}
            />
        </PageSection>
    </>
};

export default EFormSubmissions;
