import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";

const Text = ({ attributes: { fontColor, fontFamily, fontSize, text } }: TemplatesProps) => {
    const fontColorStyle = fontColor ?? "#FFFFFF";
    const fontFamilyStyle = fontFamily ?? "Arial";
    const fontSizeStyle = fontSize ?? "Arial";

    return (
        <div style={{ color: fontColorStyle, fontFamily: fontFamilyStyle, fontSize: fontSizeStyle }} className="eform-form eform-ctrl eform-ctrl-short eform-row formContentElement">
            <div>
                <div className="eform-ctrl-text-text">{text}</div>
            </div>
        </div>
    );
};

export default withDesignerControl(Text);
