import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import branding from 'constants/branding';
import {
    DropdownField,
    FontDropdownField,
    Form,
    CssDimensionField,
    ColourField,
    FileUploadField,
    FormErrorList,
    SubmitButton,
    validate,
} from '@premier/form';
import {
    Button,
    ButtonContainer,
    Divider,
    ErrorText,
    IconText,
    PaddedContainer,
    PageSection,
    Row,
    LoadingIndicator,
    Dialog,
    SuccessModal,
    Icon,
    LoadingOverlay,
    AccordionChild,
    AccordionGroup,
} from '@premier/ui';
import { BackofficeDeeplink, PageHeader } from 'components/Common';
import { BillerTitleDropdown } from '..';
import { PreviewTabs } from '../components/brandingPreview';
import EditBrandingMenuSection from '../components/EditBrandingMenuSection';
import regexUtil from '@premier/utils/regEx';
import { logoMaxSize, acceptedLogoTypes } from 'constants/billpay';

import { PlatformRoutesConfiguration } from 'components/Routing';
import * as designerActions from 'components/Settings/_actions/designerActions';
import './EditBrandingPage.scss';
import { RichTextEditor } from "packages/ui";

const EditBrandingPage = ({
    billerCode, // from URL param
    designerActions,
    merchant,
    billers,
    byBiller,
    brandingState,
    submitting,
    errors, // from Redux/API
}) => {
    const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);
    const [showPublishSuccessModal, setShowPublishSuccessModal] = useState(false);
    const [showDiscardSuccessModal, setShowDiscardSuccessModal] = useState(false);
    const [showConfirmDiscardDialog, setShowConfirmDiscardDialog] = useState(false);
    const [loadingText, setLoadingText] = useState(null);

    const [biller, setBiller] = useState(null);
    const [defaultPaymentPageText, setDefaultPaymentPageText] = useState('');

    const style = brandingState.data;

    useEffect(() => {
        if (style?.paymentPage?.paymentPageText) {
            setDefaultPaymentPageText(style.paymentPage.paymentPageText);
        }
    }, [style?.paymentPage?.paymentPageText]);

    useEffect(() => {
        if (billers?.length > 0)
            setBiller(billers.find((b) => !billerCode || b.billerCode === billerCode)); // Gets the first Biller if no billerCode specified
    }, [billers]);

    useEffect(() => {
        designerActions.getBranding(billerCode);

        return () => {
            designerActions.clearSaveBranding();
            designerActions.clearSaveAndPublish();
        };
    }, [billerCode]);

    function generateInitialStyles() {
        //#region --- Backward-compatibility functions ---
        // These functions are for backward compatibility with styles created from the old BackOffice designer.
        // These are safe to remove after old BackOffice is long gone.
        function getClosestFont(font) {
            if (!font || _.some(branding.fonts, { value: font })) return font;

            var fontName = font.split(',').splice(0, 1)[0];
            var closeFont = branding.fonts.map((f) => f.value).find((f) => f.startsWith(fontName));
            return closeFont || font;
        }
        //#endregion
        function unpackLogoPosition(logoPosition) {
            if (logoPosition && logoPosition.includes(' ')) return logoPosition.split(' ');

            return [logoPosition, 'center'];
        }

        return {
            ...style,
            banner: {
                ...style.banner,
                logoPositionHorizontal: unpackLogoPosition(style.banner.logoPosition)[0],
                logoPositionVertical: unpackLogoPosition(style.banner.logoPosition)[1],
                logo: {
                    // for FileUploadField
                    existingFile: {
                        name: _.get(style, 'banner.logoFilename'),
                        size: _.get(style, 'banner.logoFileSize'),
                    },
                },
            },
            page: {
                // backward compatibility
                ...style.page,
                fontFamily: getClosestFont(style.page.fontFamily),
            },
        };
    }

    function mapFormToApi(style) {
        const form = {
            ...style,
            banner: {
                ...style.banner,
                logoPosition: `${style.banner.logoPositionHorizontal} ${style.banner.logoPositionVertical}`,
            },
        }

        // If there is no logo, then we want to tell the back-end that any existing logo needs to be deleted by making logoFilename null.
        if (!form.banner.logo || _.isEmpty(form.banner.logo)) {
            form.banner.logoFilename = null;
        }

        return form;
    }

    function saveDraft(style) {
        callApiBlockUi(() => designerActions.saveBranding(billerCode, style, style.banner.logo?.newFiles)).then(() =>
            setShowSaveSuccessModal(true)
        );
    }

    function saveAndPublish(style) {
        callApiBlockUi(() => designerActions.saveAndPublish(billerCode, style, style.banner.logo?.newFiles)).then(() =>
            setShowPublishSuccessModal(true)
        );
    }

    function handleConfirmDiscard() {
        setShowConfirmDiscardDialog(false);
        callApiBlockUi(() => designerActions.discardDraft(billerCode), 'Discarding draft...').then(() =>
            setShowDiscardSuccessModal(true)
        );
    }

    /**
     * Call an API function and block UI while it is fetching, then refresh the page on success.
     * @param {function} fnApi The action to call which must return a promise
     * @param {function} onSuccess A function to run on success
     */
    function callApiBlockUi(fnApi, loadingText = 'Saving...') {
        designerActions.clearSaveBranding();
        designerActions.clearSaveAndPublish();
        designerActions.clearDiscardDraft();

        setLoadingText(loadingText);

        return new Promise((resolve, reject) => {
            fnApi()
                .then((r) => {
                    if (r.ok === false) {
                        reject();
                    } else {
                        designerActions.getBranding(billerCode); // Refresh page after successful API call
                        resolve();
                    }
                })
                .catch(() => {});
        });
    }

    // ===== Render =====

    if (!biller) {
        return (
            <PageSection>
                <PageHeader backButton />
                <ErrorText>Biller not found</ErrorText>
            </PageSection>
        );
    }

    return (
        <div className='edit-branding-page'>
            {submitting && <LoadingOverlay text={loadingText} />}

            <PageSection>
                <PageHeader
                    backButton
                    title={`Set your brand style ${billerCode ? `for ${_.get(biller, 'billerCodeWithName')}` : ''}`}
                />

                {byBiller && (
                    <BillerTitleDropdown
                        billerCode={billerCode}
                        billers={billers}
                        getUrl={PlatformRoutesConfiguration.designerRoute.editBillerBranding.generatePath}
                        defaultFirstOption={{
                            value: '',
                            label: 'Default brand style',
                        }}
                    />
                )}

                {(!style || brandingState.isLoading) && <LoadingIndicator />}

                {style && !brandingState.isLoading && (
                    <>
                        {style.isDraft && (
                            <PaddedContainer blueBorder>
                                <IconText info>
                                    These settings haven't been published yet. Please click "Save and Publish" to
                                    publish now or
                                    <br />
                                    <Button link onClick={() => setShowConfirmDiscardDialog(true)}>
                                        Discard changes
                                    </Button>
                                </IconText>
                            </PaddedContainer>
                        )}

                        <Form
                            initialValues={generateInitialStyles()}
                            initialValidation={{
                                banner: {
                                    height: validate().test(
                                        (value) => regexUtil.cssDimensionRegex.test(value),
                                        'Invalid banner height'
                                    ),
                                    logo: validate().maxFileSize(logoMaxSize, "The image is too big. Please upload a smaller image.")
                                        .acceptedFileTypes(acceptedLogoTypes, "Invalid file type submitted. Only png, gif, bmp, jpg and jpeg files can be uploaded."),
                                },
                            }}
                            errors={errors}
                            render={(context) => (
                                <>
                                    <Row>
                                        <div className='col-lg-6'>
                                            <PaddedContainer className='preview'>
                                                <h3>Preview</h3>
                                                { biller ? <PreviewTabs
                                                    compact
                                                    merchant={merchant}
                                                    biller={biller}
                                                    style={context.values}
                                                /> : <LoadingIndicator />}
                                            </PaddedContainer>
                                        </div>

                                        <div className='col-lg-6'>
                                            <AccordionGroup>
                                                <AccordionChild title='Brand font'>
                                                    <FontDropdownField
                                                        name='page.fontFamily'
                                                        label='Font type'
                                                        options={branding.fonts}
                                                    />
                                                </AccordionChild>
                                                <Divider />
                                                <AccordionChild title='Return bar'>
                                                    This section will only appear on your payment page if you have
                                                    integrated using one of the API AuthKey methods and have implemented
                                                    the Return Bar.
                                                    <Row>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='returnBar.fontColour'
                                                                label='Font colour'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='returnBar.backColour'
                                                                label='Background colour'
                                                            />
                                                        </div>
                                                    </Row>
                                                    <CssDimensionField
                                                        name='returnBar.height'
                                                        label='Return bar height'
                                                        suffix='px'
                                                    />
                                                </AccordionChild>
                                                <Divider />
                                                <AccordionChild title='Banner'>
                                                    <Row>
                                                        <div className='col-sm-6'>
                                                            <CssDimensionField
                                                                name='banner.height'
                                                                label='Banner height'
                                                                suffix='px'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='banner.backColour'
                                                                label='Background colour'
                                                            />
                                                        </div>
                                                    </Row>
                                                    <FileUploadField
                                                        name='banner.logo'
                                                        label='Logo'
                                                        buttonText={
                                                            _.get(context.values, 'banner.logoUrl') ||
                                                            _.get(context.values, 'banner.logo.newFiles.length')
                                                                ? 'Reupload logo'
                                                                : 'Upload logo'
                                                        }
                                                        deleteButtonText="Delete logo"
                                                    />
                                                    <Row>
                                                        <div className='col-sm-6'>
                                                            <DropdownField
                                                                name='banner.logoPositionHorizontal'
                                                                label='Position (Horizontal)'
                                                                options={[
                                                                    { value: 'left', label: 'Left' },
                                                                    { value: 'center', label: 'Center' },
                                                                    { value: 'right', label: 'Right' },
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <DropdownField
                                                                name='banner.logoPositionVertical'
                                                                label='Position (Vertical)'
                                                                options={[
                                                                    { value: 'top', label: 'Top' },
                                                                    { value: 'center', label: 'Center' },
                                                                    { value: 'bottom', label: 'Bottom' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </Row>
                                                </AccordionChild>
                                                <Divider />

                                                <AccordionChild title='Menu'>
                                                    <EditBrandingMenuSection style={context.values} errors={errors} />
                                                </AccordionChild>
                                                <Divider />

                                                <AccordionChild title='Page'>
                                                    <Row>
                                                        <div className='col-sm-6'>
                                                            <ColourField name='page.fontColour' label='Font colour' />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='page.backColour'
                                                                label='Background colour'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='page.primaryButtonFontColour'
                                                                label='Primary button font colour'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='page.primaryButtonColour'
                                                                label='Primary button colour'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='page.secondaryButtonFontColour'
                                                                label='Secondary button font colour'
                                                            />
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ColourField
                                                                name='page.secondaryButtonColour'
                                                                label='Secondary button colour'
                                                            />
                                                        </div>
                                                    </Row>
                                                </AccordionChild>
                                                <Divider />

                                                <AccordionChild title='Payment page text'>
                                                    <Row>
                                                        <RichTextEditor
                                                            hasError={false}
                                                            initialValue={defaultPaymentPageText}
                                                            name='paymentPage.paymentPageText'
                                                            onChange={(value) => { context.setValue('paymentPage.paymentPageText', value); }}
                                                            disabled={false}
                                                            toolbar={branding.designerEditorToolbar}
                                                            plugins={branding.designerEditorPlugins}
                                                        />
                                                    </Row>

                                                    {errors?.length
                                                        ? errors.map((err, i) =>
                                                            err.field === 'paymentPage.paymentPageText'
                                                                ? <ErrorText key={i}>{err.message}</ErrorText>
                                                                : null
                                                        )
                                                        : null}
                                                </AccordionChild>
                                            </AccordionGroup>

                                            <ButtonContainer justify divider>
                                                <SubmitButton
                                                    onSubmit={() => saveAndPublish(mapFormToApi(context.values))}
                                                >
                                                    Save and publish
                                                </SubmitButton>
                                                <SubmitButton
                                                    defaultStyle
                                                    onSubmit={() => saveDraft(mapFormToApi(context.values))}
                                                >
                                                    Save draft
                                                </SubmitButton>
                                            </ButtonContainer>
                                            {!billerCode && (
                                                // Only show deeplink on the default payment page, eg the billerCode prop is undefined
                                                <BackofficeDeeplink
                                                    preLinkMessage='To edit text on all pages within the default payment page for this merchant, click'
                                                    path='Designer'
                                                    linkText='here'
                                                    periodAfterLink
                                                    postLinkMessage='You may be asked to sign in again.'
                                                    topMargin
                                                />
                                            )}

                                            <FormErrorList />
                                        </div>
                                    </Row>
                                </>
                            )}
                        />
                    </>
                )}
            </PageSection>

            <SuccessModal
                show={showSaveSuccessModal}
                onClose={() => setShowSaveSuccessModal(false)}
                title='Draft saved successfully'
            />

            <SuccessModal
                show={showPublishSuccessModal}
                onClose={() => setShowPublishSuccessModal(false)}
                title='Style published successfully'
            />

            <Dialog
                show={showConfirmDiscardDialog}
                onClose={() => setShowConfirmDiscardDialog(false)}
                icon={<Icon info />}
                title='Discard changes?'
                footerButtons={
                    <>
                        <Button primary onClick={handleConfirmDiscard}>
                            Confirm
                        </Button>
                        <Button onClick={() => setShowConfirmDiscardDialog(false)}>Cancel</Button>
                    </>
                }
            >
                The brand style settings for {_.get(biller, 'billerCodeWithName')} will be reset to the latest published
                version. You will not be able to recover your current draft.
            </Dialog>

            <SuccessModal
                show={showDiscardSuccessModal}
                onClose={() => setShowDiscardSuccessModal(false)}
                title='Draft discarded successfully'
            />
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    let billerCode = ownProps.match.params.billerCode;

    let saveSubmission = state.settings.designer.brandingSubmission;
    let publishSubmission = state.settings.designer.savePublishSubmission;
    let discardSubmission = state.settings.designer.discardDraftSubmission;

    return {
        merchant: state.accounts.users.merchant,
        billers: state.accounts.users.billers,
        billerCode,
        byBiller: state.accounts.users.merchant.brandingByBiller,
        brandingState: state.settings.designer.branding,
        submitting: saveSubmission.isLoading || publishSubmission.isLoading || discardSubmission.isLoading,
        errors: saveSubmission.errors || publishSubmission.errors || discardSubmission.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        designerActions: bindActionCreators(designerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBrandingPage);
