import globalAxios from "axios";
import { checkSessionTimeOut } from "api/util";

const init = () => {
    console.debug("init axios interceptors");

    globalAxios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            checkSessionTimeOut(error.response.status);
            return Promise.reject(error);
        }
    );
};

export default { init };
