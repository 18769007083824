import classNames from "classnames";

// @ts-ignore
import { WalletTypes } from "constants/billpay";

import "./WalletLogo.scss";

import amexwalletLogo from "images/wallet-logo-amex.svg";
import applepayLogo from "images/wallet-logo-apple.svg";
import masterpassLogo from "images/wallet-logo-mastercard.svg";
import googlepayLogo from "images/wallet-logo-google.png";
import { WalletTypeDetails } from "packages/webapi-client";

type Props = {
    wallet: WalletTypeDetails;
    className?: string;
}

const WalletLogo = ({
    wallet, //data
    className
}: Props) => {
    if (!wallet.key)
        return <></>;

    const imgSrc = ((x) => {
        switch (x) {
            case WalletTypes.MASTERPASS:
                return masterpassLogo;
            case WalletTypes.AMEX_WALLET:
                return amexwalletLogo;
            case WalletTypes.APPLEPAY:
                return applepayLogo;
            case WalletTypes.GOOGLEPAY:
                return googlepayLogo;
            default:
                throw new Error(`No logo for network '${x}'`);
        }
    })(wallet.key);

    const usedClassName = className ?? classNames("card-logo");

    return <img src={imgSrc} alt={wallet.description} className={usedClassName} />;
};

export default WalletLogo;
