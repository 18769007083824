import { useContext, useMemo } from "react";
import { EFormsContext } from "../contexts/EFormsContext";
import { genders, days, months } from "../constants";
import { Special } from "../enums";
import { getLast100Years } from "../helpers";
import { ControlAttributes } from "../types";

const useSpecials = (attributes: ControlAttributes) => {
    const { countries, timeZones } = useContext(EFormsContext);

    const options = useMemo(() => {
        if (attributes.special === Special.Gender) {
            return genders.map((x) => ({ value: x, label: x }));
        } else if (attributes.special === Special.Days) {
            return days.map((x) => ({ value: x, label: x }));
        } else if (attributes.special === Special.Months) {
            return months.map((x) => ({ value: x, label: x }));
        } else if (attributes.special === Special.Last100Years) {
            return getLast100Years().map((x) => ({ value: x, label: x }));
        } else if (attributes.special === Special.Countries) {
            return countries?.map((x) => ({ value: x.name ?? "", label: x.name ?? "" })) ?? [];
        } else if (attributes.special === Special.TimeZones) {
            return timeZones?.map((x) => ({ value: x.location ?? "", label: x.location ?? "" })) ?? [];
        }

        return attributes.options?.map((option, index) => ({
            value: option,
            label: option,
        })) ?? [];
    }, [attributes.options, attributes.special, countries, timeZones]);

    return { options };
};

export default useSpecials;
