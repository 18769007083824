import { useState } from "react";
import withDesignerControl from "./withDesignerControl";
import { TemplatesProps } from "../types";
import Label from "./components/Label";
import Input from "./components/Input";
import { validateInputByValidationFormat, validateRequiredInput } from "components/EForms/helpers";
import classNames from "classnames";

const Crn = ({ name, attributes }: TemplatesProps) => {
    const [valid, setValid] = useState(true);

    const inputValidation = (value: string, setError: React.Dispatch<React.SetStateAction<string>>, attributes: any) => {
        let validationResult: boolean = true;

        if (attributes.required) {
            validationResult = validateRequiredInput(value, setError);

            if (validationResult && attributes.validationFormat) {
                validationResult = validateInputByValidationFormat(value, setError, attributes.validationFormat);
            }
        } else if (attributes.validationFormat) {
            validationResult = validateInputByValidationFormat(value, setError, attributes.validationFormat);
        }

        setValid(validationResult);
    };
    return (
        <div className={classNames("eform-form eform-ctrl eform-ctrl-short eform-row formContentElement", { "validation-error": !valid })}>
            <Label name={name} attributes={attributes} />
            <Input attributes={attributes} inputValidation={inputValidation} />
        </div>
    );
};

export default withDesignerControl(Crn);